@charset "UTF-8";

:root {
  --brand_color: #4c4cf1;
  --secondary_color: #1d2746;
  --p_color: #425466;
  --gradient_bg_color1: var(--gradient_bg_color-from, #fffbf2);
  --gradient_bg_color2: var(--gradient_bg_color-to, #edfffd);
  --border_on_white: #e6edf0;
  --border_on_highlight: #e6edf0;
  --bs-white: #fff;
  --black_25: #f9f9f9;
  --black_50: #ebebeb;
  --black_100: #d6d9dc;
  --black_150: #c8ccd0;
  --black_200: #bbc0c4;
  --black_300: #9fa6ad;
  --black_350: #9199a1;
  --black_400: #848d95;
  --black_500: #6a737c;
  --black_600: #535a60;
  --black_700: #3c4146;
  --black_750: #2f3337;
  --black_800: #242729;
  --black_900: #0c0d0e;
  --bs_sm: 0 1px 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 5%), 0 2px 8px rgb(0 0 0 / 5%);
  --bs_md: 0 1px 3px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 6%), 0 3px 8px rgb(0 0 0 / 9%);
  --bs_lg: 0 1px 4px rgb(0 0 0 / 9%), 0 3px 8px rgb(0 0 0 / 9%), 0 4px 13px rgb(0 0 0 / 13%);
  --body_bg: #ffffff;
}

/**=== Functions ===**/
.col-md-offset-right-1,
.col-lg-offset-right-1 {
  margin-right: 8.33333333%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: var(--black_800);
  line-height: 1.3;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
html {
  height: 100%;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: var(--brand_color);
}

body {
  line-height: 28px;
  font-size: 16px;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--p_color);
  letter-spacing: 0.15px;
}

p {
  color: var(--p_color);
}

:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: var(--black_800);
}

a {
  color: var(--brand_color);
}

figure {
  padding-top: 5px;
  padding-bottom: 5px;
  clear: both;
  border: none;
}

figure figcaption {
  color: rgba(0, 0, 0, 0.54);
  line-height: 20px;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  max-width: 728px;
  margin-right: auto;
}

video {
  max-width: 100%;
}

table tr td p:last-child {
  margin-bottom: 0;
}

pre[class*=language-]>code {
  border-left: 5px solid rgba(76, 76, 241, 0.8);
}

.pt_100 {
  padding-top: 100px;
}

.pb_100 {
  padding-bottom: 100px;
}

/** === Scrollbar === **/
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 30px;
}

/** === Predefined Global Classes === **/
.h1 {
  font-size: 50px;
}

.h2 {
  font-size: 44px;
}

.h3 {
  font-size: 36px;
}

.h4 {
  font-size: 30px;
}

.h5 {
  font-size: 24px;
}

.h6 {
  font-size: 18px;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.shortcode_info h1,
.shortcode_info h2,
.shortcode_info h3,
.shortcode_info h4,
.shortcode_info h5,
.shortcode_info h6,
.blog_single_item h1,
.blog_single_item h2,
.blog_single_item h3,
.blog_single_item h4,
.blog_single_item h5,
.blog_single_item h6 {
  margin-bottom: 30px;
}

button:focus {
  outline: none;
}

/** === Container === **/
.body_wrapper {
  position: relative;
  background: #fff;
  z-index: 30;
  overflow: hidden;
  min-height: 100vh;
}

@media (min-width: 1200px) {
  .wide-container .container {
    max-width: 1200px;
  }
}

@media (min-width: 1300px) {
  .wide-container .container {
    max-width: 1240px;
  }
}

@media (min-width: 1500px) {
  .wide-container .container {
    max-width: 1470px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .doc_community_area .container {
    max-width: 960px;
  }
}

@media (min-width: 991px) {
  .forum_container {
    max-width: 1830px;
  }
}

.sec_pad {
  padding: 100px 0;
}

.pl-40 {
  padding-left: 40px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.p_125 {
  padding-top: 125px;
  padding-bottom: 125px;
}

.pt_125 {
  padding-top: 125px;
}

.pb_125 {
  padding-bottom: 125px;
}

.mt-40 {
  margin-top: 40px;
}

/** === List style === **/
ul.list-style {
  margin-bottom: 2rem;
}

ul.list-style li {
  margin-bottom: 0.5rem;
  list-style-type: circle;
}

ul.list-style li:last-child {
  margin-bottom: 0;
}

/** === Key === **/
.key,
kbd {
  line-height: 0.95rem;
  border: 1px solid #ddd;
  color: #6b707f;
}

.key,
kbd,
p .key,
p kbd {
  display: inline;
  display: inline-block;
  min-width: 1em;
  padding: 0.5em 0.6em 0.4em;
  margin: 2px;
  font-weight: 400;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-decoration: none;
  line-height: 0.6rem;
  -moz-border-radius: 0.3em;
  -webkit-border-radius: 0.3em;
  border-radius: 0.3em;
  cursor: default;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #ddd;
}

/** ===== section title scss ====== **/
.section_title {
  margin-bottom: 56px;
}

.section_title p {
  max-width: 530px;
  margin: 10px auto 0;
}

.section_title.text-left p {
  max-width: inherit;
}

.section_title.title-img {
  margin-bottom: 76px;
}

.section_title.title-img .title-lg {
  font-size: 44px;
  line-height: 1.1;
  color: #1d2746;
  margin-bottom: 30px;
  font-weight: 400;
}

.section_title.title-img .action_btn {
  padding: 9px 34px;
  box-shadow: none;
  border: 2px solid var(--brand_color);
  font-weight: 500;
}

.section_title.title-img .action_btn:hover {
  background: transparent;
  color: var(--brand_color);
}

.h_title {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
  font-weight: 700;
  position: relative;
}

@media (max-width: 440px) {
  .h_title br {
    display: none;
  }
}

.c_head {
  font-size: 24px;
  line-height: 26px;
}

/*===== section title scss ======*/
.bg_color {
  background: var(--black_25);
}

.f_bg_color {
  background: #f6fbfc;
}

.local-video-container {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: content-box;
}

/** === Back to Top Button === **/
#back-to-top {
  display: inline-block;
  background-color: rgba(220, 220, 220, 0.8);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#back-to-top::after {
  content: "2";
  font-family: eleganticons;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #000;
}

#back-to-top:hover {
  cursor: pointer;
  background-color: #333;
}

#back-to-top:hover::after {
  color: #fff;
}

#back-to-top:active {
  background-color: #555;
}

#back-to-top.show {
  opacity: 1;
  visibility: visible;
}

.search-focused .click_capture {
  opacity: 1;
  visibility: visible;
}

.search-focused .banner_search_form {
  position: relative;
  z-index: 999 !important;
}

@media (min-width: 1500px) {
  .custom_container2 {
    max-width: 1470px;
  }
}

.box_pad {
  max-width: 100%;
  padding: 0 60px;
}

.visible {
  opacity: 1 !important;
}

/* Title Area css
============================================================================================ */
.main_title {
  margin-bottom: 60px;
}

.main_title h2 {
  font-size: 30px;
  color: var(--black_800);
  margin-bottom: 0;
}

.main_title p {
  margin-bottom: 0;
  margin-top: 12px;
  color: #6b707f;
}

/* End Title Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.action_btn {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  box-shadow: 0 20px 30px 0 rgba(76, 76, 241, 0.24);
  border-radius: 4px;
  background: var(--brand_color);
  display: inline-block;
  padding: 14px 28px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.18s ease-in-out;
}

.action_btn i {
  vertical-align: middle;
  font-size: 18px;
  padding-left: 5px;
}

.action_btn:hover {
  box-shadow: none;
  color: #fff;
}

.error_area .action_btn {
  font-size: 16px;
}

.error_area .action_btn i {
  padding-right: 5px;
}

.doc_border_btn {
  border: 2px solid rgba(76, 76, 241, 0.3);
  border-radius: 4px;
  color: var(--brand_color);
  font-size: 14px;
  font-weight: 500;
  background: rgba(76, 76, 241, 0.05);
  display: inline-block;
  padding: 6px 34px;
  transition: all 0.3s linear, color 0.2s;
}

.doc_border_btn.btn-round {
  border-radius: 30px;
}

@media (max-width: 420px) {
  .doc_border_btn.btn-round {
    font-size: 12px;
  }
}

.doc_border_btn i {
  margin-left: 6px;
  font-size: 16px;
  vertical-align: middle;
  display: inline-block;
  transition: all 0.2s linear, color 0s linear;
}

.doc_border_btn:hover {
  box-shadow: 0 10px 20px 0 rgba(76, 76, 241, 0.2);
  background: var(--brand_color);
  border-color: var(--brand_color);
  color: #fff;
}

.doc_border_btn:hover i {
  transform: translateX(8px);
}

.download-btn {
  background-image: -moz-linear-gradient(90deg, rgb(25, 32, 48) 0%, rgb(76, 89, 119) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(25, 32, 48) 0%, rgb(76, 89, 119) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(25, 32, 48) 0%, rgb(76, 89, 119) 100%);
  color: #242424;
  text-align: right;
  width: 300px;
  height: 80px;
  padding-right: 40px;
  display: inline-block;
  font-size: 18px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.download-btn::before {
  content: "";
  background-image: -moz-linear-gradient(90deg, rgb(175, 188, 208) 0%, rgb(237, 241, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(175, 188, 208) 0%, rgb(237, 241, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(175, 188, 208) 0%, rgb(237, 241, 255) 100%);
  position: absolute;
  right: -20px;
  top: 0;
  width: 225px;
  height: 80px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 25px;
  transform: skewX(-25deg);
  z-index: -1;
}

.download-btn .btn-icon {
  display: inline-block;
  padding-left: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.download-btn .btn-icon img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.download-btn .btn-txt {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
}

.download-btn .btn-txt .title {
  font-size: 24px;
  font-weight: 600;
  background: -webkit-linear-gradient(-90deg, #20273a, #4e5c7a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.download-btn .btn-txt .prop span {
  font-size: 14px;
  color: #5b6483;
  font-weight: 400;
}

.download-btn .btn-txt .prop span+span {
  color: #0066fe;
}

.download-btn .ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.download-btn .ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.download-btn .ribbon::before,
.download-btn .ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}

.download-btn .ribbon.ribbon-top-right::before,
.download-btn .ribbon.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.download-btn .ribbon.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.download-btn .ribbon.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.download-btn .ribbon.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.download-btn .ribbon.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* Home Advanced Search Area css
============================================================================================ */
.main_btn {
  background: var(--brand_color);
  color: #fff;
  border-radius: 4px;
  line-height: 46px;
  padding: 0 30px;
  font-size: 16px;
  transition: all 300ms linear 0s;
  display: inline-block;
}

.main_btn:hover {
  background: #fff;
}

.main_btn.blue {
  background: rgba(76, 76, 241, 0.2);
  color: var(--brand_color);
  line-height: 50px;
  padding: 0 46px;
}

.main_btn.blue:hover {
  background: var(--brand_color);
  color: #fff;
}

.submit_btn {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background: var(--brand_color);
  padding: 10px 29px;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 10px 20px 0 rgba(2, 2, 59, 0.2);
  transition: all 300ms linear 0s;
}

.submit_btn:hover {
  background: #fff;
  color: var(--brand_color);
  font-size: 16px;
  font-weight: 400;
}

.text_btn {
  color: var(--brand_color);
  font-weight: 500;
  font-size: 16px;
  transition: all 300ms linear 0s;
}

.text_btn i {
  position: relative;
  top: 4px;
  font-size: 18px;
  transition: all 300ms linear 0s;
}

.text_btn:hover {
  color: var(--brand_color);
}

.text_btn:hover i {
  margin-left: 7px;
}

.text_btn.dark_btn {
  color: var(--black_800);
  font-weight: 400;
}

.text_btn.dark_btn i {
  margin-left: 6px;
  font-size: 18px;
}

.text_btn.dark_btn:hover {
  color: var(--brand_color);
}

.text_btn.dark_btn:hover i {
  margin-left: 12px;
}

.icon_btn2 {
  background: var(--brand_color);
  color: #fff;
  border-radius: 5px;
  line-height: 60px;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  padding: 0 44px;
  transition: all 300ms linear 0s;
}

.icon_btn2 i {
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-left: 10px;
  transition: all 300ms linear 0s;
}

.icon_btn2:hover {
  background: #fff;
  color: var(--brand_color);
}

.icon_btn2:hover i {
  margin-left: 5px;
}

.icon_btn2.blue:hover {
  color: var(--brand_color);
}

.reply_btn {
  background: #4c4cf1;
  display: inline-block;
  padding: 0 12px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 3px;
  border: 1px solid #4c4cf1;
  transition: all 300ms linear 0s;
}

.reply_btn:hover {
  background: transparent;
  color: #4c4cf1;
}

.help_btn {
  background: transparent;
  display: inline-block;
  padding: 0 16px;
  color: var(--black_800);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 3px;
  border: 1px solid #d0d8dc;
  transition: all 300ms linear 0s;
}

.help_btn:hover {
  background: transparent;
  color: #4c4cf1;
}

/* Home Advanced Search Area css
  ============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*================= Top header area css ===============*/
.header_top {
  background: #192341;
  color: #8c93a8;
  height: 40px;
}

.header_top .menu {
  display: flex;
  align-items: center;
  height: 40px;
}

.header_top .menu li {
  margin-right: 50px;
}

.header_top .menu li:last-child {
  margin-right: 0;
}

.header_top .menu li a {
  color: #8c93a8;
  font-size: 14px;
}

.header_top .menu li a:hover {
  color: var(--brand_color);
}

.header_top .right-menu {
  justify-content: flex-end;
}

.header_top .right-menu li {
  position: relative;
  margin-right: 0;
  padding-right: 15px;
}

.header_top .right-menu li::after {
  position: absolute;
  content: "";
  background: #5a6279;
  height: 13px;
  width: 1px;
  margin: 0 15px;
  top: 5px;
}

.header_top .right-menu li:last-child {
  padding-right: 0;
  padding-left: 15px;
}

.header_top .right-menu li:last-child::after {
  display: none;
}

.header_area.m_menu_two .navbar .navbar-nav>li>a {
  font-size: 16px;
  font-weight: 400;
}

.header_area.m_menu_two .navbar .navbar-nav>li>a:before {
  display: none;
}

.header_area.m_menu_two .navbar .navbar-nav>li.hover a,
.header_area.m_menu_two .navbar .navbar-nav>li.active a {
  color: var(--brand_color);
}

.header_area.m_menu_two .navbar .navbar-nav>li .dropdown-menu li:hover a,
.header_area.m_menu_two .navbar .navbar-nav>li .dropdown-menu li.active a {
  color: var(--brand_color);
}

.header_area.m_menu_two .navbar .navbar-nav>li .dropdown-menu li:hover a:before,
.header_area.m_menu_two .navbar .navbar-nav>li .dropdown-menu li.active a:before {
  background: var(--brand_color);
}

/*================= header area css ===============*/
.navbar {
  border: 0;
  border-radius: 0;
  padding: 0;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 20;
  position: absolute;
}

.navbar .search-input {
  position: relative;
  margin-left: 4rem;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 300px;
}

.navbar .search-input .search-icon {
  position: absolute;
  left: 10px;
  top: 11px;
}

.navbar .search-input .search-input__clear {
  width: 18px;
  height: 18px;
  background-color: #e3e9f3;
  right: 12px;
}

.navbar .search-input input {
  width: 100%;
  font-weight: 400;
  border: 0;
  outline: 0;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  -webkit-appearance: none;
  vertical-align: middle;
  font-size: 13px;
  line-height: 18px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #f6f7f9;
  height: 39px;
  text-indent: 0;
}

.menu_one {
  background: transparent;
  transition: all 0.2s linear;
}

.header_logo img:not(.main_logo),
.header_logo img.sticky_logo {
  display: none;
}

.header_logo img.main_logo {
  display: block;
}

.dark_menu {
  box-shadow: none;
}

.dark_menu .menu.justify-content-center {
  width: 100%;
}

.dark_menu .menu>.nav-item>.nav-link {
  color: var(--black_800);
  font-size: 14px;
  font-weight: 500;
}

.dark_menu .menu>.nav-item>.nav-link:before {
  display: none;
}

.dark_menu .menu>.nav-item:hover>.nav-link,
.dark_menu .menu>.nav-item.active>.nav-link {
  color: var(--brand_color);
}

.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link:before {
  background: var(--brand_color);
}

.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link,
.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link,
.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link {
  color: var(--brand_color);
}

.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link h5,
.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link h5,
.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link h5 {
  color: var(--brand_color);
}

.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>i,
.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:focus>i,
.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item.active>i {
  color: var(--brand_color);
}

.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item {
  padding: 0 30px;
}

.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:after {
  content: "5";
  color: var(--brand_color);
}

.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:after,
.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:after {
  opacity: 1;
  right: 20px;
}

.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover .nav-link,
.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active .nav-link {
  color: var(--brand_color);
}

.dark_menu .right-nav .nav_btn.tp_btn {
  border-color: rgba(76, 76, 241, 0.15);
  color: var(--brand_color) !important;
}

.dark_menu .right-nav .nav_btn.tp_btn:hover {
  color: #fff !important;
  background: var(--brand_color);
}

.menu>.nav-item {
  padding-bottom: 37px;
  padding-top: 37px;
  transition: all 0.3s linear;
}

.menu>.nav-item>.nav-link {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0;
}

.menu>.nav-item>.nav-link:after {
  display: none;
}

.menu>.nav-item.submenu .dropdown-menu {
  margin: 0;
  border-radius: 4px;
  left: -30px;
  min-width: max-content;
  border: 1px solid var(--black_50);
  background: var(--bs-white);
  padding: 20px 0;
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
}

.menu>.nav-item.submenu .dropdown-menu:before {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--bs-white);
  position: absolute;
  top: -6px;
  transform: rotate(45deg);
  left: 40px;
  border-left: 1px solid var(--black_50);
  border-top: 1px solid var(--black_50);
  z-index: 1;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu .dropdown-menu {
    transform: translateY(20px);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu>.nav-item.submenu .dropdown-menu.dropdown_menu_two .nav-item {
  padding: 0 30px;
}

.menu>.nav-item.submenu .dropdown-menu.dropdown_menu_two .nav-item+.nav-item {
  margin-top: 8px;
}

.menu>.nav-item.submenu .dropdown-menu.dropdown_menu_two .nav-item .nav-link {
  display: flex;
  align-items: center;
}

.menu>.nav-item.submenu .dropdown-menu.dropdown_menu_two .nav-item .nav-link:before {
  display: none;
}

.menu>.nav-item.submenu .dropdown-menu.dropdown_menu_two .nav-item .nav-link img {
  margin-right: 20px;
  width: 32px;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item {
  padding: 0 40px 0 30px;
  transition: all 0.3s linear;
  position: relative;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item+.nav-item {
  margin-top: 18px;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link {
  padding: 0;
  white-space: nowrap;
  font: 400 14px/16px "Roboto", sans-serif;
  color: var(--black_700);
  transition: color 0.2s linear;
  position: relative;
  display: inline-block;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link:after {
  display: none;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--brand_color);
  transition: all 0.2s linear;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link h5 {
  padding-top: 9px;
  margin-bottom: 0;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  transition: all 0.2s linear;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link p {
  margin-bottom: 0;
  line-height: 29px;
  font-size: 14px;
  padding-bottom: 0;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link,
.menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link,
.menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link {
  color: var(--brand_color);
}

.menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link:before,
.menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link:before,
.menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link:before {
  width: 100%;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link h5,
.menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link h5,
.menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link h5 {
  color: var(--brand_color);
}

.menu>.nav-item.submenu .dropdown-menu .nav-item:hover>i,
.menu>.nav-item.submenu .dropdown-menu .nav-item:focus>i,
.menu>.nav-item.submenu .dropdown-menu .nav-item.active>i {
  color: var(--brand_color);
}

.menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu {
  transition: all 0.3s ease-in;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu:before {
  display: none;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu {
    position: absolute;
    left: 100%;
    top: -25px;
    opacity: 0;
    display: block;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.3s ease-in;
  }
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu {
  min-width: 540px;
  padding: 0;
  background: transparent;
  max-height: 309px;
  min-height: 309px;
  border: none;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu li .col-lg-7 {
  padding-left: 0;
  margin-left: -5px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu li>div {
  min-height: 309px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .nav-pills {
  padding: 30px 0;
  background: var(--bs-white);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  margin-right: -10px;
  border-top: 1px solid var(--black_50);
  border-bottom: 1px solid var(--black_50);
  border-right: 0;
  border-left: 1px solid var(--black_50);
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item {
  padding: 0 30px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:after {
  content: "5";
  position: absolute;
  right: 25px;
  top: -1px;
  font-family: "ElegantIcons";
  display: block;
  color: var(--brand_color);
  transition: all 0.2s linear;
  opacity: 0;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item .nav-link {
  font-size: 14px;
  color: var(--p_color);
  display: inline-block;
  border-radius: 0;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item .nav-link.active {
  background: transparent;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item+.nav-item {
  margin-top: 12px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:after,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:after {
  opacity: 1;
  right: 20px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover .nav-link,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active .nav-link {
  color: var(--brand_color);
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(2) .nav-link::before,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(2) .nav-link::before {
  background: #b03532;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(3) .nav-link::before,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(3) .nav-link::before {
  background: #59a627;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(4) .nav-link::before,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(4) .nav-link::before {
  background: #da6f2b;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(5) .nav-link::before,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(5) .nav-link::before {
  background: var(--black_800);
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(6) .nav-link::before,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(6) .nav-link::before {
  background: #00aa6a;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(2) .nav-link,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(2) .nav-link {
  color: #b03532;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(3) .nav-link,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(3) .nav-link {
  color: #59a627;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(4) .nav-link,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(4) .nav-link {
  color: #da6f2b;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(5) .nav-link,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(5) .nav-link {
  color: var(--black_800);
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:nth-child(6) .nav-link,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:nth-child(6) .nav-link {
  color: #00aa6a;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane {
  padding: 26px 10px 26px 25px;
  min-height: 309px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list {
  width: 50%;
  padding-bottom: 12px;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list.w_100 {
  width: 100%;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list li {
  margin-bottom: 12px;
  display: block;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list li a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  position: relative;
  font-family: "Roboto", sans-serif;
  padding: 0;
  overflow: visible;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list li a:before {
  content: "";
  width: 0;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.2s linear;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list li a:hover:before,
.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .tab_list li a.active:before {
  width: 100%;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane .text a {
  display: block;
  padding: 0;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane p {
  font-size: 14px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane:first-child {
  background: var(--brand_color);
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane:nth-child(2) {
  background: #b03532;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane:nth-child(3) {
  background: #59a627;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane:nth-child(4) {
  background: #da6f2b;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane:nth-child(5) {
  background: #1d2746;
}

.menu>.nav-item.submenu.mega_menu .dropdown-menu .tabContent .tab-pane:nth-child(6) {
  background: #00aa6a;
}

.menu>.nav-item.active>i {
  color: var(--brand_color);
}

.menu>.nav-item.active .nav-link {
  color: var(--brand_color);
}

.menu>.nav-item.active .nav-link:before {
  transform: scaleX(1);
  opacity: 1;
  background: #209ff0;
}

.menu>.nav-item:hover>i {
  color: var(--brand_color);
}

.menu>.nav-item:hover .nav-link {
  color: var(--brand_color);
}

.menu>.nav-item:hover .nav-link:before {
  transform: scaleX(1);
  opacity: 1;
  background: #209ff0;
}

@media (min-width: 992px) {
  .menu>.nav-item:hover .dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .menu>.nav-item:hover .dropdown-menu>.nav-item:hover .dropdown-menu {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

.menu>.nav-item+.nav-item {
  margin-left: 40px;
}

.mobile-dark-mode ion-icon {
  font-size: 26px;
  color: #9fa6ad;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 55px;
  top: 32px;
  transform: translateY(-48%) rotate(235deg);
}

.mobile-dark-mode ion-icon.dark-mode {
  display: none;
}

.mobile-dark-mode input {
  display: none;
}

.right-nav {
  margin-left: 80px;
  display: flex;
  align-items: center;
}

.right-nav ion-icon {
  font-size: 23px;
  color: #9fa6ad;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.right-nav .nav_btn {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 27px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #fff;
  transition: all 0.3s linear;
  color: var(--brand_color);
}

.right-nav .nav_btn.round-btn {
  border-radius: 50px;
  background-color: rgba(16, 179, 214, 0.031);
}

.right-nav .nav_btn.icon-btn {
  border-color: rgba(76, 76, 241, 0.3);
}

.right-nav .nav_btn.icon-btn i {
  color: rgba(76, 76, 241, 0.3);
  padding-right: 10px;
}

.right-nav .nav_btn:hover {
  background: var(--brand_color);
  border-color: var(--brand_color);
  color: #fff;
}

.right-nav .nav_btn:hover i {
  color: #fff;
}

.right-nav .nav_btn.tp_btn {
  background: transparent;
  border: 2px solid #fff;
  color: #fff !important;
}

.mCSB_inside>.mCSB_container {
  margin-right: 0;
}

/*================= header area css ===============*/
/*=========== dk_menu css ===========*/
.menu_two {
  box-shadow: 0 4px 10px 0 rgba(76, 76, 241, 0.06);
  z-index: 10;
  background: #fff;
}

.menu_two .nav_btn {
  border: 2px solid rgba(76, 76, 241, 0.3);
  color: var(--brand_color);
  background: rgba(76, 76, 241, 0.05);
}

.menu_two .nav_btn i {
  padding-right: 8px;
  font-size: 12px;
}

.menu_two .nav_btn:hover {
  background: var(--brand_color);
  color: #fff;
  border-color: var(--brand_color);
}

.menu_two .menu_toggle .hamburger span,
.menu_two .menu_toggle .hamburger-cross span {
  background: #1d2746;
}

.menu_two+div,
.menu_two+section {
  margin-top: 98px;
}

.dk_menu>.nav-item>.nav-link {
  color: #6b707f;
}

.dk_menu>.nav-item>.nav-link i {
  padding-right: 5px;
  font-size: 14px;
}

.mobile_dropdown_icon {
  display: none;
}

/*=========== dk_menu css ===========*/
@media (max-width: 991px) {
  .display_none {
    display: none;
  }
}

.sticky-nav-doc .navbar-brand img+img {
  display: none;
}

.sticky-nav-doc .mobile_main_menu .menu_toggle .hamburger span {
  background: #1d2746;
}

.mobile_main_menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 13px 0;
}

@media (min-width: 992px) {
  .mobile_main_menu {
    display: none;
  }
}

.mobile_main_menu .logo img {
  max-width: 80%;
  margin-left: 5px;
}

.mobile_main_menu .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_main_menu .menu_toggle {
  margin-right: 18px;
}

.mobile_main_menu .menu_toggle .hamburger span {
  width: 100%;
}

.mobile_main_menu .mobile_menu_left {
  display: flex;
}

.mobile_main_menu .nav_btn {
  margin: 0;
}

.mobile_main_menu.navbar_fixed {
  box-shadow: 0 4px 10px 0 rgba(76, 76, 241, 0.06);
  background: #fff;
  transition: all 0.2s, top 0.4s linear;
}

.mobile_main_menu.navbar_fixed .nav_btn {
  border-color: #6b707f;
}

.mobile_main_menu.navbar_fixed .nav_btn:hover {
  border-color: var(--brand_color);
}

/*============ Start menu_purple css ==============*/
/*============ menu_purple css ==============*/
.menu_purple .menu>.nav-item>.nav-link {
  position: relative;
}

.menu_purple .menu>.nav-item>.nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  transition: width 0.2s linear;
}

.menu_purple .menu>.nav-item:hover>.nav-link,
.menu_purple .menu>.nav-item.current_page_parent>.nav-link,
.menu_purple .menu>.nav-item.active>.nav-link {
  color: #fff;
}

.menu_purple .menu>.nav-item:hover>.nav-link::before,
.menu_purple .menu>.nav-item.current_page_parent>.nav-link::before,
.menu_purple .menu>.nav-item.active>.nav-link::before {
  width: 100%;
}

.menu_purple .menu>.nav-item.submenu.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:after {
  color: var(--brand_color);
}

.menu_purple .menu>.nav-item.submenu .dropdown-menu .nav-item>.nav-link:before {
  background: var(--brand_color);
}

.menu_purple .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link,
.menu_purple .menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link {
  color: var(--brand_color);
}

.menu_purple .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link h5,
.menu_purple .menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link h5 {
  color: var(--brand_color);
}

.menu_purple .nav_btn:not(.not-round-btn) {
  color: #fff;
  border-radius: 45px;
}

.menu_purple .nav_btn:not(.not-round-btn):hover {
  background: #fff;
  color: var(--brand_color);
  border-color: #fff;
}

/*============ End menu_purple css ==============*/
/*=========== menu_four css ===========*/
.sticky_menu .menu_one {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.sticky_menu .menu_one+div,
.sticky_menu .menu_one+section {
  margin-top: 100px;
}

.sticky_menu .menu_one .menu>.nav-item .nav-link {
  position: relative;
  color: var(--p_color);
  font-weight: 400;
}

.sticky_menu .menu_one .menu>.nav-item:hover>.nav-link,
.sticky_menu .menu_one .menu>.nav-item.active>.nav-link {
  color: var(--brand_color);
}

.menu_social {
  margin-bottom: 0;
  margin-left: 70px;
}

.search_form {
  width: 250px;
  list-style: none;
  position: relative;
}

.search_form .form-control {
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
  height: 45px;
  margin-top: -2px;
  padding-left: 20px;
  background: #fafcfd;
  border: 1px solid #e1e4e5;
  border-radius: 4px;
  box-shadow: none;
}

.search_form .form-control.placeholder {
  color: #8f94a6;
}

.search_form .form-control:-moz-placeholder {
  color: #8f94a6;
}

.search_form .form-control::-moz-placeholder {
  color: #8f94a6;
}

.search_form .form-control::-webkit-input-placeholder {
  color: #8f94a6;
}

.search_form button {
  position: absolute;
  top: 11px;
  right: 15px;
  border: 0;
  padding: 0;
  color: #1d2746;
  background: transparent;
  font-size: 14px;
  z-index: 3;
}

#stickyTwo .menu>.nav-item.submenu.mega_menu .dropdown-menu,
.menu_two .menu>.nav-item.submenu.mega_menu .dropdown-menu {
  border-top: 1px solid #e6eeef;
}

/*=========== menu_four css ===========*/
.nav_btn_two {
  border-color: rgba(76, 76, 241, 0.3);
  background: #f1f8fc;
}

.nav_btn_two:hover {
  border-color: var(--brand_color);
}

.navbar_fixed {
  position: fixed;
  z-index: 35;
}

.navbar_fixed .search-input {
  visibility: visible;
  opacity: 1;
}

.navbar_fixed.menu_one {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background: #fff;
  transition: all 0.2s, top 0.4s linear;
  margin-top: 0;
}

.navbar_fixed.menu_one .menu>.nav-item {
  padding-bottom: 25px;
  padding-top: 25px;
}

.navbar_fixed.menu_one .menu>.nav-item>.nav-link {
  color: var(--secondary_color);
}

.navbar_fixed.menu_one .menu>.nav-item:hover>.nav-link,
.navbar_fixed.menu_one .menu>.nav-item.active>.nav-link {
  color: var(--brand_color);
}

.navbar_fixed.menu_one .nav_btn {
  color: var(--brand_color);
  border: 2px solid rgba(76, 76, 241, 0.1);
}

.navbar_fixed .header_logo h1 {
  color: #000 !important;
}

.navbar_fixed.menu_one .nav_btn:hover {
  background: var(--brand_color);
  color: #fff;
  border-color: var(--brand_color);
}

.navbar_fixed.menu_one .nav_btn.tp_btn {
  color: #4c4cf1 !important;
}

.navbar_fixed.menu_one.menu_purple .menu>.nav-item>.nav-link:before {
  display: none;
}

.navbar_fixed.menu_one.menu_purple .menu>.nav-item:hover>.nav-link,
.navbar_fixed.menu_one.menu_purple .menu>.nav-item.active>.nav-link {
  color: var(--brand_color);
}

.navbar_fixed.menu_one.menu_purple .nav_btn {
  color: var(--brand_color);
  border: 1px solid var(--black_50);
}

.navbar_fixed.menu_one.menu_purple .nav_btn:hover {
  background: var(--brand_color);
  color: #fff;
  border-color: var(--brand_color);
}

.navbar_fixed .header_logo img:not(.sticky_logo) {
  display: none;
}

.navbar_fixed .header_logo img.sticky_logo {
  display: block;
}

.navbar_fixed.menu_two {
  box-shadow: 0 4px 10px 0 rgba(76, 76, 241, 0.06);
  width: 100%;
  background: #fff;
  left: 0;
  top: 0;
  position: fixed;
  transition: top 0.4s linear;
}

@media (min-width: 992px) {
  .navbar_fixed.menu_two .menu>.nav-item {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.navbar_fixed.menu_two+div,
.navbar_fixed.menu_two+section {
  margin-top: 86px;
}

.navbar_fixed .menu_toggle .hamburger span,
.navbar_fixed .menu_toggle .hamburger-cross span {
  background: #1d2746;
}

.navbar_fixed.dark_menu .menu>.nav-item>.nav-link {
  color: var(--black_800);
}

.navbar_fixed.dark_menu .menu>.nav-item>.nav-link:before {
  display: none;
}

.navbar_fixed.dark_menu .menu>.nav-item:hover>.nav-link,
.navbar_fixed.dark_menu .menu>.nav-item.active>.nav-link {
  color: var(--brand_color);
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link:before {
  background: var(--brand_color);
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link,
.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link,
.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link {
  color: var(--brand_color);
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link h5,
.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link h5,
.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item.active>.nav-link h5 {
  color: var(--brand_color);
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:hover>i,
.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item:focus>i,
.navbar_fixed.dark_menu .menu>.nav-item.submenu .dropdown-menu .nav-item.active>i {
  color: var(--brand_color);
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item {
  padding: 0 30px;
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:after {
  content: "5";
  color: var(--brand_color);
}

.navbar_fixed.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item:hover:after,
.navbar_fixed.dark_menu .menu>.nav-item.submenu.mega_menu .dropdown-menu .tabHeader .nav-item.active:after {
  opacity: 1;
  right: 20px;
}

.navbar_fixed.dark_menu .nav_btn.tp_btn {
  border-color: rgba(76, 76, 241, 0.3);
  color: var(--brand_color) !important;
  font-size: 16px;
  padding: 8px 27px;
}

.navbar_fixed.dark_menu .nav_btn.tp_btn:hover {
  background: var(--brand_color);
  color: #fff !important;
}

.dark_menu .menu_toggle .hamburger span,
.dark_menu .menu_toggle .hamburger-cross span {
  background: #1d2746;
}

.navbar-toggler {
  padding-left: 0;
}

.hamburger {
  height: 100%;
  width: 100%;
  display: block;
}

.hamburger span {
  width: 0%;
  height: 2px;
  position: relative;
  top: 0;
  left: 0;
  margin: 4px 0;
  display: block;
  background: #fff;
  border-radius: 3px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.hamburger span:nth-child(1) {
  transition-delay: 0s;
}

.hamburger span:nth-child(2) {
  transition-delay: 0.125s;
}

.hamburger span:nth-child(3) {
  transition-delay: 0.2s;
}

.menu_toggle {
  width: 22px;
  height: 22px;
  position: relative;
  cursor: pointer;
  display: block;
}

.menu_toggle .hamburger {
  position: absolute;
}

.menu_toggle .hamburger-cross {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(45deg);
  display: block;
}

.menu_toggle .hamburger-cross span {
  display: block;
  background: #fff;
  border-radius: 3px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.menu_toggle .hamburger-cross span:nth-child(1) {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 10px;
  transition-delay: 0.3s;
}

.menu_toggle .hamburger-cross span:nth-child(2) {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 10px;
  transition-delay: 0.4s;
}

.collapsed .menu_toggle .hamburger span {
  width: 100%;
}

.collapsed .menu_toggle .hamburger span:nth-child(1) {
  transition-delay: 0.3s;
}

.collapsed .menu_toggle .hamburger span:nth-child(2) {
  transition-delay: 0.4s;
}

.collapsed .menu_toggle .hamburger span:nth-child(3) {
  transition-delay: 0.5s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(1) {
  height: 0%;
  transition-delay: 0s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(2) {
  width: 0%;
  transition-delay: 0.2s;
}

/*=========== side_menu css ===========*/
.side_menu {
  width: 300px;
  height: 100vh;
  max-height: 100vh;
  top: 0;
  position: fixed;
  transform: translate3d(-300px, 0, 0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1013;
  background: #fff;
  padding: 20px 0;
  overflow-x: hidden;
}

.side_menu .mobile_menu_header {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.side_menu .mobile_menu_header .close_nav {
  font-size: 28px;
  color: #1d2746;
  margin-right: 15px;
  margin-top: -5px;
}

.side_menu .mobile_menu_header .close_nav i+i {
  display: none;
}

.side_menu .doc_left_sidebarlist {
  border: 0;
}

.side_menu .doc_left_sidebarlist:before {
  display: none;
}

.side_menu .nav-sidebar .nav-item {
  padding-right: 0;
}

.side_menu .mobile_nav_wrapper {
  display: flex;
  transform: translate3d(-300px, 0, 0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.side_menu .mobile_nav_top,
.side_menu .mobile_nav_bottom {
  height: calc(100vh - 65px);
  flex-shrink: 0;
  display: block;
  width: 300px;
  padding: 0 15px;
  padding-right: 25px;
}

.side_menu .mobile_nav_top {
  padding-top: 30px;
}

.side_menu .mobile_nav_top .menu {
  padding-left: 0;
  padding-right: 0;
}

.side_menu.menu-opened .mobile_nav_wrapper {
  transform: translate3d(0, 0, 0);
}

.side_menu.menu-opened .close_nav i {
  display: none;
}

.side_menu.menu-opened .close_nav i+i {
  display: block;
}

.click_capture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 99;
  opacity: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s linear;
}

@media (max-width: 992px) {
  .menu-is-opened {
    overflow: hidden;
    height: 100%;
  }

  .menu-is-opened .click_capture {
    opacity: 1;
    visibility: visible;
  }

  .menu-is-opened .side_menu {
    transform: translate3d(0, 0, 0);
  }
}

.header_area .main_btn {
  margin-left: 80px;
}

.navbar:not(.navbar_fixed) .menu>.nav-item.submenu .dropdown-menu {
  margin-top: -15px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== doc_banner_area_one css ============*/
.doc_banner_area_one {
  height: 750px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(60deg, var(--brand_color) 0%, #1d2746 100%);
  position: relative;
  overflow: hidden;
}

.doc_banner_area_one .star {
  z-index: -1;
}

.doc_banner_area_one .star_one {
  right: 170px;
  top: 50%;
  animation: star 9s both infinite alternate 5s;
}

.doc_banner_area_one .star_two {
  top: 33px;
  right: 147px;
  animation: star2 4s ease-in-out infinite alternate;
}

.doc_banner_area_one .star_three {
  top: 50px;
  left: 347px;
  animation: star 7s ease-in-out infinite alternate;
}

.doc_banner_area_one .one {
  bottom: 0;
  left: 300px;
}

.doc_banner_area_one .two {
  bottom: 0;
  right: 320px;
}

.doc_banner_area_one .three {
  bottom: 0;
  left: 520px;
}

.doc_banner_area_one .four {
  bottom: 0;
  left: 58%;
  margin-left: -20px;
}

.doc_banner_area_one .five {
  bottom: 155px;
  left: 74%;
  transform: translateX(-50%);
}

.doc_banner_area.single_breadcrumb {
  padding: 265px 0 175px;
}

.single_breadcrumb .doc_banner_content h2 {
  font-weight: 900;
  font-size: 50px;
}

.single_breadcrumb .doc_banner_content .nav li {
  margin-right: 30px;
}

.single_breadcrumb .doc_banner_content .nav li a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  position: relative;
  padding-top: 2px;
}

.single_breadcrumb .doc_banner_content .nav li a:before {
  content: "5";
  font: normal normal normal 16px/1 "ElegantIcons";
  color: #fff;
  position: absolute;
  right: -21px;
  top: 50%;
  transform: translateY(-50%);
}

.single_breadcrumb .doc_banner_content .nav li a.active {
  color: #acace6;
}

.single_breadcrumb .doc_banner_content .nav li:last-child {
  margin-right: 0;
}

.single_breadcrumb .doc_banner_content .nav li:last-child a:before {
  display: none;
}

.bl_left {
  width: 150px;
  height: 145px;
  bottom: -7px;
  left: 20px;
  z-index: 1;
}

.bl_right {
  width: 135px;
  height: 110px;
  right: 30px;
  bottom: -7px;
  z-index: 1;
}

.dark {
  position: absolute;
  width: 100%;
  left: 0;
  height: auto;
  bottom: 0;
  z-index: -1;
}

.dark_two {
  position: absolute;
  width: 100%;
  left: 0;
  height: auto;
  bottom: 0;
  z-index: -1;
}

.star {
  top: 0;
  width: 100%;
  left: 0;
  z-index: 0;
  right: 0;
  height: auto;
}

.doc_banner_text {
  text-align: center;
}

.doc_banner_text h2,
.doc_banner_text p,
.doc_banner_text h6 {
  color: #fff;
}

.doc_banner_text h2 {
  font-size: 46px;
  margin-bottom: 14px;
}

.doc_banner_text p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0;
  font-weight: 300;
}

.doc_banner_text h6 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 40px;
}

.doc_banner_text h6 span {
  color: #e1e1e1;
  margin-right: 10px;
}

.doc_banner_text h6 a {
  color: #fff;
  margin-right: 10px;
  text-decoration: underline;
}

.doc_banner_text h6 a:last-child {
  margin-right: 0;
}

.doc_banner_text .banner_search_form {
  margin-top: 65px;
}

.banner_search_form {
  max-width: 570px;
  margin: 0 auto;
  display: flex;
}

.banner_search_form .form-control {
  height: 70px;
  border-radius: 4px;
  padding-left: 30px;
  font-size: 14px;
  border: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  color: var(--black_800);
}

.banner_search_form .form-control.placeholder {
  color: #848996;
}

.banner_search_form .form-control:-moz-placeholder {
  color: #848996;
}

.banner_search_form .form-control::-moz-placeholder {
  color: #848996;
}

.banner_search_form .form-control::-webkit-input-placeholder {
  color: #848996;
}

.banner_search_form button {
  padding: 5px 25px;
  font-size: 20px;
  background: var(--brand_color);
  color: #fff;
  text-align: center;
  border: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.4s linear;
}

/*=========== doc_banner_area_one css ============*/
/*=========== Start doc_banner_area_two css ============*/
.doc_banner_area_two {
  height: 650px;
  background: #daf6fc;
  position: relative;
  z-index: 1;
  padding-top: 160px;
}

.doc_banner_area_two .table_img {
  left: 200px;
  bottom: -6px;
  width: 250px;
}

.doc_banner_area_two .flower {
  bottom: 0;
  left: 80px;
}

.doc_banner_area_two .girl {
  right: 130px;
  bottom: 0;
}

.doc_banner_area_two .bord {
  bottom: -14px;
  right: 320px;
}

.b_plus,
.b_round,
.p_absolute {
  position: absolute;
}

.b_plus.one {
  left: 120px;
  top: 120px;
}

.b_plus.two {
  right: 25%;
  top: 210px;
}

.b_round {
  border-radius: 50%;
}

.b_round.r_one {
  right: 135px;
  top: 85px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #83daed;
  animation: spin1 2s infinite alternate;
}

.b_round.r_two {
  left: 30%;
  top: 160px;
  width: 8px;
  height: 8px;
  background: var(--brand_color);
  animation: spin2 2s infinite alternate;
}

.b_round.r_three {
  background: #ffa3be;
  width: 16px;
  height: 16px;
  left: 24%;
  top: 310px;
  animation: spin1 2.5s infinite alternate;
}

.b_round.r_four {
  background: #ffa3be;
  width: 8px;
  height: 8px;
  right: 18%;
  bottom: 380px;
  animation: spin1 1s infinite alternate;
}

.doc_banner_text_two h2 {
  font-size: 46px;
  margin-bottom: 22px;
  font-weight: 700;
}

.doc_banner_text_two p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  max-width: 440px;
  margin: 0 auto;
}

.doc_banner_text_two .banner_search_form {
  max-width: 770px;
  margin: 60px auto 0;
}

.doc_banner_text_two .banner_search_form .form-control {
  margin-right: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(76, 76, 241, 0.06);
}

.doc_banner_text_two .banner_search_form .search_btn {
  border-radius: 4px;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0 10px 20px 0 rgba(12, 118, 142, 0.1);
  background: var(--brand_color);
  padding: 5px 42px;
}

.building_img {
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: -1;
}

/*=========== End doc_banner_area_two css ============*/
/*=========== Start Banner Support ============*/
.Docy-banner-support {
  height: 945px;
  background-image: linear-gradient(30deg, rgb(254, 239, 236) 0%, rgb(245, 253, 255) 100%);
  padding: 200px 0;
  position: relative;
}

@media (max-width: 991px) {
  .Docy-banner-support {
    padding: 150px 0;
  }
}

@media (max-width: 768px) {
  .Docy-banner-support {
    padding: 100px 0;
    height: 820px;
  }
}

.Docy-banner-support .banner-content-wrapper {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

@media (max-width: 991px) {
  .Docy-banner-support .banner-content-wrapper {
    max-width: 90%;
  }
}

.Docy-banner-support .banner-content-wrapper .banner-title {
  font-size: 44px;
  font-weight: 500;
  color: #1d2746;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .Docy-banner-support .banner-content-wrapper .banner-title {
    font-size: 34px;
  }
}

.Docy-banner-support .banner-content-wrapper p {
  font-size: 18px;
  color: #6b707f;
  line-height: 28px;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .Docy-banner-support .banner-content-wrapper p br {
    display: none;
  }
}

.Docy-banner-support .banner-content-wrapper .banner-search-form {
  box-shadow: 0 4px 10px 0 rgba(49, 12, 2, 0.06);
  max-width: 770px;
  margin: 0 auto;
  display: flex;
  background: #fff;
  border-radius: 30px;
}

.Docy-banner-support .banner-content-wrapper .banner-search-form input {
  width: 100%;
  background: transparent;
  border: 0;
  padding: 15px 30px;
  height: 60px;
}

.Docy-banner-support .banner-content-wrapper .banner-search-form input::placeholder {
  color: #6b707f;
}

.Docy-banner-support .banner-content-wrapper .banner-search-form button {
  background: transparent;
  border: 0;
  padding-right: 20px;
  color: var(--brand_color);
  font-size: 24px;
}

.Docy-banner-support .banner-content-wrapper .banner-search-form button i {
  transform: rotate(-90deg);
  display: inline-block;
}

.Docy-banner-support .bottom-shape {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
}

.Docy-banner-support .bottom-shape img {
  width: 100%;
}

.people-image {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 100%;
  height: 320px;
  max-width: 1250px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 165px;
  z-index: 3;
}

@media (max-width: 767px) {
  .people-image {
    height: 280px;
  }
}

@media (max-width: 576px) {
  .people-image {
    bottom: 80px;
  }
}

.people-image li {
  position: absolute;
  box-shadow: 0 40px 60px 0 rgba(59, 14, 3, 0.12);
  border-radius: 50%;
  border: 4px solid #fff;
  overflow: hidden;
}

.people-image li img {
  width: 100%;
}

.people-image li:nth-child(1) {
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  border: 6px solid #fff;
  height: 130px;
  width: 130px;
  box-shadow: 0 50px 80px 0 rgba(59, 14, 3, 0.12);
}

.people-image li:nth-child(2) {
  left: 29.4%;
  top: 0;
  height: 80px;
  width: 80px;
}

.people-image li:nth-child(3) {
  right: 26.4%;
  top: -5px;
  height: 70px;
  width: 70px;
}

.people-image li:nth-child(4) {
  left: 160px;
  top: 75px;
  height: 80px;
  width: 80px;
}

.people-image li:nth-child(5) {
  left: 26.4%;
  bottom: 40px;
  height: 80px;
  width: 80px;
}

.people-image li:nth-child(6) {
  right: 38%;
  bottom: -20px;
  height: 100px;
  width: 100px;
}

.people-image li:nth-child(7) {
  right: 85px;
  bottom: 5px;
  width: 90px;
  height: 90px;
}

.people-image li:nth-child(8) {
  left: 0;
  bottom: 0;
  border: 2px solid #fff;
}

.people-image li:nth-child(9) {
  right: 22.3%;
  bottom: 35%;
  height: 50px;
  width: 50px;
  border: 2px solid #fff;
}

.people-image li:nth-child(10) {
  right: 0;
  bottom: 145px;
  height: 60px;
  width: 60px;
  border: 2px solid #fff;
}

@media (max-width: 991px) {
  .people-image li:nth-child(1) {
    height: 110px;
    width: 110px;
  }

  .people-image li:nth-child(2) {
    height: 65px;
    width: 65px;
  }

  .people-image li:nth-child(3) {
    height: 60px;
    width: 60px;
  }

  .people-image li:nth-child(4) {
    height: 65px;
    width: 65px;
  }

  .people-image li:nth-child(5) {
    height: 65px;
    width: 65px;
  }

  .people-image li:nth-child(6) {
    height: 80px;
    width: 80px;
  }

  .people-image li:nth-child(7) {
    height: 70px;
    width: 70px;
  }

  .people-image li:nth-child(8) {
    left: 50px;
  }

  .people-image li:nth-child(9) {
    right: 27%;
  }

  .people-image li:nth-child(10) {
    right: 50px;
  }
}

@media (max-width: 768px) {
  .people-image li:nth-child(4) {
    left: 95px;
  }
}

@media (max-width: 576px) {
  .people-image li:nth-child(2) {
    left: 13%;
  }

  .people-image li:nth-child(3) {
    right: 18%;
  }
}

@media (max-width: 440px) {
  .people-image li:nth-child(1) {
    height: 100px;
    width: 100px;
  }

  .people-image li:nth-child(4) {
    left: 20px;
  }

  .people-image li:nth-child(5) {
    left: 22.4%;
    bottom: 55px;
  }

  .people-image li:nth-child(7) {
    right: 25px;
  }

  .people-image li:nth-child(8) {
    left: 10px;
  }

  .people-image li:nth-child(10) {
    right: 20px;
  }
}

.partical-animation {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 100%;
  height: 550px;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.partical-animation li {
  position: absolute;
  border-radius: 50%;
}

.partical-animation li:nth-child(1) {
  left: 165px;
  top: 120px;
}

.partical-animation li:nth-child(2) {
  left: 19%;
  top: 80px;
  height: 8px;
  width: 8px;
  background: #fd9382;
}

.partical-animation li:nth-child(3) {
  left: 19%;
  bottom: 30%;
  height: 10px;
  width: 10px;
  background: #fe97f3;
}

.partical-animation li:nth-child(3) {
  left: 28%;
  top: 35%;
  height: 35px;
  width: 35px;
  background: #f3d5de;
}

.partical-animation li:nth-child(4) {
  left: 27%;
  bottom: 30%;
  height: 12px;
  width: 12px;
  background: #83daed;
}

.partical-animation li:nth-child(5) {
  left: 33%;
  bottom: 50%;
  height: 10px;
  width: 10px;
  background: #fdc43a;
}

.partical-animation li:nth-child(6) {
  left: 51%;
  top: 32%;
  height: 48px;
  width: 48px;
  background: #4edfda;
}

.partical-animation li:nth-child(7) {
  right: 46%;
  bottom: 27%;
  height: 22px;
  width: 22px;
  background: #f39ab2;
}

.partical-animation li:nth-child(8) {
  right: 46%;
  bottom: 27%;
  height: 22px;
  width: 22px;
  background: #f39ab2;
}

.partical-animation li:nth-child(9) {
  right: 40%;
  bottom: 60%;
  height: 8px;
  width: 8px;
  background: #ffa3be;
}

.partical-animation li:nth-child(10) {
  right: 22%;
  bottom: 30%;
  height: 28px;
  width: 28px;
  background: #bff8c6;
}

.partical-animation li:nth-child(11) {
  right: 10%;
  bottom: 55%;
  height: 15px;
  width: 15px;
  background: #ffa3be;
}

.partical-animation li:nth-child(12) {
  right: 65px;
  bottom: 160px;
}

/*=========== End Banner Support ============*/
/*=========== Start breadcrumb_area css ============*/
.breadcrumb_area {
  background-image: linear-gradient(60deg, var(--brand_color) 0%, #1d2746 100%);
  padding: 190px 0 95px;
  position: relative;
}

.breadcrumb_area .one {
  right: 20%;
  bottom: 25px;
}

.breadcrumb_area .two {
  left: 20%;
  bottom: 0;
}

.breadcrumb_area .banner_search_form {
  max-width: 770px;
  position: relative;
  z-index: 5;
}

.breadcrumb_area .banner_search_form .form-control {
  height: 65px;
}

.breadcrumb_area .banner_search_form button {
  background: var(--brand_color);
}

.breadcrumb_area .custom-select {
  line-height: 65px;
  z-index: 3;
}

.breadcrumb_area .custom-select:before {
  width: 1px;
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  bottom: 15px;
  background: #e2e7e9;
  z-index: 1;
}

.wave_shap_one,
.wave_shap_two {
  bottom: 0;
  z-index: 0;
}

.custom-select {
  border-radius: 0;
  font-size: 14px;
  box-shadow: none;
  padding: 0 80px 0 20px;
  height: 100%;
  border: 0;
  background: #fff;
  color: #6b707f;
}

.custom-select:after {
  border-bottom: 2px solid #6b707f;
  border-right: 2px solid #6b707f;
  height: 8px;
  width: 8px;
  right: 28px;
  margin-top: -6px;
}

.custom-select ul {
  width: 100%;
  padding: 10px 0;
  border-radius: 4px;
}

.custom-select ul li {
  font-size: 14px;
  color: #6b707f;
  position: relative;
  min-height: 36px;
  line-height: 36px;
}

.custom-select ul li:before {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  left: 0;
  background: var(--brand_color);
  transition: all 0.2s linear;
}

.custom-select ul li:hover,
.custom-select ul li.selected {
  background: #f4f9fa;
  color: var(--brand_color);
  font-weight: 500;
}

.custom-select ul li:hover:before,
.custom-select ul li.selected:before {
  height: 100%;
}

/*=========== End breadcrumb_area css ============*/
/*=========== Start breadcrumb_area_two css ============*/
.breadcrumb_area_two {
  background: #edfbfe;
  padding: 150px 0 60px;
}

.breadcrumb_content {
  max-width: 630px;
}

.breadcrumb_content h2 {
  font-size: 36px;
  line-height: 1.3;
  margin-bottom: 0;
}

.breadcrumb_content .single_post_author {
  display: flex;
  align-items: flex-start;
  align-items: center;
  padding-top: 10px;
}

.breadcrumb_content .single_post_author img {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 20px;
}

.breadcrumb_content .single_post_author .text {
  flex: 1;
}

.breadcrumb_content .single_post_author .text h4 {
  font-size: 16px;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 0;
}

.breadcrumb_content .single_post_author .text .post_tag {
  display: inline-block;
  padding-left: 12px;
}

.breadcrumb_content .single_post_author .text .post_tag:before {
  content: "";
  display: inline-block;
  height: 13px;
  width: 1px;
  background: #b7b9be;
  margin-right: 12px;
}

.breadcrumb_content .single_post_author .text .c_blue {
  color: #2a74fd;
}

/*=========== End breadcrumb_area_two css ============*/
/*=========== Start breadcrumb_area_three css ============*/
.breadcrumb_area_three {
  background: var(--black_25);
  padding: 157px 0 70px;
  position: relative;
  z-index: 1;
}

.breadcrumb_area_three .one {
  top: 0;
  left: 35px;
}

.breadcrumb_area_three .two {
  left: 280px;
  bottom: 55px;
}

.breadcrumb_area_three .three {
  right: 178px;
  bottom: 55px;
}

.breadcrumb_area_three .four {
  right: 0;
  bottom: 0;
}

.breadcrumb_text {
  text-align: center;
  max-width: 660px;
  margin: 0 auto;
}

.breadcrumb_text h2 {
  font-size: 50px;
  line-height: 66px;
  font-weight: 300;
  margin-bottom: 15px;
}

.breadcrumb_text h2 span {
  font-weight: 700;
}

.breadcrumb_text p {
  margin-bottom: 0;
}

/*=========== End breadcrumb_area_three css ============*/
/*=========== Start page_breadcrumb css ============*/
.page_breadcrumb {
  background: #edefec;
  padding: 12px 0;
  position: relative;
  z-index: 1;
  margin-top: -2px;
}

.page_breadcrumb .row {
  align-items: center;
}

.page_breadcrumb .date {
  color: #878ea6;
  float: right;
  font-size: 14px;
}

.page_breadcrumb .date i {
  padding-right: 10px;
}

.breadcrumb {
  margin-bottom: 0;
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.breadcrumb .breadcrumb-item {
  color: #878ea6;
  font-size: 14px;
  line-height: 21px;
}

.breadcrumb .breadcrumb-item a {
  color: #878ea6;
}

.breadcrumb .breadcrumb-item a:hover {
  color: var(--brand_color);
}

.breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  content: "5";
  font-family: eleganticons;
  color: #b7b9be;
}

.breadcrumb .active {
  color: #1d2746;
}

.breadcrumb_content_two {
  position: relative;
  z-index: 1;
}

.breadcrumb_content_two h2 {
  font-size: 50px;
  color: #fff;
  font-weight: 700;
}

.breadcrumb_content_two .breadcrumb {
  justify-content: center;
}

.breadcrumb_content_two .breadcrumb .breadcrumb-item {
  font-size: 16px;
  color: #fff;
}

.breadcrumb_content_two .breadcrumb .breadcrumb-item:before {
  color: #fff;
}

.breadcrumb_content_two .breadcrumb .breadcrumb-item a {
  color: #fff;
}

.breadcrumb_area_four {
  overflow: hidden;
}

/*=========== End page_breadcrumb css ============*/
/* Home Doc Banner Two Area css
============================================================================================ */
.doc_banner_area_dip {
  background: #002454;
  text-align: center;
  padding: 215px 0 245px;
  position: relative;
}

.doc_banner_area_dip .banner_shap_img_dip li {
  position: absolute;
  z-index: 0;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(1) {
  left: 65px;
  bottom: 30%;
  top: auto;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(2) {
  bottom: 10%;
  right: 15%;
  top: auto;
  left: auto;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(3) {
  left: 380px;
  bottom: 80px;
  top: auto;
  right: auto;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(4) {
  bottom: auto;
  top: 185px;
  left: 134px;
  right: auto;
  animation: myfirst 18s linear infinite;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(5) {
  bottom: 220px;
  top: auto;
  left: auto;
  right: 65px;
  animation: secand 18s linear infinite;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(6) {
  bottom: auto;
  top: 135px;
  left: 20%;
  right: auto;
  animation: myfirst 25s linear infinite;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(7) {
  bottom: auto;
  top: 176px;
  left: auto;
  right: 10%;
  height: auto;
  width: auto;
  animation: 28s cloud infinite linear;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(8) {
  left: 72%;
  top: 30%;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(9) {
  left: 74%;
  top: 58%;
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(10) {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.doc_banner_area_dip .banner_shap_img_dip li:nth-child(11) {
  left: 25%;
  top: 60%;
  transform: translateX(-50%) translateY(-50%);
}

.doc_banner_area_dip .doc_banner_content {
  position: relative;
  z-index: 10;
}

.doc_banner_area_dip .doc_banner_content h2 {
  font-size: 46px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 45px;
}

.doc_banner_area_dip .header_search_form {
  max-width: 690px;
  margin: 0 auto 0;
  position: relative;
}

.doc_banner_area_dip .header_search_form .form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
}

.doc_banner_area_dip .header_search_form .input-wrapper {
  position: relative;
  flex: 1;
  max-width: 562px;
  margin-right: 10px;
}

.doc_banner_area_dip .header_search_form .input-wrapper i {
  position: absolute;
  left: 30px;
  font-size: 20px;
  color: #b6bac3;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.doc_banner_area_dip .header_search_form .input-wrapper input {
  background: #fff;
  border: 0;
  height: 60px;
  font-size: 16px;
  padding-left: 30px;
  width: 100%;
  border-radius: 4px;
  line-height: 60px;
  padding-right: 30px;
  outline: none;
  box-shadow: none;
}

.doc_banner_area_dip .header_search_form .input-wrapper input.placeholder {
  color: #848996;
  font-size: 16px;
}

.doc_banner_area_dip .header_search_form .input-wrapper input:-moz-placeholder {
  color: #848996;
  font-size: 16px;
}

.doc_banner_area_dip .header_search_form .input-wrapper input::-moz-placeholder {
  color: #848996;
  font-size: 16px;
}

.doc_banner_area_dip .header_search_form .input-wrapper input::-webkit-input-placeholder {
  color: #848996;
  font-size: 16px;
}

.doc_banner_area_dip .header_search_form .custom-select {
  height: 60px;
  line-height: 60px;
  font-weight: 500;
  color: #1d2746;
  padding: 0 65px 0 25px;
  background: #e8e9ec;
  float: none;
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
}

.doc_banner_area_dip .header_search_form .custom-select:after {
  border-bottom: 1px solid #1d2746;
  border-right: 1px solid #1d2746;
}

.doc_banner_area_dip .header_search_form .custom-select li {
  font-size: 14px;
  color: #1d2746;
  line-height: 40px;
}

.doc_banner_area_dip .header_search_form .custom-select:focus {
  box-shadow: none;
}

.doc_banner_area_dip .header_search_form_info {
  position: relative;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel {
  border: 1px solid rgb(230, 238, 239);
  box-shadow: 0 3px 4px 0 rgba(4, 73, 89, 0.06);
  background-color: #fff;
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 115%;
  left: 0;
  width: 100%;
  text-align: left;
  z-index: 99;
  padding: 30px;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel:before {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  top: -6px;
  transform: rotate(45deg);
  left: 40px;
  border-left: 1px solid rgb(230, 238, 239);
  border-top: 1px solid rgb(230, 238, 239);
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul {
  margin-bottom: 0;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li {
  font-size: 16px;
  color: #1d2746;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item {
  border-top: 1px solid #e6eeef;
  padding: 10px 0 5px;
  margin-top: 8px;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item li {
  color: #898d99;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 0;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item li span {
  width: 165px;
  text-align: right;
  border-right: 1px solid #dee7e8;
  line-height: 32px;
  padding-right: 15px;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item li>a {
  line-height: 26px;
  font-weight: 400;
  color: #6b707f;
  padding: 3px 22px;
  flex: 1;
  display: block;
  position: relative;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item li>a::before {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  left: -1px;
  top: 0;
  background: var(--brand_color);
  opacity: 0;
  transition: all 0.2s linear;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item li>a:hover {
  background: #f4f9fa;
}

.doc_banner_area_dip .header_search_form_info .header_search_form_panel ul>li .search_item li>a:hover:before {
  opacity: 1;
  height: 100%;
}

.doc_banner_area_dip .header_search_keyword {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  margin-top: 25px;
}

.doc_banner_area_dip .header_search_keyword span {
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
}

.doc_banner_area_dip .header_search_keyword ul {
  margin-bottom: 0;
}

.doc_banner_area_dip .header_search_keyword ul li {
  display: inline-block;
  margin-right: 4px;
}

.doc_banner_area_dip .header_search_keyword ul li a {
  display: block;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 45px;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  transition: all 300ms linear 0s;
  font-weight: 500;
}

.doc_banner_area_dip .header_search_keyword ul li a:hover {
  background: #fff;
  color: #002454;
}

/* End Home Doc Banner Two Area css
============================================================================================ */
/* Forum Banner Area css
============================================================================================ */
/* .doc_banner_area.search-banner-light {
  background-color: var(--black_25);
  padding-top: 145px;
  padding-bottom: 100px;
  background-image: url("../../img/banner-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
} */
.doc_banner_area.search-banner-light .banner_shap_img li:first-child {
  top: auto;
  bottom: 0;
}

.doc_banner_area.search-banner-light .banner_shap_img li:last-child {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.doc_banner_area.breadcrumb_green {
  padding-top: 188px;
  padding-bottom: 85px;
}

.doc_banner_area.breadcrumb_green .doc_banner_content h2 {
  margin-bottom: 0;
  font-size: 46px;
  font-weight: 700;
}

.doc_banner_area.breadcrumb_green .doc_banner_content .header_search_form {
  margin-top: 30px;
}

.doc_banner_area.breadcrumb_green .doc_banner_content .header_search_form .header_search_form_info .form-group .input-wrapper i {
  color: #999;
}

.search-banner-light .header_search_form {
  max-width: 650px;
}

.search-banner-light .header_search_form .header_search_form_info .form-group {
  margin-bottom: 15px;
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper {
  max-width: 650px;
  margin-right: 0;
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper i {
  color: var(--brand_color);
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input {
  color: var(--black_400);
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 1px 1px rgba(11, 80, 115, 0.3), 0 1px 5px rgba(60, 64, 67, 0.1);
  padding-right: 30px;
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input:focus,
.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input.placeholder {
  color: #6b707f;
  font-size: 14px;
  font-weight: 400;
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input:-moz-placeholder {
  color: #6b707f;
  font-size: 14px;
  font-weight: 400;
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input::-moz-placeholder {
  color: #6b707f;
  font-size: 14px;
  font-weight: 400;
}

.search-banner-light .header_search_form .header_search_form_info .form-group .input-wrapper input::-webkit-input-placeholder {
  color: #6b707f;
  font-size: 14px;
  font-weight: 400;
}

.search-banner-light .header_search_keyword {
  text-align: center;
  justify-content: center;
}

.search-banner-light .header_search_keyword .header-search-form__keywords-label {
  color: #54595d;
  font-weight: 400;
}

.search-banner-light .header_search_keyword ul li {
  margin-right: 4px;
}

.search-banner-light .header_search_keyword ul li a {
  background: transparent;
  padding: 0;
  border-bottom: 2px solid rgba(76, 76, 241, 0.2);
  transition: all 300ms linear 0s;
  border-radius: 0;
  color: #54595d;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  display: inline;
}

.search-banner-light .header_search_keyword ul li:hover a {
  border-color: rgb(76, 76, 241);
}

.breadcrumb_link {
  background: #f2f5f7;
}

.breadcrumb_link .bread_link_inner .nav li {
  margin-right: 32px;
}

.breadcrumb_link .bread_link_inner .nav li a {
  line-height: 60px;
  color: var(--p_color);
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.breadcrumb_link .bread_link_inner .nav li a:before {
  content: "5";
  font-family: "ElegantIcons";
  font-size: 14px;
  color: var(--p_color);
  position: absolute;
  right: -21px;
  top: 50%;
  transform: translateY(-50%);
}

.breadcrumb_link .bread_link_inner .nav li a.active {
  color: var(--black_800);
}

.breadcrumb_link .bread_link_inner .nav li:last-child {
  margin-right: 0;
}

.breadcrumb_link .bread_link_inner .nav li:last-child a:before {
  display: none;
}

.breadcrumb_link .bread_link_inner p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 60px;
}

.breadcrumb_link .bread_link_inner p i {
  padding-right: 7px;
  font-size: 12px;
}

/* End Forum Banner Area css
============================================================================================ */
/* Video Breadcrumb Area css
============================================================================================ */
.video_breadcrumb_area {
  background: linear-gradient(90deg, #fff4e6 0%, #fffbf5 100%);
}

.video_breadcrumb_area .breadcrumb_text {
  text-align: center;
  padding-top: 185px;
  padding-bottom: 95px;
}

.video_breadcrumb_area .breadcrumb_text h2 {
  color: var(--black_800);
  font-size: 50px;
  font-weight: bold;
  line-height: 59px;
  margin-bottom: 8px;
}

.video_breadcrumb_area .breadcrumb_text ul {
  justify-content: center;
}

.video_breadcrumb_area .breadcrumb_text ul li {
  margin-right: 30px;
}

.video_breadcrumb_area .breadcrumb_text ul li a {
  color: var(--p_color);
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.video_breadcrumb_area .breadcrumb_text ul li a:before {
  content: "5";
  font-family: "ElegantIcons";
  font-size: 14px;
  color: var(--p_color);
  position: absolute;
  right: -21px;
  top: 50%;
  transform: translateY(-50%);
}

.video_breadcrumb_area .breadcrumb_text ul li a.active {
  color: var(--black_800);
}

.video_breadcrumb_area .breadcrumb_text ul li:last-child {
  margin-right: 0;
}

.video_breadcrumb_area .breadcrumb_text ul li:last-child a:before {
  display: none;
}

/* End Video Breadcrumb Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== doc_features_area_one css ===========*/
.doc_features_area_one {
  position: relative;
  z-index: 1;
  padding: 100px 0 140px;
}

.doc_features_area_one:before {
  content: "";
  width: 100%;
  height: 460px;
  top: 0;
  left: 0;
  background: url("../../img/home_one/dow_bg.png") no-repeat scroll center top/cover;
  position: absolute;
  z-index: -1;
}

.doc_features_item_one {
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(4, 73, 89, 0.05);
  padding: 30px;
  transition: all 0.3s linear;
  cursor: pointer;
}

.doc_features_item_one h3 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  transition: color 0.2s linear;
}

.doc_features_item_one h3:hover {
  color: var(--brand_color);
}

.doc_features_item_one p {
  line-height: 24px;
}

.doc_features_item_one img {
  margin-right: 30px;
}

.doc_features_item_one .learn_btn {
  font-size: 14px;
}

.doc_features_item_one .learn_btn i {
  font-size: 20px;
  margin-left: 4px;
}

.doc_features_item_one:hover {
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.08);
}

.learn_btn {
  font-size: 14px;
  font-weight: 500;
  color: var(--black_800);
  padding-top: 5px;
  display: inline-block;
  transition: color 0.2s linear;
}

.learn_btn i {
  vertical-align: middle;
  display: inline-block;
  font-size: 20px;
  margin-left: 6px;
  transition: all 0.2s linear, color 0s linear;
}

.learn_btn.c_blue {
  color: var(--brand_color);
}

.learn_btn:hover {
  color: var(--brand_color);
}

.learn_btn:hover i {
  transform: translateX(8px);
}

.learn_btn:hover.c_blue {
  color: var(--black_800);
}

/*=============== doc_features_area_one css ===========*/
/* Header Feature Area css
============================================================================================ */
.h_feature_area .h_feature_box {
  max-width: 968px;
  margin: auto;
  background: var(--bs-white);
  margin-top: -180px;
  position: relative;
  border-radius: 8px;
  border-bottom: 2px solid var(--black_50);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.h_feature_area .h_feature_box .col-lg-4:last-child .h_feature_item:before {
  display: none;
}

.h_feature_item {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.h_feature_item:before {
  content: "";
  height: 140px;
  width: 1px;
  background: var(--black_50);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.h_feature_item img {
  margin-bottom: 25px;
}

.h_feature_item h4 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  transition: all 300ms linear 0s;
}

.h_feature_item:hover h4 {
  color: var(--brand_color);
}

/* End Header Feature Area css
  ============================================================================================ */
/* Solution Area css
  ============================================================================================ */
.solution_inner .col-lg-6:last-child .solution_item {
  background: #e8f9fc;
}

.solution_item {
  background: #fcf9ed;
  border-radius: 5px;
  padding: 47px 45px 42px 45px;
}

.solution_item .media .d-flex {
  padding-right: 43px;
}

.solution_item .media .media-body h4 {
  color: #1d2746;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}

.solution_item .media .media-body p {
  margin-bottom: 0;
  color: #425466;
}

.solution_item .media .media-body .text_btn {
  margin-top: 17px;
  display: inline-block;
}

/* End Solution Area css
  ============================================================================================ */
/* Topic List Area css
  ============================================================================================ */
.topic_list_inner {
  margin-bottom: -30px;
}

.topic_list_item {
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 36px 36px 38px;
  border: 1px solid var(--black_50);
  transition: all 300ms linear 0s;
}

.topic_list_item img {
  padding-right: 15px;
  max-width: 40px;
}

.topic_list_item .topic-title {
  display: flex;
  justify-content: space-between;
}

.topic_list_item .topic-title h4 {
  font-size: 20px;
  margin-bottom: 25px;
  display: flex;
}

.topic_list_item .count {
  float: right;
  color: var(--p_color);
  font-size: 14px;
  font-weight: 400;
}

.topic_list_item ul li {
  margin-bottom: 10px;
}

.topic_list_item ul li a {
  color: var(--p_color);
  font-size: 16px;
  transition: all 300ms linear 0s;
}

.topic_list_item ul li a i {
  padding-right: 15px;
}

.topic_list_item ul li a:hover {
  color: var(--brand_color);
}

.topic_list_item ul li:last-child {
  margin-bottom: 0;
}

.topic_list_item .text_btn {
  margin-top: 36px;
  display: inline-block;
}

.topic_list_item:hover {
  box-shadow: 0 16px 40px 0 rgba(10, 50, 102, 0.1);
  border-color: var(--bs-white);
}

/* End Topic List Area css
  ============================================================================================ */
/* Question List Area css
  ============================================================================================ */
.question_menu h3 {
  color: var(--black_800);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
}

.question_menu .nav-tabs {
  border: none;
  margin-right: -15px;
  margin-bottom: -15px;
}

.question_menu .nav-tabs li {
  margin-right: 15px;
  margin-bottom: 15px;
}

.question_menu .nav-tabs li a {
  border: none;
  background: rgba(76, 76, 241, 0.07);
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  border-radius: 3px;
  padding: 0 16px;
  color: var(--p_color);
  transition: all 300ms linear 0s;
}

.question_menu .nav-tabs li a:hover,
.question_menu .nav-tabs li a.active {
  background: var(--brand_color);
  color: #fff;
}

.question_list .fade {
  transition: opacity 0.5s linear;
}

.question_list .ques_item {
  background: rgba(76, 76, 241, 0.07);
  padding: 35px 25px 35px 35px;
}

.question_list .ques_item h4 {
  color: var(--black_800);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 13px;
}

.question_list .ques_item p {
  margin-bottom: 0;
}

.question_list .ques_item:nth-child(even) {
  background: var(--bs-white);
}

/* End Question List Area css
  ============================================================================================ */
/* Header Tabs Area css
  ============================================================================================ */
body.remove .header_tabs_area.tab_fixed .header_tab_items {
  transform: translateY(144px);
}

.header_tabs_area.tab_fixed .header_tab_items {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  z-index: 30;
}

.header_tabs_area.tab_fixed .header_tab_items .nav.nav-tabs li a svg {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.header_tabs_area.tab_fixed .header_tab_items .nav.nav-tabs li a img {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.header_tabs_area.tab_fixed .header_tab_content {
  margin-top: 61px;
}

.header_tab_items {
  background: #1d2746;
  transition: all 300ms linear 0s;
}

.header_tab_items .nav.nav-tabs {
  justify-content: center;
  border: none;
}

.header_tab_items .nav.nav-tabs li {
  margin-bottom: 0;
}

.header_tab_items .nav.nav-tabs li a {
  border: none;
  box-shadow: none;
  background: transparent;
  border-radius: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 7px 30px;
  transition: all 300ms linear 0s;
}

.header_tab_items .nav.nav-tabs li a svg {
  display: block;
  margin: auto;
  margin-bottom: -2px;
}

.header_tab_items .nav.nav-tabs li a svg path {
  transition: all 300ms linear 0s;
}

.header_tab_items .nav.nav-tabs li a:hover {
  background: var(--black_50);
  color: var(--p_color);
}

.header_tab_items .nav.nav-tabs li a:hover svg path {
  fill: var(--p_color);
}

.header_tab_items .nav.nav-tabs li a.active {
  background-color: var(--body_bg);
  color: var(--brand_color);
}

.header_tab_items .nav.nav-tabs li a.active svg path {
  fill: var(--brand_color);
}

.header_tab_content .tab-pane {
  padding: 0;
}

.freelancing_list {
  padding-top: 60px;
  padding-bottom: 100px;
  background: #f2f5f7;
}

.freelancing_list .section_title {
  margin-bottom: 30px;
}

.freelancing_list .section_title h2 {
  font-size: 30px;
  font-weight: bold;
}

.h_content_items.collapse-wrap {
  display: none;
}

.h_item {
  background: var(--bs-white);
  padding-top: 30px;
  padding-bottom: 22px;
  margin-bottom: 30px;
  box-shadow: 0 1px 3px rgba(29, 56, 70, 0.16);
  border-radius: 5px;
  text-align: center;
  transition: all 300ms linear 0s;
}

.h_item svg {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.h_item h4 {
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 13px;
  margin-top: 19.48px;
  transition: all 300ms linear 0s;
}

.h_item h4:hover {
  color: var(--brand_color);
}

.h_item p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.375;
  text-align: center;
  padding: 0 22.5px;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #68707F;
}

.h_item:hover {
  box-shadow: 0 14px 25px rgba(29, 56, 70, 0.06);
}

.more {
  margin-top: 40px;
}

.more a {
  background: #f0f3f5;
  box-shadow: 0 1px 1px rgba(29, 56, 70, 0.16);
  border-radius: 5px;
  color: #1d3846;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  line-height: 50px;
  margin: 0 auto;
  display: inline-block;
  font-size: 16px;
  padding: 0 51px;
  width: 220px;
}

.more a ion-icon {
  position: relative;
  top: 4.8px;
  margin-right: 13px;
  font-size: 20px;
}

.more a span {
  display: inline-block;
}

.more a span+span {
  display: none;
}

.more a.active span {
  display: none;
}

.more a.active span+span {
  display: inline-block;
}

.more a:hover {
  background: rgba(76, 76, 241, 0.2);
}

/* End Header Tabs Area css
  ============================================================================================ */
/* End Mission Area css
  ============================================================================================ */
.mission_area {
  padding-top: 75px;
}

.mission_area .main_title {
  margin-bottom: 60px;
}

.mission_item {
  box-shadow: 0 0.3px 0.9px rgba(29, 56, 70, 0.1), 0 1.6px 3.6px rgba(29, 56, 70, 0.13);
  border-radius: 5px;
  padding: 115px 50px;
  text-align: center;
  transition: all 300ms linear 0s;
  display: block;
}

.mission_item img {
  margin-bottom: 20px;
}

.mission_item.one {
  background: #e3f5fc;
}

.mission_item.one:hover {
  background: #c2e5f2;
}

.mission_item.two {
  background: #eefce3;
}

.mission_item.two:hover {
  background: #d6f2c2;
}

.mission_item svg {
  margin-bottom: 20px;
}

.mission_item h4 {
  font-size: 20px;
  font-weight: 700;
  color: #1d2746;
  margin-bottom: 7px;
}

.mission_item p {
  margin-bottom: 0;
  font-size: 18px;
  color: #6b707f;
}

/* End Mission Area css
  ============================================================================================ */
/* Quesiton Area css
  ============================================================================================ */
.common_question_area {
  padding-top: 90px;
  padding-bottom: 100px;
}

.common_question_area .main_title {
  margin-bottom: 30px;
}

.common_question_area .question_menu .nav {
  margin-bottom: 60px;
}

.common_question_area .question_menu .nav li {
  margin-right: 20px;
  margin-bottom: 0;
}

.common_question_area .question_menu .nav li:last-child {
  margin-right: 0;
}

.common_question_area .question_menu .nav li a {
  font-size: 14px;
  font-weight: 500;
  color: var(--black_800);
  border-radius: 4px;
  line-height: 55px;
  padding: 0 28px;
  display: inline-block;
  background: transparent;
}

.common_question_area .question_menu .nav li a ion-icon {
  color: var(--p_color);
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.common_question_area .question_menu .nav li a:hover {
  color: var(--brand_color);
  background: rgba(76, 76, 241, 0.2);
}

.common_question_area .question_menu .nav li a:hover ion-icon {
  color: var(--brand_color);
}

.common_question_area .question_menu .nav li a.active {
  color: #fff;
  background: var(--brand_color);
}

.common_question_area .question_menu .nav li a.active ion-icon {
  color: #fff;
}

.common_question_area .question_menu .topic_list_item {
  background: var(--bs-white);
  box-shadow: 0 0.3px 0.9px rgba(29, 56, 70, 0.1), 0 1.6px 3.6px rgba(29, 56, 70, 0.13);
  border-radius: 5px;
  padding-top: 38px;
  padding-bottom: 38px;
  transition: all 300ms linear 0s;
}

.common_question_area .question_menu .topic_list_item h4 {
  margin-bottom: 20px;
}

.common_question_area .question_menu .topic_list_item .text_btn {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
}

.common_question_area .question_menu .topic_list_item .text_btn:hover {
  color: var(--brand_color);
}

.common_question_area .question_menu .topic_list_item ul li a:hover {
  color: var(--brand_color);
}

.common_question_area .question_menu .topic_list_item:hover {
  box-shadow: 0 14px 25px rgba(29, 56, 70, 0.06);
}

/* End Quesiton Area css
============================================================================================ */
/* End Quesiton Area css
============================================================================================ */
.asking_area {
  padding: 70px 0;
}

.asking_area .asking_item {
  background: var(--bs-white);
  box-shadow: 0 1px 3px rgba(12, 0, 46, 0.16);
  border-radius: 5px;
  position: relative;
  padding: 35px 35px;
}

.asking_area .asking_item h5 {
  font-size: 14px;
  margin-bottom: 11px;
  font-weight: 400;
}

.asking_area .asking_item h4 {
  margin-bottom: 25px;
}

.asking_area .asking_item svg,
.asking_area .asking_item img {
  position: absolute;
  right: 0;
  bottom: -1px;
}

.asking_area .asking_item .main_btn {
  font-weight: 500;
}

/* End Quesiton Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.ycp .belah .luhur span.about {
  display: none;
}

.ycp .belah .luhur {
  height: 74px;
}

.ycp .belah .luhur span.tombol {
  font-size: 14px;
}

.ycp .belah .luhur span.tombol.vid-next {
  float: right;
  margin-right: 0;
}

/* Video Area css
============================================================================================ */
.video_list_area {
  background: rgba(76, 76, 241, 0.07);
}

.video_list_area.bg_gradient {
  background: linear-gradient(180deg, #ffffff 0%, #f0f3f7 100%);
}

.video_list_area.bg_gradient .accordion .card {
  box-shadow: 0 0.3px 0.9px rgba(29, 56, 70, 0.1), 0 1.6px 3.6px rgba(29, 56, 70, 0.13);
}

.video_list_area .art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-highlight span {
  background: transparent !important;
}

.video_list_area .art-video-player .art-bottom {
  overflow: visible;
}

.video_list_area .art-state {
  width: 80px;
  height: 80px;
}

.video_list_area .art-state ion-icon {
  font-size: 28px;
}

.video_list_area .art-mask-show .art-danmuku:before {
  content: "";
  background: rgba(54, 56, 149, 0.5);
  border-radius: 5px 5px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
}

.video_list_area .add_icon .art-danmuku:before {
  content: "";
  background: rgba(54, 56, 149, 0.5);
  border-radius: 5px 5px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
}

.video_list_area.theatermode {
  overflow: hidden;
}

.video_list_area.theatermode .col-lg-7 {
  flex: 0 0 100%;
  max-width: 100%;
  transition: all 300ms linear 0s;
}

.video_list_area.theatermode .col-lg-5 {
  flex: 0 0 100%;
  max-width: 100%;
}

.video_list_area.theatermode .col-lg-5 .video_list {
  width: 100%;
  margin-top: 100px;
}

.video_list_area.theatermode .col-lg-5 .video_list .video_list_inner .card .card-body .nav li {
  width: 100%;
  display: block;
}

.art-auto-size {
  height: 530px !important;
  align-items: flex-start !important;
}

.art-video-player.art-subtitle-show.art-layer-show.art-control-show.art-mask-show.art-fullscreen:hover .art-bottom {
  bottom: 0 !important;
}

.art-video-player.art-fullscreen-web {
  height: 70% !important;
  position: relative !important;
}

.art-fullscreen-web+.col-lg-5 {
  flex: 0 0 100%;
  max-width: 100%;
}

.art-video-player {
  width: 100% !important;
}

.video_list h3 {
  font-size: 24px;
  color: var(--black_800);
  margin-bottom: 30px;
}

.video_list .mCustomScrollBox {
  padding-right: 20px;
}

.video_list .mCSB_scrollTools {
  width: 8px;
  background: #fff;
  border-radius: 4px;
}

.video_list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
  background: #b8c3cc;
}

.video_list .mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background: #fff;
}

.video_list .video_list_inner {
  max-height: 472px;
  padding-right: 0;
}

.video_list .video_list_inner .card {
  background: var(--bs-white);
  border: none;
  border-radius: 5px !important;
  margin-bottom: 24px;
}

.video_list .video_list_inner .card:last-child {
  margin-bottom: 0;
}

.video_list .video_list_inner .card .card-header {
  border: none;
  background: transparent;
  padding: 0;
}

.video_list .video_list_inner .card .card-header button {
  padding: 14px 35px;
  font-size: 18px;
  color: var(--black_800);
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 0;
  border: none;
  outline: none;
  text-transform: inherit;
}

.video_list .video_list_inner .card .card-header button span {
  color: #7f8493;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 0;
  margin-left: 22px;
}

.video_list .video_list_inner .card .card-header button i {
  float: right;
  line-height: 26px;
  display: none;
  color: var(--p_color);
}

.video_list .video_list_inner .card .card-header button i+i {
  display: inline-block;
}

.video_list .video_list_inner .card .card-header button.collapsed i {
  display: inline-block;
}

.video_list .video_list_inner .card .card-header button.collapsed i+i {
  display: none;
}

.video_list .video_list_inner .card .card-body {
  padding: 8px 35px 25px 35px;
}

.video_list .video_list_inner .card .card-body .nav {
  border: none;
}

.video_list .video_list_inner .card .card-body .nav li {
  margin-bottom: 20px;
}

.video_list .video_list_inner .card .card-body .nav li a {
  padding: 0;
  border: none;
  background: transparent;
}

.video_list .video_list_inner .card .card-body .nav li a .media .d-flex {
  padding-right: 20px;
}

.video_list .video_list_inner .card .card-body .nav li a .media .d-flex .video_tab_img {
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.video_list .video_list_inner .card .card-body .nav li a .media .d-flex .video_tab_img:before {
  content: "";
  background: rgba(29, 39, 70, 0.55);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 300ms linear 0s;
}

.video_list .video_list_inner .card .card-body .nav li a .media .d-flex .video_tab_img:after {
  content: "\f215";
  font: normal normal normal 20px/1 ionicons;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  position: absolute;
  color: #fff;
  opacity: 0;
  transition: all 300ms linear 0s;
}

.video_list .video_list_inner .card .card-body .nav li a .media .media-body h4 {
  color: var(--black_800);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
  transition: all 300ms linear 0s;
}

.video_list .video_list_inner .card .card-body .nav li a .media .media-body .list div {
  display: inline-block;
  color: #7f8493;
  font-weight: 400;
  font-size: 12px;
  margin-right: 20px;
}

.video_list .video_list_inner .card .card-body .nav li a .media .media-body .list div ion-icon {
  padding-right: 6px;
}

.video_list .video_list_inner .card .card-body .nav li a .media .media-body .list div:last-child {
  margin-right: 0;
}

.video_list .video_list_inner .card .card-body .nav li a:hover .media .d-flex .video_tab_img:before,
.video_list .video_list_inner .card .card-body .nav li a.active .media .d-flex .video_tab_img:before {
  opacity: 1;
}

.video_list .video_list_inner .card .card-body .nav li a:hover .media .d-flex .video_tab_img:after,
.video_list .video_list_inner .card .card-body .nav li a.active .media .d-flex .video_tab_img:after {
  opacity: 1;
}

.video_list .video_list_inner .card .card-body .nav li a:hover .media .media-body h4,
.video_list .video_list_inner .card .card-body .nav li a.active .media .media-body h4 {
  color: var(--brand_color);
}

.video_list .video_list_inner .card .card-body .nav li:last-child {
  margin-bottom: 0;
}

.art-video-player.art-layer-show .art-layers {
  display: none !important;
}

.art-control.art-control-control13.art-control-onlyText {
  display: none;
}

/* End Video Area css
============================================================================================ */
/* End Video Area css
============================================================================================ */
.video_slider_area {
  background: #f9fbfc;
  padding-top: 110px;
  padding-bottom: 120px;
}

.video_slider_area .container {
  position: relative;
}

.video_slider_area.theatermode .col-lg-7 {
  transition: all 300ms linear 0s;
}

.video_slider_area.theatermode .gallery_main_area {
  transition: all 300ms linear 0s;
  position: absolute;
  right: 0;
  bottom: 177px;
  width: 60%;
}

.video_slider_area.theatermode .gallery_main_area .gallery-thumbs {
  margin-top: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.video_slider_area.theatermode .gallery_main_area .gallery-thumbs .item {
  padding-left: 10px;
  padding-right: 10px;
}

.video_slider_area.theatermode .gallery_main_area .gallery-thumbs .item img {
  max-width: 100%;
}

.video_slider_area.theatermode .gallery_main_area .gallery-thumbs .item .caption_text {
  padding-left: 15px;
  padding-right: 15px;
}

.video_slider_area.theatermode .gallery_main_area .gallery-thumbs .item .caption_text svg {
  width: 23px;
  height: 23px;
}

.video_slider_area.theatermode .gallery_main_area .gallery-thumbs .item .caption_text h4 {
  font-size: 12px;
  margin-top: 8px;
}

.video_slider_area.theatermode .gallery_main_area .next svg,
.video_slider_area.theatermode .gallery_main_area .prev svg {
  width: 10px;
}

.video_slider_area.theatermode .gallery_main_area .prev {
  left: 8px;
}

.video_slider_area.theatermode .gallery_main_area .next {
  right: 8px;
}

.video_slider_area .gallery_inner_thumb {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.video_slider_area .gallery_inner_thumb:before {
  content: "";
  height: 145px;
  width: 100%;
  background: linear-gradient(180deg, rgba(44, 44, 81, 0) 0%, rgba(44, 44, 81, 0.9) 73.67%);
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 5px;
  top: 7px;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-controls-right .art-control.art-control-subtitle[aria-label="Hide subtitle"] svg {
  fill: rgba(235, 245, 250, 0.5);
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-controls-right .art-control.art-control-subtitle[aria-label="Show subtitle"] svg {
  fill: #fff;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-controls-right .art-control.art-control-setting[aria-label="Hide subtitle"] svg {
  fill: #fff;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-controls-right .art-control.art-control-setting[aria-label="Show subtitle"] svg {
  fill: rgba(235, 245, 250, 0.5);
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-controls-right .art-control.art-control-control13.art-control-onlyText {
  display: none;
}

.video_slider_area .gallery-top .art-auto-size:hover .art-video-player .art-bottom {
  transform: translateY(0);
}

.video_slider_area .gallery-top .art-auto-size {
  height: 410px !important;
}

.video_slider_area .gallery-top .art-video-player .art-bottom {
  bottom: 0;
  background: rgba(235, 245, 250, 0.1);
  border-radius: 0 0 5px 5px;
  transform: translateY(48px);
  overflow: visible !important;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control-volume .art-volume-panel .art-volume-slider-handle {
  background: #fff;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control-volume .art-volume-panel .art-volume-slider-handle:after,
.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control-volume .art-volume-panel .art-volume-slider-handle:before {
  background: #fff;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control-volume .art-volume-panel .art-volume-slider-handle:after {
  background: rgba(235, 245, 250, 0.5);
}

.video_slider_area .gallery-top .art-video-player {
  border-radius: 10px;
  overflow: hidden;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-progress {
  background: rgba(235, 245, 250, 0.1);
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-progress .art-control-progress {
  height: 2px;
  transition: all 300ms linear 0s;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-progress .art-control-progress:hover {
  height: 4px;
  margin-top: -2px;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control {
  color: #fff;
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control .art-icon svg {
  fill: rgba(235, 245, 250, 0.5);
}

.video_slider_area .gallery-top .art-video-player .art-bottom .art-controls .art-control .art-icon svg:hover {
  fill: #fff !important;
}

.video_slider_area .gallery-top .art-video-player {
  height: 410px !important;
}

.video_slider_area.theatermode .gallery-top .col-lg-7 {
  flex: 0 0 100%;
  max-width: 100%;
}

.video_slider_area.theatermode .gallery-top .col-lg-7 .artplayer-app {
  height: 717px !important;
}

.video_slider_area.theatermode .gallery-top .col-lg-7 .art-video-player {
  height: 717px !important;
}

.video_slider_area.theatermode .gallery-top .col-lg-5 {
  flex: 0 0 40%;
  max-width: 40%;
}

.video_slider_area.theatermode .gallery-top .slide_text {
  margin-top: 30px;
  padding-left: 0;
  padding-bottom: 4px;
}

.video_slider_area.theatermode .gallery-top .slide_text p {
  max-width: 100%;
}

.slide_text {
  padding-left: 8px;
  transition: all 300ms linear 0s;
}

.slide_text h4 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
}

.slide_text p {
  color: var(--black_600);
  max-width: 420px;
  margin-bottom: 12px;
  transition: all 300ms linear 0s;
}

.slide_text .video_user a {
  color: var(--p_color);
  font-size: 14px;
  margin-right: 15px;
  display: inline-block;
}

.slide_text .video_user img {
  width: auto;
  max-width: 100%;
  display: inline-block;
  position: relative;
  top: -1px;
}

.slide_text .video_user ion-icon {
  position: relative;
  top: 2px;
}

.gallery_main_area {
  position: relative;
}

.gallery_main_area .prev,
.gallery_main_area .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.gallery_main_area .prev:hover svg path,
.gallery_main_area .next:hover svg path {
  fill: var(--brand_color);
}

.gallery_main_area .gallery-thumbs .slick-track {
  margin: 0 -15px;
}

.gallery_main_area .prev {
  left: -20px;
}

.gallery_main_area .prev,
.gallery_main_area .next {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid #dfe1e5;
  z-index: 0;
  color: var(--black_400);
  background-color: #FFF;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.gallery_main_area .prev.slick-disabled,
.gallery_main_area .next.slick-disabled {
  display: none !important;
}

.gallery_main_area .prev:hover,
.gallery_main_area .next:hover {
  color: var(--black_700);
}

.gallery_main_area .next {
  right: -20px;
}

.gallery-thumbs {
  margin-top: 45px;
}

.gallery-thumbs .item {
  padding: 0 15px;
  position: relative;
  cursor: pointer;
}

.gallery-thumbs .item>img {
  width: 100%;
}

.gallery-thumbs .item .caption_text .play-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  left: 50%;
  display: none;
}

.gallery-thumbs .item .caption_text .play-icon ion-icon {
  font-size: 18px;
}

.gallery-thumbs .item .caption_text img {
  margin: auto !important;
}

.gallery-thumbs .item .caption_text h4 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
  position: absolute;
  bottom: 15px;
  transform: translateX(-50%);
  left: 50%;
}

.gallery-thumbs .item .caption_text svg {
  opacity: 0;
}

.gallery-thumbs .item:hover .caption_text svg {
  opacity: 1;
}

.gallery-thumbs .item.slick-current .caption_text svg {
  opacity: 1;
}

.gallery-thumbs .slick-prev,
.gallery-thumbs .slick-next {
  z-index: 5;
}

.gallery-thumbs .slick-prev {
  left: 0;
}

/* .gallery-thumbs .slick-prev:before {
  content: "";
  font-size: 60px;
  color: var(--p_color);
  opacity: 1;
  background-image: url('../../img/svg/arrow_left.svg');
} */
.gallery-thumbs .slick-next {
  right: 0;
}

.gallery-thumbs .item.slick-current .caption_text .play-icon {
  display: flex;
}

.social_option {
  margin-top: 32px;
}

.social_option .count_div.active i {
  color: var(--brand_color);
}

.social_option .count_div {
  display: inline-block;
  line-height: 40px;
  font-size: 14px;
  margin-right: 15px;
  color: var(--p_color);
}

.social_option .count_div a,
.social_option .count_div ion-icon {
  color: var(--p_color);
  margin-right: 2px;
}

.social_option .share_gropu {
  position: relative;
  display: inline-block;
  width: 160px;
  transition: all 300ms linear 0s;
}

.social_option .share_gropu .nav {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 300ms linear 0s;
  transform: perspective(1px) translateZ(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transform: scaleX(0);
}

.social_option .share_gropu.active {
  width: 150px;
}

.social_option .share_gropu.active .nav {
  transform: scaleX(1);
}

.social_option .nav {
  background: rgba(76, 76, 241, 0.2);
  border-radius: 20px;
  box-shadow: 0 0.3px 0.9px rgba(29, 56, 70, 0.1), 0 1.6px 3.6px rgba(29, 56, 70, 0.13);
  padding-right: 12px;
}

.social_option .nav li {
  display: inline-block;
  margin-right: 10px;
}

.social_option .nav li ion-icon {
  color: var(--black_700);
}

.social_option .nav li:first-child a {
  background: #fafcfc;
  box-shadow: 0 0 3.6px rgba(29, 56, 70, 0.1);
  border-radius: 20px;
  height: 42px;
  width: 42px;
  text-align: center;
}

.social_option .nav li a {
  color: #373b46;
  display: inline-block;
  line-height: 42px;
}

.social_option .nav li a ion-icon {
  position: relative;
  top: 2px;
}

.social_option .nav li:hover a {
  color: var(--brand_color);
}

.status_area {
  margin-top: 125px;
}

.status_area .status_inner {
  background: var(--bs-white);
  box-shadow: 0 1px 3px rgba(12, 0, 46, 0.1);
  border-radius: 5px;
  padding: 25px 50px;
}

.status_area .status_inner .media {
  align-self: center;
  align-items: center;
}

.status_area .status_inner .media .d-flex {
  margin-right: 86px;
}

.status_area .status_inner .media .media-body {
  align-self: center;
}

.status_area .status_inner .media .media-body h4 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.status_area .status_inner .media .media-right .main_btn {
  background: var(--brand_color);
  color: #fff;
  line-height: 60px;
  padding: 0 37px;
  font-size: 20px;
  font-weight: 700;
}

.status_area .status_inner .media .media-right .main_btn:hover {
  color: #fff;
  background: var(--brand_color);
}

.art-state,
.play-icon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.art-state ion-icon,
.play-icon ion-icon {
  text-shadow: 0 6px 12px rgba(76, 76, 241, 0.5);
  color: var(--brand_color);
}

.video_popup_slider {
  padding: 120px 0;
  background: #ffffff;
}

.video_popup_slider .container {
  position: relative;
}

.video_popup_slider .prev1,
.video_popup_slider .next1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  background: #54595d;
  opacity: 0.5;
  border-radius: 50%;
  transition: all 300ms linear 0s;
  cursor: pointer;
  text-align: center;
}

.video_popup_slider .prev1 ion-icon,
.video_popup_slider .next1 ion-icon {
  color: #fff;
  font-size: 30px;
  position: relative;
  top: 10px;
}

.video_popup_slider .prev1:hover,
.video_popup_slider .next1:hover {
  opacity: 1;
}

.video_popup_slider .prev1.slick-disabled,
.video_popup_slider .next1.slick-disabled {
  opacity: 0;
  visibility: hidden;
}

.video_popup_slider .prev1 {
  left: 25px;
}

.video_popup_slider .next1 {
  right: 25px;
}

.video_popup_slider .popup_slick {
  margin-left: -15px;
  margin-right: -15px;
}

.video_popup_slider .popup_slick .item {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.video_popup_slider .popup_slick .item img {
  max-width: 100%;
}

.video_popup_slider .popup_slick .item:hover .item_modal_box .text svg {
  opacity: 1;
  transform: scale(1);
}

.video_popup_slider .popup_slick .item_modal_box {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 320px;
}

.video_popup_slider .popup_slick .item_modal_box video::-webkit-media-controls {
  display: none !important;
}

.video_popup_slider .popup_slick .item_modal_box video::-moz-range-track {
  display: none !important;
}

.video_popup_slider .popup_slick .item_modal_box video::-moz-range-thumb {
  display: none !important;
}

.video_popup_slider .popup_slick .item_modal_box:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 145px;
  background: linear-gradient(180deg, rgba(29, 56, 70, 0) 0%, rgba(29, 56, 70, 0.8) 100%);
}

.video_popup_slider .popup_slick .item_modal_box video {
  height: 320px;
  width: 100%;
  z-index: 3;
}

.video_popup_slider .popup_slick .item_modal_box .text {
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 0 15px;
}

.video_popup_slider .popup_slick .item_modal_box .text svg,
.video_popup_slider .popup_slick .item_modal_box .text img {
  position: absolute;
  left: 50%;
  top: 39%;
  margin-left: -20px;
  margin-top: -20px;
  opacity: 0;
  transform: scale(0.8);
  transition: all 300ms linear 0s;
}

.video_popup_slider .popup_slick .item_modal_box .text h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 17px;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 0 15px;
  left: 0;
}

.video_popup_slider .popup_slick .item_modal_box:hover .text img {
  transform: scale(1);
  opacity: 1;
}

.modal_slider_css .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.modal_slider_css .modal-dialog .modal-content {
  background: #4b5155;
  min-height: 100vh;
}

.modal_slider_css .modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  padding-top: 25px;
  width: 100%;
  height: 76px;
  position: relative;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .button_social {
  position: absolute;
  right: 0;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .close {
  height: 50px;
  width: 50px;
  background: #41484d;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  opacity: 1;
  margin: 0;
  margin-right: 25px;
  float: none;
  display: inline-block;
  transition: all 300ms linear 0s;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .close ion-icon {
  position: relative;
  left: -1px;
  top: -1px;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .close:hover {
  background: var(--brand_color);
}

.modal_slider_css .modal-dialog .modal-content .modal-header .share_icon {
  height: 50px;
  width: 50px;
  background: #41484d;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  display: inline-block;
  color: #fff;
  opacity: 1;
  margin: 0;
  margin-right: 27px;
  padding: 1rem 1rem;
  cursor: pointer;
  transition: all 300ms linear 0s;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .share_icon ion-icon {
  position: relative;
  left: -1px;
  top: -1px;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .share_icon:hover {
  background: var(--brand_color);
}

.modal_slider_css .modal-dialog .modal-content .modal-header .modal_social {
  position: absolute;
  right: 106px;
  top: 0;
  background: #393d40;
  width: 171px;
  border-radius: 25px;
  justify-content: flex-end;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transform-origin: 100% 50%;
  transition-property: transform;
  transform: scaleX(0);
  transition: all 300ms linear 0s;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .modal_social.active {
  transform: scaleX(1);
}

.modal_slider_css .modal-dialog .modal-content .modal-header .modal_social li a {
  display: inline-block;
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 300ms linear 0s;
  line-height: 50px;
  font-size: 18px;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .modal_social li a i {
  position: relative;
  top: 2px;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .modal_social li a:hover {
  color: #fff;
}

.modal_slider_css .modal-dialog .modal-content .modal-header .modal_social li:last-child a {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  background: #41484d;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 22px;
  line-height: 56px;
  margin-right: 0;
}

.modal_slider_css .modal-dialog .modal-content .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.modal_slider_css .modal-dialog .modal-content .modal-body #modal_slider {
  padding-left: 0;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting {
  position: relative;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .quesiton_slider {
  position: relative;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .prev_modal,
.modal_slider_css .modal-dialog .modal-content .modal-body .next_modal {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  background: rgba(166, 174, 179, 0.5);
  border-radius: 50%;
  transition: all 300ms linear 0s;
  color: #c0c3cc;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 5;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .prev_modal:hover,
.modal_slider_css .modal-dialog .modal-content .modal-body .next_modal:hover {
  background: var(--brand_color);
}

.modal_slider_css .modal-dialog .modal-content .modal-body .prev_modal ion-icon,
.modal_slider_css .modal-dialog .modal-content .modal-body .next_modal ion-icon {
  font-size: 20px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .prev_modal.slick-disabled,
.modal_slider_css .modal-dialog .modal-content .modal-body .next_modal.slick-disabled {
  opacity: 0;
  visibility: hidden;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .prev_modal {
  left: 60px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .next_modal {
  right: 60px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting {
  max-width: 1350px;
  margin: auto;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item {
  transform: scale(0.8);
  margin: 0 20px;
  transition: all 300ms linear 0s;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item h4 {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js {
  max-width: 100%;
  height: 669px;
  border-radius: 5px;
  overflow: hidden;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-poster {
  background-size: cover;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control.vjs-control {
  margin: 0;
  margin-right: -40px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-time-tooltip {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control .vjs-play-progress:before {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control .vjs-load-progress {
  background-color: #9da2a6;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control .vjs-play-progress {
  background-color: #fff;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control .vjs-load-progress,
.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control .vjs-play-progress {
  height: 5px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-progress-control .vjs-slider:before {
  height: 5px;
  background: #9da2a6;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-big-play-button {
  background: url(../../img/play-button.png) no-repeat;
  border: none;
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-control-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 5px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-control-bar .vjs-button {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-control-bar .vjs-current-time {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item .video-js .vjs-control-bar .vjs-duration {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item video {
  border-radius: 5px;
  overflow: hidden;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting .video_item img {
  max-width: 100%;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li .video_item {
  position: relative;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li .video_item:before {
  content: "";
  background: rgba(107, 112, 127, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li .video_item {
  position: relative;
  filter: blur(5px);
  transition: all 300ms linear 0s;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li .video_item:before {
  content: "";
  background: rgba(107, 112, 127, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  transition: all 300ms linear 0s;
  z-index: 5;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li .video_item:hover {
  filter: blur(0);
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li .video_item:hover:before {
  opacity: 1;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item {
  transform: scale(1);
  filter: blur(0);
  border-radius: 0 0 5px 5px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item:before {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item:after {
  content: "";
  background: linear-gradient(180deg, rgba(29, 56, 70, 0) 0%, rgba(29, 56, 70, 0.8) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 285px;
  width: 100%;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item h4 {
  visibility: visible;
  opacity: 1;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item .vjs-big-play-button {
  display: block;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item .vjs-playing .vjs-big-play-button {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item .vjs-has-started .vjs-big-play-button {
  display: none;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item .vjs-ended .vjs-big-play-button {
  display: block;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current .video_item .vjs-paused .vjs-big-play-button {
  display: block;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current+.slick-active .video_item {
  filter: blur(5px);
  transition: all 300ms linear 0s;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current+.slick-active .video_item:hover {
  filter: blur(0);
}

.modal_slider_css .modal-dialog .modal-content .modal-body .posting li.slick-current+.slick-active .video_item:hover:before {
  opacity: 1;
}

.modal_slider_css .modal-dialog .modal-content .modal-body h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-left: 50px;
  margin-bottom: 18px;
  margin-top: 50px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel {
  position: relative;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .prev_car,
.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .next_car {
  position: absolute;
  top: 50%;
  height: 50px;
  width: 50px;
  background: rgba(34, 37, 38, 0.9);
  border-radius: 50%;
  transition: all 300ms linear 0s;
  color: #c0c3cc;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  transform: translateY(-50%);
  opacity: 0;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .prev_car ion-icon,
.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .next_car ion-icon {
  font-size: 20px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .prev_car:hover,
.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .next_car:hover {
  background: #000;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .prev_car {
  left: 60px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .prev_car.slick-disabled {
  opacity: 0;
  visibility: hidden;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .next_car {
  right: 60px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .next_car.slick-disabled {
  opacity: 0;
  visibility: hidden;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel:hover .prev_car,
.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel:hover .next_car {
  opacity: 1;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .slick-current .text_item {
  background: #393d40;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .main_div_carousel .slick-current .text_item h4 {
  color: #fff;
}

.modal_slider_css .modal-dialog .modal-content .modal-body #modal_carousel {
  margin-bottom: 0;
}

.modal_slider_css .modal-dialog .modal-content .modal-body #modal_carousel li {
  padding-left: 10px;
  padding-right: 10px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body #modal_carousel .slick-list {
  padding: 0 27% 0 0 !important;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .text_item {
  background: #393d40;
  padding: 13px 24px;
  border-radius: 5px;
}

.modal_slider_css .modal-dialog .modal-content .modal-body .text_item h4 {
  color: #8a8e99;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 0;
}

.video_slider_area .video-js {
  min-height: 410px;
  border-radius: 5px;
  overflow: hidden;
}

.video_slider_area .video-js:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(44, 44, 81, 0.6);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video_slider_area .video-js.vjs-has-started:before {
  opacity: 0;
  visibility: hidden;
}

.video_slider_area .video-js .vjs-big-play-button {
  border: none;
}

.video_slider_area .video-js .vjs-control-bar {
  transform: translateY(45px);
}

.video_slider_area .video-js .vjs-control-bar .vjs-button {
  color: rgba(235, 245, 250, 0.5);
  transition: all 300ms linear 0s;
}

.video_slider_area .video-js .vjs-control-bar .vjs-button:hover {
  color: #fff;
}

.video_slider_area .video-js .vjs-control.vjs-button.vjs-control-button.vjs-mode-control span {
  border-color: rgba(235, 245, 250, 0.5);
  transition: all 300ms linear 0s;
}

.video_slider_area .video-js .vjs-control.vjs-button.vjs-control-button.vjs-mode-control:hover span {
  border-color: #fff;
}

.video_slider_area .video-js:hover .vjs-control-bar {
  transform: translateY(0);
}

.art-video-player .art-bottom .art-controls {
  background: var(--bs-white);
}

/* End Video Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============== Start doc_tag_area css =============*/
.doc_tag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 0;
  margin-bottom: 75px;
}

.doc_tag .nav-item {
  margin-bottom: 0;
}

.doc_tag .nav-item .nav-link {
  font-size: 14px;
  font-weight: 400;
  color: var(--brand_color);
  border: 1px solid rgba(76, 76, 241, 0.2);
  border-radius: 4px;
  background-color: rgba(16, 179, 214, 0.031);
  padding: 0 20px;
  line-height: 28px;
  margin: 0 5px;
  transition: all 0.4s linear;
}

.doc_tag .nav-item .nav-link:hover,
.doc_tag .nav-item .nav-link.active {
  background-color: var(--brand_color);
  border-color: var(--brand_color);
  box-shadow: 0 10px 20px 0 rgba(76, 76, 241, 0.2);
  color: #fff;
}

.doc_tag_title {
  margin-bottom: 45px;
}

.doc_tag_title h4 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}

.doc_tag_title .line {
  height: 2px;
  width: 100%;
  background: var(--black_50);
  display: block;
}

.doc_tab_pane .row {
  margin-bottom: -85px;
}

.doc_tag_item {
  margin-bottom: 85px;
}

.tag_list {
  margin-bottom: 32px;
}

.tag_list li {
  margin-top: 15px;
}

.tag_list li a {
  font-size: 16px;
  color: var(--p_color);
  line-height: 22px;
  transition: color 0.2s linear;
  display: flex;
}

.tag_list li a i {
  margin-right: 15px;
}

.tag_list li a:hover {
  color: var(--brand_color);
}

/*============== End doc_tag_area css =============*/
/*============== Start documentation_area css =============*/
.documentation_tab {
  border-bottom: 2px solid var(--black_50);
}

.documentation_tab .nav-item {
  margin-bottom: 0;
}

.documentation_tab .nav-item .nav-link {
  border-radius: 0;
  border: 0;
  color: var(--p_color);
  font-size: 14px;
  font-weight: 500;
  padding: 0 20px 10px;
  position: relative;
  transition: color 0.2s linear;
  background: transparent;
}

.documentation_tab .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: auto;
  right: 0;
  background: var(--brand_color);
  transition: width 0.3s linear;
}

.documentation_tab .nav-item .nav-link.active,
.documentation_tab .nav-item .nav-link:hover {
  color: var(--brand_color);
}

.documentation_tab .nav-item .nav-link.active:before,
.documentation_tab .nav-item .nav-link:hover:before {
  width: 100%;
  left: 0;
  right: auto;
}

.documentation_tab_pane {
  padding-top: 84px;
}

.documentation_tab_pane .row {
  margin-bottom: -26px;
}

.documentation_text .round {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(15, 204, 208);
  box-shadow: 0 20px 30px 0 rgba(24, 211, 214, 0.2);
  display: block;
  text-align: center;
  line-height: 100px;
  margin-bottom: 25px;
}

.documentation_text h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 18px;
}

.documentation_text p {
  margin-bottom: 0;
}

.documentation_text .learn_btn {
  padding-top: 20px;
}

.documentation_item {
  padding-right: 40px;
  margin-bottom: 58px;
}

.documentation_item .icon {
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 10px 30px 0 rgba(4, 73, 89, 0.12);
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 66px;
  margin-right: 30px;
}

.documentation_item .media-body h5 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 12px;
  transition: color 0.2s linear;
}

.documentation_item .media-body h5:hover {
  color: var(--brand_color);
}

.documentation_item .media-body p {
  margin-bottom: 0;
  line-height: 24px;
}

/*============== End documentation_area css =============*/
/*============== Start doc_faq_area css =============*/
.doc_faq_area .doc_tag {
  margin-bottom: 50px;
}

.doc_faq_info .card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.1);
  border: 0;
  margin-bottom: 30px;
}

.doc_faq_info .card .card-header {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
  border: 0;
}

.doc_faq_info .card .card-header h2 button {
  display: block;
  text-align: left;
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: var(--brand_color);
  text-decoration: none;
  padding: 19px 40px 19px 30px;
  position: relative;
}

.doc_faq_info .card .card-header h2 button i {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  display: none;
}

.doc_faq_info .card .card-header h2 button i+i {
  display: block;
}

.doc_faq_info .card .card-header h2 button.collapsed {
  color: var(--black_800);
}

.doc_faq_info .card .card-header h2 button.collapsed i {
  display: block;
}

.doc_faq_info .card .card-header h2 button.collapsed i+i {
  display: none;
}

.doc_faq_info .card .card-body {
  border: 0;
  line-height: 24px;
  padding: 0 30px 35px;
}

.doc_faq_info .card.active {
  position: relative;
  z-index: 1;
}

/*============== End doc_faq_area css =============*/
/*============== Start doc_categories_guide_area css =============*/
.doc_categories_guide_area {
  position: relative;
  z-index: 1;
}

.doc_categories_guide_area .shap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 490px;
  z-index: -1;
  background-size: cover;
}

.categories_guide_item {
  border-radius: 6px;
  background: var(--bs-white);
  box-shadow: 0 50px 120px 0 rgba(4, 73, 89, 0.08);
  padding: 40px 40px 50px;
  margin-bottom: 30px;
}

.categories_guide_item img {
  margin-bottom: 34px;
}

.categories_guide_item .doc_tag_title {
  margin-bottom: 24px;
}

.categories_guide_item .doc_tag_title h4 {
  margin-bottom: 0;
}

.categories_guide_item .tag_list {
  margin-bottom: 42px;
}

.all_doc_btn {
  padding: 10px 40px;
  margin-top: 30px;
}

/*============== End doc_categories_guide_area css =============*/
/*============== Start doc_solution_area css =============*/
.doc_solution_area .all_doc_btn {
  padding: 20px 73px;
  font-size: 18px;
}

.doc_solution_area .all_doc_btn i {
  font-size: 25px;
  margin-left: 10px;
}

.doc_solution_item {
  text-align: center;
  padding: 60px;
  margin-bottom: 30px;
}

.doc_solution_item img {
  margin-bottom: 45px;
}

.doc_solution_item h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.doc_solution_item p {
  margin-bottom: 20px;
}

.doc_solution_item.online_doc {
  background: #fcf3da;
}

.doc_solution_item.premium_doc {
  background: #daecfc;
}

/*============== End doc_solution_area css =============*/
/*============== Start doc_fun_fact_area css =============*/
.doc_fun_fact_area {
  background: var(--brand_color);
  padding: 105px 0;
  position: relative;
  z-index: 1;
}

.animated-wave {
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.doc_fact_item {
  text-align: center;
}

.doc_fact_item .counter {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
}

.doc_fact_item p {
  margin-bottom: 0;
  font-size: 18px;
  color: #fff;
  padding-top: 5px;
}

/* Fun Fact Style Two */
.active-animation .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 5s ease-in-out forwards;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.funfact-area {
  padding: 115px 0 90px;
  background-image: linear-gradient(45deg, rgb(245, 253, 255) 0%, rgb(254, 239, 236) 100%);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.funfact-area .smile {
  position: absolute;
  width: 200px;
  height: 200px;
  right: 95px;
  top: -25px;
}

.funfact-boxes {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .funfact-boxes {
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 30px);
  }
}

@media (max-width: 420px) {
  .funfact-boxes {
    width: calc(100% + 15px);
  }
}

.funfact-boxes .funfact-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 50px 80px 0 rgba(2, 47, 57, 0.12);
  min-width: 140px;
  padding: 30px 25px 20px;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .funfact-boxes .funfact-box {
    width: calc(33.33% - 30px);
    margin-right: 30px;
  }
}

@media (max-width: 420px) {
  .funfact-boxes .funfact-box {
    width: calc(50% - 15px);
    margin-right: 15px;
  }
}

.funfact-boxes .funfact-box .fanfact-icon {
  margin-bottom: 20px;
}

.funfact-boxes .funfact-box .fanfact-icon img {
  -webkit-filter: drop-shadow(0 20px 40px #086174);
  filter: drop-shadow(0 20px 40px #086174);
}

.funfact-boxes .funfact-box .counter {
  font-size: 34px;
  font-weight: 500;
  color: var(--brand_color);
  margin-bottom: 10px;
}

.funfact-boxes .funfact-box .title {
  color: #6b707f;
  font-size: 16px;
  margin: 0;
  line-height: 26px;
}

.funfact-boxes .funfact-box.color-two .fanfact-icon img {
  -webkit-filter: drop-shadow(0 20px 40px #734F08);
  filter: drop-shadow(0 20px 40px #734F08);
}

.funfact-boxes .funfact-box.color-two .counter {
  color: #fbb631;
}

.funfact-boxes .funfact-box.color-three .fanfact-icon img {
  -webkit-filter: drop-shadow(0 20px 40px #73083F);
  filter: drop-shadow(0 20px 40px #73083F);
}

.funfact-boxes .funfact-box.color-three .counter {
  color: #fd5baf;
}

.funfact-boxes .funfact-box.color-four .fanfact-icon img {
  -webkit-filter: drop-shadow(0 20px 40px #087358);
  filter: drop-shadow(0 20px 40px #087358);
}

.funfact-boxes .funfact-box.color-four .counter {
  color: #11c296;
}

.funfact-boxes .funfact-box.color-five .fanfact-icon img {
  -webkit-filter: drop-shadow(0 20px 40px #2C0873);
  filter: drop-shadow(0 20px 40px #2C0873);
}

.funfact-boxes .funfact-box.color-five .counter {
  color: #9360fc;
}

/*============== End doc_fun_fact_area css =============*/
/*============== Start theme_doc_area css =============*/
.theme_doc_area .row {
  margin-bottom: -30px;
}

.theme_doc_item {
  box-shadow: 0 4px 8px 0 rgba(4, 73, 89, 0.05);
  border-radius: 6px;
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  transition: all 0.3s linear;
  cursor: pointer;
}

.theme_doc_item img {
  margin-right: 30px;
}

.theme_doc_item .c_head {
  margin-bottom: 14px;
  transition: color 0.2s linear;
}

.theme_doc_item .c_head:hover {
  color: var(--brand_color);
}

.theme_doc_item p {
  margin-bottom: 0;
}

.theme_doc_item:hover {
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.08);
}

/*============== End theme_doc_area css =============*/
/*============== End theme_doc_area css =============*/
.doc_action_info {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 50px 150px 0 rgba(4, 73, 89, 0.1);
  padding: 88px 70px;
  position: relative;
  z-index: 2;
}

.doc_action_info.mt_130 {
  margin-bottom: -130px;
}

.doc_action_info .media img {
  margin-right: 30px;
}

.doc_action_info .media .media-body h2 {
  margin-bottom: 13px;
}

.doc_action_info .media .media-body p {
  margin-bottom: 0;
  font-size: 18px;
}

.doc_action_info .action_btn {
  box-shadow: none;
}

.doc_action_info .action_btn:hover {
  box-shadow: 0 20px 30px 0 rgba(76, 76, 241, 0.24);
  color: #fff;
}

/*============== End theme_doc_area css =============*/
/*============== Start doc_action_area_two css =============*/
.doc_action_area_three {
  background: #1d2746;
  padding: 140px 0;
  position: relative;
  z-index: 1;
}

.doc_action_area_three .shadows {
  left: 40%;
  transform: translateX(-50%);
  top: 0;
  z-index: -1;
}

.doc_action_area_three .b_man {
  right: 100px;
  bottom: 0;
}

@media (max-width: 1400px) {
  .doc_action_area_three .b_man {
    right: 0;
  }
}

.action_content h2 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 15px;
  margin-top: -10px;
}

.action_content h2 span {
  font-weight: 700;
}

.action_content p {
  font-size: 18px;
  color: #aeb5ca;
  margin-bottom: 0;
}

.action_subscribe_form .form-group {
  position: relative;
}

.action_subscribe_form .form-group .form-control {
  height: 70px;
  line-height: 70px;
}

.action_subscribe_form .form-group .form-control.placeholder {
  color: #a0a1a1;
}

.action_subscribe_form .form-group .form-control:-moz-placeholder {
  color: #a0a1a1;
}

.action_subscribe_form .form-group .form-control::-moz-placeholder {
  color: #a0a1a1;
}

.action_subscribe_form .form-group .form-control::-webkit-input-placeholder {
  color: #a0a1a1;
}

.action_subscribe_form .form-group .s_btn {
  padding: 7px 29px;
  transition: all 0.2s linear;
}

.action_subscribe_form .form-group .s_btn i {
  vertical-align: middle;
  font-size: 20px;
  transition: all 0.2s linear;
  display: inline-block;
}

.action_subscribe_form .form-group .s_btn:hover {
  background: #1d2746;
}

.action_subscribe_form .form-group .s_btn:hover i {
  transform: translateX(5px);
}

.action_subscribe_form .form-check {
  padding-left: 25px;
}

.action_subscribe_form .form-check input[type=checkbox] {
  position: relative;
  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;
  outline-width: 0;
  height: auto;
  position: absolute;
  margin: 0;
  left: 0;
  top: 5px;
}

.action_subscribe_form .form-check input[type=checkbox]:before,
.action_subscribe_form .form-check input[type=checkbox]:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #aeb5ca;
  border-radius: 2px;
}

.action_subscribe_form .form-check input[type=checkbox]:before {
  width: 14px;
  height: 14px;
}

.action_subscribe_form .form-check input[type=checkbox]:after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid var(--brand_color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 4px;
  opacity: 0;
}

.action_subscribe_form .form-check input[type=checkbox]:checked:before {
  border-color: var(--brand_color);
}

.action_subscribe_form .form-check input[type=checkbox]:checked:after {
  opacity: 1;
}

.action_subscribe_form .form-check label {
  position: relative;
  font-size: 15px;
  color: #aeb5ca;
  font-weight: 500;
}

/*============== End doc_action_area_two css =============*/
/*=========== Start doc_faq_area_two ============*/
.fact_navigation_info {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 20px 40px 0 rgba(4, 73, 89, 0.1);
  padding: 40px 40px 28px;
}

.fact_navigation {
  border: 0;
  padding-top: 8px;
  margin: 0;
}

.fact_navigation .nav-item {
  display: block;
  width: 100%;
  padding: 12px 0;
}

.fact_navigation .nav-item .nav-link {
  display: block;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: rgba(16, 179, 214, 0.5);
  position: relative;
  padding-left: 28px;
  border: 0;
  border-radius: 0;
  transition: all 0.2s linear;
}

.fact_navigation .nav-item .nav-link:before {
  content: "5";
  font-family: eleganticons;
  font-size: 20px;
  position: absolute;
  right: 0;
}

.fact_navigation .nav-item .nav-link i {
  position: absolute;
  left: 0;
  top: 2px;
}

.fact_navigation .nav-item .nav-link.active,
.fact_navigation .nav-item .nav-link:hover {
  color: var(--brand_color);
}

.fact_navigation .nav-item .nav-link.active:before,
.fact_navigation .nav-item .nav-link:hover:before {
  color: var(--brand_color);
}

.fact_navigation .nav-item+li {
  border-top: 1px solid #e6eeef;
}

/*=========== End doc_faq_area_two ============*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ Start doc_feedback_area css ==========*/
.doc_feedback_area {
  position: relative;
  z-index: 1;
  padding: 110px 0;
}

.overlay_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(9, 19, 50, 0.8);
}

.doc_feedback_info {
  position: relative;
}

.slider_nav .arrow {
  width: 25px;
  height: 2px;
  background: #989dad;
  transition: all 0.3s ease-in-out;
  display: block;
  margin-top: 12px;
}

.slider_nav .arrow:before,
.slider_nav .arrow:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  left: -2px;
  background-color: #bdb4b4;
  transition: all 0.3s ease-in-out;
}

.slider_nav .prev,
.slider_nav .next {
  height: 25px;
  width: 27px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.slider_nav .prev:hover,
.slider_nav .next:hover {
  width: 40px;
}

.slider_nav .prev:hover .arrow,
.slider_nav .next:hover .arrow {
  width: 40px;
  background: var(--brand_color);
}

.slider_nav .prev:hover .arrow:before,
.slider_nav .prev:hover .arrow:after,
.slider_nav .next:hover .arrow:before,
.slider_nav .next:hover .arrow:after {
  background: var(--brand_color);
}

.slider_nav .prev {
  left: 0;
}

.slider_nav .prev .arrow:before {
  top: 17px;
  transform: rotate(45deg);
}

.slider_nav .prev .arrow:after {
  top: 7px;
  transform: rotate(-45deg);
}

.slider_nav .prev:hover {
  left: -20px;
}

.slider_nav .next {
  right: 0;
}

.slider_nav .next .arrow:before {
  transform: rotate(-45deg);
  right: -1px;
  left: auto;
  bottom: 6px;
}

.slider_nav .next .arrow:after {
  transform: rotate(45deg);
  right: -1px;
  left: auto;
  top: 6px;
}

.slider_nav .next:hover {
  right: -20px;
}

.doc_feedback_slider {
  max-width: 770px;
  margin: 0 auto 0 !important;
  text-align: center;
}

.doc_feedback_slider .item .author_img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin: 0 auto 43px;
}

.doc_feedback_slider .item p {
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  margin-bottom: 38px;
}

.doc_feedback_slider .item h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.doc_feedback_slider .item h6 {
  font-size: 16px;
  color: #989dad;
  font-weight: 400;
  margin-bottom: 0;
}

/*============ End doc_feedback_area css ==========*/
/*============ Start doc_action_area css ==========*/
.doc_action_area {
  position: relative;
  z-index: 1;
  padding: 93px 0;
}

.doc_action_area .overlay_bg {
  background-image: linear-gradient(180deg, rgba(4, 133, 161, 0.6) 0%, rgba(29, 39, 70, 0.6) 100%);
}

.doc_action_area .action_btn {
  border: 1px solid var(--brand_color);
}

.doc_action_area .action_btn:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.action_text h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

.action_text p {
  font-size: 18px;
  line-height: 28px;
  color: #b4b9c8;
  margin-bottom: 0;
}

/*============ End doc_action_area css ==========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
@keyframes wave2 {
  25% {
    transform-origin: 0 100%;
  }

  50% {
    transform: scale(1.8, 1.3);
  }

  75% {
    transform-origin: 100% 100%;
  }

  100% {
    transform: scale(1, 1.3);
  }
}

@keyframes wave4 {
  25% {
    transform: scaleY(0.9);
  }

  75% {
    transform: scaleY(1.1) scaleX(1.02);
  }
}

@keyframes star {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    transform: scale(0.8);
    opacity: 1;
  }

  75% {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }

  100% {
    transform: scale(1.01) rotate(-5deg);
    opacity: 1;
  }
}

@keyframes star2 {
  0% {
    transform: scale(0.3);
  }

  50% {
    transform: scale(0.8);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.01);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: translateX(-10px) scale(0.9);
  }

  100% {
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}

@keyframes spin {
  0% {
    transform: translateX(-10px) scale(0.9);
  }

  100% {
    transform: translateX(30px) scale(1.3) translateY(10px);
  }
}

@-webkit-keyframes spin1 {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes spin1 {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.5);
  }
}

@-webkit-keyframes spin2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(40px);
  }
}

@keyframes spin2 {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(40px);
  }
}

@-webkit-keyframes pulse1 {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(4.5);
    border-radius: 50%;
  }
}

@keyframes pulse1 {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(4.5);
    border-radius: 50%;
  }
}

@keyframes customUp {
  0% {
    transform: translateY(8px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes clipInDown {
  0% {
    clip-path: polygon(-10% -10%, -10% -10%, 110% -10%, 110% -10%);
  }

  100% {
    clip-path: polygon(-10% 110%, -10% -10%, 110% -10%, 110% 110%);
  }
}

.clipInDown {
  animation-name: clipInDown;
}

/*================ preloader css ====================*/
#preloader {
  background: #fff;
  height: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#preloader .loading {
  display: flex;
}

#preloader .ctn-preloader {
  padding-left: 15px;
  padding-right: 15px;
}

#preloader .spinner {
  animation: spinner 3s infinite linear;
  border-radius: 50%;
  border: 3px solid #f1f6f8;
  border-left-color: var(--brand_color);
  border-top-color: var(--brand_color);
  margin: 0 auto 0em auto;
  position: absolute;
  left: -40px;
  right: -40px;
  bottom: -40px;
  top: -40px;
}

#preloader .spinner:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 6px solid #fff;
  box-shadow: 0 0 20px 0 rgba(4, 46, 56, 0.2);
  background: var(--brand_color);
  position: absolute;
  right: 31px;
  top: 41px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  #preloader .spinner:before {
    top: 18px;
  }
}

#preloader .round_spinner {
  border-width: 1px;
  border-color: rgb(238, 243, 244);
  border-style: solid;
  border-radius: 50%;
  background-color: rgb(253, 253, 253);
  box-shadow: 0 0 100px 0 rgba(4, 46, 56, 0.14);
  width: 248px;
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 40px auto 80px;
}

@media (max-width: 576px) {
  #preloader .round_spinner {
    width: 155px;
    height: 155px;
  }
}

#preloader .round_spinner h4 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--secondary_color);
}

#preloader .round_spinner h4 span {
  font-weight: 700;
}

#preloader .head {
  color: var(--secondary_color);
  display: block;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 5.2px;
  text-transform: uppercase;
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin: 5% 0 1% 0;
  padding: 0;
}

#preloader p {
  color: #425466;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
}

@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.doc_documentation_area {
  padding-bottom: 110px;
  z-index: 0;
}

@media (min-width: 1400px) {
  .doc_documentation_area {
    min-height: 1000px;
  }
}

.doc_documentation_area .overlay_bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #1d2746;
  opacity: 0;
  z-index: 1;
  visibility: hidden;
  transition: all 0.4s linear;
}

.doc_documentation_area.body_fixed .doc_left_sidebarlist {
  position: fixed;
  top: 0;
  width: 270px;
}

@media (min-width: 1500px) {
  .doc_documentation_area.body_fixed .doc_left_sidebarlist {
    width: 307px;
  }
}

@media (max-height: 630px) {
  .doc_documentation_area.body_fixed .doc_left_sidebarlist {
    padding-top: 70px;
  }
}

.doc_documentation_area.body_fixed .doc_rightsidebar {
  position: fixed;
  top: 0;
}

@media (max-height: 630px) {
  .doc_documentation_area.body_fixed .doc_rightsidebar {
    padding-top: 70px;
  }
}

.doc_documentation_area.overlay .overlay_bg {
  opacity: 0.5;
  visibility: visible;
}

.full-width-doc .body_fixed .doc_rightsidebar,
.full-width-doc .body_fixed .doc_left_sidebarlist {
  padding-top: 30px;
}

/*============ doc_left_sidebarlist css =========*/
.doc_left_sidebarlist {
  padding-top: 70px;
  padding-bottom: 30px;
  z-index: 1;
  margin-right: 30px;
  position: relative;
  height: 100%;
}

.doc_left_sidebarlist:before {
  content: "";
  width: 200%;
  right: 0;
  height: 100%;
  background: #fafcfc;
  position: absolute;
  border-right: 1px solid #E6EDF0;
  top: 0;
  z-index: -1;
}

.doc_left_sidebarlist .scroll {
  max-height: 760px;
}

.doc_left_sidebarlist .scroll .mCSB_inside>.mCSB_container {
  margin-right: 0 !important;
}

@media (max-height: 630px) {
  .doc_left_sidebarlist .scroll {
    padding-bottom: 0;
    max-height: 450px;
  }
}

.doc_left_sidebarlist h2 {
  margin-bottom: 25px;
}

.nav-sidebar {
  margin-bottom: 0;
}

.nav-sidebar .nav-item {
  margin-bottom: 20px;
  padding-right: 35px;
  position: relative;
  cursor: pointer;
}

.nav-sidebar .nav-item .nav-link {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #3c4257;
  position: relative;
}

.nav-sidebar .nav-item .nav-link img {
  padding-right: 15px;
  margin-top: -2px;
}

.nav-sidebar .nav-item .nav-link svg {
  margin-right: 15px;
  position: relative;
  top: -1px;
}

.nav-sidebar .nav-item .nav-link svg path {
  transition: all 300ms linear 0s;
}

.nav-sidebar .nav-item .nav-link+.icon {
  font-size: 20px;
  color: var(--p_color);
  float: right;
  position: absolute;
  right: 30px;
  top: -2px;
  transition: all 0.3s linear;
}

.nav-sidebar .nav-item:hover .nav-link,
.nav-sidebar .nav-item.active .nav-link {
  color: var(--brand_color);
}

.nav-sidebar .nav-item:hover .icon,
.nav-sidebar .nav-item.active .icon {
  color: var(--brand_color);
}

.nav-sidebar .nav-item:hover svg path,
.nav-sidebar .nav-item.active svg path {
  fill: #5cbbe5;
}

.nav-sidebar .nav-item.active .icon {
  transform: rotate(-180deg);
}

.nav-sidebar .nav-item .dropdown_nav {
  padding-left: 32px !important;
  margin-bottom: 0;
  padding-top: 12px;
  width: 100% !important;
  display: none;
}

.nav-sidebar .nav-item .dropdown_nav li a {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #697386;
  padding: 0;
}

.nav-sidebar .nav-item .dropdown_nav li a:hover,
.nav-sidebar .nav-item .dropdown_nav li a.active {
  color: #3c4257;
}

.nav-sidebar .nav-item .dropdown_nav li a:hover i,
.nav-sidebar .nav-item .dropdown_nav li a.active i {
  color: var(--brand_color);
}

.nav-sidebar .nav-item .dropdown_nav li a.active {
  font-weight: 500;
}

.nav-sidebar+.nav-sidebar {
  border-top: 1px solid #e5e9eb;
  padding-top: 20px;
}

.coding_nav {
  padding-top: 15px;
  padding-bottom: 20px;
}

.bottom_nav .nav-item .nav-link i {
  position: relative;
  color: var(--black_800);
  padding-left: 4px;
  transition: all 0.2s linear;
}

.bottom_nav .nav-item .nav-link:hover i {
  transform: translateX(5px);
}

/*============ doc_left_sidebarlist css =========*/
.documentation_info .c_head {
  font-weight: 500;
  margin-bottom: 12px;
}

.documentation_info .c_head .anchorjs-link {
  font-size: 22px !important;
}

.documentation_info .slideshow {
  width: auto;
  height: 600px;
}

.documentation_info .slideshow .slide {
  width: inherit;
  height: inherit;
}

.documentation_info .slideshow .slide .item--horse {
  top: 124px;
  left: 78px;
}

.documentation_info .slideshow .nav {
  display: none;
}

.documentation_info .slideshow .title {
  color: var(--brand_color);
}

.sticky-nav-doc #documentation {
  padding-top: 174px;
}

.documentation_body {
  padding-top: 60px;
}

.documentation_body footer .border_bottom {
  margin-top: 100px;
}

.shortcode_title .btn {
  display: inline-block;
  padding: 0 9.5px;
  background: rgba(76, 76, 241, 0.5);
  color: #fff;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  letter-spacing: 0.5px;
  padding-top: 1px;
}

.table-of-content {
  padding-top: 15px;
}

.doc_documentation_area .shortcode_title {
  margin-bottom: 40px;
}

.doc_documentation_area .shortcode_title h1 {
  font-size: 32px;
  margin-bottom: 15px;
}

.doc_documentation_area .shortcode_title h4 {
  margin-bottom: 15px;
}

.doc_documentation_area .shortcode_title h4 a {
  color: var(--p_color);
  margin-left: 4px;
}

.doc_documentation_area .shortcode_title h4:hover a {
  color: var(--brand_color);
}

.doc_documentation_area .shortcode_title p span {
  font-weight: 500;
}

.doc_documentation_area .shortcode_title h4 {
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
}

.doc_documentation_area .shortcode_title+ul {
  margin-top: -0.8rem;
}

.get_started {
  padding-top: 45px;
  padding-bottom: 50px;
}

.get_started .c_head i {
  color: var(--brand_color);
  font-size: 16px;
  margin-left: 8px;
}

.get_started p {
  color: var(--p_color);
}

.get_started p a {
  color: var(--brand_color);
}

.link {
  background: #f1fdf3;
  padding: 14px 10px 14px 30px;
  border-radius: 4px;
}

.link p {
  position: relative;
  margin-bottom: 0;
  padding-left: 40px;
}

.link p:before {
  content: "R";
  position: absolute;
  font-family: eleganticons;
  left: 0;
  top: 4px;
  font-size: 24px;
  color: #10d631;
}

.test_version {
  padding-top: 45px;
}

.test_version p {
  font-size: 16px;
}

.test_version p span {
  color: var(--black_800);
}

.v_menu {
  border-bottom: 1px solid #e8ecee;
  padding-bottom: 20px;
  padding-top: 5px;
  margin-bottom: 20px;
}

.v_menu .nav-item {
  display: inline-block;
}

.v_menu .nav-item .nav-link {
  font-size: 16px;
  color: var(--p_color);
  transition: all 0.2s linear;
  padding: 0;
  border: 0;
  border-radius: 0;
}

.v_menu .nav-item .nav-link span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--bs-white);
  display: inline-block;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  margin-right: 10px;
  transition: all 0.2s linear;
  position: relative;
  top: -2px;
}

.v_menu .nav-item .nav-link.active,
.v_menu .nav-item .nav-link:hover {
  color: var(--brand_color);
  border: 0;
  background: transparent;
}

.v_menu .nav-item .nav-link.active span,
.v_menu .nav-item .nav-link:hover span {
  background: var(--brand_color);
  color: #fff;
}

.v_menu .nav-item+li {
  margin-left: 55px;
}

.process_tab_shortcode .version {
  background: #f8fafb;
  padding: 30px;
  font-size: 15px;
  margin-bottom: 28px;
}

.process_tab_shortcode .version p {
  margin-bottom: 20px;
  font-size: 15px;
  color: #6b707f;
}

.process_tab_shortcode .version p:last-child {
  margin-bottom: 0;
}

.process_tab_shortcode .version .v_head {
  padding-bottom: 24px;
}

.process_tab_shortcode .version .v_middle p {
  margin-bottom: 0;
  font-size: 15px;
}

.process_tab_shortcode .version .v_middle p .red {
  color: #f12249;
}

.process_tab_shortcode .version .v_middle p .green {
  color: #11c52f;
}

.process_tab_shortcode .version .v_footer {
  padding-top: 25px;
}

.process_tab_shortcode .version .v_footer p {
  margin-bottom: 0;
}

.developer {
  padding: 34px 0 20px;
}

.developer p a {
  color: var(--brand_color);
}

.developer .tag_list li {
  margin-top: 10px;
}

.developer .tag_list li i {
  padding-right: 10px;
}

.help_text {
  padding: 0 0 0;
}

.help_text .help_info {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  margin-left: -30px;
  margin-right: -30px;
}

.help_text .help_info .help_item {
  width: 33.33%;
  margin-bottom: 15px;
  padding: 0 30px;
}

.help_text .help_info .help_item h4 {
  font-size: 16px;
  margin-bottom: 15px;
  transition: color 0.2s linear;
}

.help_text .help_info .help_item h4 i {
  font-size: 18px;
  top: 2px;
  position: relative;
}

.help_text .help_info .help_item h4:hover {
  color: var(--brand_color);
}

.code_structure {
  padding-top: 50px;
}

.code_structure .c_head {
  margin-bottom: 8px;
}

.process_tab_shortcode {
  position: relative;
  overflow: hidden;
}

.process_tab_shortcode .previous,
.process_tab_shortcode .next {
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  padding: 0;
  border-radius: 0;
  background: #e1ebf0;
  border: 0;
  color: var(--brand_color);
  outline: none;
  box-shadow: none;
  transition: all 0.4s linear;
  height: 32px;
  width: 19px;
  text-align: center;
}

.process_tab_shortcode .previous:focus,
.process_tab_shortcode .next:focus {
  outline: none;
  box-shadow: none;
}

.process_tab_shortcode .previous:hover,
.process_tab_shortcode .next:hover {
  outline: none;
  box-shadow: none;
  background: var(--brand_color);
  color: #fff;
}

.process_tab_shortcode .next {
  right: -30px;
  border-radius: 5px 0 0 5px;
}

.process_tab_shortcode .previous {
  left: -30px;
  border-radius: 0 5px 5px 0;
}

.process_tab_shortcode:hover .next {
  right: 0;
}

.process_tab_shortcode:hover .previous {
  left: 0;
}

.question_box {
  padding-top: 35px;
  padding-bottom: 70px;
}

.question_box .question_text h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 18px;
}

.question_box .question_text p a {
  color: var(--brand_color);
}

.question_box .question_text_two {
  padding-left: 50px;
}

.question_box .signup_form {
  padding-top: 10px;
  padding-bottom: 20px;
}

.question_box .signup_form .input-group {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 6px 0 rgba(2, 48, 58, 0.14);
}

.question_box .signup_form .input-group .form-control {
  background: transparent;
  border: 0;
  font-size: 16px;
  color: var(--black_800);
  box-shadow: none;
  padding-left: 20px;
  height: 50px;
}

.question_box .signup_form .input-group .form-control.placeholder {
  color: #9c9fa9;
}

.question_box .signup_form .input-group .form-control:-moz-placeholder {
  color: #9c9fa9;
}

.question_box .signup_form .input-group .form-control::-moz-placeholder {
  color: #9c9fa9;
}

.question_box .signup_form .input-group .form-control::-webkit-input-placeholder {
  color: #9c9fa9;
}

.question_box .signup_form .input-group button {
  padding: 0;
  background: transparent;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--brand_color);
  padding: 0 20px;
  position: relative;
}

.question_box .signup_form .input-group button:before {
  content: "";
  width: 1px;
  background: #e8ecee;
  top: 8px;
  bottom: 8px;
  left: 0;
  position: absolute;
}

.feedback_link {
  align-items: center;
  padding-top: 20px;
}

.feedback_link h6 {
  font-weight: 500;
  font-size: 14px !important;
  color: var(--p_color);
  margin-bottom: 0;
  margin-top: -10px;
}

.feedback_link h6 a {
  color: var(--brand_color);
}

.feedback_link h6 i {
  padding-right: 8px;
}

.feedback_link p {
  margin-bottom: 0;
  text-align: right;
}

.feedback_link .h_btn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  padding: 1px 14px 0;
  background-color: #27ae60;
  transition: all 0.2s linear;
  margin-left: 18px;
  border-radius: 2px;
}

.feedback_link .h_btn.red {
  background: #e74c3c;
}

.feedback_link .h_btn span {
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  background: #fff;
  font-weight: 400;
  color: var(--black_800);
  margin-left: 6px;
  position: relative;
  top: -1px;
}

.feedback_link .h_btn:hover {
  color: #fff;
}

.help_form {
  max-width: 730px;
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
}

.help_form .modal-content {
  border: 0;
}

.help_form .modal-content .shortcode_title {
  margin-bottom: 30px;
}

.contact_form .form-group {
  margin-bottom: 30px;
}

.contact_form .form-group .form-control,
.contact_form .form-group textarea {
  border: 1px solid rgb(244, 248, 248);
  height: 58px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 10px 0 rgba(4, 73, 89, 0.08);
  color: #878b99;
  font-weight: 400;
  padding-left: 30px;
}

.contact_form .form-group .form-control.placeholder,
.contact_form .form-group textarea.placeholder {
  color: #878b99;
}

.contact_form .form-group .form-control:-moz-placeholder,
.contact_form .form-group textarea:-moz-placeholder {
  color: #878b99;
}

.contact_form .form-group .form-control::-moz-placeholder,
.contact_form .form-group textarea::-moz-placeholder {
  color: #878b99;
}

.contact_form .form-group .form-control::-webkit-input-placeholder,
.contact_form .form-group textarea::-webkit-input-placeholder {
  color: #878b99;
}

.contact_form .form-group .form-control:focus,
.contact_form .form-group textarea:focus {
  box-shadow: 0 20px 30px 0 rgba(4, 73, 89, 0.1);
}

.contact_form .form-group textarea {
  width: 100%;
  height: 160px;
  padding-top: 20px;
  display: block;
}

.contact_form .form-group .action_btn {
  border: 0;
  padding: 15px 40px;
  box-shadow: none;
  font-weight: 500;
}

.contact_form .form-group .action_btn:hover {
  box-shadow: 0 20px 30px 0 rgba(76, 76, 241, 0.24);
}

/*========== doc_rightsidebar css ============*/
.mCSB_scrollTools {
  width: 0;
}

.open_icon {
  width: 40px;
  height: 40px;
  background: #e8eeff;
  line-height: 40px;
  font-size: 32px;
  text-align: center;
  position: absolute;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  left: -40px;
  color: var(--brand_color);
  display: none;
}

.open_icon i {
  display: inline-block;
}

.open_icon i+i {
  display: none;
}

.open_icon.overlay i {
  display: none;
}

.open_icon.overlay i+i {
  display: inline-block;
}

.full-width-doc .doc_rightsidebar {
  padding-right: 15px;
}

.full-width-doc .doc_rightsidebar .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 225px;
}

.doc_rightsidebar {
  margin-left: -20px;
  padding-top: 70px;
  height: 100%;
  position: relative;
}

.doc_rightsidebar .pageSideSection {
  margin-left: 25px;
}

.doc_rightsidebar .pageSideSection::before {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e6edf0;
  left: 0;
}

.doc_rightsidebar .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 204px;
}

@media (max-height: 530px) {
  .doc_rightsidebar {
    padding-bottom: 0;
    max-height: 300px;
  }
}

.doc_rightsidebar.scroll .mCSB_inside>.mCSB_container {
  margin-right: 0;
}

.doc_rightsidebar h6 {
  font-size: 12px;
  font-weight: 600;
  color: #9DAAB6;
}

.doc_rightsidebar h6 i {
  margin-right: 6px;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.doc_rightsidebar .doc_right_link li {
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.doc_rightsidebar .doc_right_link li a {
  font-size: 14px;
  font-weight: 500;
  color: var(--p_color);
}

.doc_rightsidebar .doc_right_link li a i {
  padding-right: 12px;
  position: absolute;
  left: 0;
  font-size: 12px;
  top: 5px;
}

.doc_menu .nav-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 0;
  color: #677681;
  position: relative;
  margin-bottom: 6px;
}

.doc_menu .nav-link.active {
  color: var(--brand_color);
  font-weight: 500;
}

.doc_menu .nav-link.active+.nav .active::before {
  display: none;
}

.doc_menu .nav-link.active::before {
  left: -25px;
  height: 100%;
  content: " ";
  position: absolute;
  border-left: 2px solid;
  color: var(--brand_color);
}

.doc_menu .nav {
  padding-left: 20px;
}

.mobile_menu {
  width: 300px;
  position: fixed;
  height: 100vh;
  max-height: 100vh !important;
  top: 0;
  background: #fff;
  right: -300px;
  z-index: 1050;
  transition: all 0.4s linear;
}

.mobile_menu .doc_left_sidebarlist {
  padding-left: 20px;
}

.mobile_menu .doc_left_sidebarlist:before {
  display: none;
}

.mobile_menu.open {
  right: 0;
}

.mobile_menu .close_nav {
  padding-top: 20px;
  padding-left: 30px;
  font-size: 28px;
}

/*=============== doc_documentation_full_area css =========*/
.full-width-doc .doc_left_sidebarlist {
  margin-right: 0;
  padding-left: 0;
}

.full-width-doc.body_fixed .doc_left_sidebarlist {
  width: 287px;
}

.full-width-doc .doc_rightsidebar {
  margin-left: 0;
}

body.doc-no-banner .doc_left_sidebarlist {
  padding-top: 0;
  margin-top: 70px;
}

body.doc-no-banner .doc_left_sidebarlist::before {
  background: transparent;
}

body:not(.onepage-doc) .sticky_menu .doc_documentation_area.body_fixed .doc_left_sidebarlist,
body:not(.onepage-doc) .sticky_menu .doc_documentation_area.body_fixed .doc_rightsidebar {
  top: 78px;
}

.resource .c_head {
  margin-bottom: 5px;
}

.resource .tag_list {
  padding-top: 0;
}

.resource .tag_list li {
  margin-top: 4px;
}

.resource .tag_list li a {
  display: inline-block;
  font-weight: 500;
}

/*========== typography_content css ========*/
.doc .code-preview {
  border: 1px solid #e5ebef;
  padding: 20px;
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.02);
  position: relative;
}

.doc .code-preview #header-name {
  margin-bottom: 0;
}

.doc .code-preview img {
  max-width: 100%;
}

.doc .code-preview.video_img {
  display: inline-block;
}

.doc .code-toolbar .snippets {
  margin-bottom: 0;
}

.doc .code-toolbar .snippets code {
  margin-bottom: 20px;
}

.doc .code_item {
  padding-top: 15px;
}

.doc .code_item p a {
  color: var(--brand_color);
}

.vjs-iframe-blocker {
  display: none;
}

.video-js {
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
  height: 360px;
}

.video-js .mfp-close {
  right: -55px;
  top: -10px;
}

/*============ Sticky Nav doc css ================*/
.sticky-nav-doc:not(.onepage-doc) .body_fixed .doc_rightsidebar,
.sticky-nav-doc:not(.onepage-doc) .body_fixed .doc_left_sidebarlist {
  padding-top: 100px;
}

.sticky-nav-doc .body_fixed.body_navbar_fixed .doc_left_sidebarlist,
.sticky-nav-doc .body_fixed.body_navbar_fixed .doc_rightsidebar {
  padding-top: 30px;
}

/*============ onepage doc css ================*/
.onepage-doc .shortcode_info {
  padding-left: 0;
  padding-right: 0;
}

.onepage-doc .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 230px;
}

.onepage-doc.body_fixed .doc_left_sidebarlist {
  padding-top: 30px;
}

.onepage-doc .doc_left_sidebarlist {
  width: 270px;
  margin-right: 0;
  border: 0;
  padding-top: 0;
}

.onepage-doc .doc_left_sidebarlist:before {
  display: none;
}

.onepage-doc .nav-sidebar .nav-item {
  padding-right: 0;
  background: #f8fafb;
  margin-bottom: 0;
  margin-top: 3px;
  position: relative;
}

.onepage-doc .nav-sidebar .nav-item .docs-progress-bar {
  position: absolute;
  background: #ebf0f1;
  bottom: 0;
  left: 0;
  top: 0;
  max-height: 48px;
}

.onepage-doc .nav-sidebar .nav-item .nav-link {
  padding: 13px 20px;
}

.onepage-doc .nav-sidebar .nav-item .icon {
  top: 0;
  right: 20px;
  line-height: 48px;
  font-size: 18px;
  position: absolute;
  color: var(--brand_color);
  transform: rotate(0deg);
}

.onepage-doc .nav-sidebar .nav-item .icon i {
  display: inline-block;
}

.onepage-doc .nav-sidebar .nav-item .icon i+i {
  display: none;
}

.onepage-doc .nav-sidebar .nav-item.active .icon i {
  display: none;
}

.onepage-doc .nav-sidebar .nav-item.active .icon i+i {
  display: inline-block;
}

.onepage-doc .nav-sidebar .nav-item.active .dropdown_nav {
  display: block;
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #fff;
  position: relative;
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav:before {
  content: "";
  width: 2px;
  bottom: 15px;
  background: #edf1f3;
  position: absolute;
  top: 15px;
  left: 20px;
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav .nav-item {
  margin-top: 0;
  background: transparent;
  position: relative;
  padding-left: 22px;
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav .nav-item:before {
  content: "5";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "ElegantIcons";
  color: var(--brand_color);
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav .nav-item .docs-progress-bar {
  display: none;
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav .nav-item .nav-link {
  padding: 0;
  color: var(--p_color);
}

.onepage-doc .nav-sidebar .nav-item .dropdown_nav .nav-item.active .nav-link {
  color: var(--brand_color);
}

.onepage-doc .doc_rightsidebar {
  margin-left: 0;
  padding-right: 10px;
  padding-top: 0;
}

.onepage-doc .doc-container {
  padding-top: 100px;
}

.doc_rightsidebar .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-size: 16px;
  font-weight: 400;
}

.doc_rightsidebar .dropdown-toggle {
  background: #fafcfd;
  border: 1px solid #e1e4e5;
  border-radius: 5px;
  font-size: 16px;
  color: #6b707f;
  padding: 9px 20px 0;
  box-shadow: none;
}

.doc_rightsidebar .dropdown-toggle:after {
  content: "3";
  border: 0;
  font-family: "ElegantIcons";
  top: -2px;
  position: relative;
  color: #6b707f;
}

.doc_rightsidebar .dropdown-toggle i {
  padding-right: 8px;
}

.doc_rightsidebar .dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none !important;
}

.doc_rightsidebar .bootstrap-select {
  margin-bottom: 30px;
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu {
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  background: #fff;
  border: 0;
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu {
  border: 0;
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a {
  color: var(--p_color);
  position: relative;
  font-size: 14px;
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a:before {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--brand_color);
  transition: all 0.2s linear;
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a i {
  padding-right: 8px;
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a.active,
.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a:hover {
  background: #f6f6f6;
  color: var(--brand_color);
}

.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a.active:before,
.doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a:hover:before {
  height: 100%;
}

.doc_rightsidebar #font-switcher {
  margin-bottom: 30px;
}

.remove .doc_left_sidebarlist {
  transition: all 300ms linear 0s;
}

.remove .doc_rightsidebar {
  transition: all 300ms linear 0s;
}

.test.remove .doc_left_sidebarlist {
  padding-top: 140px;
  transition: all 300ms linear 0s;
}

.test.remove .doc_rightsidebar {
  padding-top: 140px;
  transition: all 300ms linear 0s;
}

.test .doc_left_sidebarlist {
  padding-top: 70px;
  transition: all 300ms linear 0s;
}

.test .doc_rightsidebar {
  padding-top: 70px;
  transition: all 300ms linear 0s;
}

.fontsize-controllers .btn-group {
  border: 1px solid #dfe2e4;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 0 0 rgba(3, 13, 37, 0.2), inset 0 -8px 14px 0 rgba(3, 13, 37, 0.1);
  border-radius: 4px;
}

.fontsize-controllers .btn-group .btn {
  font-size: 16px;
  font-weight: 400;
  color: var(--black_800);
  line-height: 1.2;
  border: 0;
}

.fontsize-controllers .btn-group .btn:focus {
  outline: none;
  box-shadow: none;
  background: #eff0f1;
}

.fontsize-controllers .btn-group .btn.rvfs-reset {
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
}

.print {
  color: var(--p_color);
  font-size: 18px;
}

.nav_title {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background: #1d2746;
  border-radius: 4px;
  line-height: 22px;
  padding: 13px 30px;
  margin-bottom: 0;
}

body:not(.onepage-doc) .body_fixed #font-switcher {
  width: 204px;
}

/* Collaps Tabs Area css
============================================================================================ */
.collaps_tabs {
  margin-bottom: 70px;
}

.collaps_tabs .collaps_tab_inner .shortcode_title {
  margin-bottom: 25px;
}

.collaps_tabs .collaps_tab_inner .shortcode_title h4 a {
  color: var(--black_800);
}

.doc-btm {
  margin-top: 70px;
}

.doc-btm .card_tagged li {
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  margin-right: 9.32px;
}

.doc-btm .card_tagged li a {
  background: #edf0f5;
  color: #616366;
  line-height: 25px;
  padding: 0 14px;
  display: inline-block;
  border-radius: 12.5px;
  font-size: 14px;
  font-weight: 400;
}

.doc-btm .card_tagged li a:hover {
  color: var(--brand_color);
  background: rgba(76, 76, 241, 0.15);
}

.topic_item_tabs {
  margin-top: 50px;
  margin-bottom: -30px;
  overflow: hidden;
}

.topic_item_tabs .topic_list_item {
  background: transparent;
  border: 1px solid #e6edf0;
  border-radius: 5px;
  padding: 37px 35px 35px;
  box-shadow: none !important;
}

.topic_item_tabs .topic_list_item h4 {
  font-weight: 500;
  font-size: 16px;
}

.topic_item_tabs .topic_list_item .navbar-nav li a {
  color: var(--p_color);
}

.topic_item_tabs .topic_list_item .navbar-nav li a i {
  color: var(--brand_color);
}

.topic_item_tabs .topic_list_item .navbar-nav li:hover a {
  color: var(--brand_color);
}

.topic_item_tabs .topic_list_item .navbar-nav li:hover a i {
  color: var(--brand_color);
}

.blog_comment_box.topic_comment .c_head {
  display: inline-block;
  margin-bottom: 15px;
}

.blog_comment_box.topic_comment .c_head a {
  color: var(--black_800);
  text-decoration: underline;
  font-weight: 500;
}

.blog_comment_box.topic_comment .cancle {
  display: inline-block;
  padding: 0;
  border: none;
  color: #54595d;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
}

.get_quote_form .form-group {
  position: relative;
}

.get_quote_form .form-group .form-control {
  border-color: #e1e8ea;
  font-size: 16px;
  color: var(--p_color);
  font-weight: 400;
  box-shadow: none !important;
  border-radius: 4px;
}

.get_quote_form .form-group .form-control.placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--p_color);
}

.get_quote_form .form-group .form-control:-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--p_color);
}

.get_quote_form .form-group .form-control::-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--p_color);
}

.get_quote_form .form-group .form-control::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--p_color);
}

.get_quote_form .form-group .form-control:focus {
  border-color: var(--brand_color);
}

.get_quote_form .form-group label {
  margin-bottom: 0;
}

.get_quote_form .form-group input:focus~.floating-label,
.get_quote_form .form-group textarea:focus~.floating-label,
.get_quote_form .form-group input:not(:focus):valid~.floating-label {
  top: -10px;
  left: 26px;
  font-size: 12px;
  color: var(--brand_color);
  background-color: #fff;
  padding: 0 5px 0 5px;
}

.get_quote_form .form-group textarea:focus~.floating-label,
.get_quote_form .form-group textarea:not(:focus):valid~.floating-label {
  top: -11px;
  left: 26px;
  font-size: 12px;
  color: var(--brand_color);
  background-color: #fff;
  padding: 0 5px 0 5px;
}

.get_quote_form .form-group .floating-label {
  position: absolute;
  pointer-events: none;
  left: 40px;
  top: 16px;
  transition: 0.2s;
  margin-bottom: 0;
  color: var(--p_color);
}

.get_quote_form .form-group .action_btn {
  background: var(--brand_color);
  border-radius: 4px;
}

.get_quote_form .form-group .action_btnhover {
  box-shadow: 0 15px 35px -3px rgba(76, 76, 241, 0.3);
}

.get_quote_form .form-group:last-child {
  margin-bottom: 0;
}

.check_input input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check_input label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: var(--p_color);
}

.check_input label:before {
  content: "";
  background-color: transparent;
  border: 1px solid var(--p_color);
  padding: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 9px;
  top: -1px;
  position: relative;
}

.check_input input:checked+label:after {
  content: "N";
  font-family: "ElegantIcons";
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 12px;
}

/* Forum Collaps Tabs Area css
============================================================================================ */
.small-dialog {
  padding: 50px 40px;
}

.small-dialog .count {
  background: var(--brand_color);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: -20px;
  top: -20px;
}

.small-dialog h2 {
  font-size: 20px;
  font-weight: 500;
  color: var(--black_800);
  margin-bottom: 13px;
}

.small-dialog p {
  margin-bottom: 0;
}

.gallery_content .mfp-container {
  max-width: 510px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 42%;
  height: auto;
  padding: 0;
}

.gallery_content .mfp-container .mfp-arrow {
  top: auto;
  margin-top: 0;
  padding: 0;
  bottom: 0;
  opacity: 1;
  width: 50%;
  top: auto;
  bottom: -50px;
  background: #fff;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0;
}

.gallery_content .mfp-container .mfp-arrow:hover {
  background: var(--brand_color);
}

.gallery_content .mfp-container .mfp-arrow:hover:after {
  color: #fff;
}

.gallery_content .mfp-container .mfp-arrow:hover:before {
  color: #fff;
}

.gallery_content .mfp-container .mfp-arrow-right:after {
  content: "Next";
  border: none;
  margin: 0;
  top: 0;
  left: 40%;
  transform: translateX(-50%);
  color: var(--p_color);
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
}

.gallery_content .mfp-container .mfp-arrow-right:before {
  content: "5";
  font-family: "ElegantIcons";
  background: transparent;
  border: none;
  margin: 0;
  left: 54%;
  transform: translateX(-50%);
  color: var(--p_color);
  font-size: 18px;
  top: -1px;
  opacity: 1;
}

.gallery_content .mfp-container .mfp-arrow-left:after {
  content: "Previous";
  border: none;
  margin: 0;
  top: 0;
  left: 38%;
  transform: translateX(-50%);
  color: var(--p_color);
  font-size: 16px;
  font-weight: 500;
}

.gallery_content .mfp-container .mfp-arrow-left:before {
  content: "4";
  font-family: "ElegantIcons";
  background: transparent;
  border: none;
  margin: 0;
  left: 29%;
  transform: translateX(-50%);
  color: var(--p_color);
  font-size: 18px;
  top: -1px;
}

.mfp-bg.gallery_content {
  width: 100%;
  max-width: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

/* Styles for dialog window */
.small-dialog {
  background: white;
  padding: 50px 40px;
  text-align: left;
  max-width: 510px;
  margin: 0 auto;
  position: relative;
}

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
 * Fade-move animation for second dialog
 */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Data Table Area css
============================================================================================ */
.data_table_area .dataTables_length {
  margin-top: 15px;
  margin-bottom: 15px;
}

.data_table_area .dataTables_length label {
  color: var(--p_color);
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 0;
  position: relative;
  top: 2px;
}

.data_table_area .dataTables_length .mdb-select {
  height: 30px;
  width: 80px;
  border: 1px solid var(--black_50);
  color: var(--p_color);
}

.data_table_area .dataTables_filter {
  margin-top: 0;
  margin-bottom: 15px;
}

.data_table_area .dataTables_filter input[type=search] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--black_50);
  border-radius: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.data_table_area .dataTables_filter input[type=search]:focus {
  border-bottom: 1px solid var(--brand_color);
  -webkit-box-shadow: 0 1px 0 0 var(--brand_color);
  box-shadow: 0 1px 0 0 var(--brand_color);
}

.data_table_area .table.dataTable {
  border: 1px solid var(--black_50);
}

.data_table_area .table.dataTable thead tr {
  background: #f7f8f9;
  border-bottom: none;
}

.data_table_area .table.dataTable thead tr th {
  border: none;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  padding-right: 30px;
}

.data_table_area .table.dataTable thead tr th[aria-sort=ascending]:after {
  color: var(--black_800);
}

.data_table_area .table.dataTable thead tr th[aria-sort=descending]:before {
  color: var(--black_800);
}

.data_table_area .table.dataTable thead tr th[aria-sort=descending]:after {
  color: rgba(0, 0, 0, 0.4);
}

.data_table_area .table.dataTable tbody tr td {
  border-top: 1px solid var(--black_50);
  font-weight: 400;
  font-size: 14px;
  color: var(--p_color);
  white-space: nowrap;
}

.data_table_area .dataTables_info {
  font-weight: 400;
  color: var(--p_color);
  margin-top: 4px;
}

.data_table_area .dataTables_paginate {
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 10px;
}

.data_table_area .dataTables_paginate .paginate_button {
  margin-right: 5px;
  padding: 0;
  height: 32px;
  width: 32px;
  border: 1px solid transparent !important;
  box-sizing: border-box;
  color: var(--p_color) !important;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 31px;
  outline: none;
  box-shadow: none;
  background: transparent !important;
  border-radius: 3px !important;
}

.data_table_area .dataTables_paginate .paginate_button.previous {
  margin-right: 15px;
  width: auto;
  border: none !important;
}

.data_table_area .dataTables_paginate .paginate_button.next {
  margin-right: 0;
  margin-left: 10px;
  width: auto;
  border: none !important;
}

.data_table_area .dataTables_paginate .paginate_button:hover {
  border-color: var(--black_50) !important;
  background: transparent;
  color: #54595d !important;
}

.data_table_area .dataTables_paginate .paginate_button.current {
  box-shadow: none !important;
  background: var(--brand_color) !important;
  color: #fff !important;
  border-color: var(--brand_color) !important;
}

table.dataTable tbody tr {
  background: var(--bs-white);
}

/* 3d video Slider Area css
============================================================================================ */
.flipvideo_area .slider-wrapper.no-padding .js-slider--main {
  padding-top: 7px;
}

.flipvideo_area .slider-wrapper.no-padding .js-slider--main .swiper-slide-shadow-left,
.flipvideo_area .slider-wrapper.no-padding .js-slider--main .swiper-slide-shadow-right {
  background-image: unset;
}

.flipvideo_area .slider-wrapper.no-padding .js-slider--main .swiper-slide.swiper-slide-prev,
.flipvideo_area .slider-wrapper.no-padding .js-slider--main .swiper-slide.swiper-slide-next {
  position: relative;
}

.flipvideo_area .slider-wrapper.no-padding .js-slider--main .swiper-slide.swiper-slide-prev:before,
.flipvideo_area .slider-wrapper.no-padding .js-slider--main .swiper-slide.swiper-slide-next:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  height: 98%;
}

.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav {
  padding-bottom: 0;
}

.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav .swiper-button-prev {
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav .swiper-button-next {
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav .swiper-button-prev,
.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav .swiper-button-next {
  opacity: 0;
  transition: all 300ms linear 0s;
  background: var(--brand_color);
}

.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav .swiper-button-prev:after,
.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav .swiper-button-next:after {
  font-size: 16px;
}

.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav:hover .swiper-button-prev,
.flipvideo_area .slider-wrapper.no-padding .swiper-container.js-slider--nav:hover .swiper-button-next {
  opacity: 1;
}

.swiper-pagination-bullet {
  border: 1px solid #1da1f2;
}

.swiper-pagination-bullet-active {
  background: #1da1f2;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical>.swiper-wrapper {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow>.swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode>.swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical>.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal>.swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical>.swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal>.swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical>.swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper-container.one {
  padding-top: 40px;
  text-align: center;
}

.swiper-container.one .swiper-slide {
  padding: 0 43px;
}

.swiper-container {
  width: 100%;
  padding-bottom: 60px;
}

.swiper-slide img {
  display: block;
  margin: auto;
  width: 100%;
}

.js-slider--main .swiper-slide {
  width: 390px;
  height: auto;
  padding: 0 0;
}

.swiper-slide .slider-image .preview-icon {
  z-index: -1;
  width: calc(100% - 30px);
}

.swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ee0f6f;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 26px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.swiper-pagination-bullet-active {
  background: rgba(0, 0, 0, 0) -webkit-linear-gradient(left, #ee0f6f 0%, #f89482 100%) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0) linear-gradient(to right, #ee0f6f 0%, #f89482 100%) repeat scroll 0 0;
  border: medium none;
  height: 12px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 12px;
}

/* End 3d video Slider Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.status-chart {
  padding: 62px 0 112px;
}

.status-chart .section_title {
  margin-bottom: 70px;
}

.Docy-chart-wrapper {
  position: relative;
}

.full-amount {
  position: absolute;
  height: 230px;
  width: 230px;
  padding: 100px 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  font-size: 30px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .full-amount {
    display: none;
  }
}

@media (max-width: 1200px) {
  .full-amount {
    height: 200px;
    width: 200px;
    padding: 85px 0;
  }
}

@media (max-width: 1200px) {
  .full-amount {
    height: 150px;
    width: 150px;
    padding: 58px 0;
  }
}

@media (max-width: 768px) {
  .full-amount {
    padding: 40px 0;
    height: 100px;
    width: 100px;
  }
}

.full-amount:before {
  content: "";
  position: absolute;
  left: 30px;
  top: 30px;
  background: #ffffff;
  border-radius: 50%;
  height: 170px;
  width: 170px;
}

@media (max-width: 1200px) {
  .full-amount:before {
    height: 110px;
    width: 110px;
    left: 20px;
    top: 20px;
  }
}

@media (max-width: 991px) {
  .full-amount:before {
    height: 110px;
    width: 110px;
    left: 20px;
    top: 20px;
  }
}

@media (max-width: 768px) {
  .full-amount:before {
    height: 80px;
    width: 80px;
    left: 10px;
    top: 10px;
  }
}

.full-amount .total-count {
  position: relative;
  z-index: 22;
  color: #a262f8;
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 768px) and (max-width: 768px) {
  .full-amount .total-count {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .canvas {
    height: 100vh;
    width: 100vw;
  }
}

.chart-info {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  list-style: none;
}

@media (max-width: 1200px) {
  .chart-info {
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .chart-info {
    display: none;
  }
}

.chart-info li {
  position: absolute;
  width: 370px;
}

.chart-info li .counterup {
  font-size: 34px;
  font-weight: 500;
  color: #42dabf;
  margin-bottom: 5px;
}

.chart-info li .border-image {
  position: absolute;
}

.chart-info li .border-image img {
  width: 100%;
}

.chart-info li .border-image svg {
  position: absolute;
}

.chart-info li .border-image:before {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #42dabf;
  position: absolute;
  left: 0;
  top: -3px;
  z-index: 2;
}

.chart-info li.color-two .counterup {
  color: #f9327a;
}

.chart-info li.color-two .border-image:before {
  left: auto;
  right: 0;
  background: #fa5d96;
}

.chart-info li.color-three .counterup {
  color: #35bae9;
}

.chart-info li.color-three .border-image:before {
  top: 55px;
  background: #35bae9;
}

.chart-info li.color-four .counterup {
  color: #fcc103;
}

.chart-info li.color-four .border-image:before {
  top: 55px;
  left: auto;
  right: 0;
  background: #fcc103;
}

.chart-info li p {
  max-width: 210px;
  margin: 23px 0 0;
}

.chart-info li.info-left-top {
  top: 0;
  left: 30px;
}

.chart-info li.info-left-top svg {
  max-width: 370px;
}

@media (max-width: 1200px) {
  .chart-info li.info-left-top .border-image {
    max-width: 292px;
  }

  .chart-info li.info-left-top .border-image svg {
    max-width: 280px;
  }

  .chart-info li.info-left-top .border-image:before {
    top: 4px;
  }
}

@media (max-width: 991px) {
  .chart-info li.info-left-top {
    left: 0;
  }

  .chart-info li.info-left-top .border-image {
    max-width: 210px;
  }

  .chart-info li.info-left-top .border-image svg {
    max-width: 220px;
  }

  .chart-info li.info-left-top .border-image:before {
    top: 9px;
  }
}

.chart-info li.info-right-top {
  right: 125px;
  top: 35px;
  width: 330px;
  text-align: right;
}

@media (max-width: 991px) {
  .chart-info li.info-right-top {
    top: 20px;
  }
}

.chart-info li.info-right-top p {
  margin-left: auto;
}

.chart-info li.info-right-top svg {
  max-width: 330px;
  right: 0;
}

.chart-info li.info-right-top .border-image {
  right: 0;
}

@media (max-width: 1200px) {
  .chart-info li.info-right-top .border-image {
    width: 230px;
  }

  .chart-info li.info-right-top .border-image svg {
    max-width: 251px;
    right: 0;
    height: 40px;
  }
}

@media (max-width: 992px) {
  .chart-info li.info-right-top {
    right: 40px;
  }

  .chart-info li.info-right-top .border-image {
    width: 185px;
  }
}

.chart-info li.info-left-bottom {
  bottom: 0;
  left: 0;
  width: 350px;
  margin-left: 75px;
}

@media (max-width: 1200px) {
  .chart-info li.info-left-bottom {
    margin-left: 0;
  }
}

.chart-info li.info-left-bottom .border-image {
  bottom: 115px;
}

@media (max-width: 1200px) {
  .chart-info li.info-left-bottom .border-image {
    width: 330px;
  }
}

@media (max-width: 991px) {
  .chart-info li.info-left-bottom {
    margin-left: 0;
  }

  .chart-info li.info-left-bottom .border-image {
    width: 240px;
    overflow: hidden;
    height: 80px;
    bottom: 40px;
  }

  .chart-info li.info-left-bottom .border-image svg {
    max-width: 325px;
    left: -90px;
  }
}

.chart-info li.info-right-bottom {
  right: 50px;
  bottom: -9px;
  text-align: right;
}

.chart-info li.info-right-bottom p {
  margin-left: auto;
}

.chart-info li.info-right-bottom .border-image {
  right: 0;
  bottom: 122px;
}

.chart-info li.info-right-bottom .border-image svg {
  right: 0;
}

@media (max-width: 1200px) {
  .chart-info li.info-right-bottom .border-image {
    width: 271px;
  }

  .chart-info li.info-right-bottom .border-image svg {
    width: 320px;
  }
}

@media (max-width: 991px) {
  .chart-info li.info-right-bottom .border-image {
    width: 175px;
  }

  .chart-info li.info-right-bottom .border-image svg {
    width: 230px;
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.related-communities {
  padding: 55px 0 50px;
}

.related-communities .section_title {
  margin-bottom: 70px;
}

.dmt-4 {
  margin-top: 38px;
}

.communities-boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

@media (max-width: 992px) {
  .communities-boxes {
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 30px);
  }
}

@media (max-width: 420px) {
  .communities-boxes {
    width: calc(100% + 15px);
  }
}

.communities-boxes .Docy-com-box {
  text-align: center;
  margin-bottom: 57px;
  width: 20%;
  flex: 0 0 205px;
}

@media (max-width: 1200px) {
  .communities-boxes .Docy-com-box {
    flex: 0 0 165px;
  }
}

@media (max-width: 991px) {
  .communities-boxes .Docy-com-box {
    flex: 0 0 180px;
  }
}

@media (max-width: 768px) {
  .communities-boxes .Docy-com-box {
    flex: 0 0 185px;
  }
}

@media (max-width: 420px) {
  .communities-boxes .Docy-com-box {
    flex: 0 0 150px;
  }
}

.communities-boxes .Docy-com-box .icon-container {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 40px 70px 0 rgba(2, 47, 57, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  margin: 0 auto 34px;
}

.communities-boxes .Docy-com-box .docy-com-box-content .title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #1d2746;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.communities-boxes .Docy-com-box .docy-com-box-content .title a {
  color: #1d2746;
}

.communities-boxes .Docy-com-box .docy-com-box-content .title a:hover {
  color: var(--brand_color);
}

.communities-boxes .Docy-com-box .docy-com-box-content .title:hover {
  color: var(--brand_color);
}

.communities-boxes .Docy-com-box .docy-com-box-content .total-post {
  color: #6b707f;
  font-size: 14px;
}

.more-communities {
  padding: 22px 0;
  border-top: 1px solid #e8ecee;
  border-bottom: 1px solid #e8ecee;
}

.more-communities .collapse-btn {
  text-align: center;
  display: block;
  color: var(--brand_color);
  font-weight: 500;
}

.more-communities .collapse-btn i {
  vertical-align: -1px;
}

.more-communities .collapse-btn.active i:before {
  content: "K";
}

.more-communities .collapse-wrap {
  margin-top: 80px;
  display: none;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.about-journalism {
  background: rgb(250, 252, 253);
  overflow: hidden;
  position: relative;
}

.light-header {
  position: absolute;
  bottom: -23px;
  left: 0;
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .light-header {
    bottom: -10px;
  }
}

.light-header .title-light {
  color: #eff4f7;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

@media (max-width: 768px) {
  .light-header .title-light {
    font-size: 30px;
    bottom: -10px;
  }
}

.journalism-content-wrapper {
  max-width: 550px;
  padding: 113px 0 65px;
}

@media (max-width: 1480px) {
  .journalism-content-wrapper {
    padding: 83px 0 35px;
  }
}

.journalism-content-wrapper .quote {
  margin-left: -20px;
}

.journalism-content-wrapper .journalism-title {
  font-size: 30px;
  line-height: 40px;
  color: #1d2746;
  font-weight: 700;
  margin-bottom: 20px;
}

.journalism-content-wrapper p {
  font-size: 20px;
  color: #6b707f;
  line-height: 34px;
  margin-bottom: 40px;
}

.journalism-content-wrapper .journalism-info .name {
  color: #1d2746;
  font-size: 16px;
  margin: 0;
}

.journalism-content-wrapper .journalism-info .designation {
  font-size: 14px;
  color: #6b707f;
}

.journalism-feature-image {
  position: relative;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .journalism-feature-image {
    margin-left: 20px;
  }
}

.journalism-feature-image:before {
  content: "";
  position: absolute;
  height: 600px;
  width: 600px;
  background-image: linear-gradient(45deg, rgb(236, 249, 252) 0%, rgb(254, 239, 236) 100%);
  border-radius: 50%;
  z-index: 1;
  left: 20px;
  top: 85px;
}

@media (max-width: 992px) {
  .journalism-feature-image:before {
    left: -20px;
    top: 15px;
  }
}

.journalism-feature-image img {
  margin-left: -27px;
  position: relative;
  z-index: 2;
}

@media (max-width: 1480px) {
  .journalism-feature-image {
    margin-top: 80px;
  }

  .journalism-feature-image img {
    width: 500px;
  }

  .journalism-feature-image:before {
    height: 500px;
    width: 500px;
  }
}

@media (max-width: 992px) {
  .journalism-feature-image img {
    max-width: 500px;
  }
}

@media (max-width: 576px) {
  .journalism-feature-image img {
    max-width: 300px;
  }
}

@media (max-width: 576px) {
  .journalism-feature-image:before {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 992px) {
  .md-order-two {
    order: 2;
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.communities {
  padding: 115px 0 90px;
}

.community-box {
  border: 1px solid #f6f9fa;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 3px 0 rgba(2, 47, 57, 0.14);
  text-align: center;
  padding: 40px 70px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  overflow: hidden;
}

.community-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

.community-box .icon-container {
  margin-bottom: 33px;
}

.community-box .community-content .com-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #6b707f;
  margin-bottom: 27px;
}

.community-box .community-content .details-link {
  color: #9296a3;
  font-size: 16px;
  font-weight: 500;
}

.community-box .community-content .details-link i {
  display: inline-block;
  vertical-align: -2px;
  transition: all 0.3s ease-in-out;
  color: #9296a3;
}

.community-box .community-content .details-link:hover {
  color: #26c6da;
}

.community-box .community-content .details-link:hover i {
  color: #26c6da;
  margin-left: 3px;
}

.community-box:hover {
  box-shadow: 0 40px 30px 0 rgba(2, 47, 57, 0.1);
  transform: translateY(-5px);
}

.community-box:hover:before {
  background: var(--brand_color);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.community-posts-area {
  padding: 110px 0 120px;
}

.community-posts-area .section_title {
  margin-bottom: 73px;
}

.community-post {
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(2, 47, 57, 0.1);
  padding: 23px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
}

.community-post.forum-item {
  padding: 23px 15px;
}

.community-post.forum-item .post-content p {
  font-size: 14px;
  line-height: 1.3;
}

@media (max-width: 667px) {
  .community-post {
    display: block;
  }
}

@media (max-width: 420px) {
  .community-post {
    padding: 20px 15px;
  }
}

.community-post:hover {
  box-shadow: 0 24px 40px 0 rgba(2, 47, 57, 0.12);
  border-color: #eef3f6;
  transform: translateX(10px);
}

.community-post .post-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.community-post .post-content .author-avatar {
  width: 44px;
  margin-right: 25px;
  border-radius: 50%;
  overflow: hidden;
  height: 44px;
}

.community-post .post-content .author-avatar.forum-icon {
  border-radius: 0;
}

.community-post .post-content .author-avatar.forum-icon img {
  padding: 2px;
}

.community-post .post-content .author-avatar img {
  max-width: 44px;
}

@media (max-width: 667px) {
  .community-post .post-content .author-avatar {
    margin-right: 20px;
  }
}

@media (max-width: 420px) {
  .community-post .post-content .author-avatar {
    margin-right: 15px;
  }
}

.community-post .post-content .entry-content {
  flex: 2;
}

.community-post .post-content .entry-content .post-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.7;
}

.community-post .post-content .entry-content .post-title:hover {
  color: var(--brand_color);
}

.community-post .post-content .entry-content p {
  margin: 0;
  color: var(--p_color);
}

@media (max-width: 667px) {
  .community-post .post-meta-wrapper {
    margin-top: 15px;
    margin-left: 70px;
  }
}

.community-post .post-meta-wrapper .post-meta-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

.community-post .post-meta-wrapper .post-meta-info li {
  display: inline-block;
}

.community-post .post-meta-wrapper .post-meta-info li:not(:last-child) {
  margin-right: 50px;
}

@media (max-width: 991px) {
  .community-post .post-meta-wrapper .post-meta-info li:not(:last-child) {
    margin-right: 20px;
  }
}

.community-post .post-meta-wrapper .post-meta-info li a {
  display: block;
  color: #868b99;
}

.community-post .post-meta-wrapper .post-meta-info li a i {
  margin-right: 10px;
}

.community-post .post-meta-wrapper .post-meta-info li a:hover {
  color: var(--brand_color);
}

.community-post.style-two {
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  position: relative;
}

.community-post.style-two:not(:last-child) {
  border-bottom: 1px solid #eaeeef;
}

.community-post.style-two .entry-content .post-title {
  font-size: 16px;
}

.community-post.style-two .post-meta-wrapper .post-meta-info li:not(:last-child) {
  margin-right: 30px;
}

.community-post.style-two .post-meta-wrapper .post-meta-info li i {
  transition: all 0.3s ease-in-out;
}

.community-post.style-two .post-meta-wrapper .post-meta-info li:nth-child(2):hover a {
  color: #b1b5c0;
}

.community-post.style-two .post-meta-wrapper .post-meta-info li:nth-child(2):hover i {
  color: #f9ae44;
}

.community-post.style-two .post-content .entry-content .post-title {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.community-post.style-two .post-content .com-featured {
  position: absolute;
  right: 0;
  top: -2px;
  height: 30px;
  width: 30px;
  z-index: 2;
  text-align: center;
  color: #fff;
  padding: 0 15px;
}

.community-post.style-two .post-content .com-featured i {
  z-index: 22;
  position: relative;
  font-size: 14px;
}

.community-post.style-two .post-content .com-featured:after {
  content: "";
  top: 0;
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 0 solid transparent;
  border-top: 30px solid #00ae69;
  z-index: 1;
}

.community-post.style-two .post-content .cat-wrap {
  display: inline-block;
  vertical-align: 3px;
}

.community-post.style-two .meta {
  margin: 0;
  padding: 0;
  list-style: none;
}

.community-post.style-two .meta li {
  display: inline-block;
  margin-right: 15px;
  color: #838793;
  font-size: 14px;
  min-width: 200px;
}

.community-post.style-two .meta li img {
  display: inline-block;
  vertical-align: -2px;
}

.community-post.style-two .meta li i,
.community-post.style-two .meta li img {
  margin-right: 10px;
  font-size: 14px;
}

.community-post.style-two .meta li a {
  font-size: 14px;
  color: #838793;
}

.community-post.style-two .meta li a:hover {
  color: var(--brand_color);
}

.community-post.style-two:hover {
  background: #f7f9fa;
  transform: translateX(0);
  box-shadow: none;
  border-color: #f7f9fa;
}

.badge {
  font-size: 12px;
  background: #8152e0;
  padding: 2px 5px 0;
  color: #fff;
  border-radius: 3px;
  margin-right: 5px;
  display: inline-block;
  line-height: 1.45;
  border-bottom: 1px solid #6030c0;
  vertical-align: middle;
  font-weight: 500;
  height: 22px;
}

.badge:hover {
  color: #fff;
}

.badge.color-yellow {
  background: #fad05a;
  color: #1d2746;
  border-color: #dbaa20;
}

.badge.color-ass {
  background: #eeeeee;
  color: #1d2746;
  border-color: #d9d9d9;
}

.badge.color-green {
  background: #22936d;
  border-color: #07734f;
}

.badge.color-orange {
  background: #f88546;
  border-color: #d26428;
}

.badge.color-theme {
  background: #27b2da;
  border-color: #1697bc;
}

.badge.color-pink {
  background: #f053b3;
  border-color: #c91c86;
}

.badge.color-pink {
  background: #f053b3;
  border-color: #c91c86;
}

.bb-radius {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.06);
  border: 1px solid var(--black_50);
  margin-top: -1px;
}

.button-container .all_doc_btn {
  margin-top: 40px;
}

@keyframes slideDown {
  0% {
    transform: translate3d(0, 15px, 0);
  }

  100% {
    transform: translate3d(0, 23px, 0);
  }
}

.doc_border_btn.border-light {
  border-color: #cfebf1 !important;
}

.doc_border_btn.border-light svg {
  margin-left: 8px;
  vertical-align: 0;
  transition: all 0.3s ease-in-out;
}

.doc_border_btn.border-light:hover {
  border-color: var(--brand_color) !important;
}

.doc_border_btn.border-light:hover svg path {
  fill: #fff;
}

.pagination-wrapper {
  background: #fff;
  padding: 23px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.06);
}

@media (max-width: 768px) {
  .pagination-wrapper {
    display: block;
    text-align: center;
  }

  .pagination-wrapper .view-post-of {
    margin-bottom: 20px;
  }
}

.pagination-wrapper p {
  margin: 0;
}

.pagination-wrapper .post-pagination {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 768px) {
  .pagination-wrapper .post-pagination {
    margin-to: 30px;
  }
}

.pagination-wrapper .post-pagination li {
  display: inline-block;
}

.pagination-wrapper .post-pagination li.pegi-disable {
  display: none;
}

.pagination-wrapper .post-pagination li:not(:last-child) {
  margin-right: 3px;
}

.pagination-wrapper .post-pagination li a {
  display: block;
  color: var(--p_color);
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: var(--black_25);
  border-radius: 3px;
}

.pagination-wrapper .post-pagination li a:hover,
.pagination-wrapper .post-pagination li a.active {
  background: var(--brand_color);
  color: #fff;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========== shortcode_text css ==============*/
.shortcode_text .shortcode_title p {
  margin-bottom: 20px;
}

.shortcode_text ul li {
  margin-bottom: 6px;
}

.shortcode_text ul li a {
  color: #6b707f;
}

.shortcode_text ul li a:hover {
  color: var(--brand_color);
}

.shortcode_text .c_head {
  margin-top: 30px;
}

.article_list {
  margin-bottom: 30px;
}

.doc_documentation_area .shortcode_title p {
  color: var(--black_500);
}

/*============== shortcode_info css =========*/
.doc-middle-content {
  padding-left: 15px;
  padding-right: 60px;
  padding-top: 70px;
}

.full-width-doc .doc-middle-content {
  padding-left: 50px;
  padding-right: 50px;
}

body.doc.test.remove .shortcode_info h4 {
  padding-top: 100px;
  margin-top: -100px;
}

.shortcode_info h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 25px;
  padding-top: 40px;
  margin-top: -40px;
}

.shortcode_info h6 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.shortcode_info p {
  margin-bottom: 30px;
  color: var(--p_color);
}

.shortcode_info footer {
  padding-top: 25px;
}

.tab_shortcode {
  margin-bottom: 50px;
}

.tab_shortcode .nav-tabs {
  border: 0;
}

.tab_shortcode .nav-tabs .nav-item .nav-link {
  font-size: 16px;
  color: var(--p_color);
  border-radius: 0;
  padding: 9px 20px;
  background: #f0f2f5;
  border: 0;
  border-left: 1px solid #dbe1e4;
  position: relative;
}

.tab_shortcode .nav-tabs .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 2.1px;
  background: var(--brand_color);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: width 0.2s linear;
}

.tab_shortcode .nav-tabs .nav-item .nav-link.active {
  background: #fff;
  color: var(--black_800);
}

.tab_shortcode .nav-tabs .nav-item .nav-link.active:before {
  opacity: 1;
  width: 100%;
}

.tab_shortcode .nav-tabs .nav-item:last-child .nav-link {
  border-right: 1px solid #dbe1e4;
}

.tab_shortcode .tab-content {
  color: var(--p_color);
  padding: 25px 30px;
  border: 1px solid #dbe1e4;
  border-radius: 5px;
  border-top-left-radius: 0;
}

/*============== shortcode_info css =========*/
/*=========== toggle_shortcode css ========*/
.toggle_shortcode {
  padding-bottom: 60px;
}

.toggle_btn {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: var(--black_800);
  background: #F2F6F7;
  text-align: left;
  border-radius: 4px;
  padding: 12px 30px;
  position: relative;
}

.toggle_btn:before {
  content: "C";
  position: absolute;
  font-size: 18px;
  font-family: eleganticons;
  right: 30px;
  transform: rotate(180deg);
  transition: all 0.2s linear;
  color: var(--brand_color);
}

.toggle_btn.collapsed:before {
  transform: rotate(0deg);
  color: var(--black_800);
}

.toggle_btn:focus {
  color: var(--black_800);
}

.toggle_body {
  border: 1px solid #edf1f7;
  padding: 18px 30px 24px 30px;
  border-radius: 0 0 4px 4px;
  line-height: 26px;
}

/*=========== toggle_shortcode css ========*/
/*=========== doc_accordion Shortcode css ========*/
.doc_accordion {
  border: 0;
  margin-bottom: 1rem;
}

.doc_accordion:last-child {
  margin-bottom: 0;
}

.doc_accordion .card-header {
  border-radius: 0;
  border: 0;
  background: transparent;
  padding: 0;
}

.doc_accordion .card-header button {
  padding: 12px 30px;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: left;
  width: 100%;
  font-size: 16px;
  color: var(--brand_color);
  font-weight: 500;
  background: #F2F6F7;
  text-decoration: none;
  position: relative;
}

.doc_accordion .card-header button svg {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  transition: all 0.2s linear;
}

.doc_accordion .card-header button svg+svg {
  display: block;
}

.doc_accordion .card-header button.collapsed {
  color: #202122;
  border-radius: 4px;
}

.doc_accordion .card-header button.collapsed svg {
  display: block;
  color: var(--p_color);
}

.doc_accordion .card-header button.collapsed svg+svg {
  display: none;
}

/*=========== doc_accordion Shortcode css ========*/
/*=========== message_alert Shortcode css ========*/
.message_alert {
  border: 1px solid #e5e9eb;
  border-radius: 4px;
  background: #fafcfd;
  padding: 32px 40px 30px;
  margin-bottom: 20px;
}

.message_alert i {
  font-size: 24px;
  color: #abb0c0;
  margin-right: 25px;
}

.message_alert h5 {
  font-size: 16px;
  color: var(--black_800);
  font-weight: 500;
  margin-bottom: 12px;
}

.message_alert p {
  margin-bottom: 0;
  color: var(--black_600);
}

.message_alert .close {
  position: absolute;
  right: 14px;
  top: 12px;
  font-size: 24px;
  opacity: 1;
}

.message_alert .close i {
  margin-right: 0;
}

.alert-danger {
  background: #fff3f4;
  border-color: #fbadb3;
}

.alert-danger i,
.alert-danger h5 {
  color: #fa303e;
}

.alert-success {
  background: #f1fdf3;
  border-color: #94e3a1;
}

.alert-success i,
.alert-success h5 {
  color: #10d631;
}

.alert-warning {
  background: #fefbf1;
  border-color: #f2dca0;
}

.alert-warning i,
.alert-warning h5 {
  color: #f6ba18;
}

.alert-info {
  background: #f1fbfd;
  border-color: #a0e3f2;
}

.alert-info i,
.alert-info h5 {
  color: var(--brand_color);
}

/*=========== message_alert Shortcode css ========*/
/*=========== notice Shortcode css ========*/
.notice_shortcode {
  padding-top: 25px;
}

.notice {
  border-left: 10px solid;
  padding: 30px 40px;
  margin-bottom: 20px;
  margin-top: 0;
}

.notice:before,
.notice:after {
  display: none;
}

.notice i {
  font-size: 24px;
  margin-right: 25px;
}

.notice h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.notice p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  color: #6b707f;
}

.notice p span {
  padding: 1px 10px;
  background: #fff;
  color: #ed2937;
}

.notice-success {
  background: #459e6d;
  border-color: #30845e;
}

.notice-success p,
.notice-success i,
.notice-success h5 {
  color: #fff;
}

.notice-warning {
  background: #fefaed;
  border-color: #f6ba18;
}

.notice-warning i {
  color: #f6ba18;
  padding-top: 4px;
}

.notice-danger {
  background: #ffeff0;
  border-color: #fa303e;
}

.notice-danger i {
  color: #fa303e;
}

.explanation {
  position: relative;
  background: linear-gradient(90deg, #fff, #75e3fb);
  border: 10px solid #fff;
  padding: 1rem 2rem;
  border-radius: 16px;
}

.explanation::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid #e6edf0;
  border-radius: 8px;
  pointer-events: none;
}

.explanation::after {
  font-family: "Roboto", sans-serif;
  content: "Hey!";
  text-transform: uppercase;
  font-weight: 700;
  top: -19px;
  left: 1rem;
  padding: 0 0.5rem;
  font-size: 0.6rem;
  position: absolute;
  z-index: 1;
  color: #000;
  background: #fff;
}

/*=========== lightbox Shortcode css ========*/
.lightbox_shortcode {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.lightbox_shortcode img {
  background-color: rgb(240, 242, 245);
  box-shadow: 0 4px 14px 0 rgba(4, 73, 89, 0.08);
  max-width: 100%;
}

.img_popup {
  position: absolute;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 52px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(29, 39, 70, 0.5);
  border-radius: 50%;
  color: #fff;
  font-size: 26px;
}

.img_popup i {
  margin-left: -2px;
}

.img_popup:hover,
.img_popup:focus {
  background: #fff;
  color: var(--black_800);
  box-shadow: 4px 15px 34px 2px rgba(4, 73, 89, 0.2);
}

button.mfp-close {
  font-size: 30px;
  color: #6b707f;
  right: 10px;
  top: 5px;
}

.mfp-bg {
  background: var(--bs-white);
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
  padding: 0;
}

.mfp-with-zoom .mfp-container {
  transform: scale(0);
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
  transform: scale(1);
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 1;
}

.mfp-figure:after {
  background-color: rgb(236, 239, 244);
  box-shadow: 0 20px 80px 0 rgba(4, 73, 89, 0.12);
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/*=========== lightbox Shortcode css ========*/
/*=========== tooltip Shortcode css ========*/
.tooltipster-sidetip .tooltipster-box {
  max-width: 300px;
  background: #fff;
  box-shadow: 0 30px 90px -20px rgba(0, 0, 0, 0.3), 0 0 1px 1px rgba(0, 0, 0, 0.05);
  border: 0;
  margin-top: 0;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content {
  padding: 0;
  background: #fff;
  border-radius: 5px;
  z-index: 1;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content .blog_grid_post {
  margin-bottom: 0;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content .blog_grid_post img {
  max-width: 100%;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content .blog_grid_post .grid_post_content {
  padding: 30px 20px 10px;
  box-shadow: none;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content .blog_grid_post .grid_post_content .post_tag a {
  font-size: 14px;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content .blog_grid_post .grid_post_content .b_title {
  font-size: 18px;
  line-height: 25px;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content .blog_grid_post .grid_post_content p {
  color: var(--p_color);
  font-size: 14px;
  line-height: 22px;
}

.tooltip_content p {
  margin-bottom: 25px;
}

.tooltip_content p i {
  vertical-align: middle;
}

.tooltip_content p a {
  color: var(--brand_color);
}

.tooltip_content .text-decoration {
  position: relative;
  display: inline-block;
}

.tooltip_content .text-decoration:before {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--brand_color);
  position: absolute;
  bottom: 5px;
  left: 0;
}

.direction_steps {
  display: inline-flex;
  align-items: center;
}

.direction_step {
  padding: 0 8px;
  font-size: 13px;
  border-radius: 4px;
  background: rgba(127, 130, 248, 0.8);
  color: #fff;
  position: relative;
  line-height: 20px;
}

.direction_step+.direction_step {
  margin-left: 28px;
}

.direction_step+.direction_step:before {
  content: "$";
  font-family: eleganticons;
  position: absolute;
  left: -22px;
  color: rgba(127, 130, 248, 0.8);
}

.tip_content {
  background: #fff;
  box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.16);
  max-width: 320px;
  max-height: 100%;
  border-radius: 6px;
  text-align: left;
}

.tip_content .text {
  padding: 25px;
}

.tip_content p {
  font-size: 14px;
  line-height: 26px;
  color: #6b707f;
  margin-bottom: 0;
}

.tip_content .title {
  font-size: 14px;
  color: #1d2746;
  font-weight: 500;
  padding-top: 0;
  margin-bottom: 8px;
}

.tip_content .title span {
  display: block;
  font-weight: 400;
  color: #6b707f;
  font-size: 14px;
  padding-top: 5px;
}

.tip_content img {
  max-width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.tip_content#tooltipTwo {
  min-height: 450px;
}

/*=========== tooltip Shortcode css ========*/
/*=========== image_pointer Shortcode css ========*/
.image_pointer .pointing_img {
  padding-bottom: 20px;
}

.image_pointer .pointing_img img {
  max-width: 100%;
  box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.1);
}

.image_pointer .pointing_img h6 {
  margin-top: 35px;
}

.image_pointer .pointing_img h6 a {
  color: var(--brand_color);
}

.modal-open {
  padding-right: 0 !important;
}

.modal-backdrop {
  background: #fff;
}

.img_modal {
  background: #fff;
  padding-right: 0 !important;
  box-shadow: 0 4px 14px 0 rgba(4, 73, 89, 0.08);
}

.img_modal img {
  box-shadow: 0 30px 80px 0 rgba(4, 73, 89, 0.12);
}

.img_modal .close {
  font-size: 30px;
  padding-right: 25px;
  padding-top: 25px;
  opacity: 1;
  color: #6b707f;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  z-index: 3;
}

.img_modal.fade .pointing_img_container {
  max-width: 1170px;
  margin: 0rem auto;
  padding: 80px 0;
  height: 100%;
  display: flex;
  align-items: center;
  transform: translate(0, 0);
}

.img_modal.fade .pointing_img_container .modal-content {
  border: 0;
  padding: 0;
}

.img_modal.fade .pointing_img_container .modal-content img {
  max-width: 100%;
}

.img_pointing {
  width: 10px;
  height: 10px;
  background: #a54ffe;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.img_pointing:before,
.img_pointing:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  background: #a54ffe;
}

.img_pointing:before {
  transform: scale(2.1);
  opacity: 0.3;
}

.img_pointing:after {
  transform: scale(3);
  opacity: 0.2;
}

.img_pointing .dot {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(165, 79, 254, 0.9);
  animation: pulse1 3s infinite;
  transform: translate(-50%, -50%);
  animation-delay: 1.5s;
  will-change: transform;
}

.img_pointing_content {
  padding: 25px;
}

.img_pointing_content p {
  font-size: 14px;
  line-height: 22px;
  font-family: "Roboto", sans-serif;
  color: #6b707f;
  margin-bottom: 0;
}

.img_pointing_content p span {
  color: var(--black_800);
}

.img_pointing_content p+p {
  margin-top: 15px;
}

.pointing_img_two {
  position: relative;
  margin-bottom: 60px;
}

.pointing_img_two img {
  box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.1);
}

/*=========== image_pointer Shortcode css ========*/
/*=========== caniuse Shortcode css ========*/
.caniuse ul {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.caniuse ul .caniuse_agents_item {
  text-align: center;
  border-radius: 5px;
  padding: 23px 30px 20px;
  margin: 6px;
}

.caniuse ul .caniuse_agents_item img {
  height: 40px;
  display: block;
}

.caniuse ul .caniuse_agents_item span {
  display: block;
  font-size: 20px;
  padding-top: 13px;
}

.caniuse_section+.caniuse_section {
  margin-top: 55px;
}

.light_blue {
  background: #ece6fc;
  color: #784bfb;
}

.light_red {
  background: #fce6e6;
  color: #f84343;
}

.blue {
  background: #e6f8fc;
  color: var(--brand_color);
}

.caniuse_section_legend p {
  padding-top: 25px;
}

.caniuse_section_legend .caniuse_legend_list {
  padding: 15px 0 10px;
}

.caniuse_section_legend .caniuse_legend_list span {
  margin-right: 20px;
}

.caniuse_section_legend .caniuse_legend_list a {
  font-size: 14px;
  font-weight: 500;
  padding: 0 18px;
  display: inline-block;
  margin: 0 3px;
  border-radius: 2px;
}

.caniuse_section_legend .caniuse_legend_list a.blue:hover {
  background: var(--brand_color);
  color: #fff;
}

.purpale_btn {
  background: #fad9ea;
  color: #e32a8b;
}

.purpale_btn:hover {
  background: #e32a8b;
  color: #fff;
}

.green_btn {
  background: #cef5d4;
  color: #11ca30;
}

.green_btn:hover {
  background: #11ca30;
  color: #fff;
}

.violate_btn {
  background: #e8d9fa;
  color: #9744fd;
}

.violate_btn:hover {
  background: #9744fd;
  color: #fff;
}

/*=========== caniuse Shortcode css ========*/
/*=========== table Shortcode css ========*/
.table_shortcode thead {
  background: #5757ff;
}

.table_shortcode thead th {
  padding: 20px 25px;
  border: 0;
}

.table_shortcode th,
.table_shortcode td {
  border: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  vertical-align: middle;
}

.table_shortcode th {
  font-weight: 500;
}

.table_shortcode tbody tr {
  background: #f0f2f5;
}

.table_shortcode tbody tr th {
  background: var(--brand_color);
}

.table_shortcode tbody tr td,
.table_shortcode tbody tr th {
  padding: 12px 25px;
}

.table_shortcode tbody tr td {
  color: #6b707f;
}

.table_shortcode tbody tr:nth-child(odd) {
  background: var(--bs-white);
}

.basic_table {
  padding-top: 45px;
}

.basic_table .s_title {
  margin-bottom: 10px;
}

.basic_table p {
  margin-bottom: 25px;
}

.basic_table_info {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #f1f2f3;
}

.basic_table_info thead th {
  border: 0;
}

.basic_table_info tbody tr th,
.basic_table_info tbody tr td {
  border-color: #eaeff4;
}

.basic_table_info.table-hover tbody tr {
  transition: all 0.3s linear;
}

.basic_table_info.table-hover tbody tr:hover {
  background: var(--brand_color);
  color: #fff;
}

/*=========== table Shortcode css ========*/
/*============ Footnote ===========*/
.text-component--has-footnotes {
  counter-reset: footnotes;
}

.footnotes {
  border-top: 1px solid var(--color-contrast-low);
  font-size: 0.875em;
}

.footnotes #footnotes {
  font-weight: 700;
}

.footnotes .footnotes_item {
  font-size: 14px;
  position: relative;
  padding-left: 25px;
}

.footnotes .footnotes_item .back-article {
  position: absolute;
  left: 1px;
  top: 2px;
  font-size: 16px;
}

.footnotes .footnotes_item strong {
  color: var(--black_800);
  padding-bottom: 7px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

.footnotes .footnotes_item+.footnotes_item {
  margin-top: 20px;
}

.footnotes-link {
  counter-increment: footnotes;
  font-size: 0.75em;
  vertical-align: super;
}

.footnotes-link::before {
  content: "["counter(footnotes) "]";
}

.footnotes-link:target,
.footnotes-link:target {
  outline: 2px solid var(--brand_color);
  outline-offset: 2px;
}

/*============ Footnote ===========*/
/*============ List Style ===========*/
.single_list {
  list-style: none;
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.02);
  border: 1px solid #e5ebef;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 2px;
}

.single_list li {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  position: relative;
  padding-left: 20px;
}

.single_list li:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--brand_color);
  position: absolute;
  left: 0;
  top: 9px;
}

.single_list li+li {
  margin-top: 15px;
}

/** === List Style === **/
.steps-panel {
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.02);
  border: 1px solid #e5ebef;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 2px;
}

ul.ordered-list li::before {
  content: "h";
  font-family: "ElegantIcons";
  font-size: 10px;
}

.ordered-list {
  position: relative;
  list-style: none;
  padding-left: 20px;
  margin: 20px 0;
}

.ordered-list::before {
  content: "";
  position: absolute;
  left: 32px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #c4cdd5;
}

.ordered-list li {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin: 16px 0;
  position: relative;
  padding-left: 35px;
  counter-increment: a;
}

.ordered-list li::before {
  content: counter(a);
  position: absolute;
  color: #fff;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  background-color: var(--brand_color);
  text-align: center;
  line-height: 23px;
  top: -4px;
  left: -4px;
  border: 5px solid var(--bs-white);
}

/*============ changelog css ===========*/
.changelog_inner {
  padding-right: 30px;
  padding-bottom: 100px;
}

.changelog_info {
  padding-top: 15px;
}

.changelog_info .version_info {
  margin-right: 15px;
  position: relative;
  height: 100%;
}

.changelog_info .c_version {
  flex: 1;
  background: #1d2746;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  height: 26px;
  line-height: 27px;
  border-radius: 3px;
  color: #fff;
  min-width: 50px;
  max-width: 60px;
  display: block;
  margin: 0 auto;
}

.changelog_info .changelog_date {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
}

.changelog_info .changelog_date .c_date h6 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}

.changelog_info .changelog_date .c_date p {
  font-size: 14px;
  margin-bottom: 0;
  color: #6b707f;
}

.changelog_info .line {
  height: 106%;
  width: 2px;
  background: #e2e5ee;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
}

.changelog_info:last-child .line {
  height: 100%;
}

.changelog_info:last-child .changelog_content {
  padding-bottom: 0;
}

.changelog_content {
  padding-bottom: 60px;
}

.changelog_content p {
  display: flex;
  margin-bottom: 2px;
  font-size: 14px;
}

.changelog_content span {
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  padding: 0 10px;
  text-transform: uppercase;
  min-width: 110px;
  display: inline-block;
  text-align: center;
  line-height: 23px;
  border-radius: 3px;
  margin-right: 20px;
  height: 22px;
  flex: 0;
  letter-spacing: 0.8px;
}

.changelog_content span.improve {
  background: var(--brand_color);
}

.changelog_content span.new {
  background: #0ed193;
}

.changelog_content span.update {
  background: #ffa100;
}

.changelog_content span.fixed {
  background: #ea3940;
}

.changelog_content .download-links {
  border-top: 1px dotted rgba(51, 51, 51, 0.2);
  margin-top: 15px;
}

.changelog_content .changelog_btn {
  font-size: 14px;
  color: #6b707f;
  font-weight: 500;
  margin-top: 12px;
  display: inline-block;
  opacity: 0.5;
}

.changelog_content .changelog_btn i {
  margin-right: 10px;
}

.changelog_content .changelog_btn:hover {
  color: var(--brand_color);
}

.changelog_content .changelog_btn+.changelog_btn {
  margin-left: 28px;
}

.changelog_content:hover .changelog_btn {
  opacity: 1;
}

.tour_info_content p {
  font-size: 14px;
  margin-bottom: 0;
}

.tour_item {
  padding: 70px 0;
}

.tour_item .arrow.text-right {
  margin-right: -80px;
  position: relative;
  z-index: 1;
}

.tour_intro_item .tour_info_content {
  position: relative;
}

.tour_intro_item .tour_info_content p {
  margin-bottom: 55px;
}

.tour_intro_item .tour_info_content .arrow {
  position: absolute;
  bottom: 0;
  left: -20px;
}

.tour_intro_item.flex-row-reverse .arrow {
  right: -20px;
  left: auto;
}

.tour_intro_item+.tour_intro_item {
  margin-top: 60px;
}

.last_tour_item {
  padding-bottom: 0;
}

.just_text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.just_text p {
  margin-bottom: 0;
}

.just_text+.just_text {
  margin-top: 70px;
}

.answer_bottom {
  align-items: flex-start;
}

.tour_preview_img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/*========== cheatsheet_info css =========*/
.cheatsheet_info+.cheatsheet_info {
  margin-top: 30px;
}

.cheatsheet_accordian .card {
  border: 0;
  padding: 0;
  overflow: visible;
  margin-top: 10px;
  background: transparent;
}

.cheatsheet_accordian .card .card-header {
  padding: 0;
  border: 0;
  background: transparent;
}

.cheatsheet_accordian .card .card-header button {
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  background: transparent;
  color: var(--black_800);
  text-decoration: none;
}

.cheatsheet_accordian .card .card-header button .minus {
  display: inline-block;
}

.cheatsheet_accordian .card .card-header button .pluse {
  display: none;
}

.cheatsheet_accordian .card .card-header button.collapsed .pluse {
  display: inline-block;
}

.cheatsheet_accordian .card .card-header button.collapsed .minus {
  display: none;
}

.cheatsheet_item {
  background: var(--bs-white);
  text-align: left;
  padding: 20px 20px 30px;
  margin-bottom: 30px;
  transition: all 0.3s linear;
}

.cheatsheet_item:hover {
  box-shadow: 0 20px 27px 0 rgba(4, 73, 89, 0.08);
}

.cheatsheet_item .cheatsheet_num {
  text-align: right;
}

.cheatsheet_item p {
  margin-bottom: 0;
}

.cheatsheet_item h5 {
  margin-bottom: 0;
  color: var(--brand_color);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Hotspot Area css
============================================================================================ */
.pointing_img_container .divs {
  visibility: hidden;
  height: 0;
}

.pointing_img_container .nav.list>li {
  position: absolute;
  top: 0;
}

.pointing_img_container .nav.list>li.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.pointing_img_container .nav.list>li .dropdown-menu {
  display: block;
  top: 0;
  left: 0;
  transform: translate(0);
  position: absolute;
  max-width: 500px;
  border-radius: 10px;
  min-width: 400px;
  background: var(--bs-white);
  padding: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
  border: 1px solid var(--black_50);
  z-index: 30;
}

.pointing_img_container .nav.list>li .dropdown-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 16px 9px 0;
  border-color: transparent var(--black_50) transparent transparent;
  position: absolute;
  left: -16px;
  top: 30px;
  z-index: 1;
}

.pointing_img_container .nav.list>li .dropdown-menu::after {
  content: "";
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 9px 16px 9px 0;
  border-color: transparent var(--black_50) transparent transparent;
  position: absolute;
  left: -21px;
  top: 30px;
}

.pointing_img_container .nav.list>li .dropdown-menu .text_part {
  padding-top: 27px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.pointing_img_container .nav.list>li .dropdown-menu .text_part .close_btn {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.pointing_img_container .nav.list>li .dropdown-menu .text_part h4 {
  font-size: 24px;
  margin-bottom: 18px;
}

.pointing_img_container .nav.list>li .dropdown-menu .text_part h4 span {
  color: #6b707f;
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
}

.pointing_img_container .nav.list>li .dropdown-menu .text_part p {
  font-size: 16px;
  line-height: 26px;
  color: var(--p_color);
  max-width: 340px;
}

.pointing_img_container .nav.list>li .dropdown-menu .prev {
  border-bottom-left-radius: 10px;
}

.pointing_img_container .nav.list>li .dropdown-menu .next {
  border-bottom-right-radius: 10px;
}

.pointing_img_container .nav.list>li .dropdown-menu .prev,
.pointing_img_container .nav.list>li .dropdown-menu .next {
  background: var(--brand_color);
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  line-height: 50px;
  padding: 0 19px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.pointing_img_container .nav.list>li .dropdown-menu .bullets_pointing {
  background: #1d3846;
  height: 50px;
  margin-left: -30px;
  margin-right: -30px;
}

.pointing_img_container .nav.list>li .dropdown-menu .bullets_pointing .nav {
  padding: 21px 0;
}

.pointing_img_container .nav.list>li .dropdown-menu .bullets_pointing .nav li {
  height: 8px;
  width: 8px;
  border: 1px solid #3b6980;
  border-radius: 50%;
  margin-right: 26px;
  position: relative;
}

.pointing_img_container .nav.list>li .dropdown-menu .bullets_pointing .nav li:before {
  content: "";
  width: 26px;
  height: 1px;
  background: #3b6980;
  position: absolute;
  left: 7px;
  top: 3px;
}

.pointing_img_container .nav.list>li .dropdown-menu .bullets_pointing .nav li:last-child {
  margin-right: 0;
}

.pointing_img_container .nav.list>li .dropdown-menu .bullets_pointing .nav li:last-child:before {
  display: none;
}

.pointing_img_container .nav.list>li:nth-child(1) {
  z-index: 5;
  top: 48px;
  left: 158px;
}

.pointing_img_container .nav.list>li:nth-child(1) .dropdown-menu {
  left: 35px !important;
  top: -38px !important;
}

.pointing_img_container .nav.list>li:nth-child(1).active .dropdown-menu {
  left: 43px !important;
  transition: all 300ms linear 0s;
}

.pointing_img_container .nav.list>li:nth-child(1).active .bullets_pointing .nav li:first-child {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 0 8px #ffffff;
}

.pointing_img_container .nav.list>li:nth-child(2) {
  right: 128px;
  top: 80px;
  left: auto;
  z-index: 4;
}

.pointing_img_container .nav.list>li:nth-child(2) .dropdown-menu {
  left: auto !important;
  top: -35px !important;
  right: 26px;
}

.pointing_img_container .nav.list>li:nth-child(2) .dropdown-menu:before {
  left: auto;
  right: -16px;
  transform: rotate(180deg);
}

.pointing_img_container .nav.list>li:nth-child(2).active .dropdown-menu {
  right: -172px;
  transition: all 300ms linear 0s;
}

.pointing_img_container .nav.list>li:nth-child(2).active .bullets_pointing .nav li:nth-child(2) {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 0 8px #ffffff;
}

.pointing_img_container .nav.list>li:nth-child(3) {
  top: 34%;
  left: 55%;
  z-index: 3;
  transform: translateX(-50%) translateY(50%);
}

.pointing_img_container .nav.list>li:nth-child(3) .dropdown-menu {
  left: auto !important;
  top: -35px !important;
  right: 26px;
}

.pointing_img_container .nav.list>li:nth-child(3) .dropdown-menu:before {
  left: auto;
  right: -20px;
  transform: rotate(180deg);
}

.pointing_img_container .nav.list>li:nth-child(3).active .dropdown-menu {
  right: 34px;
  transition: all 300ms linear 0s;
}

.pointing_img_container .nav.list>li:nth-child(3).active .bullets_pointing .nav li:nth-child(3) {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 0 8px #ffffff;
}

.pointing_img_container .nav.list>li:nth-child(4) {
  top: 51%;
  left: 75px;
  z-index: 2;
  transform: translateX(0%) translateY(50%);
}

.pointing_img_container .nav.list>li:nth-child(4) .dropdown-menu {
  left: 39px !important;
  top: -37px !important;
}

.pointing_img_container .nav.list>li:nth-child(4).active .dropdown-menu {
  left: 46px !important;
  transition: all 300ms linear 0s;
}

.pointing_img_container .nav.list>li:nth-child(4).active .bullets_pointing .nav li:nth-child(4) {
  background: #fff;
  border-color: #fff;
  box-shadow: 0 0 8px #ffffff;
}

.pointing_img_container .nav.list>li.active .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.blur nav {
  filter: blur(6px);
}

.blur .doc_banner_area.search-banner-light {
  filter: blur(6px);
}

.blur .breadcrumb_link {
  filter: blur(6px);
}

.blur .doc_mobile_menu {
  filter: blur(6px);
}

.blur .shortcode_title {
  filter: blur(6px);
}

.blur .image_pointer {
  filter: blur(6px);
}

.blur .shortcode_info footer {
  filter: blur(6px);
}

.blur .forum_footer_area {
  filter: blur(6px);
}

/* End Hotspot Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*==============typography_area css ================*/
.typography_area {
  padding: 115px 0 120px;
}

.typography_area p {
  line-height: 28px;
}

.heading_content .t_title {
  color: var(--p_color);
  margin-bottom: 0;
  padding-bottom: 20px;
}

.heading_content .bold,
.heading_content .medium,
.heading_content .regular {
  margin-bottom: 22px;
  line-height: 1;
}

.typography_preview {
  margin-top: 30px;
}

.typography_underline h4 {
  margin-bottom: 35px;
}

.highlight_text,
.typography_underline {
  padding-right: 100px;
  margin-top: 85px;
}

.highlight_text h5 {
  margin-bottom: 30px;
}

.highlight_text span {
  display: inline-block;
  padding: 0 6px;
}

.highlight_text .h_black {
  background: #222d39;
  color: #fff;
}

.highlight_text .h_green {
  background: #15e18d;
  color: #fff;
}

.highlight_text .h_blue {
  background: #2cabed;
  color: #fff;
}

.highlight_text p .tooltips_one {
  color: #4b5ffa;
}

.highlight_text p .tooltips_two {
  color: #ef971a;
}

.tooltip_blue .arrow:before {
  border-top-color: #4b5ffa;
}

.tooltip_blue.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #4b5ffa;
}

.tooltip_blue .tooltip-inner {
  background: #4b5ffa;
}

.tooltip_danger.show {
  opacity: 1;
}

.tooltip_danger {
  opacity: 1;
}

.tooltip_danger .arrow:before {
  border-top-color: #ef971a;
}

.tooltip_danger.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #ef971a;
}

.tooltip_danger .tooltip-inner {
  background: #ef971a;
}

.dropcap_content p span {
  float: left;
  font-size: 54px;
  margin-right: 10px;
  color: #fc5bc1;
  font-weight: 700;
  line-height: 55px;
  font-family: "Roboto", sans-serif;
}

.dropcap_content p .r_dropcap {
  background: #13c5bf;
  border-radius: 4px;
  color: #fff;
  padding: 0 6px;
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 10px;
}

.typography_list h5,
.dropcap_content h5 {
  margin-bottom: 30px;
}

.typography_list ul li a,
.typography_list ol li a {
  font-size: 15px;
  line-height: 34px;
  color: var(--p_color);
  font-weight: 400;
}

.typography_list ol {
  padding-left: 15px;
}

.typography_list ol li ol {
  padding-left: 30px;
}

.typography_list .unorderlist li {
  position: relative;
  padding-left: 14px;
}

.typography_list .unorderlist li:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #a7acbd;
  position: absolute;
  left: 0;
  top: 14px;
}

.typography_list .unorderlist li ul {
  padding-left: 10px;
}

.blockquote_inner {
  margin-top: 85px;
}

.blockquote_inner h6 {
  color: var(--p_color);
  margin-bottom: 0;
}

.blockquote {
  background: transparent;
  padding: 0 0 0 40px;
  border-color: #1d2746;
  border-width: 2px;
  margin-bottom: 55px;
}

.blockquote:before,
.blockquote:after {
  display: none;
}

.blockquote p {
  font-size: 18px;
  font-style: italic;
  color: #4b505e;
  line-height: 30px;
}

.blockquote_two {
  padding: 14px 40px 26px 140px;
  border: 0;
  position: relative;
  margin-top: 25px;
}

.blockquote_two:before,
.blockquote_two:after {
  display: none;
}

.blockquote_two span {
  background: var(--bs-white);
  padding: 0 4px;
}

.blockquote_two .quote_icon {
  font-size: 150px;
  color: #d3d6e1;
  position: absolute;
  transform: rotate(180deg);
  top: 18px;
  background: transparent;
  left: 40px;
  line-height: 155px;
}

.blockquote_two h5 {
  font-size: 22px;
  line-height: 40px;
  font-style: italic;
  font-weight: 400;
  color: var(--p_color);
}

.button_inner {
  margin-top: 60px;
}

.button_inner h4 {
  margin-bottom: 50px;
  padding-top: 30px;
}

.button_inner h6 {
  color: var(--p_color);
  font-weight: 400;
}

.button_inner .button_inner_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0 50px;
  border-bottom: 1px solid #e6eeef;
}

.button_inner:last-child .button_inner_one {
  border: 0;
  padding-bottom: 0;
}

.btn_small {
  padding: 0 16px;
}

.btn_small_two {
  padding: 8px 29px;
}

.doc_border_btn_two,
.btn_small_three {
  padding: 10px 47px;
}

.btn_bg {
  padding: 15px 78px;
}

.btn_radious_none {
  border-radius: 0;
  padding: 10px 68px;
}

.btn_radious_45 {
  border-radius: 45px;
  padding: 5px 28px;
}

.action_btn.btn_small,
.action_btn.btn_small_two,
.action_btn.btn_small_three,
.action_btn.btn_bg,
.action_btn.btn_radious_none,
.action_btn.btn_radious_45 {
  box-shadow: none;
  border: 2px solid var(--brand_color);
  transition: all 0.3s linear;
}

.action_btn.btn_small:hover,
.action_btn.btn_small_two:hover,
.action_btn.btn_small_three:hover,
.action_btn.btn_bg:hover,
.action_btn.btn_radious_none:hover,
.action_btn.btn_radious_45:hover {
  background: rgba(76, 76, 241, 0.05);
  border-color: rgba(76, 76, 241, 0.3);
  color: var(--brand_color);
}

.icon_btn {
  border: 2px solid rgba(76, 76, 241, 0.3);
  margin-left: 0;
  background: rgba(76, 76, 241, 0.05);
}

.icon_btn i {
  padding-right: 7px;
}

.icon_btn:hover {
  border-color: var(--brand_color);
}

.arrow_btn_medium {
  padding: 13px 28px;
}

.arrow_btn_big {
  padding: 18px 44px;
  font-size: 18px;
}

.arrow_btn_big i {
  font-size: 28px;
  padding-left: 12px;
}

.arrow_btn_small {
  padding: 15px 37px;
}

.arrow_btn_small_two {
  padding: 10px 23px;
}

.dropcap_inner {
  padding: 50px 0;
}

/*==============typography_area css ================*/
/*==============action_area_three css ================*/
.action_area_three {
  background: url("../../img/action_bg.jpg") no-repeat scroll center 0/cover;
  padding: 110px 0;
  background-attachment: fixed;
}

.action_content_three {
  max-width: 520px;
  margin: 0 auto;
}

.action_content_three h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}

.action_content_three .doc_border_btn {
  background: transparent;
  color: #fff;
  border-color: #b4b6da;
  padding: 13px 34px;
}

.action_content_three .doc_border_btn:hover {
  background: #ffffff;
  color: var(--brand_color);
  border-color: #fff;
}

/*==============action_area_three css ================*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/** === Forum heading bar === **/
.post-header {
  display: flex;
  justify-content: space-between;
  background: rgba(76, 76, 241, 0.05);
  padding: 17px 30px;
  border: 1px solid #eaeeef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.post-header.forums-header {
  padding: 17px 15px;
  display: flex;
  text-align: left;
}

@media (max-width: 768px) {
  .post-header {
    display: block;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .post-header {
    padding: 15px;
  }
}

.post-header .support-total-info {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 768px) {
  .post-header .support-total-info {
    margin-bottom: 20px;
  }
}

.post-header .support-total-info li {
  display: inline-block;
  font-size: 14px;
}

.post-header .support-total-info li.open-ticket {
  color: #1d2746;
  font-weight: 500;
}

.post-header .support-total-info li.close-ticket a {
  color: #6b707f;
}

.post-header .support-total-info li a {
  font-size: 14px;
  font-weight: 500;
}

.post-header .support-total-info li a:hover {
  color: var(--brand_color);
}

.post-header .support-total-info li:not(:last-child) {
  margin-right: 20px;
}

.post-header .support-total-info li i {
  color: #00ae69;
  margin-right: 10px;
  vertical-align: -2px;
}

.post-header .support-total-info li:nth-child(1) i {
  color: #f06292;
}

.post-header .category-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.post-header .category-menu li {
  display: inline-block;
}

.post-header .category-menu li .dropdown-menu {
  top: 12px !important;
  transform: translate3d(0, 23px, 0) !important;
}

.post-header .category-menu li:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 546px) {
  .post-header .category-menu li:not(:last-child) {
    margin-right: 0;
  }
}

.post-header .category-menu li:nth-child(4) .dropdown-menu,
.post-header .category-menu li:nth-child(3) .dropdown-menu {
  right: -11px;
  left: auto !important;
}

@media (max-width: 380px) {
  .post-header .category-menu li:nth-child(4) .dropdown-menu {
    width: 190px;
  }
}

.post-header .category-menu li:nth-child(3) .title:before,
.post-header .category-menu li:nth-child(3) .title:after {
  right: 40px;
}

.post-header .category-menu li:nth-child(1) .title:before,
.post-header .category-menu li:nth-child(1) .title:after {
  right: auto;
  left: 30px;
}

.post-header .category-menu li:nth-child(2) .title:before,
.post-header .category-menu li:nth-child(2) .title:after {
  right: auto;
  left: 25px;
}

.post-header .category-menu .dropdown-toggle {
  position: relative;
  background: transparent;
  box-shadow: none;
  border: 0;
  color: #6b707f;
  padding: 0 15px;
  font-size: 14px;
}

.post-header .category-menu .dropdown-toggle:after {
  content: "3";
  font-family: eleganticons;
  border: 0;
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-58%);
}

.post-header .category-menu .btn-secondary:not(:disabled):not(.disabled).active,
.post-header .category-menu .btn-secondary:not(:disabled):not(.disabled):active,
.post-header .category-menu .show>.btn-secondary.dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #6b707f;
  box-shadow: none;
}

.post-header .category-menu .dropdown-menu {
  border: 1px solid #e6eeef;
  background: #fff;
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
  padding: 0;
  width: 260px;
  animation: slideDown 0.12s cubic-bezier(0, 0.1, 0.1, 1) backwards;
}

@media (max-width: 576px) {
  .post-header .category-menu .dropdown-menu {
    width: 210px;
  }
}

.post-header .category-menu .title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background: #f7f8f9;
  border-bottom: 1px solid #e6eeef;
  padding: 14px 20px 11px;
  line-height: 1;
  color: #6b707f;
  position: relative;
}

.post-header .category-menu .title:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #e6eeef;
  position: absolute;
  right: 30px;
  transform: rotate(45deg);
  top: -8px;
}

.post-header .category-menu .title:after {
  content: "";
  height: 16px;
  width: 16px;
  background: #f7f8f9;
  position: absolute;
  right: 30px;
  transform: rotate(45deg);
  top: -6px;
}

.post-header .category-menu .cate-search-form {
  padding: 0 20px;
  margin-top: 10px;
}

.post-header .category-menu .cate-search-form input {
  width: 100%;
  background: #fbfcfc;
  border: 2px solid #e2e7e8;
  border-radius: 4px;
  padding: 6px 20px;
  font-size: 14px;
}

.post-header .category-menu .cate-search-form input::placeholder {
  color: #a8acb6;
}

.post-header .category-menu .cate-search-form input:focus {
  background: #fff;
}

.post-header .category-menu .all-users {
  font-size: 14px;
  padding: 12px 22px;
  border-bottom: 1px solid #e6eeef;
  font-weight: 500;
  color: #6b707f;
}

.post-header .category-menu .all-users a {
  padding: 7px 0;
  color: #838793;
}

.post-header .category-menu .all-users a span {
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background: #27b2da;
  display: inline-block;
  margin-right: 10px;
  vertical-align: -3px;
}

.post-header .category-menu .all-users a span.color-pupple {
  background: #8152e0;
}

.post-header .category-menu .all-users a span.color-yellow {
  background: #fad05a;
}

.post-header .category-menu .all-users a span.color-ass {
  background: #dfdfdf;
}

.post-header .category-menu .all-users a span.color-green {
  background: #22936d;
}

.post-header .category-menu .all-users a span.color-orange {
  background: #f88546;
}

.post-header .category-menu .all-users a span.color-light-green {
  background: #1eba17;
}

.post-header .category-menu .all-users a:not(:last-child) {
  border-bottom: 1px solid #e6eeef;
}

.post-header .category-menu .all-users a img {
  width: 20px;
  margin-right: 10px;
  display: inline-block;
}

.post-header .category-menu .all-users a:hover,
.post-header .category-menu .all-users a:focus {
  background: transparent;
  color: #1d2746;
}

.post-header .category-menu .short-by {
  padding: 10px 20px;
}

.post-header .category-menu .short-by a {
  padding: 7px 0 7px 20px;
  position: relative;
  color: #838793;
  font-size: 14px;
}

.post-header .category-menu .short-by a.active-short {
  color: #1d2746;
}

.post-header .category-menu .short-by a.active-short:before {
  content: "N";
  position: absolute;
  font-family: eleganticons;
  left: 0;
  top: 7px;
  color: #0abe76;
  font-size: 14px;
}

.post-header .category-menu .short-by a:hover {
  background: transparent;
  color: #1d2746;
}

ul.forum-titles {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

ul.forum-titles li {
  text-align: right;
  font-size: 14px;
}

ul.forum-titles .forum-topic-count,
ul.forum-titles .forum-reply-count {
  flex-basis: 25%;
}

ul.forum-titles .forum-freshness {
  flex-basis: 50%;
}

ul.forum-titles .forum-freshness .freshness-box {
  display: flex;
  font-size: 14px;
  line-height: 1.3;
  flex-direction: column;
}

ul.forum-titles .forum-freshness .freshness-box a {
  color: #6b707f;
}

ul.forum-titles .forum-freshness .freshness-box a:hover {
  color: var(--brand_color);
}

ul.forum-titles .forum-freshness .freshness-box .freshness-btm {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  align-items: center;
}

ul.forum-titles .forum-freshness .freshness-box .freshness-btm a.bbp-author-link {
  padding-right: 10px;
}

ul.forum-titles .forum-freshness .freshness-box .freshness-btm .bbp-author-avatar img {
  max-width: 30px;
  border-radius: 50%;
}

.forum-page-content .communities-boxes {
  margin: 0 -30px;
}

.answer-action {
  border-radius: 6px;
  background: var(--bs-white);
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .answer-action {
    display: block;
  }
}

.answer-action .action-content {
  display: flex;
  align-items: center;
}

.answer-action .action-content .image-wrap {
  margin-right: 30px;
}

.answer-action .action-content .ans-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
}

.answer-action .action-content p {
  margin: 0;
  color: var(--p_color);
}

.answer-action .btn-ans {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 25px;
  box-shadow: none;
  border: 1px solid var(--brand_color);
  transition: all 0.3s ease-in-out;
}

.answer-action .btn-ans:hover {
  background: transparent;
  color: var(--brand_color);
}

@media (max-width: 768px) {
  .answer-action .btn-ans {
    margin-left: 130px;
    margin-top: 5px;
  }
}

.call-to-action {
  padding: 55px 0;
  background: #1d2746;
  position: relative;
}

.call-to-action .container {
  position: relative;
}

.call-to-action .overlay-bg {
  position: absolute;
  background-image: url(../../img/home_support/overlay_bg.png);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.call-to-action .action-content-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 576px) {
  .call-to-action .action-content-wrapper {
    display: block;
    text-align: center;
  }
}

.call-to-action .action-content-wrapper .action-title-wrap {
  display: flex;
  align-items: center;
}

.call-to-action .action-content-wrapper .action-title-wrap img {
  padding-right: 45px;
}

.call-to-action .action-content-wrapper .action-title-wrap .action-title {
  color: #fff;
  font-size: 44px;
  line-height: 1.2;
  font-weight: 500;
}

@media (max-width: 991px) {
  .call-to-action .action-content-wrapper .action-title-wrap .action-title {
    font-size: 34px;
  }
}

.call-to-action .action-content-wrapper .action_btn {
  border: 1px solid var(--brand_color);
  font-size: 16px;
  padding: 12px 25px;
  font-weight: 500;
}

.call-to-action .action-content-wrapper .action_btn:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.status_widget .status {
  margin-bottom: 25px;
}

.status_widget .offline {
  color: #f4b130;
  border: 2px solid #f5d597;
  padding: 0 13px;
  display: inline-block;
  border-radius: 4px;
  margin-left: 5px;
}

.status_widget .title-sm {
  font-size: 16px;
  color: #1d2746;
  font-weight: 500;
  margin-bottom: 10px;
}

.status_widget .open-hours {
  margin-bottom: 30px;
}

.status_widget .open-hours p {
  margin-bottom: 0;
}

@media (min-width: 991px) {
  .status_widget .open-hours p {
    max-width: 240px;
  }
}

.status_widget .current-time {
  margin-top: 28px;
}

.status_widget .current-time:after {
  content: "";
  display: block;
  clear: both;
}

.status_widget .current-time li {
  float: left;
  width: 50%;
}

.w_tag_list.style-light li a {
  background: #f0f2f5;
}

.w_tag_list.style-light li a:hover {
  background: rgba(76, 76, 241, 0.15);
  color: var(--brand_color);
  box-shadow: none;
}

.usefull-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.usefull-links li {
  padding: 7px 0;
}

.usefull-links li i {
  margin-right: 10px;
}

.usefull-links li a {
  color: #6b707f;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

.usefull-links li a:hover {
  color: var(--brand_color);
}

.ticket_categories li {
  padding: 12px 0 10px;
  position: relative;
}

.ticket_categories li:not(:last-child) {
  border-bottom: 1px solid #e4e7e9;
}

.ticket_categories li img {
  margin-right: 10px;
  margin-bottom: 5px;
}

.ticket_categories li a {
  font-size: 16px;
  color: var(--black_700);
}

.ticket_categories li a:hover {
  color: var(--brand_color);
}

.ticket_categories li .count {
  position: absolute;
  right: 0;
  background: rgba(175, 184, 193, 0.2);
  padding: 4px 7px 2px;
  border-radius: 10px;
  color: #838793;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.ticket_categories li .count.count-fill {
  right: 35px;
  background: #0abe76;
  color: #fff;
}

/* Forum Profile Area css
============================================================================================ */
.forum-user-wrapper {
  background: #fbfbfc;
  padding-bottom: 135px;
}

.forum-user-wrapper .forum_main_inner {
  margin-top: -60px;
}

.author_option {
  background: var(--bs-white);
  border-radius: 10px;
  padding: 30px 30px 50px 30px;
  box-shadow: 0 30px 60px 0 rgba(2, 2, 48, 0.08);
}

.author_option .author_img {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.author_option .nav.nav-tabs {
  border: none;
}

.author_option .nav.nav-tabs li {
  margin-bottom: 10px;
}

.author_option .nav.nav-tabs li a {
  border: none;
  background: rgba(208, 215, 222, 0.32);
  line-height: 50px;
  padding: 0 28px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  color: var(--black_800);
}

.author_option .nav.nav-tabs li a i {
  padding-right: 2px;
}

.author_option .nav.nav-tabs li a svg {
  position: relative;
  top: -2.5px;
  margin-right: 7px;
}

.author_option .nav.nav-tabs li a:hover,
.author_option .nav.nav-tabs li a.active {
  background: #4c4cf1;
  color: #fff;
}

.author_option .nav.nav-tabs li a:hover svg path,
.author_option .nav.nav-tabs li a.active svg path {
  fill: #fff;
}

.author_option .nav.nav-tabs li:last-child {
  margin-bottom: 0;
}

.forum_body_area {
  background: var(--bs-white);
  padding: 50px 40px 30px 40px;
  border-radius: 10px;
  box-shadow: 0 30px 60px 0 rgba(2, 2, 48, 0.08);
}

.search_forum {
  margin-bottom: 45px;
}

.search_forum input {
  height: 50px;
  border: 1px solid #f1f1f8;
  border-radius: 25px;
  background: #f7f7fb;
  padding: 0 30px;
  line-height: 50px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 400;
  color: #848996;
  font-family: "Roboto", sans-serif;
}

.search_forum input:focus {
  border: 1px solid #f1f1f8;
  background: #f7f7fb;
  outline: none !important;
  box-shadow: none !important;
}

.search_forum input.placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #848996;
  font-family: "Roboto", sans-serif;
}

.search_forum input:-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #848996;
  font-family: "Roboto", sans-serif;
}

.search_forum input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #848996;
  font-family: "Roboto", sans-serif;
}

.search_forum input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #848996;
  font-family: "Roboto", sans-serif;
}

.search_forum .input-group-append button {
  position: relative;
  background: #4c4cf1;
  width: 80px;
  padding: 0;
  border: none;
  color: #fff;
  text-align: center;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.search_forum .input-group-append button i {
  transform: rotate(-90deg);
  position: absolute;
  left: 58%;
  text-align: center;
  top: 0;
  height: 100%;
}

.forum_topic_list h2 {
  color: #232361;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
}

.forum_l_inner {
  border-radius: 5px;
  border: 1px solid #eaeeef;
}

.forum_l_inner .forum_head {
  background: rgba(76, 76, 241, 0.05);
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid rgba(76, 76, 241, 0.05);
}

.forum_l_inner .forum_head .left li {
  margin-right: 25px;
  line-height: 58px;
  color: #1d2746;
  font-weight: 500;
  font-size: 14px;
}

.forum_l_inner .forum_head .left li i {
  color: #f06292;
  font-size: 14px;
  margin-right: 7px;
}

.forum_l_inner .forum_head .left li:nth-child(2) {
  color: #6b707f;
  font-weight: 400;
}

.forum_l_inner .forum_head .left li:nth-child(2) i {
  color: #0abe76;
  position: relative;
  top: 1px;
}

.forum_l_inner .forum_head .left li a {
  color: #6b707f;
  font-weight: 400;
  font-size: 14px;
}

.forum_l_inner .forum_head .right li {
  margin-right: 35px;
}

.forum_l_inner .forum_head .right li .dropdown button {
  padding: 0;
  line-height: 58px;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  color: #6b707f;
  border: none;
}

.forum_l_inner .forum_head .right li .dropdown button:after {
  display: none;
}

.forum_l_inner .forum_head .right li .dropdown button i {
  font-size: 16px;
  color: #6b707f;
  position: relative;
  top: 1px;
  padding-left: 2px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu {
  border: 1px solid #e6eeef;
  background: #fff;
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
  padding: 0;
  width: 260px;
  top: 46px !important;
  animation: slideDown 0.12s cubic-bezier(0, 0.1, 0.1, 1) backwards;
  transform: translate3d(0, 23px, 0) !important;
  border-radius: 4px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #fafcfc;
  position: absolute;
  left: 30px;
  transform: rotate(45deg);
  top: -8px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu:after {
  content: "";
  height: 16px;
  width: 16px;
  background: #e6eeef;
  position: absolute;
  left: 30px;
  transform: rotate(45deg);
  top: -9px;
  z-index: -1;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .title {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  background: #fafcfc;
  padding: 14px 20px 11px;
  line-height: 1;
  color: #6b707f;
  position: relative;
  box-shadow: 0 1px 0 #e6edf0;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .cate-search-form {
  padding: 0 20px;
  margin-top: 10px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .cate-search-form input {
  width: 100%;
  background: #fafcfc;
  border: 1px solid #dfe3e6;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px 20px;
  font-size: 12px;
  height: 36px;
  color: #6b707f;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .cate-search-form input.placeholder {
  font-size: 12px;
  color: #6b707f;
  font-weight: 400;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .cate-search-form input:-moz-placeholder {
  font-size: 12px;
  color: #6b707f;
  font-weight: 400;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .cate-search-form input::-moz-placeholder {
  font-size: 12px;
  color: #6b707f;
  font-weight: 400;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .cate-search-form input::-webkit-input-placeholder {
  font-size: 12px;
  color: #6b707f;
  font-weight: 400;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users {
  font-size: 14px;
  padding: 10px 0 0 0;
  font-weight: 500;
  color: #6b707f;
  max-height: 200px;
  overflow-x: hidden;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item {
  font-size: 12px;
  padding: 7px 40px;
  border: none;
  font-weight: 400;
  color: #54595d;
  box-shadow: 0 1px 0 #e6edf0;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item .color {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #ee0701;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item .color.blue {
  background: #5319e7;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item .color.dark_blue {
  background: #051375;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item .color.green {
  background: #128a0c;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item .color.pink {
  background: #cc317c;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item ion-icon {
  color: green;
  position: relative;
  top: 4px;
  font-size: 16px;
  margin-right: 5px;
  opacity: 0;
  transition: all 300ms linear 0s;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item img {
  padding-right: 8px;
  border-radius: 50%;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item:hover,
.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item.active-short {
  color: var(--black_800);
  background: #f2f9fc;
  box-shadow: 0 1px 0 #f2f9fc;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item:hover ion-icon,
.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .dropdown-item.active-short ion-icon {
  opacity: 1;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .mCSB_scrollTools {
  width: 5px;
  background: #fff;
  border-radius: 2.5px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #cacbcc !important;
  width: 5px;
}

.forum_l_inner .forum_head .right li .dropdown .dropdown-menu .all_users .mCSB_scrollTools .mCSB_draggerRail {
  width: 5px;
  background: transparent;
}

.forum_l_inner .forum_head .right li .dropdown.list_sirt .dropdown-menu {
  border: 1px solid #e6eeef;
  background: #f7f8f9;
  box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
  width: 260px;
  top: 46px !important;
  animation: slideDown 0.12s cubic-bezier(0, 0.1, 0.1, 1) backwards;
  transform: translate3d(0, 23px, 0) !important;
  padding: 14px 0;
  z-index: 2;
}

.forum_l_inner .forum_head .right li .dropdown.list_sirt .dropdown-menu:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #f7f8f9;
  position: absolute;
  left: 30px;
  transform: rotate(45deg);
  top: -8px;
  z-index: -1;
}

.forum_l_inner .forum_head .right li .dropdown.list_sirt .dropdown-menu a {
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #6b707f;
  padding: 5px 20px;
}

.forum_l_inner .forum_head .right li .dropdown.list_sirt .dropdown-menu a.active-short,
.forum_l_inner .forum_head .right li .dropdown.list_sirt .dropdown-menu a:hover {
  background: #fff;
}

.forum_l_inner .forum_head .right li .dropdown.right_dir .dropdown-menu {
  right: 0;
  left: auto !important;
}

.forum_l_inner .forum_head .right li .dropdown.right_dir .dropdown-menu:before {
  right: 30px;
  left: auto;
}

.forum_l_inner .forum_head .right li .dropdown.right_dir .dropdown-menu:after {
  left: auto;
  right: 30px;
}

.forum_l_inner .forum_head .right li:last-child {
  margin-right: 0;
}

.forum_l_inner .forum_head .right li:first-child .dropdown .dropdown-menu {
  right: auto;
  left: 0;
}

.forum_l_inner .forum_head .right li:first-child .dropdown .dropdown-menu:before {
  right: auto;
  left: 30px;
}

.forum_l_inner .forum_head .page {
  line-height: 58px;
  display: inline-block;
  font-size: 14px;
  color: var(--p_color);
}

.forum_l_inner .forum_body .topic_list li {
  border-bottom: 1px solid #eaeeef;
  padding: 18px 20px;
}

.forum_l_inner .forum_body .topic_list li:last-child {
  border-bottom: 0;
}

.forum_l_inner .forum_body .topic_list li .media {
  align-items: center;
}

.forum_l_inner .forum_body .topic_list li .media .d-flex {
  padding-right: 18px;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h4 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  display: inline-block;
  transition: all 300ms linear 0s;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h4:hover {
  color: #4c4cf1;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz {
  line-height: 22px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 0 6px;
  background: #8152e0;
  border-radius: 3px;
  margin-left: 7px;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.yellow {
  background: #fad05a;
  color: #1d2746;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.gray {
  color: var(--black_800);
  background: #eeeeee;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.green {
  background: #22936d;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.orange {
  background: #f88546;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.pest {
  background: #27b2da;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.pink {
  background: #f053b3;
}

.forum_l_inner .forum_body .topic_list li .media .media-body .t_title .docbuzz.greenl {
  background: #1eba17;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h6 {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  color: #838793;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-right: 16px;
  transition: all 300ms linear 0s;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h6:hover {
  color: #4c4cf1;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h6+h6 {
  margin-right: 0;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h6 i {
  padding-right: 5px;
  top: 0;
  position: relative;
}

.forum_l_inner .forum_body .topic_list li .media .media-body h6 img {
  padding-right: 5px;
  position: relative;
  top: -1px;
}

.forum_l_inner .forum_body .topic_list li .media .media-right {
  vertical-align: middle;
  padding-right: 12px;
}

.forum_l_inner .forum_body .topic_list li .media .media-right .nav+.count ion-icon {
  margin-left: 0;
}

.forum_l_inner .forum_body .topic_list li .media .media-right .count {
  display: inline-block;
  margin-left: 40px;
  font-size: 16px;
  color: #868b99;
  font-weight: 400;
  transition: all 300ms linear 0s;
}

.forum_l_inner .forum_body .topic_list li .media .media-right .count:hover {
  color: #4c4cf1;
}

.forum_l_inner .forum_body .topic_list li .media .media-right .count i,
.forum_l_inner .forum_body .topic_list li .media .media-right .count ion-icon {
  margin-right: 2px;
  transition: all 300ms linear 0s;
  font-size: 18px;
  margin-bottom: -2px;
}

.forum_l_inner .forum_body .topic_list li .media .media-right .count.rate:hover {
  color: #868b99;
}

.forum_l_inner .forum_body .topic_list li .media .media-right .count.rate:hover i {
  color: #f9ae44;
}

.forum_l_inner .forum_body .topic_list li.check {
  position: relative;
}

.forum_l_inner .forum_body .topic_list li.check:before {
  content: "";
  background: url(../../img/icon/green-check.png) no-repeat;
  height: 32px;
  width: 32px;
  position: absolute;
  right: 0;
  top: 0;
}

.list_pagination {
  padding-top: 20px;
}

.list_pagination .left {
  line-height: 36px;
}

.list_pagination .left p {
  font-size: 14px;
  color: #6b6b80;
  font-weight: 400;
  margin-bottom: 0;
}

.list_pagination .right nav ul {
  border: none;
  margin-bottom: 0;
}

.list_pagination .right nav ul li {
  margin-right: 6px;
}

.list_pagination .right nav ul li a {
  border: none;
  background: #f8f8f8;
  padding: 0;
  font-size: 14px;
  color: #6b6b80;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  border-radius: 10px !important;
  outline: none;
  box-shadow: none;
}

.list_pagination .right nav ul li:hover a,
.list_pagination .right nav ul li.active a {
  background: #4c4cf1;
  color: #fff;
}

.list_pagination .right nav ul li:last-child {
  margin-right: 0;
}

.profile_info .p_info_item_inner .col-sm-4:last-child .p_info_item img {
  margin-top: -13px;
}

.profile_info .p_info_item_inner .p_info_item {
  border: 1px solid var(--black_50);
  text-align: center;
  padding-bottom: 40px;
  padding-top: 40px;
  border-radius: 6px;
}

.profile_info .p_info_item_inner .p_info_item img {
  margin-bottom: 20px;
}

.profile_info .p_info_item_inner .p_info_item h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.profile_info .p_info_item_inner .p_info_item .info_btn {
  color: #4c4cf1;
  background: #f1f1fe;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  padding: 0 12px;
  display: inline-block;
  border-radius: 13px;
}

.profile_info .p_info_item_inner .p_info_item .info_number {
  background: #fff4ee;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  padding: 0 18px;
  display: inline-block;
  border-radius: 13px;
  color: #ff762b;
}

.info_list {
  padding-top: 45px;
  padding-bottom: 60px;
}

.info_list li {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 500;
  color: var(--p_color);
}

.info_list li span {
  min-width: 250px;
  color: var(--black_800);
  font-size: 14px;
  font-weight: 700;
  float: left;
  overflow: hidden;
}

.info_list li a {
  color: var(--p_color);
  display: block;
  overflow: hidden;
  font-weight: 400;
}

.info_list li:last-child {
  margin-bottom: 0;
}

.p_social {
  border-top: 1px solid var(--black_50);
  margin-left: -40px;
  margin-right: -40px;
  padding-top: 22px;
}

.p_social li {
  margin-right: 10px;
}

.p_social li:first-child {
  padding-left: 40px;
}

.p_social li:last-child {
  padding-right: 40px;
  margin-right: 0;
}

.p_social li a {
  height: 45px;
  width: 45px;
  background: var(--black_50);
  color: var(--black_500);
  font-size: 14px;
  text-align: center;
  line-height: 45px;
  display: inline-block;
  border-radius: 10px;
  transition: all 300ms linear 0s;
}

.p_social li a:hover {
  color: #fff;
  background: #4c4cf1;
}

.topic_comment_list li .media {
  padding-left: 18px;
  padding-right: 25px;
  padding-top: 28px;
}

.topic_comment_list li .media .d-flex {
  padding-right: 18px;
}

.topic_comment_list li .media .media-body {
  border-bottom: 1px solid #e8ecee;
  padding-bottom: 28px;
}

.topic_comment_list li .media .media-body .t_title h4 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.topic_comment_list li .media .media-body .dropdown {
  margin-top: -30px;
  float: right;
  margin-right: 14px;
}

.topic_comment_list li .media .media-body .dropdown button {
  font-size: 34px;
  color: #838793;
  padding: 0;
  line-height: 26px;
  border: none;
  outline: none;
  box-shadow: none;
}

.topic_comment_list li .media .media-body .dropdown button:after {
  display: none;
}

.topic_comment_list li .media .media-body .dropdown .dropdown-menu {
  left: auto !important;
  right: -14px;
  top: 20px !important;
  padding: 14px 0;
  border: 1px solid #e8eff0;
  background: #fff;
  min-width: 218px;
  box-shadow: 0 30px 40px 0 rgba(4, 4, 89, 0.1);
}

.topic_comment_list li .media .media-body .dropdown .dropdown-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  right: 16px;
  top: -10px;
  z-index: 1;
}

.topic_comment_list li .media .media-body .dropdown .dropdown-menu:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #e8eff0 transparent;
  position: absolute;
  right: 16px;
  top: -11px;
}

.topic_comment_list li .media .media-body .dropdown .dropdown-menu a {
  padding: 0 24px;
  line-height: 34px;
  font-size: 16px;
  color: var(--p_color);
}

.topic_comment_list li .media .media-body h6 {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: var(--p_color);
  margin-right: 18px;
  margin-bottom: 13px;
}

.topic_comment_list li .media .media-body h6 i {
  padding-right: 7px;
  font-size: 14px;
}

.topic_comment_list li .media .media-body p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
}

.topic_comment_list li .media .media-body .help_start {
  font-size: 14px;
  color: #838793;
  float: right;
  margin-top: -22px;
}

.topic_comment_list li .media .media-body .help_start i {
  color: #f9ae44;
  font-size: 14px;
  padding-right: 5px;
}

.topic_comment_list li .media .help_btn {
  margin-left: 7px;
}

.topic_comment_list li:last-child .media .media-body {
  border-bottom: 0;
}

/* End Forum Area css
============================================================================================ */
/* Forum Sidebar Area css
============================================================================================ */
.topics-filter {
  margin-bottom: 30px;
}

.topics-filter .nav-item .nav-link {
  position: relative;
  color: var(--black_400);
}

.topics-filter .nav-item .nav-link ion-icon {
  margin-right: 2px;
  margin-top: -2px;
}

.topics-filter .nav-item .nav-link.active,
.topics-filter .nav-item .nav-link:hover {
  color: var(--black_800);
}

.topics-filter .nav-item .nav-link.active::after,
.topics-filter .nav-item .nav-link:hover::after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: " ";
  width: 100%;
  height: 2px;
  border-radius: 25%;
  background: var(--brand_color);
}

.forum_sidebar_area {
  padding-top: 100px;
  padding-bottom: 100px;
}

.forum_sidebar_area .custom_container {
  position: relative;
}

.forum_sidebar_area .right_side_forum {
  margin-left: 50px;
}

.left_side_forum {
  max-width: 265px;
}

.wd_title {
  margin-bottom: 25px;
  font-size: 18px;
}

.l_widget {
  margin-bottom: 45px;
}

.forum_list .navbar-nav li {
  margin-bottom: 5px;
}

.forum_list .navbar-nav li a {
  font-size: 14px;
  font-weight: 400;
  color: var(--p_color);
  display: block;
  line-height: 38px;
  border-radius: 2px;
  padding: 0 15px;
  transition: all 300ms linear 0s;
}

.forum_list .navbar-nav li a i {
  font-size: 14px;
  font-weight: 400;
  color: #54595d;
  padding-right: 8px;
  transition: all 300ms linear 0s;
}

.forum_list .navbar-nav li a:hover {
  background: rgba(76, 76, 241, 0.15);
  color: var(--brand_color);
}

.forum_list .navbar-nav li a:hover i {
  color: var(--brand_color);
}

.forum_list .navbar-nav li.active a {
  background: var(--brand_color);
  color: #fff;
  font-weight: 500;
}

.forum_list .navbar-nav li.active a i {
  color: #fff;
}

.forum_list .navbar-nav li:last-child {
  margin-bottom: 0;
}

.l_tags_wd .nav {
  margin-bottom: -10px;
}

.l_tags_wd .nav li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.l_tags_wd .nav li a {
  font-weight: 400;
  font-size: 14px;
  color: #54595d;
  background: #f2f5f7;
  border-radius: 3px;
  padding: 0 14.5px;
  display: inline-block;
  line-height: 24px;
  transition: all 300ms linear 0s;
}

.l_tags_wd .nav li a:hover {
  background: #dcedf5;
  color: var(--brand_color);
}

.comment_list .navbar-nav li {
  border-bottom: 1px solid #e6edf0;
  padding: 10px 0;
}

.comment_list .navbar-nav li:first-child {
  padding-top: 0;
}

.comment_list .navbar-nav li .media .d-flex {
  padding-right: 10px;
}

.comment_list .navbar-nav li .media .d-flex i {
  margin-top: 3px;
  color: var(--p_color);
}

.comment_list .navbar-nav li .media .media-body h4 {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0;
  transition: all 300ms linear 0s;
}

.comment_list .navbar-nav li .media .media-body h5 {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  margin-left: 9px;
  margin-bottom: 0;
}

.comment_list .navbar-nav li .media .media-body p {
  margin-bottom: 0;
  font-size: 12px;
}

.comment_list .navbar-nav li:hover .media .media-body h4 {
  color: var(--brand_color);
}

.comment_list .navbar-nav li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.r_widget {
  margin-bottom: 50px;
}

.qustion_wd button {
  background: var(--brand_color);
  border: none;
  border-radius: 40px !important;
  height: 80px;
  line-height: 80px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  padding: 0;
  width: 100%;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 300ms linear 0s;
}

.qustion_wd button img {
  margin-right: 20px;
  position: relative;
  top: -1px;
}

.qustion_wd button i {
  float: right;
  line-height: 80px;
}

.qustion_wd button:hover {
  color: #fff;
  background: var(--brand_color);
}

.r_heading {
  background: rgba(76, 76, 241, 0.05);
  border-bottom: 1px solid rgba(76, 76, 241, 0.05);
  padding-left: 20px;
  padding-right: 20px;
}

.r_heading h3 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  line-height: 50px;
}

.r_heading h5 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  color: var(--p_color);
  line-height: 50px;
}

.user_list_wd {
  border: 1px solid #eaeeef;
  border-radius: 6px;
}

.user_list_wd .navbar-nav {
  padding: 0 20px;
}

.user_list_wd .navbar-nav li {
  padding: 11px 0 8px;
  border-bottom: 1px solid var(--black_50);
}

.user_list_wd .navbar-nav li:last-child {
  border-bottom: 0;
}

.user_list_wd .navbar-nav li a .media .d-flex {
  padding-right: 9px;
}

.user_list_wd .navbar-nav li a .media .media-body {
  vertical-align: middle;
}

.user_list_wd .navbar-nav li a .media .media-body h4 {
  font-size: 14px;
  font-weight: 400;
  color: #54595d;
  margin-bottom: 0;
  margin-top: 2px;
}

.user_list_wd .navbar-nav li a .media .media-right .count {
  display: inline-block;
  font-size: 12px;
  color: var(--p_color);
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.user_list_wd .navbar-nav li a .media .media-right i {
  color: var(--p_color);
}

.user_list_wd .navbar-nav li:hover a .media .media-body h4 {
  color: var(--brand_color);
}

.question_list_wd {
  border: 1px solid #e6edf0;
  border-radius: 6px;
}

.question_list_wd .nav-tabs {
  border: none;
  margin-bottom: 0;
}

.question_list_wd .nav-tabs li a {
  border: none;
  background: transparent !important;
  border-radius: 0;
  line-height: 50px;
  padding: 0 8px;
  color: var(--p_color);
  font-weight: 400;
  font-size: 12px;
  border-bottom: 3px solid transparent !important;
  position: relative;
}

.question_list_wd .nav-tabs li a:before {
  content: "";
  height: 14px;
  width: 1px;
  background: rgba(76, 76, 241, 0.2);
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
}

.question_list_wd .nav-tabs li a.active {
  border-color: var(--brand_color) !important;
}

.question_list_wd .nav-tabs li:last-child a:before {
  display: none;
}

.question_list_wd .navbar-nav {
  padding: 7px 20px 18px;
}

.question_list_wd .navbar-nav li {
  padding: 15px 0 15px;
}

.question_list_wd .navbar-nav li a {
  font-size: 14px;
  font-weight: 400;
  color: #54595d;
  position: relative;
  padding-left: 45px;
  display: inline-block;
  line-height: 1.5;
}

.question_list_wd .navbar-nav li a span {
  height: 30px;
  width: 30px;
  border: 1px solid #e6edf0;
  text-align: center;
  line-height: 28px;
  color: var(--p_color);
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  border-radius: 50%;
  margin-right: 9px;
  position: absolute;
  left: 0;
  top: 0;
}

.question_list_wd .navbar-nav li a:hover {
  color: var(--brand_color);
}

.question_list_wd .navbar-nav li a:hover span {
  color: var(--brand_color);
}

.forum_topic_list_inner .forum_l_inner .forum_head {
  padding-left: 30px;
  padding-right: 30px;
}

.forum_topic_list_inner .forum_l_inner .forum_head .nav.left li {
  margin-right: 30px;
}

.forum_topic_list_inner .forum_l_inner .forum_head .nav.left li:last-child {
  margin-right: 0;
}

.forum_topic_list_inner .forum_l_inner .forum_head .nav.left li:nth-child(3) a i {
  color: #00aa6a;
}

.forum_topic_list_inner .forum_l_inner .forum_head .nav.right li {
  margin-right: 50px;
}

.forum_topic_list_inner .forum_l_inner .forum_head .nav.right li:last-child {
  margin-right: 0;
}

.forum_body .navbar-nav li {
  padding: 25.5px 30px;
  transition: all 300ms linear 0s;
}

.forum_body .navbar-nav li .media .media-body .t_title h4 {
  margin-bottom: 8px;
}

.forum_body .navbar-nav li .media .media-body .t_title h4 ion-icon {
  color: #22863a;
  position: relative;
  top: 3px;
  font-size: 20px;
  margin-left: 5px;
}

.forum_body .navbar-nav li .media .media-body h6 svg {
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.forum_body .navbar-nav li .media .media-body h6:hover {
  color: var(--brand_color);
}

.forum_body .navbar-nav li .media .media-body h6:hover svg path {
  fill: var(--brand_color);
}

.forum_body .navbar-nav li .media .media-right .nav {
  display: inline-block;
}

.forum_body .navbar-nav li .media .media-right .nav li {
  display: inline-block;
  padding: 0;
  margin-left: -12px;
  border: none;
  transition: all 300ms linear 0s;
}

.forum_body .navbar-nav li .media .media-right .nav li a {
  margin-left: 0;
}

.forum_body .navbar-nav li .media .media-right .nav li a:after {
  display: none;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu {
  min-width: 400px;
  background: var(--bs-white);
  left: auto !important;
  right: -18px !important;
  padding: 30px 30px 30px 30px;
  margin-top: 15px;
  border: 1px solid var(--black_50);
  box-shadow: 0 30px 40px rgba(10, 71, 102, 0.1);
  border-radius: 4px;
  display: block;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  transition: all 300ms linear 0s;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu:before {
  content: "";
  position: absolute;
  right: 18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent var(--bs-white) transparent;
  top: -8px;
  z-index: 1;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu:after {
  content: "";
  height: 16px;
  width: 16px;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #e6edf0 transparent;
  position: absolute;
  right: 18px;
  top: -17px;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .media {
  margin-bottom: 50px;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .media .d-flex {
  padding-right: 15px;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .media .media-body h4 {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 18px;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .media .media-body .follow_btn {
  width: 90px;
  height: 30px;
  border: 1px solid #6b707f;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  transition: all 300ms linear 0s;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .media .media-body .follow_btn:hover {
  background: var(--brand_color);
  color: #fff;
  border-color: var(--brand_color);
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .answere_items {
  text-align: center;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .answere_items h4 {
  font-size: 14px;
  font-weight: 400;
}

.forum_body .navbar-nav li .media .media-right .nav li .dropdown-menu .answere_items h6 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.forum_body .navbar-nav li .media .media-right .nav li:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.forum_body .navbar-nav li .media .media-right .nav:hover li {
  margin-left: 4px;
}

.forum_body .navbar-nav li .media .media-right a {
  margin-left: 45px;
  color: var(--p_color);
}

.forum_body .navbar-nav li .media .media-right a i {
  margin-right: 10px;
  color: #818799;
}

.forum_body .navbar-nav li .media .media-right a:hover {
  color: var(--brand_color);
}

.forum_body .navbar-nav li .media .media-right a:hover i {
  color: var(--brand_color);
}

.forum_body .navbar-nav li:hover .media .media-body .t_title h4 {
  color: var(--brand_color);
}

.pagination_inner {
  margin-top: 40px;
}

.pagination_inner h6 {
  font-size: 16px;
  color: var(--p_color);
  font-weight: 400;
  margin-bottom: 0;
  line-height: 32px;
}

.pagination_inner h6 span {
  color: var(--black_800);
}

.pagination_inner .pagination {
  border: none;
  margin-bottom: 0;
}

.pagination_inner .pagination li {
  margin-right: 5px;
}

.pagination_inner .pagination li:first-child,
.pagination_inner .pagination li:last-child {
  margin-right: 15px;
}

.pagination_inner .pagination li:first-child a,
.pagination_inner .pagination li:last-child a {
  border: none;
  height: auto;
  width: auto;
}

.pagination_inner .pagination li:first-child a:hover,
.pagination_inner .pagination li:last-child a:hover {
  color: var(--brand_color);
}

.pagination_inner .pagination li:last-child {
  margin-right: 0;
  margin-left: 15px;
}

.pagination_inner .pagination li a {
  padding: 0;
  height: 32px;
  width: 32px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--p_color);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
  outline: none;
  box-shadow: none;
  background: var(--bs-white);
}

.pagination_inner .pagination li a i {
  font-size: 18px;
  top: 2px;
  position: relative;
}

.pagination_inner .pagination li.active:first-child a,
.pagination_inner .pagination li.active:last-child a {
  background: transparent;
  color: #54595d;
}

.pagination_inner .pagination li.active a {
  background: var(--brand_color);
  color: #fff;
}

.pagination_inner .pagination li.active a:hover {
  background: var(--brand_color);
  color: #fff;
  border-color: var(--brand_color);
}

.pagination_inner .pagination li:hover a {
  border-color: var(--black_50);
  background: transparent;
}

.pagination_inner .go_btn {
  float: right;
  width: 80px;
}

.pagination_inner .go_btn input {
  border: 1px solid var(--black_50);
  box-sizing: border-box;
  border-radius: 4px;
  width: 40px;
  color: #54595d;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  height: 32px;
  overflow: hidden;
  line-height: 30px;
  padding: 0;
  max-width: 40px;
  outline: none;
  box-shadow: none;
  text-align: center;
}

.pagination_inner .go_btn input.placeholder {
  color: #54595d;
  font-size: 16px;
  font-weight: 400;
}

.pagination_inner .go_btn input:-moz-placeholder {
  color: #54595d;
  font-size: 16px;
  font-weight: 400;
}

.pagination_inner .go_btn input::-moz-placeholder {
  color: #54595d;
  font-size: 16px;
  font-weight: 400;
}

.pagination_inner .go_btn input::-webkit-input-placeholder {
  color: #54595d;
  font-size: 16px;
  font-weight: 400;
}

.pagination_inner .go_btn .input-group-append button {
  height: 32px;
  width: 40px;
  border-radius: 3px;
  border: 1px solid #e6edf0;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  color: var(--brand_color);
  font-size: 16px;
  font-weight: 400;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;
}

.pagination_inner .go_btn .input-group-append button:hover {
  background: rgba(76, 76, 241, 0.2);
  border-color: rgba(76, 76, 241, 0.2);
}

.pagination_inner .go_btn .input-group-append button:focus {
  color: var(--brand_color);
  border-color: #e6edf0;
}

.fade {
  transition: opacity 0.5s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

/* End Forum Sidebar Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
.forum-post-top {
  display: flex;
}

.forum-post-top .author-avatar img {
  border-radius: 50%;
  margin-right: 15px;
}

.forum-post-top .forum-post-author .author-name {
  font-size: 16px;
  font-weight: 500;
  color: var(--black_700);
}

.forum-post-top .forum-post-author .author-name:hover {
  color: var(--brand_color);
}

.forum-post-top .forum-post-author .forum-author-meta {
  display: flex;
}

.forum-post-top .forum-post-author .forum-author-meta span,
.forum-post-top .forum-post-author .forum-author-meta a {
  color: var(--black_400);
  font-size: 14px;
}

.forum-post-top .forum-post-author .forum-author-meta a:hover {
  color: var(--brand_color);
}

.forum-post-top .forum-post-author .forum-author-meta .author-badge svg {
  margin-top: -5px;
  margin-right: 5px;
}

.forum-post-top .forum-post-author .forum-author-meta .author-badge i {
  margin-right: 5px;
  color: #838793;
}

.forum-post-top .forum-post-author .forum-author-meta .author-badge:first-child {
  margin-right: 20px;
}

.action-button-container {
  display: flex;
  justify-content: flex-end;
}

.action-button-container.action-btns {
  justify-content: flex-start;
  margin-top: 30px;
}

.action-button-container.action-btns .action_btn {
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  padding: 0 15px;
  font-weight: 400;
}

.action-button-container .ask-btn {
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 28px;
  margin-top: 2px;
}

.action-button-container .ask-btn:hover {
  background: #16c9f0;
}

.action-button-container .reply-btn {
  margin-right: 10px;
}

.action-button-container .too-btn {
  background: #fff;
  border: 1px solid #d0d8dc;
  color: #1d2746;
}

.action-button-container .too-btn:hover {
  background: #fff;
  border: 1px solid #1d2746;
  color: #1d2746;
}

.q-title {
  display: flex;
  padding: 40px 15px 15px 0;
}

.q-title h1 {
  font-size: 24px;
  line-height: 1.4;
}

.q-title .badge {
  margin-left: 10px;
  line-height: 1.4;
  margin-top: 5px;
}

.forum-post-content {
  padding-left: 62px;
}

.forum-post-content p {
  color: var(--p_color);
}

.forum-post-content .forum-post-btm {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8ecee;
  padding-bottom: 20px;
  padding-top: 30px;
}

.forum-post-content .forum-post-btm .taxonomy {
  font-size: 14px;
}

.forum-post-content .forum-post-btm .taxonomy i,
.forum-post-content .forum-post-btm .taxonomy img {
  margin-right: 10px;
}

.forum-post-content .forum-post-btm .taxonomy a {
  color: #838793;
}

.forum-post-content .forum-post-btm .taxonomy a:hover {
  color: var(--brand_color);
}

.forum-post-content .forum-post-btm .taxonomy a+a {
  padding-right: 2px;
}

.question-icon {
  font-size: 50px;
  margin-right: 15px;
  font-weight: 600;
}

/** === Best Answer === **/
.best-answer {
  background: var(--black_25);
  padding: 30px 40px 30px 30px;
  margin-top: 60px;
  border-radius: 6px;
}

.best-answer .accepted-ans-mark {
  color: #0abe76;
  font-size: 14px;
  text-align: right;
}

.best-answer .accepted-ans-mark i {
  padding-right: 5px;
}

.best-answer .best-ans-content {
  margin-top: 30px;
}

.best-answer .best-ans-content .question-icon {
  margin-top: 10px;
  margin-right: 20px;
}

.best-answer .best-ans-content p {
  font-size: 15px;
}

.best-answer .best-ans-content p:last-child {
  margin-bottom: 0;
}

/** === All answer === **/
.all-answers {
  margin-top: 60px;
}

.all-answers .title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.all-answers .filter-bar {
  background: var(--black_25);
  border: 1px solid var(--black_50);
  padding: 10px 30px;
  justify-content: space-between;
  border-radius: 4px;
}

.all-answers .filter-bar .custom-select {
  box-shadow: none;
}

.all-answers .filter-bar .custom-select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem transparent;
  border: none;
}

.all-answers .filter-bar .custom-select::after {
  border-bottom: 1px solid #C3C3C3;
  border-right: 1px solid #C3C3C3;
  height: 6px;
  width: 6px;
  right: 5px;
}

.all-answers .filter-bar p {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  color: var(--p_color);
}

.all-answers .filter-bar .nice-select {
  background: var(--black_25);
  height: 100%;
  border: 0;
  padding: 0 25px 0 0;
}

.all-answers .filter-bar .nice-select .list {
  width: 100px;
}

.all-answers .filter-bar .nice-select::-ms-expand {
  display: none;
}

.all-answers .forum-comment {
  margin-top: 30px;
}

.all-answers .forum-comment .comment-content {
  margin-left: 60px;
  margin-top: 10px;
  border-bottom: 1px solid var(--black_50);
  padding-bottom: 28px;
}

.all-answers .forum-comment .comment-content p {
  font-size: 14px;
  line-height: 1.6;
}

.all-answers .forum-comment .comment-content p:last-child {
  margin-bottom: 0;
}

.all-answers .forum-comment .comment-content .action-btns {
  margin-top: 20px;
  display: none;
  opacity: 0;
  transition: all 5s ease-in;
}

.all-answers .forum-comment:hover .comment-content .action-btns {
  display: block;
  opacity: 1;
}

.all-answers .pagination-wrapper {
  background: transparent;
  box-shadow: none;
  margin-top: 15px;
}

/** === Forum Sidebar === **/
.forum_sidebar {
  padding-left: 28px;
  font-size: 14px;
  padding-right: 5px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== blog_top_post_area css ==========*/
.blog_top_post {
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.06);
  border-radius: 6px;
  margin: 0;
}

.blog_top_post .p_top_img {
  position: relative;
  padding-right: 0;
}

.blog_top_post .p_top_img .p_img {
  position: absolute;
  padding-right: 0;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  background: url("../../img/blog-grid/top_post.jpg");
  background-size: cover;
}

.blog_top_post .b_top_post_content {
  padding: 50px 30px 50px 50px;
}

.blog_top_post .b_top_post_content .post_tag {
  padding-bottom: 15px;
}

.blog_top_post .b_top_post_content h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 17px;
  transition: color 0.2s linear;
}

.blog_top_post .b_top_post_content h3:hover {
  color: var(--brand_color);
}

.blog_top_post .b_top_post_content p {
  margin-bottom: 22px;
  color: var(--p_color);
}

.blog_top_post .b_top_post_content .post_author {
  padding-top: 45px;
}

.post_tag a {
  font-size: 14px;
  color: var(--black_400);
  display: inline-block;
  margin-top: 10px;
}

.post_tag a.cat-Docy {
  color: #fd8d2a;
}

.post_tag a.cat-megento {
  color: #f26322;
}

.post_tag a.cat-woocommerce {
  color: #96588a;
}

.post_tag a.c_blue {
  color: var(--brand_color);
}

.post_tag a.cat-laravel {
  color: #ff2d20;
}

.post_tag a+a {
  padding-left: 10px;
}

.post_tag a+a:before {
  content: "";
  width: 1px;
  height: 13px;
  background: #c9c9e2;
  display: inline-block;
  margin-right: 13px;
}

.post_tag a.author {
  margin-top: 4px;
  margin-right: 5px;
}

.post_tag a.author .avatar {
  margin-right: 5px;
}

.post_author .round_img {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.post_author .round_img img {
  height: 40px;
  width: auto;
}

.post_author .author_text {
  margin-top: 5px;
}

.post_author .author_text h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.post_author .author_text .date {
  font-size: 13px;
  font-weight: 400;
  color: var(--p_color);
}

/*=========== blog_top_post_area css ==========*/
/*=========== Start blog_grid_area css ==========*/
.doc_blog_grid_area {
  padding-bottom: 100px;
}

.blog_grid_inner {
  border-top: 1px solid var(--black_50);
}

.blog_tab .nav-item {
  margin-bottom: 0;
}

.blog_tab .nav-item .nav-link {
  padding: 17px 21px;
  border: 0;
  border-radius: 0;
  font-weight: 500;
  color: #858997;
  font-size: 14px;
  position: relative;
  background: transparent;
  transition: all 0.2s ease-in;
}

.blog_tab .nav-item .nav-link:before {
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  height: 2px;
  left: 0;
  background: var(--brand_color);
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: all 0.3s ease-in;
}

.blog_tab .nav-item .nav-link.active,
.blog_tab .nav-item .nav-link:hover {
  background: #e9f6f9;
  color: var(--brand_color);
}

.blog_tab .nav-item .nav-link.active:before,
.blog_tab .nav-item .nav-link:hover:before {
  transform: scale(1);
}

.blog_tab .nav-item.cat-laravel .nav-link:hover {
  background: rgba(255, 45, 32, 0.2);
  color: rgb(255, 45, 32);
}

.blog_tab .nav-item.cat-laravel .nav-link:before {
  background: rgb(255, 45, 32);
}

.blog_tab .nav-item.cat-Docy .nav-link:hover {
  background: rgba(253, 141, 42, 0.2);
  color: rgb(253, 141, 42);
}

.blog_tab .nav-item.cat-Docy .nav-link:before {
  background: rgb(253, 141, 42);
}

.blog_tab .nav-item.cat-megento .nav-link:hover {
  background: rgba(242, 99, 34, 0.2);
  color: rgb(242, 99, 34);
}

.blog_tab .nav-item.cat-megento .nav-link:before {
  background: rgb(242, 99, 34);
}

.blog_tab .nav-item.cat-woocommerce .nav-link:hover {
  background: rgba(150, 88, 138, 0.2);
  color: rgb(150, 88, 138);
}

.blog_tab .nav-item.cat-woocommerce .nav-link:before {
  background: rgb(150, 88, 138);
}

.blog_grid_tab {
  padding-top: 60px;
  margin-bottom: -40px;
}

.b_title {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  transition: all 0.2s linear;
}

.b_title:hover {
  color: var(--brand_color);
}

.blog_grid_post {
  background: var(--bs-white);
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.06);
  margin-bottom: 40px;
  transition: all 0.3s linear;
  overflow: hidden;
  cursor: pointer;
}

.blog_grid_post .grid_post_content {
  padding: 20px 30px 25px;
}

.blog_grid_post .grid_post_content .post_tag {
  padding-bottom: 10px;
}

.blog_grid_post .grid_post_content .b_title {
  margin-bottom: 18px;
}

.blog_grid_post .grid_post_content .post_author {
  padding-top: 18px;
}

.blog_grid_post:hover {
  box-shadow: 0 20px 24px 0 rgba(4, 73, 89, 0.1);
}

.doc_blog_grid_area_two {
  padding: 100px 0 120px;
}

/*=========== End blog_grid_area css ==========*/
/*=========== Start blog_area css ==========*/
.blog_area {
  padding: 100px 0 120px;
}

.blog_single_item .blog_single_img {
  display: block;
  margin-bottom: 25px;
}

.blog_single_item .blog_single_img img {
  max-width: 100%;
}

.blog_single_item p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 26px;
}

.blog_single_item p a {
  color: var(--brand_color);
  text-decoration: underline;
}

blockquote {
  background: rgba(76, 76, 241, 0.05);
  border-left: 4px solid var(--brand_color);
  font-size: 20px;
  padding: 50px 40px;
  position: relative;
  z-index: 0;
  margin: 46px 0;
}

blockquote:before,
blockquote:after {
  content: "";
  position: absolute;
  z-index: -1;
}

blockquote:before {
  background: url("../../img/blog-classic/quote_top.png") no-repeat scroll left top;
  width: 132px;
  height: 81px;
  top: 0;
}

blockquote:after {
  background: url("../../img/blog-classic/quote_bottom.png") no-repeat scroll left bottom;
  width: 77px;
  height: 75px;
  right: 40px;
  bottom: 30px;
}

blockquote .c_head {
  color: var(--p_color);
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 45px;
}

blockquote .author {
  font-weight: 500;
  font-size: 16px;
}

.single_post_two {
  padding-top: 10px;
}

.single_post_two h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}

.single_post_two img {
  margin-bottom: 25px;
  max-width: 100%;
}

.single_post_two p {
  margin-bottom: 28px;
}

.blog_social {
  padding-top: 20px;
}

.blog_social h5 {
  font-size: 16px;
  margin-bottom: 0;
}

.blog_social .f_social_icon {
  display: inline-block;
  margin-top: 28px;
  position: relative;
  z-index: 0;
  padding-top: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.blog_social .f_social_icon:before,
.blog_social .f_social_icon:after {
  content: "";
  width: 105%;
  position: absolute;
  top: 50%;
  height: 1px;
  background: #dde5e7;
}

.blog_social .f_social_icon:before {
  left: -103%;
}

.blog_social .f_social_icon:after {
  right: -103%;
}

.blog_social .f_social_icon li a {
  margin-right: 0;
  border: 1px solid #e5edef;
  color: #989da9;
  width: 44px;
  height: 44px;
  line-height: 44px;
}

.blog_social .f_social_icon li+li {
  margin-left: 5px;
}

.blog_post_author {
  background: #f8fafd;
  padding: 40px;
  padding-right: 70px;
  margin-top: 35px;
  border-radius: 5px;
}

.blog_post_author .author_img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 30px;
  overflow: hidden;
}

.blog_post_author .media-body h5 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}

.blog_post_author .media-body p {
  margin-bottom: 0;
}

.comment_inner {
  padding-top: 76px;
}

.comment_inner .c_head {
  margin-bottom: 0;
}

.comment_box {
  margin-bottom: 0;
}

.comment_box .post_comment .comment_author {
  padding-top: 40px;
}

.comment_box .post_comment .comment_author .img_rounded {
  border-radius: 50%;
  margin-right: 30px;
}

.comment_box .post_comment .comment_author .media-body {
  border-bottom: 1px solid #edf1f7;
  padding-bottom: 24px;
  position: relative;
}

.comment_box .post_comment .comment_author .media-body .comment_info h3 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 500;
}

.comment_box .post_comment .comment_author .media-body .comment_info .comment_date {
  font-size: 14px;
  font-weight: 400;
  color: #6b707f;
  margin-bottom: 15px;
}

.comment_box .post_comment .comment_author .media-body p {
  margin-bottom: 8px;
  color: var(--p_color);
}

.comment_box .post_comment .comment_author .media-body .comment_reply {
  font-size: 14px;
  font-weight: 500;
  color: var(--black_400);
  transition: all 0.2s linear;
  position: absolute;
  right: 0;
  top: 0;
}

.comment_box .post_comment .comment_author .media-body .comment_reply i {
  vertical-align: middle;
  font-size: 18px;
  padding-left: 5px;
}

.comment_box .post_comment .comment_author .media-body .comment_reply:hover {
  color: var(--brand_color);
}

.comment_box .post_comment .comment_author .media-body .comment_reply:hover i {
  color: var(--brand_color);
}

.comment_box .post_comment .reply_comment {
  padding-left: 80px;
}

.blog_comment_box {
  padding-top: 80px;
}

.blog_comment_box p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: var(--p_color);
}

.blog_comment_box .get_quote_form {
  padding-top: 40px;
}

.blog_comment_box .get_quote_form .form-group {
  margin-bottom: 30px;
}

.blog_comment_box .get_quote_form .form-group .form-control {
  height: 60px;
  border-radius: 4px;
  border: 1px solid #dadce0;
  font-size: 14px;
  line-height: 1.4;
  padding-left: 30px;
}

.blog_comment_box .get_quote_form .form-group .form-control.placeholder {
  color: var(--p_color);
}

.blog_comment_box .get_quote_form .form-group .form-control:-moz-placeholder {
  color: var(--p_color);
}

.blog_comment_box .get_quote_form .form-group .form-control::-moz-placeholder {
  color: var(--p_color);
}

.blog_comment_box .get_quote_form .form-group .form-control::-webkit-input-placeholder {
  color: var(--p_color);
}

.blog_comment_box .get_quote_form .form-group .form-control:focus {
  box-shadow: 0 20px 30px 0 rgba(4, 73, 89, 0.1);
  border: 2px solid var(--brand_color);
}

.blog_comment_box .get_quote_form .form-group .form-control.message {
  height: 160px;
  padding-top: 22px;
}

.blog_comment_box .get_quote_form .thm_btn {
  padding: 12px 35px;
  box-shadow: none;
}

.blog_comment_box .get_quote_form .thm_btn:hover {
  box-shadow: 0 20px 30px 0 rgba(76, 76, 241, 0.24);
}

/*============= doc_blog_classic_area css ==============*/
.doc_blog_classic_area blockquote {
  margin-top: 0;
  margin-bottom: 50px;
}

.blog_classic_item {
  overflow: hidden;
  margin-bottom: 50px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.blog_classic_item .b_top_post_content {
  padding: 26px 40px 30px;
  background: var(--bs-white);
}

.blog_classic_item .b_top_post_content .post_tag {
  padding-bottom: 10px;
}

.blog_classic_item .b_top_post_content .p_bottom {
  align-items: center;
}

.blog_classic_item .b_top_post_content .post_author {
  padding-top: 0;
  align-items: center;
}

.blog_classic_item:hover {
  box-shadow: 0 20px 24px 0 rgba(4, 73, 89, 0.1);
}

.video_post {
  position: relative;
  z-index: 1;
}

.video_post:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(9, 16, 50, 0.2);
  position: absolute;
  z-index: 0;
}

.video_icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 20px 40px 0 rgba(1, 16, 58, 0.14);
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  position: absolute;
  transition: all 0.3s linear;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_icon:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 0.2px solid #fff;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  left: 0;
  transform: scale(1.5);
}

.video_icon i {
  color: var(--brand_color);
  text-shadow: 0 6px 13px rgba(76, 76, 241, 0.3);
  position: relative;
}

.video_icon i:after {
  width: 300%;
  height: 300%;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  box-shadow: 0 1px 15px 1px rgba(255, 255, 255, 0.5);
  content: "";
  -webkit-animation: pulse 2s infinite;
  -webkit-transform: scale(8);
  -ms-transform: scale(8);
  transform: scale(8);
  animation: pulse 2s infinite;
}

.blog_link_post {
  background: var(--black_25);
  border-left: 4px solid var(--brand_color);
  padding: 45px 40px;
  line-height: 34px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}

.blog_link_post:before,
.blog_link_post:after {
  content: "";
  position: absolute;
}

.blog_link_post:before {
  background: url("../../img/blog-classic/icon_01.png") no-repeat;
  top: 5px;
  left: 40px;
  width: 42px;
  height: 41px;
}

.blog_link_post:after {
  background: url("../../img/blog-classic/icon_02.png") no-repeat;
  bottom: -35px;
  right: 30px;
  width: 100px;
  height: 100px;
}

.blog_link_post p {
  font-size: 20px;
  color: var(--p_color);
  margin-bottom: 0;
  transition: color 0.2s linear;
}

.blog_link_post p:hover {
  color: var(--brand_color);
}

.blog_related_post {
  padding-top: 80px;
}

.blog_related_post .c_head {
  margin-bottom: 45px;
}

.blog_related_post .row {
  margin-bottom: -40px;
}

.blog_related_post .blog_grid_post {
  box-shadow: none;
  background: transparent !important;
}

.blog_related_post .blog_grid_post img {
  border-radius: 4px;
  max-width: 100%;
}

.blog_related_post .grid_post_content {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.blog_related_post .grid_post_content .post_tag {
  padding-bottom: 4px;
}

.blog_related_post .grid_post_content .b_title {
  font-size: 18px;
  line-height: 24px;
}

.blog_related_post .grid_post_content p {
  margin-bottom: 0;
}

/*============ keyframes animation  =============*/
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}

/*============= doc_blog_classic_area css ==============*/
.pagination .page-numbers {
  width: 36px;
  height: 36px;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid rgba(76, 76, 241, 0.3);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  vertical-align: middle;
  color: var(--brand_color);
  transition: all 0.2s linear;
}

.pagination .page-numbers i {
  font-size: 24px;
}

.pagination .page-numbers+.page-numbers {
  margin-left: 10px;
}

.pagination .page-numbers:hover,
.pagination .page-numbers.current {
  background-color: var(--brand_color);
  border-color: var(--brand_color);
  box-shadow: 0 20px 30px 0 rgba(76, 76, 241, 0.24);
  color: #fff;
}

.pagination .page-numbers.current {
  box-shadow: none;
}

/*=========== End blog_area css ==========*/
/*=========== Start blog_sidebar css ==========*/
.blog_sidebar>ul.list-unstyled {
  margin-top: -10px;
}

.widget .c_head {
  margin-bottom: 35px;
  font-size: 22px;
}

.widget+.widget {
  margin-top: 53px;
}

.about_widget .img {
  border: 1px solid var(--black_50);
  padding: 8px;
  border-radius: 4px;
}

.about_widget .img img {
  max-width: 100%;
}

.about_widget .text {
  padding-top: 28px;
}

.about_widget .text h3 {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 12px;
}

.about_widget .text p {
  margin-bottom: 0;
}

.categorie_list {
  margin-bottom: 0;
}

.categorie_list li a {
  font-size: 16px;
  font-weight: 400;
  color: var(--black_700);
  position: relative;
  padding-left: 20px;
}

.categorie_list li a:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #aab0c0;
  position: absolute;
  left: 0;
  top: 6.5px;
}

.categorie_list li a span {
  padding-left: 10px;
}

.categorie_list li a:hover {
  color: var(--brand_color);
}

.categorie_list li a:hover:before {
  background: var(--brand_color);
}

.categorie_list li+li {
  margin-top: 8px;
}

.recent_post_item {
  align-items: center;
}

.recent_post_item img {
  border-radius: 4px;
  margin-right: 20px;
}

.recent_post_item .media-body h5 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  transition: color 0.2s linear;
  margin-bottom: 5px;
}

.recent_post_item .media-body h5:hover {
  color: var(--brand_color);
}

.recent_post_item .media-body .entry_post_date {
  font-size: 14px;
  color: var(--p_color);
}

.recent_post_item+.recent_post_item {
  margin-top: 20px;
}

.w_tag_list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.w_tag_list li {
  margin: 4px;
}

.w_tag_list li a {
  color: var(--p_color);
  padding: 2px 18px;
  display: inline-block;
  background: var(--black_25);
  font-size: 14px;
  border-radius: 4px;
  transition: all 0.2s linear;
}

.w_tag_list li a:hover {
  box-shadow: 0 10px 20px 0 rgba(76, 76, 241, 0.24);
  background: var(--brand_color);
  color: #fff;
}

.comments_widget {
  margin-bottom: 0;
}

.comments_widget li h6 {
  font-size: 13px;
  font-weight: 500;
  color: var(--black_800);
  margin-bottom: 12px;
}

.comments_widget li h6 i {
  color: #6b707f;
  padding-right: 8px;
}

.comments_widget li .text {
  display: block;
  background-color: var(--black_25);
  border: 1px solid rgb(231, 237, 238);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--p_color);
  padding: 8px 24px;
}

.comments_widget li+li {
  margin-top: 23px;
}

.instragram_info {
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
}

.instragram_info .instragram_item {
  display: block;
  margin: 5px;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.1s linear;
  width: 30%;
}

.instragram_info .instragram_item img {
  max-width: 100%;
}

.instragram_info .instragram_item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 39, 70, 0.5);
  z-index: 0;
  opacity: 0;
  transition: all 0.2s linear;
}

.instragram_info .instragram_item:hover:before {
  opacity: 1;
}

/*=========== End blog_sidebar css ==========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*================ signup_area css ==============*/
.signup_area {
  height: 100vh;
  min-height: 580px;
  background: #fbfcfd;
}

.signup_area .row {
  height: 100%;
}

.sign_left {
  width: calc(50% - 360px);
  position: relative;
  z-index: 1;
  padding: 100px 70px 0;
}

.sign_left .top {
  left: 0;
  top: 0;
  z-index: -1;
}

.sign_left .bottom {
  bottom: 0;
  right: 0;
  z-index: -1;
}

.sign_left .round {
  position: absolute;
  width: 650px;
  height: 650px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.06);
  left: -150px;
  bottom: -60px;
  z-index: -1;
}

.sign_left h2 {
  color: #fff;
  font-size: 36px;
  line-height: 44px;
}

.signup_left {
  background: #795fee;
}

.signup_left .middle {
  top: 50%;
  margin-top: -150px;
}

.sign_right {
  width: calc(50% + 360px);
  flex: 1;
  display: flex;
  align-items: center;
}

.sign_inner {
  max-width: 520px;
  margin: 0 auto;
}

.sign_inner h3 {
  font-size: 26px;
  line-height: 34px;
}

.sign_inner p {
  font-size: 14px;
}

.sign_inner p a {
  color: var(--black_800);
  font-weight: 500;
}

.sign_inner .btn-google {
  box-shadow: 0 4px 10px 0 rgba(4, 73, 89, 0.06);
  border-radius: 6px;
  border: 1px solid #e6ecee;
  font-size: 14px;
  color: var(--p_color);
  padding: 8px 27px;
  display: inline-block;
  margin-top: 20px;
}

.sign_inner .btn-google img {
  vertical-align: inherit;
  padding-right: 8px;
}

.sign_inner .btn-google:hover {
  box-shadow: none;
}

.sign_inner .divider {
  border-bottom: 1px solid #e3e8ea;
  position: relative;
  margin-bottom: 30px;
  margin-top: 36px;
}

.sign_inner .divider .or-text {
  font-size: 16px;
  color: var(--black_800);
  position: absolute;
  top: -14px;
  padding: 0 10px;
  background: #fbfcfd;
  display: inline-block;
  left: 50%;
  margin-left: -21px;
}

.login_form .form-group {
  margin-bottom: 30px;
  position: relative;
}

.login_form .form-group .small_text {
  color: var(--black_800);
  margin-bottom: 5px;
}

.login_form .form-group .form-control {
  border-radius: 6px;
  background-color: #fbfcfd;
  border: 1px solid rgb(227, 232, 234);
  font-size: 14px;
  color: var(--black_800);
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  height: 60px;
  line-height: 60px;
  padding: 2px 30px 0;
  transition: all 0.2s linear;
}

.login_form .form-group .form-control.placeholder {
  color: #878b99;
  font-family: "Roboto", sans-serif;
}

.login_form .form-group .form-control:-moz-placeholder {
  color: #878b99;
  font-family: "Roboto", sans-serif;
}

.login_form .form-group .form-control::-moz-placeholder {
  color: #878b99;
  font-family: "Roboto", sans-serif;
}

.login_form .form-group .form-control::-webkit-input-placeholder {
  color: #878b99;
  font-family: "Roboto", sans-serif;
}

.login_form .form-group .form-control:focus {
  box-shadow: 0 20px 30px 0 rgba(4, 73, 89, 0.1);
}

.login_form .form-group .check_box {
  margin-top: -10px;
}

.login_form .form-group .check_box input[type=checkbox] {
  position: relative;
  top: 1px;
  width: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.login_form .form-group .check_box input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  background: #fbfcfd;
  top: 0;
  left: 0;
  border-radius: 2px;
  border: 1px solid #a6abb7;
}

.login_form .form-group .check_box input[type=checkbox]:checked:after {
  content: "N";
  font-family: "ElegantIcons";
  display: block;
  font-size: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  color: var(--brand_color);
  text-align: center;
  line-height: 12px;
  left: 0;
}

.login_form .form-group .l_text {
  font-size: 16px;
  color: var(--p_color);
  padding-left: 20px;
  margin-bottom: 0;
}

.login_form .form-group .l_text span {
  color: var(--brand_color);
}

.login_form .form-group .confirm_password {
  position: relative;
}

.login_form .form-group .forget_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  font-size: 14px;
  color: #a5a9b4;
}

.login_form .thm_btn {
  padding: 9px 24px;
  box-shadow: none;
}

.login_form .thm_btn:hover {
  box-shadow: 0 20px 30px 0 rgba(76, 76, 241, 0.24);
}

/*================ signup_area css ==============*/
/*================ signin_left css ==============*/
.signin_left {
  background: var(--brand_color);
}

.signin_left:before {
  content: "";
  left: 0;
  width: 100%;
  height: 100px;
  background-image: linear-gradient(180deg, rgb(16, 179, 214) 0%, rgb(29, 39, 70) 100%);
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.signin_left .round {
  bottom: 100px;
  width: 600px;
  height: 600px;
  left: -50px;
}

.signin_left .middle {
  bottom: 50px;
}

.signin_left .bottom {
  bottom: 100px;
}

/*================ signin_left css ==============*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== contact_area css =========*/
.get_info_area {
  position: relative;
  z-index: 1;
}

.get_info_item {
  border: 1px solid #e3e8ea;
  border-radius: 6px;
  padding: 31px 35px;
  margin-left: 0;
  margin-right: 0;
}

.get_info_item .media img {
  margin-right: 60px;
}

.get_info_item .media .media-body h5 {
  margin-bottom: 4px;
  font-size: 22px;
}

.get_info_item .media .media-body p {
  margin-bottom: 0;
  color: #6b707f;
}

.get_info_item .time {
  font-size: 16px;
  color: var(--p_color);
}

.get_info_item .time span {
  color: var(--black_800);
}

.get_info_item .doc_border_btn {
  max-width: 190px;
  width: 100%;
  display: block;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.get_info_item+.get_info_item {
  margin-top: 30px;
}

.contact_info .contact_form .form-group h6 {
  font-size: 16px;
  font-weight: 500;
}

.contact_info .contact_form .form-group .box_info {
  margin-left: -20px;
  margin-right: -20px;
}

.contact_info .contact_form .form-group .form-check {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--p_color);
  padding-left: 22px;
  margin: 20px 20px 15px;
}

.contact_info .contact_form .form-group .form-check input[type=checkbox] {
  width: 12px;
  position: absolute;
  outline: none;
  height: 12px;
  top: 1px;
  left: 0;
  margin: 0;
}

.contact_info .contact_form .form-group .form-check input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid #6b707f;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #fff;
}

.contact_info .contact_form .form-group .form-check input[type=checkbox]:after {
  content: "";
  display: block;
  width: 5px;
  height: 11px;
  border: solid var(--brand_color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: -2px;
  left: 5px;
  opacity: 0;
  transition: all 0.2s linear;
}

.contact_info .contact_form .form-group .form-check input[type=checkbox]:checked:before {
  border-color: var(--brand_color);
}

.contact_info .contact_form .form-group .form-check input[type=checkbox]:checked:after {
  opacity: 1;
}

.contact_info .contact_form .form-group .form-check input[type=checkbox]:checked+label {
  color: var(--brand_color);
}

.contact_info .contact_form .form-group .form-check input[type=radio] {
  width: 12px;
  position: absolute;
  outline: none;
  height: 12px;
  margin: 0;
  left: 0;
  top: 1px;
}

.contact_info .contact_form .form-group .form-check input[type=radio]:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid #6b707f;
  border-radius: 50%;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: all 0.2s linear;
}

.contact_info .contact_form .form-group .form-check input[type=radio]:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--brand_color);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -3px;
  margin-top: -3px;
  transform: scale(0);
  transition: all 0.2s linear;
}

.contact_info .contact_form .form-group .form-check input[type=radio]:checked:before {
  border-color: var(--brand_color);
}

.contact_info .contact_form .form-group .form-check input[type=radio]:checked:after {
  transform: scale(1);
}

.contact_info .contact_form .form-group .form-check input[type=radio]:checked+label {
  color: var(--brand_color);
}

.contact_info .contact_form .form-group .form-control {
  height: 60px;
  line-height: 55px;
  background: #f9fbfc;
  box-shadow: none;
  border-color: var(--black_50);
  padding-left: 30px;
  font-weight: 400;
  transition: all 0.2s linear;
  border-radius: 6px;
}

.contact_info .contact_form .form-group .form-control.placeholder {
  color: #878b99;
}

.contact_info .contact_form .form-group .form-control:-moz-placeholder {
  color: #878b99;
}

.contact_info .contact_form .form-group .form-control::-moz-placeholder {
  color: #878b99;
}

.contact_info .contact_form .form-group .form-control::-webkit-input-placeholder {
  color: #878b99;
}

.contact_info .contact_form .form-group .form-control.message {
  height: 200px;
  padding-top: 0;
}

.contact_info .contact_form .form-group .form-control:focus {
  background: #fbfcfd;
  box-shadow: 0 20px 30px 0 rgba(4, 73, 89, 0.1);
}

.contact_info .contact_form .form-group:last-child {
  margin-bottom: 0;
}

.contact_info .contact_fill {
  padding-top: 20px;
}

/*=========== contact_area css =========*/
/* Support Area css
============================================================================================ */
.support_area {
  background: #002454;
  overflow: hidden;
  z-index: 2;
}

.support_area .container {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.support_area .shap {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.support_area .left {
  position: relative;
  align-self: center;
  z-index: 3;
}

.support_area .left h3 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 5px;
}

.support_area .left p {
  color: #fff;
}

.support_area .left .icon_btn2 {
  margin-top: 25px;
}

.support_area .right {
  position: relative;
}

.support_area .right img {
  z-index: 4;
  position: relative;
}

/* End Support Area css
  ============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ error_area css ========*/
.error_area {
  display: flex;
  position: relative;
  z-index: 1;
  height: 100vh;
  min-height: 620px;
}

.error_dot {
  position: absolute;
  border-radius: 50%;
}

.error_dot.one {
  width: 8px;
  height: 8px;
  background: var(--brand_color);
  left: 325px;
  top: 120px;
  animation: spin2 2s infinite alternate;
}

.error_dot.two {
  width: 16px;
  height: 16px;
  background: #f99e43;
  left: 170px;
  bottom: 260px;
  animation: spin1 1s infinite alternate;
}

.error_dot.three {
  width: 30px;
  height: 30px;
  background: #beebf5;
  animation: spin1 2s infinite alternate;
  right: 225px;
  top: 370px;
}

.error_dot.four {
  width: 6px;
  height: 6px;
  background: #ffa3be;
  bottom: 148px;
  right: 185px;
  animation: spin2 2s infinite alternate;
}

.error_content_two {
  max-width: 970px;
  margin: 0 auto;
}

.error_content_two h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;
}

.error_content_two p {
  font-size: 18px;
  color: #82879c;
}

.error_img {
  padding-bottom: 55px;
  position: relative;
  z-index: 1;
}

.error_img .error_shap {
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.error_img .clipInDown {
  display: inline-block;
  position: relative;
}

.error_img .one {
  top: -50px;
}

.error_img .two {
  margin: 0 12px;
  top: -12px;
}

.error_img .three {
  top: -58px;
}

.error_img .img_one {
  animation: customUp 1.5s infinite alternate;
}

.error_img .img_two {
  animation: customUp 1.8s infinite alternate;
}

.error_img .img_three {
  animation: customUp 2s infinite alternate;
}

.error_search {
  margin-bottom: 40px;
  padding-top: 25px;
}

.error_search .form-control {
  height: 60px;
  border-radius: 4px;
  background: var(--bs-white);
  box-shadow: 0 16px 50px 0 rgba(3, 62, 75, 0.1);
  font-size: 16px;
  font-weight: 400;
  color: var(--black_800);
  border: 0;
  padding-left: 30px;
}

.error_search .form-control.placeholder {
  color: #82879c;
}

.error_search .form-control:-moz-placeholder {
  color: #82879c;
}

.error_search .form-control::-moz-placeholder {
  color: #82879c;
}

.error_search .form-control::-webkit-input-placeholder {
  color: #82879c;
}

/*============ error_area css ========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============  doc_banner_area css  =============*/
.doc_banner_area {
  background: #3636cd;
  text-align: center;
  padding: 290px 0 200px;
  position: relative;
}

.banner_shap_img li {
  position: absolute;
  z-index: 0;
}

.banner_shap_img li:nth-child(1),
.banner_shap_img li:nth-child(2) {
  top: 0;
  left: 0;
}

.banner_shap_img li:nth-child(3),
.banner_shap_img li:nth-child(4) {
  bottom: 0;
  right: 0;
}

.banner_shap_img li:nth-child(5) {
  left: 145px;
  top: 44%;
}

.banner_shap_img li:nth-child(6) {
  top: 50%;
  right: 375px;
}

.banner_shap_img li:nth-child(7),
.banner_shap_img li:nth-child(8),
.banner_shap_img li:nth-child(9) {
  border-radius: 50%;
  background: #fff;
  opacity: 0.14;
}

.banner_shap_img li:nth-child(7) {
  width: 16px;
  height: 16px;
  left: 435px;
  bottom: 265px;
}

.banner_shap_img li:nth-child(8) {
  width: 30px;
  height: 30px;
  right: 135px;
  top: 255px;
}

.banner_shap_img li:nth-child(9) {
  width: 20px;
  height: 20px;
  right: 225px;
  opacity: 1;
  bottom: 115px;
}

.banner_creative1 .doc_banner_content h2 {
  color: #fff;
}

.doc_banner_content {
  position: relative;
}

.doc_banner_content h2 {
  font-size: 50px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 12px;
}

.doc_banner_content p {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
}

.header_search_form {
  max-width: 720px;
  margin: 57px auto 0;
}

.header_search_form .form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header_search_form .input-wrapper {
  position: relative;
  flex: 1;
  max-width: 570px;
  margin-right: 10px;
}

.header_search_form .input-wrapper i {
  position: absolute;
  left: 25px;
  font-size: 16px;
  color: #b6bac3;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.header_search_form .input-wrapper input {
  background: #fff;
  border: 0;
  height: 60px;
  font-size: 16px;
  padding-left: 52px;
  width: 100%;
  border-radius: 45px;
  padding-right: 155px;
}

.header_search_form .input-wrapper input.placeholder {
  color: #848996;
}

.header_search_form .input-wrapper input:-moz-placeholder {
  color: #848996;
}

.header_search_form .input-wrapper input::-moz-placeholder {
  color: #848996;
}

.header_search_form .input-wrapper input::-webkit-input-placeholder {
  color: #848996;
}

.header_search_form .custom-select {
  height: 60px;
  line-height: 60px;
  font-weight: 500;
  color: var(--black_800);
  padding: 0 65px 0 25px;
  background: var(--black_25);
  float: none;
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
}

.header_search_form .custom-select:after {
  border-bottom: 1px solid var(--black_800);
  border-right: 1px solid var(--black_800);
}

.header_search_form .custom-select li {
  font-size: 14px;
  color: #1d2746;
  line-height: 40px;
}

.header_search_form .custom-select:focus {
  box-shadow: none;
}

.header_search_form .header_form_submit {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #0d0d75;
  padding: 10px 48px;
  border-radius: 45px;
  border: 0;
  box-shadow: 0 10px 20px 0 rgba(2, 2, 59, 0.2);
  transition: all 0.2s linear;
}

.header_search_form .header_form_submit:hover {
  background: #fff;
  color: #0d0d75;
}

.header_search_form_info {
  position: relative;
}

.header_search_form_info .header_search_form_panel {
  border: 1px solid var(--black_50);
  box-shadow: 0 3px 4px 0 rgba(4, 73, 89, 0.06);
  background-color: var(--bs-white);
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 115%;
  left: 0;
  width: 100%;
  text-align: left;
  z-index: 99;
  padding: 30px;
}

.header_search_form_info .header_search_form_panel:before {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--bs-white);
  position: absolute;
  top: -6px;
  transform: rotate(45deg);
  left: 40px;
  border-left: 1px solid var(--black_50);
  border-top: 1px solid var(--black_50);
}

.header_search_form_info .header_search_form_panel ul {
  margin-bottom: 0;
}

.header_search_form_info .header_search_form_panel ul>li {
  font-size: 16px;
  color: var(--black_800);
}

.header_search_form_info .header_search_form_panel ul>li .search_item {
  border-top: 1px solid var(--black_50);
  padding: 10px 0 5px;
  margin-top: 8px;
}

.header_search_form_info .header_search_form_panel ul>li .search_item li {
  color: var(--black_600);
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 0;
}

.header_search_form_info .header_search_form_panel ul>li .search_item li span {
  width: 165px;
  text-align: right;
  border-right: 1px solid var(--black_50);
  line-height: 32px;
  padding-right: 15px;
}

.header_search_form_info .header_search_form_panel ul>li .search_item li>a {
  line-height: 26px;
  font-weight: 400;
  color: var(--black_400);
  padding: 3px 22px;
  flex: 1;
  display: block;
  position: relative;
}

.header_search_form_info .header_search_form_panel ul>li .search_item li>a::before {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  left: -1px;
  top: 0;
  background: #4a4ad2;
  opacity: 0;
  transition: all 0.2s linear;
}

.header_search_form_info .header_search_form_panel ul>li .search_item li>a:hover {
  background: var(--black_25);
}

.header_search_form_info .header_search_form_panel ul>li .search_item li>a:hover:before {
  opacity: 1;
  height: 100%;
}

.header_search_form_info .custom-select ul li:hover,
.header_search_form_info .custom-select ul li.selected {
  color: var(--brand_color);
}

.header_search_form_info .custom-select ul li:before {
  background: var(--brand_color);
}

.header_search_keyword {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.header_search_keyword span {
  font-size: 14px;
  color: #fff;
  margin-right: 10px;
}

.header_search_keyword ul {
  margin-bottom: 0;
}

.header_search_keyword ul li {
  display: inline-block;
  margin-right: 7px;
}

.header_search_keyword ul li a {
  display: block;
  font-size: 13px;
  padding: 0 19px;
  border-radius: 45px;
  background: #4a4ad2;
  color: #fff;
}

/*=============  doc_banner_area css  =============*/
/*============== doc_features_area css  ===========*/
.doc_features_area {
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  background: #fbfbfc;
}

.doc_features_area .doc_features_shap {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
}

.doc_features_inner {
  overflow: hidden;
  box-shadow: 0 30px 60px 0 rgba(2, 2, 48, 0.08);
  border-radius: 10px;
  background: var(--bs-white);
  top: -60px;
  position: relative;
}

.doc_features_inner .see_more_item {
  display: none;
  flex-wrap: wrap;
  width: 100%;
}

.see_btn {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--brand_color);
  display: inline-block;
  margin: 16px 50px 12px;
}

.see_btn i {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -5px;
  display: inline-block;
}

.doc_features_item {
  width: 33.3333333333%;
  float: left;
  padding: 35px 30px;
  border-bottom: 1px solid var(--border_on_highlight);
  border-left: 1px solid var(--border_on_highlight);
}

.doc_features_item img {
  margin-right: 15px;
}

.doc_features_item h4 {
  font-size: 16px;
  line-height: 26px;
  color: var(--black_800);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-bottom: 2px;
}

.doc_features_item h4:hover {
  color: var(--brand_color);
}

.doc_features_item p {
  margin-bottom: 0;
  font-size: 14px;
  color: #425466;
}

.doc_features_item:nth-child(1),
.doc_features_item:nth-child(4),
.doc_features_item:nth-child(7),
.doc_features_item:nth-child(10) {
  border-left: 0;
}

/*============== doc_features_area css  ===========*/
/*============== recommended_topic_area css  ===========*/
.recommended_topic_inner {
  position: relative;
  z-index: 1;
  padding: 100px 0;
}

.doc_shap_one,
.doc_round {
  position: absolute;
  z-index: -1;
}

.doc_round {
  border-radius: 50%;
}

.doc_round.one {
  width: 260px;
  height: 260px;
  background: #f9bf44;
  bottom: 120px;
  left: 120px;
}

.doc_round.two {
  background: #5e44ff;
  width: 100px;
  height: 100px;
  top: 160px;
  right: 70px;
}

.doc_shap_one {
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.doc_title {
  margin-bottom: 76px;
}

.doc_title h2 {
  font-size: 34px;
  font-weight: 700;
  color: var(--black_800);
  margin-bottom: 15px;
}

.doc_title p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
  color: #6B707F;
}

.recommended_item {
  border-radius: 20px;
  box-shadow: 0 30px 50px 0 rgba(2, 2, 48, 0.06);
  padding: 40px 25px 30px 35px;
  margin-bottom: 30px;
  background: var(--bs-white);
}

.recommended_item img {
  margin-bottom: 25px;
  max-width: 44px;
}

.recommended_item h3 {
  font-size: 18px;
  color: var(--black_700);
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 18px;
  transition: color 0.2s linear;
}

.recommended_item h3:hover {
  color: var(--brand_color);
}

.recommended_item ul li {
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  line-height: 18px;
}

.recommended_item ul li:before {
  content: "5";
  font-family: eleganticons;
  position: absolute;
  left: -5px;
  width: auto;
  font-size: 18px;
  color: var(--brand_color);
  top: -2px;
}

.recommended_item ul li a {
  color: var(--p_color);
}

.recommended_item ul li a:hover {
  color: var(--brand_color) !important;
}

.recommended_item ul li+li {
  margin-top: 15px;
}

.question_text {
  font-size: 16px;
  line-height: 24px;
  color: var(--brand_color);
  font-weight: 500;
  margin-top: 35px;
  display: inline-block;
}

.question_text:hover {
  color: var(--brand_color);
}

/*============== recommended_topic_area css  ===========*/
/*============= doc_community_area css  ================*/
.doc_community_area {
  padding: 200px 0 75px;
  position: relative;
}

.doc_community_area .shap_one,
.doc_community_area .shap_two {
  position: absolute;
  z-index: -1;
}

.doc_community_area .shap_one {
  bottom: 0;
}

.doc_community_area .shap_two {
  top: 0;
}

.doc_community_area .doc_title {
  margin-bottom: 64px;
}

.doc_community_info .question_text {
  color: #a7acb7;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 40px;
}

.doc_community_info .question_text:hover {
  color: var(--brand_color);
}

.doc_community_item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  background: var(--bs-white);
  box-shadow: 0 20px 30px 0 rgba(2, 2, 48, 0.06);
  margin-bottom: 20px;
  padding: 38px 70px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.doc_community_item .doc_community_icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  text-align: center;
  font-size: 36px;
  background: rgba(76, 76, 241, 0.06);
  color: var(--brand_color);
  margin-right: 50px;
}

.doc_community_item h4 {
  font-size: 16px;
  color: var(--black_800);
  font-weight: 700;
  margin-bottom: 5px;
  transition: color 0.2s linear;
}

.doc_community_item h4:hover {
  color: var(--brand_color);
}

.doc_community_item p {
  font-size: 14px;
  color: #6b707f;
}

.doc_community_item .doc_entry_info {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}

.doc_community_item .doc_entry_info .author_avatar {
  display: flex;
  margin-bottom: 0;
  margin-right: 15px;
}

.doc_community_item .doc_entry_info .author_avatar img {
  border-radius: 100px;
}

.doc_community_item .doc_entry_info .author_avatar li {
  width: 36px;
  height: 36px;
  line-height: 34px;
  border-radius: 50%;
  border: 2px solid var(--black_50);
  font-size: 14px;
  font-weight: 500;
  color: #232361;
  text-align: center;
  z-index: 1;
  position: relative;
}

.doc_community_item .doc_entry_info .author_avatar li img {
  max-width: 100%;
}

.doc_community_item .doc_entry_info .author_avatar li+li {
  margin-left: -10px;
}

.doc_community_item .doc_entry_info .author_avatar li:nth-child(even) {
  z-index: 0;
}

.doc_community_item .doc_entry_info .author_avatar li:last-child {
  border-color: var(--black_100);
}

.doc_community_item .doc_entry_info .text {
  font-size: 14px;
  line-height: 1.3;
  color: #90939b;
}

.doc_community_item:hover {
  box-shadow: 0 10px 30px 0 rgba(2, 2, 48, 0.02);
}

.doc_community_item:nth-child(2) .doc_community_icon {
  background: #fef9ee;
  color: #f7b126;
}

.doc_community_item:nth-child(3) .doc_community_icon {
  background: #f1fbf8;
  color: #0fbc8b;
}

/*============= doc_community_area css  ================*/
/*============= doc_testimonial_area css  ================*/
.doc_testimonial_area {
  padding: 160px 0 135px;
}

.doc_testimonial_slider {
  padding-right: 100px;
}

.doc_testimonial_slider .item {
  padding-left: 15px;
  position: relative;
  padding-top: 10px;
}

.doc_testimonial_slider .item:before {
  content: "“";
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 150px;
  color: var(--brand_color);
  opacity: 0.1;
  position: absolute;
  left: -5px;
  width: 38px;
  height: 38px;
  line-height: 130px;
  top: 0;
  z-index: 1;
}

.doc_testimonial_slider .item h3 {
  font-size: 34px;
  line-height: 52px;
  font-weight: 400;
  margin-bottom: 20px;
}

.doc_testimonial_slider .item .name {
  font-size: 14px;
  font-weight: 700;
  color: #232361;
  margin-bottom: 5px;
}

.doc_testimonial_slider .item .name span {
  font-weight: 400;
  color: var(--black_500);
}

.doc_testimonial_slider .item .sign {
  display: inline-block;
}

.doc_testimonial_slider .slick-dots {
  position: relative;
  bottom: 0;
  text-align: left;
  margin-top: 30px;
}

.doc_testimonial_slider .slick-dots li {
  width: auto;
  height: auto;
}

.doc_testimonial_slider .slick-dots li button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #dbdbfc;
  transition: all 0.2s;
}

.doc_testimonial_slider .slick-dots li button:before {
  display: none;
}

.doc_testimonial_slider .slick-dots li:nth-child(even) button {
  background: #a5a5f8;
}

.doc_testimonial_slider .slick-dots li.slick-active button {
  background: var(--brand_color);
}

.doc_img_slider .item {
  text-align: center;
  position: relative;
  padding-bottom: 25px;
  z-index: 1;
}

.doc_img_slider .item img {
  display: inline-block;
  border-radius: 20px;
}

.doc_img_slider .item .dot {
  border-radius: 0;
  position: absolute;
  left: 60px;
  bottom: 0;
  z-index: -1;
}

.doc_img_slider .item .round {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
}

.doc_img_slider .item .round.one {
  width: 150px;
  height: 150px;
  right: 60px;
  top: 60px;
  background-image: linear-gradient(50deg, rgb(87, 54, 254) 0%, rgb(253, 64, 110) 100%);
  opacity: 0.1;
}

.doc_img_slider .item .round.two {
  width: 280px;
  height: 280px;
  right: 0;
  top: 50%;
  margin-top: -140px;
  background-image: linear-gradient(50deg, rgb(87, 54, 254) 0%, rgb(253, 64, 110) 100%);
}

.doc_img_slider .item.slick-active .one {
  animation: zoomIn 0.8s linear both 0.4s;
}

.doc_img_slider .item.slick-active .two {
  animation: zoomIn 0.8s linear both 0.5s;
}

/*============= doc_testimonial_area css  ================*/
.doc_subs_full .doc_subscribe_inner {
  border-radius: 0;
  box-shadow: none;
}

.doc_subscribe_inner {
  border-radius: 20px;
  background-color: rgb(76, 76, 241);
  box-shadow: 0 50px 80px 0 rgba(3, 3, 125, 0.2);
  display: flex;
  flex-wrap: wrap;
  padding: 150px 70px;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.doc_subscribe_inner .one,
.doc_subscribe_inner .two {
  position: absolute;
  z-index: 0;
}

.doc_subscribe_inner .one.one,
.doc_subscribe_inner .two.one {
  top: 0;
  left: 0;
}

.doc_subscribe_inner .one.two,
.doc_subscribe_inner .two.two {
  right: 0;
  bottom: 0;
}

.doc_subscribe_inner h2 {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 0;
}

.doc_subscribe_inner .text,
.doc_subscribe_inner .doc_subscribe_form {
  width: 50%;
}

.doc_subscribe_inner .doc_subscribe_form {
  padding-left: 30px;
  position: relative;
}

.doc_subscribe_inner .doc_subscribe_form .form-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.doc_subscribe_inner .doc_subscribe_form .input-fill {
  position: relative;
  flex: 1;
}

.doc_subscribe_inner .doc_subscribe_form .input-fill:before {
  content: "U";
  position: absolute;
  font-family: eleganticons;
  font-size: 18px;
  margin-right: 10px;
  top: 10px;
  left: 22px;
  transform: rotate(-90deg);
  display: inline-block;
  color: var(--black_400);
}

.doc_subscribe_inner .doc_subscribe_form .input-fill input {
  height: 50px;
  border: 0;
  border-radius: 45px;
  line-height: 51px;
  font-size: 14px;
  width: 100%;
  padding-left: 50px;
}

.doc_subscribe_inner .doc_subscribe_form .input-fill input.placeholder {
  color: #848996;
}

.doc_subscribe_inner .doc_subscribe_form .input-fill input:-moz-placeholder {
  color: #848996;
}

.doc_subscribe_inner .doc_subscribe_form .input-fill input::-moz-placeholder {
  color: #848996;
}

.doc_subscribe_inner .doc_subscribe_form .input-fill input::-webkit-input-placeholder {
  color: #848996;
}

.doc_subscribe_inner .doc_subscribe_form .submit_btn {
  border-radius: 45px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  background: #0d0d75;
  padding: 10px 30px;
  color: #fff;
  margin-left: 10px;
  transition: background 0.4s linear, color 0.3s;
}

.doc_subscribe_inner .doc_subscribe_form .submit_btn:hover {
  background: #fff;
  color: #0d0d75;
}

.doc_subscribe_inner .doc_subscribe_form ul {
  margin-bottom: 0;
}

.doc_subscribe_inner .doc_subscribe_form ul li {
  margin-right: 22px;
  display: inline-block;
  line-height: 18px;
}

.doc_subscribe_inner .doc_subscribe_form ul li a {
  font-size: 13px;
  color: #d3d3f4;
  position: relative;
  padding-left: 20px;
}

.doc_subscribe_inner .doc_subscribe_form ul li a:before {
  content: "N";
  font-family: eleganticons;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
}

.doc_subscribe_inner .doc_subscribe_form ul li:last-child {
  margin-right: 0;
}

.doc_subscribe_inner .doc_subscribe_form ul li:hover a {
  color: #fff;
}

.doc_subscribe_inner .doc_subscribe_form p {
  color: #fff;
  width: 100%;
  margin-bottom: 0;
  margin-top: 5px;
}

/*=============== doc_footer_area css  ==============*/
.doc_footer_top {
  padding: 100px 0 90px;
  background: #f9f9f9;
}

.pl-30 {
  padding-left: 30px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-70 {
  padding-left: 70px;
}

.f_title_two {
  font-size: 18px;
  color: var(--black_800);
  margin-bottom: 28px;
}

.doc_about_widget {
  padding-right: 128px;
}

.doc_about_widget p {
  font-size: 16px;
  line-height: 30px;
  color: #6b707f;
  font-weight: 500;
  margin-top: 15px;
}

.doc_about_widget ul {
  margin-bottom: 0;
  margin-top: 40px;
}

.doc_about_widget ul li {
  display: inline-block;
  margin-right: 5px;
}

.doc_about_widget ul li a {
  width: 44px;
  height: 44px;
  display: block;
  text-align: center;
  color: var(--black_500);
  font-size: 14px;
  line-height: 45px;
  border-radius: 10px;
  background: var(--black_50);
  transition: all 0.2s linear;
}

.doc_about_widget ul li a:hover {
  background: var(--black_100);
  color: var(--brand_color);
  text-decoration: none;
}

.doc_service_list_widget {
  padding-top: 5px;
}

.doc_service_list_widget ul {
  margin-bottom: 0;
}

.doc_service_list_widget ul li a {
  font-size: 14px;
  font-weight: 500;
  color: var(--black_500);
}

.doc_service_list_widget ul li a img {
  margin-right: 14px;
}

.doc_service_list_widget ul li a:hover {
  color: var(--brand_color);
}

.doc_service_list_widget ul li+li {
  margin-top: 10px;
}

.doc_footer_bottom {
  border-top: 1px solid #f0f0f7;
  padding: 30px 0 30px;
}

.doc_footer_bottom p {
  margin-bottom: 0;
  font-size: 14px;
  color: #6b707f;
}

.doc_footer_bottom p span {
  color: var(--brand_color);
}

.doc_footer_menu {
  margin-bottom: 0;
}

.doc_footer_menu li {
  display: inline-block;
  margin-right: 30px;
}

.doc_footer_menu li a {
  font-size: 14px;
  color: #6b707f;
  transition: all 0.2s;
}

.doc_footer_menu li a:hover {
  color: var(--brand_color);
}

/*=============== doc_footer_area css  ==============*/
input,
textarea {
  color: var(--black_400);
  background-color: var(--bs-white);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Animation Area css
============================================================================================ */
@keyframes myfirst {
  0% {
    left: 6%;
  }

  100% {
    left: 100%;
  }
}

@keyframes secand {
  0% {
    right: 3%;
  }

  100% {
    right: 100%;
  }
}

@keyframes cloud {
  0% {
    right: 10%;
    top: 176px;
    opacity: 0;
  }

  20% {
    right: 44%;
    top: 100px;
    opacity: 1;
  }

  40% {
    right: 65%;
    top: 0;
    opacity: 1;
  }

  60% {
    right: 80%;
    top: 60px;
    opacity: 1;
  }

  80% {
    right: 90%;
    top: 100px;
    opacity: 0.5;
  }

  100% {
    right: 100%;
    top: 176px;
    opacity: 0;
  }
}

/* End Animation Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========== Start footer_area css ==========*/
.footer_area {
  padding-top: 100px;
  position: relative;
  z-index: 1;
}

.footer_area .leaf {
  right: 60px;
  bottom: 0;
  width: 180px;
  z-index: -1;
}

.footer_area .f_man {
  right: 280px;
  bottom: 40px;
}

.footer_area .f_man_two {
  bottom: 60px;
  left: 125px;
}

.footer_area .f_cloud {
  bottom: 410px;
  left: 150px;
}

.footer_area .f_email {
  left: 75px;
  bottom: 290px;
}

.footer_area .f_email_two {
  bottom: 200px;
  left: 250px;
}

.footer_area.footer_three {
  padding-top: 115px;
}

.footer_area.footer_three .about_wd img {
  margin-bottom: 18px;
}

.footer_area.footer_three .about_wd p {
  margin-bottom: 0;
  color: #6b707f;
}

.footer_area.footer_three .link_wd ul li a {
  color: var(--black_500);
}

.footer_area.footer_three .link_wd ul li:hover a {
  color: var(--brand_color);
}

.footer_area.footer_three .footer_widgets {
  position: relative;
}

.footer_area.footer_three .footer_widgets .vector_svg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer_area.footer_three .footer_widgets .vector_svg li {
  position: absolute;
  bottom: 0;
}

.footer_area.footer_three .footer_widgets .vector_svg li:first-child {
  left: 6%;
  transform: translateX(-50%);
}

.footer_area.footer_three .footer_widgets .vector_svg li:nth-child(2) {
  left: 18%;
  transform: translateX(-50%);
}

.footer_area.footer_three .footer_widgets .vector_svg li:nth-child(3) {
  left: 32%;
  transform: translateX(-50%);
}

.footer_area.footer_three .footer_widgets .vector_svg li:nth-child(4) {
  right: 6%;
  transform: translateX(50%);
}

.footer_area.footer_three .footer_widgets .vector_svg li:nth-child(5) {
  right: 11%;
  transform: translateX(50%);
}

.footer_area.footer_three .footer_widgets .vector_svg li:nth-child(6) {
  right: 16%;
  transform: translateX(50%);
}

.footer_area.footer_three .footer_widgets .vector_svg li:nth-child(7) {
  right: 23%;
  transform: translateX(50%);
}

.footer_area.footer_three .footer_copyright .right .nav li a {
  color: var(--black_400);
}

.footer_area.footer_three .footer_copyright .right .nav li:hover a {
  color: var(--brand_color);
}

.footer_area.footer_three .footer_copyright .left p {
  color: var(--black_400);
}

.list_social li {
  margin-bottom: 15px;
}

.list_social li:last-child {
  margin-bottom: 0;
}

.list_social li a {
  color: var(--black_500);
  font-size: 16px;
  font-weight: 400;
  transition: all 300ms linear 0s;
}

.list_social li a i {
  margin-right: 17px;
  position: relative;
  top: 1px;
}

.list_social li:hover a {
  color: var(--brand_color);
}

.footer_p_top {
  padding-top: 225px;
}

.footer_top .border_bottom {
  margin-top: 100px;
}

.border_bottom {
  width: 100%;
  height: 1px;
  background: #e8f0f1;
}

.f_widget .f_title {
  font-size: 18px;
  margin-bottom: 30px;
}

.f_widget.subscribe_widget {
  padding-right: 40px;
}

.f_widget.subscribe_widget .f_logo {
  display: inline-block;
  margin-bottom: 32px;
}

.f_widget.subscribe_widget h4 {
  margin-bottom: 34px;
}

.f_widget.link_widget .link_list {
  margin-bottom: 0;
}

.f_widget.link_widget .link_list li a {
  color: var(--p_color);
  font-size: 16px;
}

.f_widget.link_widget .link_list li a:hover {
  color: var(--brand_color);
}

.f_widget.link_widget .link_list li+li {
  margin-top: 15px;
}

.footer_subscribe_form {
  position: relative;
}

.footer_subscribe_form .form-control {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 20px 30px 0 rgba(4, 73, 89, 0.08);
  height: 56px;
  border: 1px solid transparent;
  color: var(--black_800);
  padding-left: 25px;
  position: relative;
  transition: all 0.2s linear;
}

.footer_subscribe_form .form-control.placeholder {
  color: #878b99;
}

.footer_subscribe_form .form-control:-moz-placeholder {
  color: #878b99;
}

.footer_subscribe_form .form-control::-moz-placeholder {
  color: #878b99;
}

.footer_subscribe_form .form-control::-webkit-input-placeholder {
  color: #878b99;
}

.footer_subscribe_form .form-control:focus {
  outline: none;
}

.footer_subscribe_form .s_btn {
  border: 0;
  font-size: 16px;
  font-weight: 400;
  background: var(--brand_color);
  border-radius: 4px;
  color: #fff;
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 8px;
  padding: 7px 22px;
}

.f_social_icon {
  padding-top: 40px;
}

.f_social_icon li {
  display: inline-block;
}

.f_social_icon li a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid rgba(76, 76, 241, 0.3);
  font-size: 14px;
  line-height: 45px;
  text-align: center;
  display: block;
  color: var(--brand_color);
  margin-right: 6px;
}

.f_social_icon li a:hover {
  background: var(--brand_color);
  border-color: var(--brand_color);
  box-shadow: 0 10px 20px 0 rgba(76, 76, 241, 0.2);
  color: #fff;
}

.footer_bottom {
  padding: 38px 0 66px;
  position: relative;
  z-index: 1;
}

.footer_bottom p {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--p_color);
}

.footer_bottom p a {
  color: var(--brand_color);
}

.pl_30 {
  padding-left: 30px;
}

.pl_70 {
  padding-left: 70px;
}

.simple_footer {
  padding: 30px 0;
  position: relative;
  z-index: 1;
  background: #1d2746;
}

.simple_footer .row {
  align-items: center;
}

.simple_footer .leaf_right {
  position: absolute;
  right: 170px;
  bottom: 0;
  z-index: -1;
}

.simple_footer p {
  margin-bottom: 0;
  font-size: 16px;
  color: #a4a8b4;
}

.simple_footer p a {
  color: var(--brand_color);
}

.simple_footer .f_social_icon {
  margin-bottom: 0;
  padding-top: 0;
}

.simple_footer .f_social_icon li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #8d93a4;
  border-color: #8d93a4;
}

.simple_footer .f_social_icon li a:hover {
  background: var(--brand_color);
  border-color: var(--brand_color);
  color: #fff;
}

/*========== End footer_area css ==========*/
/* Footer Area css
============================================================================================ */
.footer_two {
  background: #ecf3f5;
  padding-top: 0;
}

.footer_two .footer_widgets {
  position: relative;
}

.footer_two .footer_widgets .footer_vector {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer_two .footer_widgets .footer_vector img {
  left: 130px;
  position: absolute;
  bottom: 0;
  overflow: visible;
}

.footer_two .footer_widgets .footer_vector img+img {
  right: 60px;
  position: absolute;
  bottom: -50px;
  left: auto;
}

.f_title {
  color: var(--black_800);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
}

.about_wd img {
  margin-bottom: 25px;
}

.about_wd p {
  max-width: 350px;
  margin-bottom: 30px;
}

.about_wd .nice_select {
  width: 175px;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  height: 50px;
  background: transparent;
  padding: 0 20px;
}

.about_wd .nice_select span {
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: var(--p_color);
}

.about_wd .nice_select:after {
  content: "3";
  font-family: "ElegantIcons";
  border: none;
  transform: rotate(0);
  width: auto;
  margin-top: -19px;
  font-size: 20px;
  color: var(--p_color);
}

.about_wd .nice_select .list {
  width: 100%;
  border: none;
}

.about_wd .nice_select .list li {
  font-size: 16px;
  font-weight: 400;
  color: var(--p_color);
}

.link_wd ul li {
  margin-bottom: 18px;
}

.link_wd ul li a {
  color: var(--p_color);
  font-size: 16px;
  font-weight: 400;
  transition: all 300ms linear 0s;
}

.link_wd ul li a:hover {
  color: var(--brand_color);
}

.link_wd ul li:last-child {
  margin-bottom: 0;
}

.f_social li {
  margin-right: 20px;
}

.f_social li:last-child {
  margin-right: 0;
}

.f_social li a {
  color: var(--p_color);
  font-size: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  border: 1px solid var(--p_color);
  display: inline-block;
  transition: all 300ms linear 0s;
}

.f_social li a:hover {
  color: #fff;
  background: var(--brand_color);
  border-color: var(--brand_color);
}

.footer_copyright {
  border-top: 1px solid #e3e5e6;
  padding: 40px 0;
}

.footer_copyright .left p {
  margin-bottom: 0;
  color: #7984a6;
}

.footer_copyright .right ul li {
  margin-right: 30px;
}

.footer_copyright .right ul li:last-child {
  margin-right: 0;
}

.footer_copyright .right ul li a {
  color: #7984a6;
  font-size: 16px;
  transition: all 300ms linear 0s;
}

.footer_copyright .right ul li a:hover {
  color: var(--brand_color);
}

.footer_four {
  background: #ecf2f5;
}

.footer_four .f_title {
  color: var(--black_800);
}

.footer_four .about_wd p {
  color: #54595d;
}

.footer_four .about_wd .nice-select {
  border-color: #b8bdcc;
}

.footer_four .about_wd .nice-select span {
  color: var(--p_color);
}

.footer_four .link_wd ul li a {
  color: #54595d;
}

.footer_four .link_wd ul li:hover a {
  color: var(--brand_color);
}

.footer_four .social_wd .f_social li a {
  color: var(--p_color);
  border-color: var(--p_color);
}

.footer_four .social_wd .f_social li:hover a {
  color: #fff;
  background: var(--brand_color);
  border-color: var(--brand_color);
}

.footer_four .footer_copyright .left p {
  color: var(--p_color);
}

.footer_four .footer_copyright .right ul li a {
  color: var(--p_color);
}

.footer_four .footer_copyright .right ul li:hover a {
  color: var(--brand_color);
}

/* End Footer Area css
  ============================================================================================ */
/* Forum Footer Area css
  ============================================================================================ */
.forum_footer_area {
  background: #f2f5f7;
  padding-top: 55px;
  padding-bottom: 45px;
  position: relative;
  z-index: 1;
}

.forum_footer_area .forum_footer_inner {
  max-width: 1065px;
}

.forum_footer_area .forum_footer_inner .col-lg-4 .f_widget {
  margin-left: 20px;
}

.ab_wd a {
  font-size: 16px;
  font-weight: 400;
  color: var(--black_600);
}

.ab_wd a img {
  margin-right: 25px;
}

.ab_wd .nice_select {
  margin-left: 50px;
  width: 130px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  border: 1px solid var(--black_50);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 18px;
  margin-top: 18px;
}

.ab_wd .nice_select span {
  color: var(--black_500);
  font-size: 14px;
  font-weight: 400;
}

.ab_wd .nice_select:after {
  content: "3";
  font-family: "ElegantIcons";
  font-size: 16px;
  border: none;
  transform: rotate(0);
  height: auto;
  right: 27px;
  margin-top: -20px;
}

.ab_wd .nice_select ul {
  width: 100%;
}

.ab_wd .nice_select ul li {
  font-size: 14px;
  font-weight: 400;
  color: #54595d;
}

.f_link_wd .navbar-nav li {
  margin-bottom: 5px;
}

.f_link_wd .navbar-nav li a {
  font-size: 14px;
  font-weight: 400;
  color: var(--p_color);
  transition: all 300ms linear 0s;
}

.f_link_wd .navbar-nav li a:hover {
  color: var(--brand_color);
}

.f_link_wd .navbar-nav li:last-child {
  margin-bottom: 0;
}

.forum_footer_inner .col-lg-3:nth-child(2) .f_widget {
  margin-left: 25px;
}

.forum_footer_inner .col-lg-3:nth-child(3) .f_widget {
  margin-left: 50px;
}

.forum_footer_inner .col-lg-3:nth-child(4) .f_widget {
  margin-left: 55px;
}

/* End Forum Footer Area css
  ============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
body.body_dark {
  --brand_color: #3577f1 !important;
  --bs-white: #2c303a !important;
  --p_color: #c3c3c3 !important;
  --black_900: #f8f8f8 !important;
  --black_800: #f1f1f1 !important;
  --black_700: #e1e1e1 !important;
  --black_600: #bebebe !important;
  --black_500: #b9b9b9 !important;
  --black_400: #a2a2a2 !important;
  --black_150: rgba(255, 255, 255, 0.3) !important;
  --black_100: #d6d9dc !important;
  --black_50: #494949 !important;
  --black_25: #383838 !important;
  --border_on_highlight: #393e4b !important;
  --border_on_white: #2c303a !important;
  --body_bg: #131417 !important;
  color: #f6f6f6 !important;
  color: var(--black_150);
  /*--===== Header Logo--=====*/
}

body.body_dark .doc_features_item p,
body.body_dark .recommended_item ul li a,
body.body_dark .doc_title p {
  color: #c3c3c3;
}

body.body_dark .doc_community_item p {
  color: var(--black_100);
}

body.body_dark .doc_community_area .shap_one {
  display: none;
}

body.body_dark .doc_features_area .doc_features_shap {
  display: none;
}

body.body_dark .navbar_fixed.menu_one {
  background: #171544;
  box-shadow: 0 0 15px 0 #000;
}

body.body_dark .doc_categories_guide_area .shap {
  display: none;
}

body.body_dark .forum_footer_area,
body.body_dark .doc_footer_top {
  background: #010101;
}

body.body_dark .nice-select .list {
  background-color: #2c303a;
  color: #e1e1e1;
}

body.body_dark .header_search_form .custom-select li {
  color: var(--black_800);
}

body.body_dark .nice-select .option:hover,
body.body_dark .nice-select .option.focus,
body.body_dark .nice-select .option.selected.focus {
  background: var(--black_750);
}

body.body_dark .ab_wd .nice_select ul li {
  color: var(--p_color);
}

body.body_dark .header_search_form_info .custom-select ul li:hover,
body.body_dark .header_search_form_info .custom-select ul li.selected {
  color: var(--black_500);
}

body.body_dark .doc_community_area,
body.body_dark .common_question_area,
body.body_dark .freelancing_list,
body.body_dark .asking_area {
  background: #242729;
}

body.body_dark .body_wrapper,
body.body_dark .img_modal,
body.body_dark .forum-user-wrapper,
body.body_dark .blog_top_post,
body.body_dark .doc_features_area,
body.body_dark .video_slider_area {
  background: #131417;
}

body.body_dark .custom-select.custom-select {
  color: #C3C3C3;
}

body.body_dark .h_item p,
body.body_dark .main_title p {
  color: #c8ccd0;
}

body.body_dark .community-post.style-two:hover,
body.body_dark .header_search_form .input-wrapper input {
  background: #252830;
}

body.body_dark .w_tag_list.style-light li a,
body.body_dark .ticket_categories li .count,
body.body_dark .nice-select .list,
body.body_dark pre[class*=language-]>code {
  background: #2c303a;
}

body.body_dark .community-post.style-two:not(:last-child),
body.body_dark .bb-radius,
body.body_dark .post-header,
body.body_dark .community-post.style-two:hover,
body.body_dark .doc_footer_bottom,
body.body_dark .ticket_categories li:not(:last-child),
body.body_dark .single_list,
body.body_dark .comments_widget li .text,
body.body_dark .common_question_area .question_menu .topic_list_item,
body.body_dark .footer_copyright,
body.body_dark .pagination_inner .go_btn .input-group-append button,
body.body_dark .forum_l_inner .forum_head,
body.body_dark .r_heading,
body.body_dark .user_list_wd,
body.body_dark .navbar-nav li,
body.body_dark .comment_list .navbar-nav li,
body.body_dark .question_list_wd,
body.body_dark .forum_l_inner,
body.body_dark .forum_body .topic_list li {
  border-color: #2c303a;
}

body.body_dark .navbar_fixed.menu_one .menu>.nav-item>.nav-link,
body.body_dark .breadcrumb .breadcrumb-item:last-child,
body.body_dark .doc_faq_info .card .card-header h2 button,
body.body_dark .bbp-login-form legend,
body.body_dark #new-post legend,
body.body_dark .bbp-logged-in h4 a,
body.body_dark .search-result-item .doc-item a,
body.body_dark .topics-filter .nav-item .nav-link.active,
body.body_dark .topics-filter .nav-item .nav-link:hover,
body.body_dark .learn_btn,
body.body_dark .question-icon,
body.body_dark .question_list .ques_item h4,
body.body_dark .bbp-login-form label,
body.body_dark #new-post label,
body.body_dark .dark_menu .menu>.nav-item>.nav-link,
body.body_dark .navbar_fixed.menu_one .search-icon,
body.body_dark .search-result-item .doc-article:hover a,
body.body_dark .cheatsheet_accordian .card .card-header button,
body.body_dark .forum_l_inner .forum_head .left li,
body.body_dark .pagination_inner .go_btn input,
body.body_dark .text_btn.dark_btn,
body.body_dark .topic-heading,
body.body_dark .doc_community_item .doc_entry_info .author_avatar li {
  color: #fff;
}

body.body_dark .contact_info .contact_form .form-group .form-control {
  background: #2c303a;
}

body.body_dark .search-result-item .doc-item a,
body.body_dark .search-result-item .doc-section,
body.body_dark .search .searchbar-tabs,
body.body_dark #docy-search-result:before,
body.body_dark #docy-search-result,
body.body_dark .wp-editor-container,
body.body_dark .filter_form input,
body.body_dark .topic_list_item,
body.body_dark .wp-block-archives select,
body.body_dark .wp-block-categories select,
body.body_dark .widget select,
body.body_dark .main-post,
body.body_dark .all-answers,
body.body_dark .bbp-reply-form,
body.body_dark .search-main,
body.body_dark #comments,
body.body_dark .blog_comment_box,
body.body_dark .bbp-login-form input[type=text],
body.body_dark .bbp-login-form input[type=password],
body.body_dark #new-post .bbp-the-content-wrapper textarea,
body.body_dark #new-post input[type=text],
body.body_dark .post-header,
body.body_dark .community-post.style-two:not(:last-child) {
  border-color: rgba(255, 255, 255, 0.1);
}

body.body_dark .navbar_fixed .header_logo img.sticky_logo {
  display: none;
}

body.body_dark .navbar_fixed img.white_logo {
  display: block;
}

body.body_dark .sticky_menu .menu_one {
  background: #171544;
}

body.body_dark .header_logo img.main_logo.logo_light_version {
  display: none;
}

body.body_dark .header_logo .white_logo.logo_dark_version {
  display: block;
}

.js-darkmode-btn {
  position: relative;
  background: #1d2746;
  color: gold;
  border-radius: 50px;
  padding: 0 5px;
  margin-left: 15px;
  height: 26px;
}

.js-darkmode-btn label {
  margin: 0;
  cursor: pointer;
}

.js-darkmode-btn label:hover {
  color: gold;
}

.js-darkmode-btn .ball {
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #fafafa;
  border-radius: 50%;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  cursor: default;
  transition: all 0.3s linear 0s;
}

.js-darkmode-btn ion-icon {
  color: gold;
  font-size: 14px;
}

.js-darkmode-btn input {
  display: none;
}

/**=== Footer Dark Logo ===**/
body:not(.body_dark) img.logo-dark {
  display: none;
}

body.body_dark img.logo-dark {
  display: block;
}

body.body_dark img.logo-light {
  display: none;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.body_dark {
  /** === Focus, active color === **/
  /** !-- End Focus, active color **/
  /** === Border color === **/
  /** !-- End Border color **/
}

.body_dark.onepage-doc .nav-sidebar .nav-item .docs-progress-bar,
.body_dark .fontsize-controllers .btn-group .btn:focus,
.body_dark .doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a.active,
.body_dark .doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu li a:hover {
  background: #444857;
}

.body_dark.onepage-doc .nav-sidebar .nav-item,
.body_dark .doc_rightsidebar .bootstrap-select>.dropdown-menu,
.body_dark .doc_rightsidebar .dropdown-toggle,
.body_dark .fontsize-controllers .btn-group,
.body_dark.onepage-doc .nav-sidebar .nav-item .dropdown_nav,
.body_dark .doc_rightsidebar .doc_switch input[type=checkbox],
.body_dark .doc_rightsidebar .pageSideSection::before,
.body_dark .doc_rightsidebar .bootstrap-select>.dropdown-menu .inner .dropdown-menu {
  background: #2c303a;
}

.body_dark .side_menu,
.body_dark .doc_left_sidebarlist:before {
  background: #1e1f26;
}

.body_dark .doc_rightsidebar .doc_switch input[type=checkbox],
.body_dark .topic_item_tabs .topic_list_item,
.body_dark .comment_box .post_comment .comment_author .media-body,
.body_dark .doc_rightsidebar .dropdown-toggle,
.body_dark .fontsize-controllers .btn-group .btn.rvfs-reset,
.body_dark .fontsize-controllers .btn-group,
.body_dark .doc_left_sidebarlist:before,
.body_dark .doc_left_sidebarlist,
.body_dark .nav-sidebar+.nav-sidebar,
.body_dark .doc_rightsidebar,
.body_dark .v_menu,
.body_dark .toggle_body,
.body_dark .basic_table_info,
.body_dark .basic_table_info tbody tr th,
.body_dark .basic_table_info tbody tr td,
.body_dark .typography_content .code-preview,
.body_dark .doc_left_sidebarlist:before {
  border-color: #2c303a;
}

.body_dark .blog_comment_box.topic_comment .get_quote_form .form-group input:focus~.floating-label,
.body_dark .blog_comment_box.topic_comment .get_quote_form .form-group textarea:focus~.floating-label,
.body_dark .blog_comment_box.topic_comment .get_quote_form .form-group input:not(:focus):valid~.floating-label,
.body_dark .blog_comment_box.topic_comment .get_quote_form .form-group .form-control {
  background: #252830;
  border-color: #252830;
}

.body_dark.onepage-doc .nav-sidebar .nav-item .dropdown_nav:before {
  background: #272830;
}

.body_dark .nav-sidebar .nav-item .nav-link {
  color: #c7c9d3;
}

.body_dark .nav-sidebar .nav-item .nav-link.active,
.body_dark .nav-sidebar .nav-item .nav-link:hover {
  color: var(--brand_color);
}

.body_dark .nav-sidebar .nav-item .dropdown_nav li a {
  color: #bbb;
}

.body_dark .doc_menu .nav-link,
.body_dark .doc_rightsidebar .doc_right_link li a {
  color: #bbb;
}

.body_dark .doc_menu .nav-link.active,
.body_dark .doc_menu .nav-link:hover,
.body_dark .side_menu .mobile_menu_header .close_nav {
  color: #fff;
}

.body_dark .documentation_item .icon {
  background: rgba(255, 255, 255, 0.2);
}

.body_dark .fontsize-controllers .btn-group .btn,
.body_dark .doc_rightsidebar .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  color: #c7c9d3;
}

.body_dark .border_bottom {
  background: #384c59;
}

.body_dark .link,
.body_dark .version,
.body_dark .question_box .signup_form .input-group,
.body_dark .tab_shortcode .tab-content {
  border: 1px solid #384c59;
  background: #1f3341;
}

.body_dark .tab_shortcode .nav-tabs .nav-item .nav-link {
  background: #1f3341;
  border-color: #384c59;
}

.body_dark .question_box .signup_form .input-group .form-control {
  color: #6b707f;
}

.body_dark .question_box .signup_form .input-group button:before {
  background: #384c59;
}

.body_dark .search-banner-light .header_search_keyword .header-search-form__keywords-label,
.body_dark .search-banner-light .header_search_keyword ul li a,
.body_dark .get_started p {
  color: #d3d4d4;
}

.body_dark .f_bg_color,
.body_dark .page_breadcrumb,
.body_dark .toggle_btn,
.body_dark .doc_accordion .card-header button {
  background: #1f3341;
}

.body_dark .shortcode_text ul li a,
.body_dark .dark_menu .menu>.nav-item>.nav-link,
.body_dark .tab_shortcode .nav-tabs .nav-item .nav-link.active,
.body_dark .page_breadcrumb .breadcrumb .breadcrumb-item.active,
.body_dark .breadcrumb-item+.breadcrumb-item::before,
.body_dark .toggle_btn.collapsed:before,
.body_dark .toggle_btn.collapsed,
.body_dark .doc_accordion .card-header button,
.body_dark .table {
  color: #fff;
}

.body_dark .toggle_body,
.body_dark .doc_accordion,
.body_dark .message_alert {
  background: #061f2f;
}

.body_dark .toggle_btn {
  color: var(--brand_color);
}

.body_dark .menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link h5 {
  color: #1d2746;
}

.body_dark .card_tagged li a {
  color: #e3e4e8;
  background: #444857;
}

.body_dark .card_tagged li a:hover {
  opacity: 0.9;
}

.body_dark .data_table_area .table.dataTable thead tr {
  background: #2f3337;
}

/*---------------------------------------------------- */

/*# sourceMappingURL=style-main.css.map */