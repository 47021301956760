/*=============  doc_banner_area css  =============*/
.doc_banner_area {
  background: #3636cd;
  text-align: center;
  padding: 290px 0 200px;
  position: relative;
}

.banner_shap_img {
  li {
    position: absolute;
    z-index: 0;
    &:nth-child(1),
    &:nth-child(2) {
      top: 0;
      left: 0;
    }
    &:nth-child(3),
    &:nth-child(4) {
      bottom: 0;
      right: 0;
    }
    &:nth-child(5) {
      left: 145px;
      top: 44%;
    }
    &:nth-child(6) {
      top: 50%;
      right: 375px;
    }
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      border-radius: 50%;
      background: #fff;
      opacity: 0.14;
    }
    &:nth-child(7) {
      width: 16px;
      height: 16px;
      left: 435px;
      bottom: 265px;
    }
    &:nth-child(8) {
      width: 30px;
      height: 30px;
      right: 135px;
      top: 255px;
    }
    &:nth-child(9) {
      width: 20px;
      height: 20px;
      right: 225px;
      opacity: 1;
      bottom: 115px;
    }
  }
}

.banner_creative1 .doc_banner_content h2 {
  color: #fff;
}

.doc_banner_content {
  position: relative;
  h2 {
    font-size: 50px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 12px;
  }
  p {
    font-size: 20px;
    color: #fff;
    font-weight: 300;
  }
}
.header_search_form {
  max-width: 720px;
  margin: 57px auto 0;
  .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .input-wrapper {
    position: relative;
    flex: 1;
    max-width: 570px;
    margin-right: 10px;
    i {
      position: absolute;
      left: 25px;
      font-size: 16px;
      color: #b6bac3;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
    }
    input {
      background: #fff;
      border: 0;
      height: 60px;
      font-size: 16px;
      padding-left: 52px;
      width: 100%;
      border-radius: 45px;
      padding-right: 155px;
      @include placeholder {
        color: #848996;
      }
    }
  }
  .custom-select {
    height: 60px;
    line-height: 60px;
    font-weight: 500;
    color: var(--black_800);
    padding: 0 65px 0 25px;
    background: var(--black_25);
    float: none;
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    &:after {
      border-bottom: 1px solid $black_800;
      border-right: 1px solid $black_800;
    }
    li {
      font-size: 14px;
      color: #1d2746;
      line-height: 40px;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .header_form_submit {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #0d0d75;
    padding: 10px 48px;
    border-radius: 45px;
    border: 0;
    box-shadow: 0 10px 20px 0 rgba(2, 2, 59, 0.2);
    transition: all 0.2s linear;
    &:hover {
      background: #fff;
      color: #0d0d75;
    }
  }
}

.header_search_form_info {
  position: relative;
  .header_search_form_panel {
    border: 1px solid $black_50;
    box-shadow: 0 3px 4px 0 rgba(4, 73, 89, 0.06);
    background-color: var(--bs-white);
    border-radius: 3px;
    display: none;
    position: absolute;
    top: 115%;
    left: 0;
    width: 100%;
    text-align: left;
    z-index: 99;
    padding: 30px;
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--bs-white);
      position: absolute;
      top: -6px;
      transform: rotate(45deg);
      left: 40px;
      border-left: 1px solid $black_50;
      border-top: 1px solid $black_50;
    }
    ul {
      margin-bottom: 0;
      > li {
        font-size: 16px;
        color: $black_800;
        .search_item {
          border-top: 1px solid var(--black_50);
          padding: 10px 0 5px;
          margin-top: 8px;
          li {
            color: $black_600;
            font-weight: 400;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border: 0;
            span {
              width: 165px;
              text-align: right;
              border-right: 1px solid var(--black_50);
              line-height: 32px;
              padding-right: 15px;
            }
            > a {
              line-height: 26px;
              font-weight: 400;
              color: $black_400;
              padding: 3px 22px;
              flex: 1;
              display: block;
              position: relative;
              &::before {
                content: "";
                width: 2px;
                height: 0;
                position: absolute;
                left: -1px;
                top: 0;
                background: #4a4ad2;
                opacity: 0;
                transition: all 0.2s linear;
              }
              &:hover {
                background: $black_25;
                &:before {
                  opacity: 1;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .custom-select ul li:hover, .custom-select ul li.selected {
    color: var(--brand_color);
  }
  .custom-select ul li:before {
    background: var(--brand_color);
  }
}

.header_search_keyword {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  span {
    font-size: 14px;
    color: #fff;
    margin-right: 10px;
  }
  ul {
    margin-bottom: 0;
    li {
      display: inline-block;
      margin-right: 7px;
      a {
        display: block;
        font-size: 13px;
        padding: 0 19px;
        border-radius: 45px;
        background: #4a4ad2;
        color: #fff;
      }
    }
  }
}
/*=============  doc_banner_area css  =============*/

/*============== doc_features_area css  ===========*/
.doc_features_area {
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  background: #fbfbfc;
  .doc_features_shap {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
  }
}
.doc_features_inner {
  overflow: hidden;
  box-shadow: 0 30px 60px 0 rgba(2, 2, 48, 0.08);
  border-radius: 10px;
  background: $body_bg;
  top: -60px;
  position: relative;
  .see_more_item {
    display: none;
    flex-wrap: wrap;
    width: 100%;
  }
}

.see_btn {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--brand_color);
  display: inline-block;
  margin: 16px 50px 12px;
  i {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -5px;
    display: inline-block;
  }
}

.doc_features_item {
  width: calc(100% / 3);
  float: left;
  padding: 35px 30px;
  border-bottom: 1px solid var(--border_on_highlight);
  border-left: 1px solid var(--border_on_highlight);
  img {
    margin-right: 15px;
  }
  h4 {
    font-size: 16px;
    line-height: 26px;
    color: $black_800;
    font-family: $rob;
    font-weight: 700;
    margin-bottom: 2px;
    &:hover {
      color: var(--brand_color);
    }
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    color: #425466;
  }
  &:nth-child(1),
  &:nth-child(4),
  &:nth-child(7),
  &:nth-child(10) {
    border-left: 0;
  }
}
/*============== doc_features_area css  ===========*/

/*============== recommended_topic_area css  ===========*/
.recommended_topic_inner {
  position: relative;
  z-index: 1;
  padding: 100px 0;
}
.doc_shap_one,
.doc_round {
  position: absolute;
  z-index: -1;
}
.doc_round {
  border-radius: 50%;
  &.one {
    width: 260px;
    height: 260px;
    background: #f9bf44;
    bottom: 120px;
    left: 120px;
  }
  &.two {
    background: #5e44ff;
    width: 100px;
    height: 100px;
    top: 160px;
    right: 70px;
  }
}
.doc_shap_one {
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
.doc_title {
  margin-bottom: 76px;
  h2 {
    font-size: 34px;
    font-weight: 700;
    color: $black_800;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
    color: #6B707F;
  }
}
.recommended_item {
  border-radius: 20px;
  box-shadow: 0 30px 50px 0 rgba(2, 2, 48, 0.06);
  padding: 40px 25px 30px 35px;
  margin-bottom: 30px;
  background: $body_bg;
  img {
    margin-bottom: 25px;
    max-width: 44px;
  }
  h3 {
    font-size: 18px;
    color: $black_700;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 18px;
    transition: color 0.2s linear;
    &:hover {
      color: var(--brand_color);
    }
  }
  ul {
    li {
      padding-left: 20px;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      &:before {
        content: "\35";
        font-family: eleganticons;
        position: absolute;
        left: -5px;
        width: auto;
        font-size: 18px;
        color: var(--brand_color);
        top: -2px;
      }
      a {
        color: $p-color;
        &:hover {
          color: var(--brand_color) !important;
        }
      }
      & + li {
        margin-top: 15px;
      }
    }
  }
}
.question_text {
  font-size: 16px;
  line-height: 24px;
  color: var(--brand_color);
  font-weight: 500;
  margin-top: 35px;
  display: inline-block;
  &:hover {
    color: var(--brand_color);
  }
}
/*============== recommended_topic_area css  ===========*/

/*============= doc_community_area css  ================*/
.doc_community_area {
  padding: 200px 0 75px;
  position: relative;
  .shap_one,
  .shap_two {
    position: absolute;
    z-index: -1;
  }
  .shap_one {
    bottom: 0;
  }
  .shap_two {
    top: 0;
  }
  .doc_title {
    margin-bottom: 64px;
  }
}
.doc_community_info {
  .question_text {
    color: #a7acb7;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 40px;
    &:hover {
      color: var(--brand_color);
    }
  }
}
.doc_community_item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  background: var(--bs-white);
  box-shadow: 0 20px 30px 0 rgba(2, 2, 48, 0.06);
  margin-bottom: 20px;
  padding: 38px 70px;
  transition: all 0.2s linear;
  cursor: pointer;
  .doc_community_icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
    font-size: 36px;
    background: rgba(76, 76, 241, 0.06);
    color: var(--brand_color);
    margin-right: 50px;
  }
  h4 {
    font-size: 16px;
    color: $black_800;
    font-weight: 700;
    margin-bottom: 5px;
    transition: color 0.2s linear;
    &:hover {
      color: var(--brand_color);
    }
  }
  p {
    font-size: 14px;
    color: #6b707f;
  }
  .doc_entry_info {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    .author_avatar {
      display: flex;
      margin-bottom: 0;
      margin-right: 15px;
      li {
        width: 36px;
        height: 36px;
        line-height: 34px;
        border-radius: 50%;
        border: 2px solid var(--black_50);
        font-size: 14px;
        font-weight: 500;
        color: #232361;
        text-align: center;
        z-index: 1;
        position: relative;
        img {
          max-width: 100%;
        }
        & + li {
          margin-left: -10px;
        }
        &:nth-child(even) {
          z-index: 0;
        }
        &:last-child {
          border-color: var(--black_100);
        }
      }
    }
    .text {
      font-size: 14px;
      line-height: 1.3;
      color: #90939b;
    }
  }
  &:hover {
    box-shadow: 0 10px 30px 0 rgba(2, 2, 48, 0.02);
  }
  &:nth-child(2) {
    .doc_community_icon {
      background: #fef9ee;
      color: #f7b126;
    }
  }
  &:nth-child(3) {
    .doc_community_icon {
      background: #f1fbf8;
      color: #0fbc8b;
    }
  }
}
/*============= doc_community_area css  ================*/

/*============= doc_testimonial_area css  ================*/

.doc_testimonial_area {
  padding: 160px 0 135px;
}
.doc_testimonial_slider {
  padding-right: 100px;
  .item {
    padding-left: 15px;
    position: relative;
    padding-top: 10px;
    &:before {
      content: "“";
      font-family: $rob;
      font-weight: 400;
      font-size: 150px;
      color: var(--brand_color);
      opacity: 0.1;
      position: absolute;
      left: -5px;
      width: 38px;
      height: 38px;
      line-height: 130px;
      top: 0;
      z-index: 1;
    }
    h3 {
      font-size: 34px;
      line-height: 52px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    .name {
      font-size: 14px;
      font-weight: 700;
      color: #232361;
      margin-bottom: 5px;
      span {
        font-weight: 400;
        color: $black_500;
      }
    }
    .sign {
      display: inline-block;
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    text-align: left;
    margin-top: 30px;
    li {
      width: auto;
      height: auto;
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #dbdbfc;
        transition: all 0.2s;
        &:before {
          display: none;
        }
      }
      &:nth-child(even) {
        button {
          background: #a5a5f8;
        }
      }
      &.slick-active {
        button {
          background: var(--brand_color);
        }
      }
    }
  }
}
.doc_img_slider {
  .item {
    text-align: center;
    position: relative;
    padding-bottom: 25px;
    z-index: 1;
    img {
      display: inline-block;
      border-radius: 20px;
    }
    .dot {
      border-radius: 0;
      position: absolute;
      left: 60px;
      bottom: 0;
      z-index: -1;
    }
    .round {
      border-radius: 50%;
      position: absolute;
      z-index: -1;
      &.one {
        width: 150px;
        height: 150px;
        right: 60px;
        top: 60px;
        background-image: linear-gradient(
          50deg,
          rgb(87, 54, 254) 0%,
          rgb(253, 64, 110) 100%
        );
        opacity: 0.1;
      }
      &.two {
        width: 280px;
        height: 280px;
        right: 0;
        top: 50%;
        margin-top: -140px;
        background-image: linear-gradient(
          50deg,
          rgb(87, 54, 254) 0%,
          rgb(253, 64, 110) 100%
        );
      }
    }
    &.slick-active {
      .one {
        animation: zoomIn 0.8s linear both 0.4s;
      }
      .two {
        animation: zoomIn 0.8s linear both 0.5s;
      }
    }
  }
}
/*============= doc_testimonial_area css  ================*/
.doc_subs_full {
  .doc_subscribe_inner {
    border-radius: 0;
    box-shadow: none;
  }
}
.doc_subscribe_inner {
  border-radius: 20px;
  background-color: rgb(76, 76, 241);
  box-shadow: 0 50px 80px 0 rgba(3, 3, 125, 0.2);
  display: flex;
  flex-wrap: wrap;
  padding: 150px 70px;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .one,
  .two {
    position: absolute;
    z-index: 0;
    &.one {
      top: 0;
      left: 0;
    }
    &.two {
      right: 0;
      bottom: 0;
    }
  }
  h2 {
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .text,
  .doc_subscribe_form {
    width: 50%;
  }
  .doc_subscribe_form {
    padding-left: 30px;
    position: relative;
    .form-group {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
    }
    .input-fill {
      position: relative;
      flex: 1;
      &:before {
        content: "\55";
        position: absolute;
        font-family: eleganticons;
        font-size: 18px;
        margin-right: 10px;
        top: 10px;
        left: 22px;
        transform: rotate(-90deg);
        display: inline-block;
        color: var(--black_400);
      }
      input {
        height: 50px;
        border: 0;
        border-radius: 45px;
        line-height: 51px;
        font-size: 14px;
        width: 100%;
        padding-left: 50px;
        @include placeholder {
          color: #848996;
        }
      }
    }
    .submit_btn {
      border-radius: 45px;
      border: 0;
      font-size: 14px;
      font-weight: 500;
      background: #0d0d75;
      padding: 10px 30px;
      color: #fff;
      margin-left: 10px;
      transition: background 0.4s linear, color 0.3s;
      &:hover {
        background: #fff;
        color: #0d0d75;
      }
    }
    ul {
      margin-bottom: 0;
      li {
        margin-right: 22px;
        display: inline-block;
        line-height: 18px;
        a {
          font-size: 13px;
          color: #d3d3f4;
          position: relative;
          padding-left: 20px;
          &:before {
            content: "\4e";
            font-family: eleganticons;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 14px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          a {
            color: #fff;
          }
        }
      }
    }
    p {
      color: #fff;
      width: 100%;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

/*=============== doc_footer_area css  ==============*/
.doc_footer_top {
  padding: 100px 0 90px;
  background: #f9f9f9;
}
.pl-30 {
  padding-left: 30px;
}
.pl-100 {
  padding-left: 100px;
}
.pl-70 {
  padding-left: 70px;
}
.f_title_two {
  font-size: 18px;
  color: $black_800;
  margin-bottom: 28px;
}
.doc_about_widget {
  padding-right: 128px;
  p {
    font-size: 16px;
    line-height: 30px;
    color: #6b707f;
    font-weight: 500;
    margin-top: 15px;
  }
  ul {
    margin-bottom: 0;
    margin-top: 40px;
    li {
      display: inline-block;
      margin-right: 5px;
      a {
        width: 44px;
        height: 44px;
        display: block;
        text-align: center;
        color: var(--black_500);
        font-size: 14px;
        line-height: 45px;
        border-radius: 10px;
        background: $black_50;
        transition: all 0.2s linear;
        &:hover {
          background: $black_100;
          color: var(--brand_color);
          text-decoration: none;
        }
      }
    }
  }
}
.doc_service_list_widget {
  padding-top: 5px;
  ul {
    margin-bottom: 0;
    li {
      a {
        font-size: 14px;
        font-weight: 500;
        color: $black_500;
        img {
          margin-right: 14px;
        }
        &:hover {
          color: var(--brand_color);
        }
      }
      & + li {
        margin-top: 10px;
      }
    }
  }
}
.doc_footer_bottom {
  border-top: 1px solid #f0f0f7;
  padding: 30px 0 30px;
  p {
    margin-bottom: 0;
    font-size: 14px;
    color: #6b707f;
    span {
      color: var(--brand_color);
    }
  }
}
.doc_footer_menu {
  margin-bottom: 0;
  li {
    display: inline-block;
    margin-right: 30px;
    a {
      font-size: 14px;
      color: #6b707f;
      transition: all 0.2s;
      &:hover {
        color: var(--brand_color);
      }
    }
  }
}
/*=============== doc_footer_area css  ==============*/

input, textarea {
  color: var(--black_400);
  background-color: var(--bs-white);
}
