html {
  height: 100%;
}
a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: var(--brand_color);
  }
}

body {
  line-height: 28px;
  font-size: 16px;
  height: 100%;
  font-family: $rob;
  font-weight: 400;
  color: $p_color;
  letter-spacing: 0.15px;
}
p{
  color: $p_color;
}
:focus {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  font-family: $rob;
  color: $black_800;
}
a {
  color: var(--brand_color);
}
figure {
  padding-top: 5px;
  padding-bottom: 5px;
  clear: both;
  border: none;
}
figure figcaption {
  color: rgba(0, 0, 0, 0.54);
  line-height: 20px;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  max-width: 728px;
  margin-right: auto;
}
video {
  max-width: 100%;
}

table tr td p:last-child {
  margin-bottom: 0;
}

pre[class*="language-"]>code {
  border-left: 5px solid brand_color_rgba(0.8);
}

.pt_100 {
  padding-top: 100px;
}
.pb_100 {
  padding-bottom: 100px;
}

/** === Scrollbar === **/
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 30px;
}

/** === Predefined Global Classes === **/
.h1 {
  font-size: 50px;
}
.h2 {
  font-size: 44px;
}
.h3 {
  font-size: 36px;
}
.h4 {
  font-size: 30px;
}
.h5 {
  font-size: 24px;
}
.h6 {
  font-size: 18px;
}
.bold {
  font-weight: 700;
}
.medium {
  font-weight: 500;
}
.regular {
  font-weight: 400;
}

.shortcode_info h1,
.shortcode_info h2,
.shortcode_info h3,
.shortcode_info h4,
.shortcode_info h5,
.shortcode_info h6,
.blog_single_item h1,
.blog_single_item h2,
.blog_single_item h3,
.blog_single_item h4,
.blog_single_item h5,
.blog_single_item h6 {
  margin-bottom: 30px;
}

button:focus {
  outline: none;
}

/** === Container === **/
.body_wrapper {
  position: relative;
  background: #fff;
  z-index: 30;
  overflow: hidden;
  min-height: 100vh;
}

.wide-container .container {
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  @media (min-width: 1300px) {
    max-width: 1240px;
  }
  @media (min-width: 1500px) {
    max-width: 1470px;
  }
}

.container {
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
}

.doc_community_area .container {
  @media (min-width: 1200px) {
    max-width: 960px;
  }
}

.forum_container {
  @media (min-width: 991px) {
    max-width: 1830px;
  }
}

.sec_pad {
  padding: 100px 0;
}

.pl-40 {
  padding-left: 40px;
}
.pl-60 {
  padding-left: 60px;
}
.pr-60 {
  padding-right: 60px;
}
.p_125 {
  padding-top: 125px;
  padding-bottom: 125px;
}
.pt_125 {
  padding-top: 125px;
}
.pb_125 {
  padding-bottom: 125px;
}
.mt-40 {
  margin-top: 40px;
}

/** === List style === **/
ul.list-style {
  margin-bottom: 2rem;
}
ul.list-style li {
  margin-bottom: 0.5rem;
  list-style-type: circle;
}
ul.list-style li:last-child {
  margin-bottom: 0;
}

/** === Key === **/
.key,
kbd {
  line-height: 0.95rem;
  border: 1px solid #ddd;
  color: #6b707f;
}
.key,
kbd,
p .key,
p kbd {
  display: inline;
  display: inline-block;
  min-width: 1em;
  padding: 0.5em 0.6em 0.4em;
  margin: 2px;
  font-weight: 400;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-decoration: none;
  line-height: 0.6rem;
  -moz-border-radius: 0.3em;
  -webkit-border-radius: 0.3em;
  border-radius: 0.3em;
  cursor: default;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 1px solid #ddd;
}

/** ===== section title scss ====== **/
.section_title {
  margin-bottom: 56px;
  p {
    max-width: 530px;
    margin: 10px auto 0;
  }
  &.text-left {
    p {
      max-width: inherit;
    }
  }

  &.title-img {
    margin-bottom: 76px;
    .title-lg {
      font-size: 44px;
      line-height: 1.1;
      color: #1d2746;
      margin-bottom: 30px;
      font-weight: 400;
    }

    .action_btn {
      padding: 9px 34px;
      box-shadow: none;
      border: 2px solid var(--brand_color);
      font-weight: 500;

      &:hover {
        background: transparent;
        color: var(--brand_color);
      }
    }
  }
}
.h_title {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
  font-weight: 700;
  position: relative;

  @media (max-width: 440px) {
    br {
      display: none;
    }
  }
}
.c_head {
  font-size: 24px;
  line-height: 26px;
}
/*===== section title scss ======*/
.bg_color {
  background: var(--black_25);
}
.f_bg_color {
  background: #f6fbfc;
}

.local-video-container {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: content-box;
}

/** === Back to Top Button === **/
#back-to-top {
  display: inline-block;
  background-color: rgba(220, 220, 220, 0.8);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#back-to-top::after {
  content: "\32";
  font-family: eleganticons;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #000;
}
#back-to-top:hover {
  cursor: pointer;
  background-color: #333;
}
#back-to-top:hover::after {
  color: #fff;
}
#back-to-top:active {
  background-color: #555;
}
#back-to-top.show {
  opacity: 1;
  visibility: visible;
}

.search-focused {
  .click_capture {
    opacity: 1;
    visibility: visible;
  }
  .banner_search_form {
    position: relative;
    z-index: 999 !important;
  }
}
@media (min-width: 1500px) {
  .custom_container2 {
    max-width: 1470px;
  }
}
.box_pad {
  max-width: 100%;
  padding: 0 60px;
}
.visible {
  opacity: 1 !important;
}

/* Title Area css
============================================================================================ */
.main_title {
  margin-bottom: 60px;
  h2 {
    font-size: 30px;
    color: $h_title;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    margin-top: 12px;
    color: #6b707f;
  }
}
/* End Title Area css
============================================================================================ */
