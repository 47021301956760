/*============== Start doc_tag_area css =============*/
.doc_tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: 0;
    margin-bottom: 75px;

    .nav-item {
        margin-bottom: 0;

        .nav-link {
            font-size: 14px;
            font-weight: 400;
            color: var(--brand_color);
            border: 1px solid brand_color_rgba(.2);
            border-radius: 4px;
            background-color: rgba(16, 179, 214, 0.031);
            padding: 0 20px;
            line-height: 28px;
            margin: 0 5px;
            transition: all 0.4s linear;

            &:hover,
            &.active {
                background-color: var(--brand_color);
                border-color: var(--brand_color);
                box-shadow: 0 10px 20px 0 brand_color_rgba(0.2);
                color: #fff;
            }
        }
    }
}

.doc_tag_title {
    margin-bottom: 45px;

    h4 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .line {
        height: 2px;
        width: 100%;
        background: $black_50;
        display: block;
    }
}

.doc_tab_pane {
    .row {
        margin-bottom: -85px;
    }
}

.doc_tag_item {
    margin-bottom: 85px;
}

.tag_list {
    margin-bottom: 32px;

    li {
        margin-top: 15px;

        a {
            font-size: 16px;
            color: $p_color;
            line-height: 22px;
            transition: color 0.2s linear;
            display: flex;

            i {
                margin-right: 15px;
            }

            &:hover {
                color: var(--brand_color);
            }
        }
    }
}

/*============== End doc_tag_area css =============*/

/*============== Start documentation_area css =============*/
.documentation_tab {
    border-bottom: 2px solid var(--black_50);

    .nav-item {
        margin-bottom: 0;

        .nav-link {
            border-radius: 0;
            border: 0;
            color: $p_color;
            font-size: 14px;
            font-weight: 500;
            padding: 0 20px 10px;
            position: relative;
            transition: color 0.2s linear;
            background: transparent;

            &:before {
                content: "";
                width: 0;
                height: 2px;
                position: absolute;
                bottom: -2px;
                left: auto;
                right: 0;
                background: var(--brand_color);
                transition: width 0.3s linear;
            }

            &.active,
            &:hover {
                color: var(--brand_color);

                &:before {
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}

.documentation_tab_pane {
    padding-top: 84px;

    .row {
        margin-bottom: -26px;
    }
}

.documentation_text {
    .round {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgb(15, 204, 208);
        box-shadow: 0 20px 30px 0 rgba(24, 211, 214, 0.2);
        display: block;
        text-align: center;
        line-height: 100px;
        margin-bottom: 25px;
    }

    h4 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 18px;
    }

    p {
        margin-bottom: 0;
    }

    .learn_btn {
        padding-top: 20px;
    }
}

.documentation_item {
    padding-right: 40px;
    margin-bottom: 58px;

    .icon {
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 10px 30px 0 rgba(4, 73, 89, 0.12);
        width: 70px;
        height: 70px;
        text-align: center;
        line-height: 66px;
        margin-right: 30px;
    }

    .media-body {
        h5 {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 12px;
            transition: color 0.2s linear;

            &:hover {
                color: var(--brand_color);
            }
        }

        p {
            margin-bottom: 0;
            line-height: 24px;
        }
    }
}

/*============== End documentation_area css =============*/

/*============== Start doc_faq_area css =============*/
.doc_faq_area {
    .doc_tag {
        margin-bottom: 50px;
    }
}

.faq_tab_pane {}

.doc_faq_info {
    .card {
        border-radius: 4px;
        background: #fff;
        box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.1);
        border: 0;
        margin-bottom: 30px;

        .card-header {
            padding: 0;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: 0;

            h2 {
                button {
                    display: block;
                    text-align: left;
                    width: 100%;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                    color: var(--brand_color);
                    text-decoration: none;
                    padding: 19px 40px 19px 30px;
                    position: relative;

                    i {
                        position: absolute;
                        right: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 20px;
                        display: none;

                        &+i {
                            display: block;
                        }
                    }

                    &.collapsed {
                        color: $h_title;

                        i {
                            display: block;

                            &+i {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .card-body {
            border: 0;
            line-height: 24px;
            padding: 0 30px 35px;
        }

        &.active {
            position: relative;
            z-index: 1;
        }
    }
}

/*============== End doc_faq_area css =============*/

/*============== Start doc_categories_guide_area css =============*/
.doc_categories_guide_area {
    position: relative;
    z-index: 1;

    .shap {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 490px;
        z-index: -1;
        background-size: cover;
    }
}

.categories_guide_item {
    border-radius: 6px;
    background: var(--bs-white);
    box-shadow: 0 50px 120px 0 rgba(4, 73, 89, 0.08);
    padding: 40px 40px 50px;
    margin-bottom: 30px;

    img {
        margin-bottom: 34px;
    }

    .doc_tag_title {
        margin-bottom: 24px;

        h4 {
            margin-bottom: 0;
        }
    }

    .tag_list {
        margin-bottom: 42px;
    }

}

.all_doc_btn {
    padding: 10px 40px;
    margin-top: 30px;
}

/*============== End doc_categories_guide_area css =============*/

/*============== Start doc_solution_area css =============*/
.doc_solution_area {
    .all_doc_btn {
        padding: 20px 73px;
        font-size: 18px;

        i {
            font-size: 25px;
            margin-left: 10px;
        }
    }
}

.doc_solution_item {
    text-align: center;
    padding: 60px;
    margin-bottom: 30px;

    img {
        margin-bottom: 45px;
    }

    h4 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 20px;
    }

    &.online_doc {
        background: #fcf3da;
    }

    &.premium_doc {
        background: #daecfc;
    }
}

/*============== End doc_solution_area css =============*/

/*============== Start doc_fun_fact_area css =============*/
.doc_fun_fact_area {
    background: var(--brand_color);
    padding: 105px 0;
    position: relative;
    z-index: 1;
}

.animated-wave {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.doc_fact_item {
    text-align: center;

    .counter {
        color: #fff;
        font-size: 50px;
        line-height: 60px;
        font-weight: 700;
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
        color: #fff;
        padding-top: 5px;
    }
}



/* Fun Fact Style Two */

.active-animation {
    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        animation: dash 5s ease-in-out forwards;
    }
}


@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}


.funfact-area {
    padding: 115px 0 90px;
    background-image: linear-gradient( 45deg, rgb(245,253,255) 0%, rgb(254,239,236) 100%);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    .smile {

        position: absolute;
        width: 200px;
        height: 200px;
        right: 95px;
        top: -25px;

    }
}

.funfact-boxes {
    display: flex;
    justify-content: space-between;

    @media(max-width: 992px) {
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% + 30px);
    }

    @media(max-width: 420px) {
        width: calc(100% + 15px);

    }


    .funfact-box {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 50px 80px 0 rgba(2, 47, 57, 0.12);
        min-width: 140px;
        padding: 30px 25px 20px;
        margin-bottom: 30px;

        @media(max-width: 992px) {
            width: calc(33.33% - 30px);
            margin-right: 30px;
        }

        @media(max-width: 420px) {
            width: calc(50% - 15px);
            margin-right: 15px;
        }

        .fanfact-icon {
            margin-bottom: 20px;

            img {
                -webkit-filter: drop-shadow(0 20px 40px #086174);
                filter: drop-shadow(0 20px 40px #086174);
                // background-image: linear-gradient( 90deg, rgb(16,179,214) 0%, rgb(149,231,249) 100%);
            }
        }

        .counter {
            font-size: 34px;
            font-weight: 500;
            color: var(--brand_color);
            margin-bottom: 10px;
        }

        .title {
            color: #6b707f;
            font-size: 16px;
            margin: 0;
            line-height: 26px;
        }

        &.color-two {

            .fanfact-icon {
                img {
                    -webkit-filter: drop-shadow(0 20px 40px #734F08);
                    filter: drop-shadow(0 20px 40px #734F08);

                }
            }

            .counter {
                color: #fbb631;
            }
        }

        &.color-three {

            .fanfact-icon {
                img {
                    -webkit-filter: drop-shadow(0 20px 40px #73083F);
                    filter: drop-shadow(0 20px 40px #73083F);

                }
            }

            .counter {
                color: #fd5baf;
            }
        }

        &.color-four {

            .fanfact-icon {
                img {
                    -webkit-filter: drop-shadow(0 20px 40px #087358);
                    filter: drop-shadow(0 20px 40px #087358);

                }
            }

            .counter {
                color: #11c296;
            }
        }

        &.color-five {

            .fanfact-icon {
                img {
                    -webkit-filter: drop-shadow(0 20px 40px #2C0873);
                    filter: drop-shadow(0 20px 40px #2C0873);

                }
            }

            .counter {
                color: #9360fc;
            }
        }

    }
}




/*============== End doc_fun_fact_area css =============*/

/*============== Start theme_doc_area css =============*/
.theme_doc_area {
    .row {
        margin-bottom: -30px;
    }
}

.theme_doc_item {
    box-shadow: 0 4px 8px 0 rgba(4, 73, 89, 0.05);
    border-radius: 6px;
    background: #fff;
    padding: 30px;
    margin-bottom: 30px;
    transition: all 0.3s linear;
    cursor: pointer;

    img {
        margin-right: 30px;
    }

    .c_head {
        margin-bottom: 14px;
        transition: color 0.2s linear;

        &:hover {
            color: var(--brand_color);
        }
    }

    p {
        margin-bottom: 0;
    }

    &:hover {
        box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.08);
    }
}

/*============== End theme_doc_area css =============*/

/*============== End theme_doc_area css =============*/
.doc_action_info {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 50px 150px 0 rgba(4, 73, 89, 0.1);
    padding: 88px 70px;
    position: relative;
    z-index: 2;

    &.mt_130 {
        margin-bottom: -130px;
    }

    .media {
        img {
            margin-right: 30px;
        }

        .media-body {
            h2 {
                margin-bottom: 13px;
            }

            p {
                margin-bottom: 0;
                font-size: 18px;
            }
        }
    }

    .action_btn {
        box-shadow: none;

        &:hover {
            box-shadow: 0 20px 30px 0 brand_color_rgba(0.24);
            color: #fff;
        }
    }
}

/*============== End theme_doc_area css =============*/

/*============== Start doc_action_area_two css =============*/
.doc_action_area_three {
    background: #1d2746;
    padding: 140px 0;
    position: relative;
    z-index: 1;

    .shadows {
        left: 40%;
        transform: translateX(-50%);
        top: 0;
        z-index: -1;
    }

    .b_man {
        right: 100px;
        bottom: 0;

        @media (max-width:1400px) {
            right: 0;
        }
    }
}

.action_content {
    h2 {
        font-size: 36px;
        line-height: 48px;
        font-weight: 300;
        color: #fff;
        margin-bottom: 15px;
        margin-top: -10px;

        span {
            font-weight: 700;
        }
    }

    p {
        font-size: 18px;
        color: #aeb5ca;
        margin-bottom: 0;
    }
}

.action_subscribe_form {
    .form-group {
        position: relative;

        .form-control {
            height: 70px;
            line-height: 70px;

            @include placeholder {
                color: #a0a1a1;
            }
        }

        .s_btn {
            padding: 7px 29px;
            transition: all 0.2s linear;

            i {
                vertical-align: middle;
                font-size: 20px;
                transition: all 0.2s linear;
                display: inline-block;
            }

            &:hover {
                background: #1d2746;

                i {
                    transform: translateX(5px);
                }
            }
        }
    }

    .form-check {
        padding-left: 25px;

        input[type=checkbox] {
            position: relative;
            appearance: none;
            outline: none;
            border: none;
            cursor: pointer;
            outline-width: 0;
            height: auto;
            position: absolute;
            margin: 0;
            left: 0;
            top: 5px;

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid #aeb5ca;
                border-radius: 2px;
            }

            &:before {
                width: 14px;
                height: 14px;
            }

            &:after {
                content: "";
                display: block;
                width: 6px;
                height: 10px;
                border: solid var(--brand_color);
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                top: 0;
                left: 4px;
                opacity: 0;
            }

            &:checked {
                &:before {
                    border-color: var(--brand_color);
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        label {
            position: relative;
            font-size: 15px;
            color: #aeb5ca;
            font-weight: 500;
        }
    }
}

/*============== End doc_action_area_two css =============*/

/*=========== Start doc_faq_area_two ============*/
.doc_faq_area_two {}

.fact_navigation_info {
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 20px 40px 0 rgba(4, 73, 89, 0.1);
    padding: 40px 40px 28px;
}

.fact_navigation {
    border: 0;
    padding-top: 8px;
    margin: 0;

    .nav-item {
        display: block;
        width: 100%;
        padding: 12px 0;

        .nav-link {
            display: block;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
            color: rgba(16, 179, 214, 0.5);
            position: relative;
            padding-left: 28px;
            border: 0;
            border-radius: 0;
            transition: all 0.2s linear;

            &:before {
                content: "\35";
                font-family: eleganticons;
                font-size: 20px;
                position: absolute;
                right: 0;
            }

            i {
                position: absolute;
                left: 0;
                top: 2px;
            }

            &.active,
            &:hover {
                color: var(--brand_color);

                &:before {
                    color: var(--brand_color);
                }
            }
        }

        &+li {

            border-top: 1px solid #e6eeef;
        }
    }
}

/*=========== End doc_faq_area_two ============*/