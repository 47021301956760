/*========== shortcode_text css ==============*/
.shortcode_text {
  .shortcode_title {
    p {
      margin-bottom: 20px;
    }
  }
  ul {
    li {
      margin-bottom: 6px;
      a {
        color: #6b707f;
        &:hover {
          color: var(--brand_color);
        }
      }
    }
  }
  .c_head {
    margin-top: 30px;
  }
}
.article_list {
  margin-bottom: 30px;
}
.doc_documentation_area .shortcode_title p {
  color: $black_500;
}
/*============== shortcode_info css =========*/
.doc-middle-content {
  padding-left: 15px;
  padding-right: 60px;
  padding-top: 70px;
}
.full-width-doc .doc-middle-content {
  padding-left: 50px;
  padding-right: 50px;
}

body.doc.test.remove {
  .shortcode_info h4 {
    padding-top: 100px;
    margin-top: -100px;
  }
}

.shortcode_info {
    h4 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 25px;
      padding-top: 40px;
      margin-top: -40px;
    }

    h6 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 30px;
      color: $p_color;
    }

    footer {
      padding-top: 25px;
    }
}

.tab_shortcode {
  margin-bottom: 50px;
  .nav-tabs {
    border: 0;
    .nav-item {
      .nav-link {
        font-size: 16px;
        color: $p_color;
        border-radius: 0;
        padding: 9px 20px;
        background: #f0f2f5;
        border: 0;
        border-left: 1px solid #dbe1e4;
        position: relative;
        &:before {
          content: "";
          width: 0;
          height: 2.1px;
          background: var(--brand_color);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: width 0.2s linear;
        }
        &.active {
          background: #fff;
          color: $h_title;
          &:before {
            opacity: 1;
            width: 100%;
          }
        }
      }
      &:last-child {
        .nav-link {
          border-right: 1px solid #dbe1e4;
        }
      }
    }
  }
  .tab-content {
    color: $p_color;
    padding: 25px 30px;
    border: 1px solid #dbe1e4;
    border-radius: 5px;
    border-top-left-radius: 0;
  }
}
/*============== shortcode_info css =========*/

/*=========== toggle_shortcode css ========*/
.toggle_shortcode {
  padding-bottom: 60px;
}
.toggle_btn {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: $h_title;
  background: #F2F6F7;
  text-align: left;
  border-radius: 4px;
  padding: 12px 30px;
  position: relative;
  &:before {
    content: "\43";
    position: absolute;
    font-size: 18px;
    font-family: eleganticons;
    right: 30px;
    transform: rotate(180deg);
    transition: all 0.2s linear;
    color: var(--brand_color);
  }
  &.collapsed {
    &:before {
      transform: rotate(0deg);
      color: $h_title;
    }
  }
  &:focus {
    color: $h_title;
  }
}
.toggle_body {
  border: 1px solid #edf1f7;
  padding: 18px 30px 24px 30px;
  border-radius: 0 0 4px 4px;
  line-height: 26px;
}
/*=========== toggle_shortcode css ========*/

/*=========== doc_accordion Shortcode css ========*/
.doc_accordion {
  border: 0;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  .card-header {
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 0;
    button {
      padding: 12px 30px;
      border-radius: 4px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      text-align: left;
      width: 100%;
      font-size: 16px;
      color: var(--brand_color);
      font-weight: 500;
      background: #F2F6F7;
      text-decoration: none;
      position: relative;
      svg {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        transition: all 0.2s linear;
        & + svg {
          display: block;
        }
      }
      &.collapsed {
        color: #202122;
        border-radius: 4px;
        svg {
          display: block;
          color: $p_color;
          & + svg {
            display: none;
          }
        }
      }
    }
  }
}
/*=========== doc_accordion Shortcode css ========*/

/*=========== message_alert Shortcode css ========*/
.message_alert {
  border: 1px solid #e5e9eb;
  border-radius: 4px;
  background: #fafcfd;
  padding: 32px 40px 30px;
  margin-bottom: 20px;
  i {
    font-size: 24px;
    color: #abb0c0;
    margin-right: 25px;
  }
  h5 {
    font-size: 16px;
    color: $h_title;
    font-weight: 500;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 0;
    color: $black_600;
  }
  .close {
    position: absolute;
    right: 14px;
    top: 12px;
    font-size: 24px;
    opacity: 1;
    i {
      margin-right: 0;
    }
  }
}
.alert-danger {
  background: #fff3f4;
  border-color: #fbadb3;
  i,
  h5 {
    color: #fa303e;
  }
}
.alert-success {
  background: #f1fdf3;
  border-color: #94e3a1;
  i,
  h5 {
    color: #10d631;
  }
}
.alert-warning {
  background: #fefbf1;
  border-color: #f2dca0;
  i,
  h5 {
    color: #f6ba18;
  }
}
.alert-info {
  background: #f1fbfd;
  border-color: #a0e3f2;
  i,
  h5 {
    color: var(--brand_color);
  }
}
/*=========== message_alert Shortcode css ========*/

/*=========== notice Shortcode css ========*/
.notice_shortcode {
  padding-top: 25px;
}
.notice {
  border-left: 10px solid;
  padding: 30px 40px;
  margin-bottom: 20px;
  margin-top: 0;
  &:before,
  &:after {
    display: none;
  }
  i {
    font-size: 24px;
    margin-right: 25px;
  }
  h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    color: #6b707f;
    span {
      padding: 1px 10px;
      background: #fff;
      color: #ed2937;
    }
  }
}
.notice-success {
  background: #459e6d;
  border-color: #30845e;
  p,
  i,
  h5 {
    color: #fff;
  }
}

.notice-warning {
  background: #fefaed;
  border-color: #f6ba18;
  i {
    color: #f6ba18;
    padding-top: 4px;
  }
}
.notice-danger {
  background: #ffeff0;
  border-color: #fa303e;
  i {
    color: #fa303e;
  }
}
.explanation {
  position: relative;
  //    background: (linear,left top,right top,from(#fff),to(#75e3fb));
  background: linear-gradient(90deg, #fff, #75e3fb);
  border: 10px solid #fff;
  padding: 1rem 2rem;
  border-radius: 16px;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 1px solid #e6edf0;
    border-radius: 8px;
    pointer-events: none;
  }
  &::after {
    font-family: "Roboto", sans-serif;
    content: "Hey!";
    text-transform: uppercase;
    font-weight: 700;
    top: -19px;
    left: 1rem;
    padding: 0 0.5rem;
    font-size: 0.6rem;
    position: absolute;
    z-index: 1;
    color: #000;
    background: #fff;
  }
}

/*=========== lightbox Shortcode css ========*/
.lightbox_shortcode {
  display: inline-block;
  position: relative;
  z-index: 1;
  img {
    background-color: rgb(240, 242, 245);
    box-shadow: 0 4px 14px 0 rgba(4, 73, 89, 0.08);
    max-width: 100%;
  }
}
.img_popup {
  position: absolute;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 52px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(29, 39, 70, 0.5);
  border-radius: 50%;
  color: #fff;
  font-size: 26px;
  i {
    margin-left: -2px;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: $h_title;
    box-shadow: 4px 15px 34px 2px rgba(4, 73, 89, 0.2);
  }
}

button.mfp-close {
  font-size: 30px;
  color: #6b707f;
  right: 10px;
  top: 5px;
}
.mfp-bg {
  background: $bs_white;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
  padding: 0;
}
.mfp-with-zoom .mfp-container {
  transform: scale(0);
}
.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
  transform: scale(1);
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 1;
}
.mfp-figure:after {
  background-color: rgb(236, 239, 244);
  box-shadow: 0 20px 80px 0 rgba(4, 73, 89, 0.12);
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
/*=========== lightbox Shortcode css ========*/

/*=========== tooltip Shortcode css ========*/
.tooltipster-sidetip {
  .tooltipster-box {
    max-width: 300px;
    background: #fff;
    box-shadow: 0 30px 90px -20px rgba(0, 0, 0, 0.3),
      0 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 0;
    margin-top: 0;
    .tooltipster-content {
      padding: 0;
      background: #fff;
      border-radius: 5px;
      z-index: 1;
      .blog_grid_post {
        margin-bottom: 0;
        img {
          max-width: 100%;
        }
        .grid_post_content {
          padding: 30px 20px 10px;
          box-shadow: none;
          .post_tag {
            a {
              font-size: 14px;
            }
          }
          .b_title {
            font-size: 18px;
            line-height: 25px;
          }
          p {
            color: $p_color;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.tooltip_content {
  p {
    margin-bottom: 25px;
    i {
      vertical-align: middle;
    }
    a {
      color: var(--brand_color);
    }
  }
  .text-decoration {
    position: relative;
    display: inline-block;
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: var(--brand_color);
      position: absolute;
      bottom: 5px;
      left: 0;
    }
  }
}

.direction_steps {
  display: inline-flex;
  align-items: center;
}
.direction_step {
  padding: 0 8px;
  font-size: 13px;
  border-radius: 4px;
  background: rgba(127, 130, 248, 0.8);
  color: #fff;
  position: relative;
  line-height: 20px;
  & + .direction_step {
    margin-left: 28px;
    &:before {
      content: "\24";
      font-family: eleganticons;
      position: absolute;
      left: -22px;
      color: rgba(127, 130, 248, 0.8);
    }
  }
}

.tip_content {
  background: #fff;
  box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.16);
  max-width: 320px;
  max-height: 100%;
  border-radius: 6px;
  text-align: left;
  .text {
    padding: 25px;
  }
  p {
    font-size: 14px;
    line-height: 26px;
    color: #6b707f;
    margin-bottom: 0;
  }
  .title {
    font-size: 14px;
    color: #1d2746;
    font-weight: 500;
    padding-top: 0;
    margin-bottom: 8px;
    span {
      display: block;
      font-weight: 400;
      color: #6b707f;
      font-size: 14px;
      padding-top: 5px;
    }
  }
  img {
    max-width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  &#tooltipTwo {
    min-height: 450px;
  }
}

/*=========== tooltip Shortcode css ========*/

/*=========== image_pointer Shortcode css ========*/
.image_pointer {
  .pointing_img {
    padding-bottom: 20px;
    img {
      max-width: 100%;
      box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.1);
    }
    h6 {
      margin-top: 35px;
      a {
        color: var(--brand_color);
      }
    }
  }
}
.modal-open {
  padding-right: 0 !important;
}
.modal-backdrop {
  background: #fff;
}
.img_modal {
  background: #fff;
  padding-right: 0 !important;
  box-shadow: 0 4px 14px 0 rgba(4, 73, 89, 0.08);
  img {
    box-shadow: 0 30px 80px 0 rgba(4, 73, 89, 0.12);
  }
  .close {
    font-size: 30px;
    padding-right: 25px;
    padding-top: 25px;
    opacity: 1;
    color: #6b707f;
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    z-index: 3;
  }
  &.fade {
    .pointing_img_container {
      max-width: 1170px;
      margin: 0rem auto;
      padding: 80px 0;
      height: 100%;
      display: flex;
      align-items: center;
      transform: translate(0, 0);
      .modal-content {
        border: 0;
        padding: 0;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
.img_pointing {
  width: 10px;
  height: 10px;
  background: #a54ffe;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    background: #a54ffe;
  }
  &:before {
    transform: scale(2.1);
    opacity: 0.3;
  }
  &:after {
    transform: scale(3);
    opacity: 0.2;
  }
  .dot {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgba(165, 79, 254, 0.9);
    animation: pulse1 3s infinite;
    transform: translate(-50%, -50%);
    animation-delay: 1.5s;
    will-change: transform;
  }
}
.img_pointing_content {
  padding: 25px;
  p {
    font-size: 14px;
    line-height: 22px;
    font-family: $rob;
    color: #6b707f;
    margin-bottom: 0;
    span {
      color: $h_title;
    }
    & + p {
      margin-top: 15px;
    }
  }
}

.pointing_img_two {
  position: relative;
  margin-bottom: 60px;
  img {
    box-shadow: 0 20px 50px 0 rgba(4, 73, 89, 0.1);
  }
  .img_pointing {
  }
}
/*=========== image_pointer Shortcode css ========*/

/*=========== caniuse Shortcode css ========*/
.caniuse {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -6px;
    .caniuse_agents_item {
      text-align: center;
      border-radius: 5px;
      padding: 23px 30px 20px;
      margin: 6px;
      img {
        height: 40px;
        display: block;
      }
      span {
        display: block;
        font-size: 20px;
        padding-top: 13px;
      }
    }
  }
}
.caniuse_section {
  & + .caniuse_section {
    margin-top: 55px;
  }
}
.light_blue {
  background: #ece6fc;
  color: #784bfb;
}
.light_red {
  background: #fce6e6;
  color: #f84343;
}
.blue {
  background: #e6f8fc;
  color: var(--brand_color);
}

.caniuse_section_legend {
  p {
    padding-top: 25px;
  }
  .caniuse_legend_list {
    padding: 15px 0 10px;
    span {
      margin-right: 20px;
    }
    a {
      font-size: 14px;
      font-weight: 500;
      padding: 0 18px;
      display: inline-block;
      margin: 0 3px;
      border-radius: 2px;
      &.blue {
        &:hover {
          background: var(--brand_color);
          color: #fff;
        }
      }
    }
  }
}
.purpale_btn {
  background: #fad9ea;
  color: #e32a8b;
  &:hover {
    background: #e32a8b;
    color: #fff;
  }
}
.green_btn {
  background: #cef5d4;
  color: #11ca30;
  &:hover {
    background: #11ca30;
    color: #fff;
  }
}
.violate_btn {
  background: #e8d9fa;
  color: #9744fd;
  &:hover {
    background: #9744fd;
    color: #fff;
  }
}
/*=========== caniuse Shortcode css ========*/

/*=========== table Shortcode css ========*/
.table_shortcode {
  thead {
    background: #5757ff;
    th {
      padding: 20px 25px;
      border: 0;
    }
  }
  th,
  td {
    border: 0;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    vertical-align: middle;
  }
  th {
    font-weight: 500;
  }
  tbody {
    tr {
      background: #f0f2f5;
      th {
        background: var(--brand_color);
      }
      td,
      th {
        padding: 12px 25px;
      }
      td {
        color: #6b707f;
      }
      &:nth-child(odd) {
        background: $bs_white;
      }
    }
  }
}
.basic_table {
  padding-top: 45px;
  .s_title {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 25px;
  }
}
.basic_table_info {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #f1f2f3;
  thead th {
    border: 0;
  }
  tbody {
    tr {
      th,
      td {
        border-color: #eaeff4;
      }
    }
  }
  &.table-hover tbody tr {
    transition: all 0.3s linear;
    &:hover {
      background: var(--brand_color);
      color: #fff;
    }
  }
}
/*=========== table Shortcode css ========*/

/*============ Footnote ===========*/
.text-component--has-footnotes {
  counter-reset: footnotes;
}

.footnotes {
  border-top: 1px solid var(--color-contrast-low);
  font-size: 0.875em;
  #footnotes {
    font-weight: 700;
  }
  .footnotes_item {
    font-size: 14px;
    position: relative;
    padding-left: 25px;
    .back-article {
      position: absolute;
      left: 1px;
      top: 2px;
      font-size: 16px;
    }
    strong {
      color: $black_800;
      padding-bottom: 7px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
    }
    & + .footnotes_item {
      margin-top: 20px;
    }
  }
}

.footnotes-link {
  counter-increment: footnotes;
  font-size: 0.75em;
  vertical-align: super;
  &::before {
    content: "[" counter(footnotes) "]";
  }
  &:target,&:target {
    outline: 2px solid var(--brand_color);
    outline-offset: 2px;
  }
}

/*============ Footnote ===========*/

/*============ List Style ===========*/
.single_list {
  list-style: none;
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.02);
  border: 1px solid #e5ebef;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 2px;
  li {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    position: relative;
    padding-left: 20px;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: var(--brand_color);
      position: absolute;
      left: 0;
      top: 9px;
    }
    & + li {
      margin-top: 15px;
    }
  }
}

/** === List Style === **/
.steps-panel {
  box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.02);
  border: 1px solid #e5ebef;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 2px;
}
ul.ordered-list {
  li::before {
    content: "\68";
    font-family: "ElegantIcons";
    font-size: 10px;
  }
}
.ordered-list {
  position: relative;
  list-style: none;
  padding-left: 20px;
  margin: 20px 0;
  &::before {
    content: "";
    position: absolute;
    left: 32px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #c4cdd5;
  }
  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin: 16px 0;
    position: relative;
    padding-left: 35px;
    counter-increment: a;
    &::before {
      content: counter(a);
      position: absolute;
      color: #fff;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      font-weight: 500;
      font-size: 12px;
      background-color: var(--brand_color);
      text-align: center;
      line-height: 23px;
      top: -4px;
      left: -4px;
      border: 5px solid $bs_white;
    }
  }
}

/*============ changelog css ===========*/
.changelog_inner {
  padding-right: 30px;
  padding-bottom: 100px;
}
.changelog_info {
  padding-top: 15px;
  .version_info {
    margin-right: 15px;
    position: relative;
    height: 100%;
  }
  .c_version {
    flex: 1;
    background: #1d2746;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    height: 26px;
    line-height: 27px;
    border-radius: 3px;
    color: #fff;
    min-width: 50px;
    max-width: 60px;
    display: block;
    margin: 0 auto;
  }
  .changelog_date {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
    .c_date {
      h6 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
        color: #6b707f;
      }
    }
  }
  .line {
    height: 106%;
    width: 2px;
    background: #e2e5ee;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
  }
  &:last-child {
    .line {
      height: 100%;
    }
    .changelog_content {
      padding-bottom: 0;
    }
  }
}
.changelog_content {
  padding-bottom: 60px;
  p {
    display: flex;
    margin-bottom: 2px;
    font-size: 14px;
  }
  span {
    font-weight: 400;
    font-size: 10px;
    color: #fff;
    padding: 0 10px;
    text-transform: uppercase;
    min-width: 110px;
    display: inline-block;
    text-align: center;
    line-height: 23px;
    border-radius: 3px;
    margin-right: 20px;
    height: 22px;
    flex: 0;
    letter-spacing: 0.8px;
    &.improve {
      background: var(--brand_color);
    }
    &.new {
      background: #0ed193;
    }
    &.update {
      background: #ffa100;
    }
    &.fixed {
      background: #ea3940;
    }
  }
  .download-links {
    border-top: 1px dotted rgba(51, 51, 51, 0.2);
    margin-top: 15px;
  }
  .changelog_btn {
    font-size: 14px;
    color: #6b707f;
    font-weight: 500;
    margin-top: 12px;
    display: inline-block;
    opacity: 0.5;
    i {
      margin-right: 10px;
    }
    &:hover {
      color: var(--brand_color);
    }
    & + .changelog_btn {
      margin-left: 28px;
    }
  }
  &:hover .changelog_btn {
    opacity: 1;
  }
}

.tour_info_content {
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.tour_item {
  padding: 70px 0;
  .arrow.text-right {
    margin-right: -80px;
    position: relative;
    z-index: 1;
  }
}
.tour_intro_item {
  .tour_info_content {
    position: relative;
    p {
      margin-bottom: 55px;
    }
    .arrow {
      position: absolute;
      bottom: 0;
      left: -20px;
    }
  }
  &.flex-row-reverse {
    .arrow {
      right: -20px;
      left: auto;
    }
  }
  & + .tour_intro_item {
    margin-top: 60px;
  }
}
.last_tour_item {
  padding-bottom: 0;
}
.just_text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  p {
    margin-bottom: 0;
  }
  & + .just_text {
    margin-top: 70px;
  }
}
.answer_bottom {
  align-items: flex-start;
}
.tour_preview_img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/*========== cheatsheet_info css =========*/
.cheatsheet_info {
  & + .cheatsheet_info {
    margin-top: 30px;
  }
}
.cheatsheet_accordian {
  .card {
    border: 0;
    padding: 0;
    overflow: visible;
    margin-top: 10px;
    background: transparent;
    .card-header {
      padding: 0;
      border: 0;
      background: transparent;
      button {
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
        background: transparent;
        color: $h_title;
        text-decoration: none;
        .minus {
          display: inline-block;
        }
        .pluse {
          display: none;
        }
        &.collapsed {
          .pluse {
            display: inline-block;
          }
          .minus {
            display: none;
          }
        }
      }
    }
  }
}
.cheatsheet_item {
  background: $bs_white;
  text-align: left;
  padding: 20px 20px 30px;
  margin-bottom: 30px;
  transition: all 0.3s linear;
  &:hover {
    box-shadow: 0 20px 27px 0 rgba(4, 73, 89, 0.08);
  }
  .cheatsheet_num {
    text-align: right;
  }
  p {
    margin-bottom: 0;
  }
  h5 {
    margin-bottom: 0;
    color: var(--brand_color);
  }
}
