/** === Forum heading bar === **/
.post-header {
  display: flex;
  justify-content: space-between;
  background: brand_color_rgba(0.05);
  padding: 17px 30px;
  border: 1px solid #eaeeef;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  &.forums-header {
    padding: 17px 15px;
    display: flex;
    text-align: left;
  }

  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }

  @media (max-width: 576px) {
    padding: 15px;
  }

  .support-total-info {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    li {
      display: inline-block;
      font-size: 14px;

      &.open-ticket {
        color: #1d2746;
        font-weight: 500;
      }

      &.close-ticket {
        a {
          color: #6b707f;
        }
      }

      a {
        font-size: 14px;
        font-weight: 500;

        &:hover {
          color: var(--brand_color);
        }
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      i {
        color: #00ae69;
        margin-right: 10px;
        vertical-align: -2px;
      }

      &:nth-child(1) {
        i {
          color: #f06292;
        }
      }
    }
  }

  .category-menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      .dropdown-menu {
        top: 12px !important;
        transform: translate3d(0, 23px, 0) !important;
      }

      &:not(:last-child) {
        margin-right: 10px;

        @media (max-width: 546px) {
          margin-right: 0;
        }
      }

      &:nth-child(4),
      &:nth-child(3) {
        .dropdown-menu {
          right: -11px;
          left: auto !important;
        }
      }

      &:nth-child(4) {
        @media (max-width: 380px) {
          .dropdown-menu {
            width: 190px;
          }
        }
      }

      &:nth-child(3) {
        .title {
          &:before,
          &:after {
            right: 40px;
          }
        }
      }

      &:nth-child(1) {
        .title {
          &:before,
          &:after {
            right: auto;
            left: 30px;
          }
        }
      }

      &:nth-child(2) {
        .title {
          &:before,
          &:after {
            right: auto;
            left: 25px;
          }
        }
      }
    }

    .dropdown-toggle {
      position: relative;
      background: transparent;
      box-shadow: none;
      border: 0;
      color: #6b707f;
      padding: 0 15px;
      font-size: 14px;

      &:after {
        content: "\33";
        font-family: eleganticons;
        border: 0;
        position: absolute;
        top: 50%;
        right: -5px;
        transform: translateY(-58%);
      }
    }

    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
      border: none;
      color: #6b707f;
      box-shadow: none;
    }

    .dropdown-menu {
      border: 1px solid #e6eeef;
      background: #fff;
      box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
      padding: 0;
      width: 260px;
      animation: slideDown 0.12s cubic-bezier(0, 0.1, 0.1, 1) backwards;

      @media (max-width: 576px) {
        width: 210px;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      background: #f7f8f9;
      border-bottom: 1px solid #e6eeef;
      padding: 14px 20px 11px;
      line-height: 1;
      color: #6b707f;
      position: relative;

      &:before {
        content: "";
        height: 16px;
        width: 16px;
        background: #e6eeef;
        position: absolute;
        right: 30px;
        transform: rotate(45deg);
        top: -8px;
      }

      &:after {
        content: "";
        height: 16px;
        width: 16px;
        background: #f7f8f9;
        position: absolute;
        right: 30px;
        transform: rotate(45deg);
        top: -6px;
      }
    }

    .cate-search-form {
      padding: 0 20px;
      margin-top: 10px;

      input {
        width: 100%;
        background: #fbfcfc;
        border: 2px solid #e2e7e8;
        border-radius: 4px;
        padding: 6px 20px;
        font-size: 14px;

        &::placeholder {
          color: #a8acb6;
        }

        &:focus {
          background: #fff;
        }
      }
    }

    .all-users {
      font-size: 14px;
      padding: 12px 22px;
      border-bottom: 1px solid #e6eeef;
      font-weight: 500;
      color: #6b707f;

      a {
        span {
          border-radius: 4px;
          width: 16px;
          height: 16px;
          background: #27b2da;
          display: inline-block;
          margin-right: 10px;
          vertical-align: -3px;

          &.color-pupple {
            background: #8152e0;
          }

          &.color-yellow {
            background: #fad05a;
          }

          &.color-ass {
            background: #dfdfdf;
          }

          &.color-green {
            background: #22936d;
          }

          &.color-orange {
            background: #f88546;
          }

          &.color-light-green {
            background: #1eba17;
          }
        }

        padding: 7px 0;
        color: #838793;

        &:not(:last-child) {
          border-bottom: 1px solid #e6eeef;
        }

        img {
          width: 20px;
          margin-right: 10px;
          display: inline-block;
        }

        &:hover,
        &:focus {
          background: transparent;
          color: #1d2746;
        }
      }
    }

    .short-by {
      padding: 10px 20px;

      a {
        padding: 7px 0 7px 20px;
        position: relative;
        color: #838793;
        font-size: 14px;

        &.active-short {
          color: #1d2746;

          &:before {
            content: "\4e";
            position: absolute;
            font-family: eleganticons;
            left: 0;
            top: 7px;
            color: #0abe76;
            font-size: 14px;
          }
        }

        &:hover {
          background: transparent;
          color: #1d2746;
        }
      }
    }
  }
}

ul.forum-titles {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-end;

  li {
    text-align: right;
    font-size: 14px;
  }

  .forum-topic-count,
  .forum-reply-count {
    flex-basis: 25%;
  }

  .forum-freshness {
    flex-basis: 50%;

    .freshness-box {
      display: flex;
      font-size: 14px;
      line-height: 1.3;
      flex-direction: column;

      a {
        color: #6b707f;
        &:hover {
          color: var(--brand_color);
        }
      }

      .freshness-btm {
        display: flex;
        justify-content: flex-end;
        padding-top: 5px;
        align-items: center;

        a.bbp-author-link {
          padding-right: 10px;
        }

        .bbp-author-avatar {
          img {
            max-width: 30px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.forum-page-content {
  .communities-boxes {
    margin: 0 -30px;
  }
}

.answer-action {
  border-radius: 6px;
  background: var(--bs-white);
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    display: block;
  }

  .action-content {
    display: flex;
    align-items: center;

    .image-wrap {
      margin-right: 30px;
    }

    .ans-title {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      color: $p_color;
    }
  }

  .btn-ans {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 25px;
    box-shadow: none;
    border: 1px solid var(--brand_color);
    transition: all 0.3s ease-in-out;

    &:hover {
      background: transparent;
      color: var(--brand_color);
    }

    @media (max-width: 768px) {
      margin-left: 130px;
      margin-top: 5px;
    }
  }
}

.call-to-action {
  padding: 55px 0;
  background: #1d2746;
  position: relative;

  .container {
    position: relative;
  }

  .overlay-bg {
    position: absolute;
    background-image: url(../img/home_support/overlay_bg.png);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    opacity: 0.5;
  }

  .action-content-wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      display: block;
      text-align: center;
    }

    .action-title-wrap {
      display: flex;
      align-items: center;

      img {
        padding-right: 45px;
      }

      .action-title {
        color: #fff;
        font-size: 44px;
        line-height: 1.2;
        font-weight: 500;

        @media (max-width: 991px) {
          font-size: 34px;
        }
      }
    }

    .action_btn {
      border: 1px solid var(--brand_color);
      font-size: 16px;
      padding: 12px 25px;
      font-weight: 500;

      &:hover {
        background: transparent;
        border-color: #fff;
        color: #fff;
      }
    }
  }
}

.status_widget {
  .status {
    margin-bottom: 25px;
  }

  .offline {
    color: #f4b130;
    border: 2px solid #f5d597;
    padding: 0 13px;
    display: inline-block;
    border-radius: 4px;
    margin-left: 5px;
  }

  .title-sm {
    font-size: 16px;
    color: #1d2746;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .open-hours {
    margin-bottom: 30px;

    p {
      margin-bottom: 0;

      @media (min-width: 991px) {
        max-width: 240px;
      }
    }
  }

  .current-time {
    margin-top: 28px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    li {
      float: left;
      width: 50%;
    }
  }
}

.w_tag_list {
  &.style-light {
    li {
      a {
        background: #f0f2f5;

        &:hover {
          background: brand_color_rgba(.15);;
          color: var(--brand_color);
          box-shadow: none;
        }
      }
    }
  }
}

.usefull-links {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 7px 0;

    i {
      margin-right: 10px;
    }

    a {
      color: #6b707f;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;

      &:hover {
        color: var(--brand_color);
      }
    }
  }
}

.ticket_categories {
  li {
    padding: 12px 0 10px;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #e4e7e9;
    }

    img {
      margin-right: 10px;
      margin-bottom: 5px;
    }

    a {
      font-size: 16px;
      color: $black_700;

      &:hover {
        color: var(--brand_color);
      }
    }

    .count {
      position: absolute;
      right: 0;
      background: rgba(175,184,193,.2);
      padding: 4px 7px 2px;
      border-radius: 10px;
      color: #838793;
      font-size: 14px;
      display: inline-block;
      line-height: 1;

      &.count-fill {
        right: 35px;
        background: #0abe76;
        color: #fff;
      }
    }
  }
}

/* Forum Profile Area css
============================================================================================ */
.forum-user-wrapper {
  background: #fbfbfc;
  padding-bottom: 135px;
  .forum_main_inner {
    margin-top: -60px;
  }
}
.author_option {
  background: var(--bs-white);
  border-radius: 10px;
  padding: 30px 30px 50px 30px;
  box-shadow: 0 30px 60px 0 rgba(2, 2, 48, 0.08);
  .author_img {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .nav.nav-tabs {
    border: none;
    li {
      margin-bottom: 10px;
      a {
        border: none;
        background: rgba(208,215,222,.32);
        line-height: 50px;
        padding: 0 28px;
        border-radius: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $black_800;
        i {
          padding-right: 2px;
        }
        svg {
          position: relative;
          top: -2.5px;
          margin-right: 7px;
        }
        &:hover,
        &.active {
          background: $baseColor2;
          color: #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.forum_body_area {
  background: var(--bs-white);
  padding: 50px 40px 30px 40px;
  border-radius: 10px;
  box-shadow: 0 30px 60px 0 rgba(2, 2, 48, 0.08);
}
.search_forum {
  margin-bottom: 45px;
  input {
    height: 50px;
    border: 1px solid #f1f1f8;
    border-radius: 25px;
    background: #f7f7fb;
    padding: 0 30px;
    line-height: 50px;
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px;
    font-weight: 400;
    color: #848996;
    font-family: $rob;
    &:focus {
      border: 1px solid #f1f1f8;
      background: #f7f7fb;
      outline: none !important;
      box-shadow: none !important;
    }
    @include placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #848996;
      font-family: $rob;
    }
  }
  .input-group-append {
    button {
      position: relative;
      background: $baseColor2;
      width: 80px;
      padding: 0;
      border: none;
      color: #fff;
      text-align: center;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      i {
        transform: rotate(-90deg);
        position: absolute;
        left: 58%;
        text-align: center;
        top: 0;
        height: 100%;
      }
    }
  }
}
.forum_topic_list {
  h2 {
    color: #232361;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 20px;
  }
}
.forum_l_inner {
  border-radius: 5px;
  border: 1px solid #eaeeef;
  .forum_head {
    background: brand_color_rgba(.05);
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid brand_color_rgba(.05);
    .left {
      li {
        margin-right: 25px;
        line-height: 58px;
        i {
          color: #f06292;
          font-size: 14px;
          margin-right: 7px;
        }
        color: #1d2746;
        font-weight: 500;
        font-size: 14px;
        &:nth-child(2) {
          color: #6b707f;
          font-weight: 400;
          i {
            color: #0abe76;
            position: relative;
            top: 1px;
          }
        }
        a {
          color: #6b707f;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    .right {
      li {
        margin-right: 35px;
        .dropdown {
          button {
            padding: 0;
            line-height: 58px;
            outline: none;
            box-shadow: none;
            font-size: 14px;
            font-weight: 400;
            color: #6b707f;
            border: none;
            &:after {
              display: none;
            }
            i {
              font-size: 16px;
              color: #6b707f;
              position: relative;
              top: 1px;
              padding-left: 2px;
            }
          }
          .dropdown-menu {
            border: 1px solid #e6eeef;
            background: #fff;
            box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
            padding: 0;
            width: 260px;
            top: 46px !important;
            animation: slideDown 0.12s cubic-bezier(0, 0.1, 0.1, 1) backwards;
            transform: translate3d(0, 23px, 0) !important;
            border-radius: 4px;
            &:before {
              content: "";
              height: 16px;
              width: 16px;
              background: #fafcfc;
              position: absolute;
              left: 30px;
              transform: rotate(45deg);
              top: -8px;
            }
            &:after {
              content: "";
              height: 16px;
              width: 16px;
              background: #e6eeef;
              position: absolute;
              left: 30px;
              transform: rotate(45deg);
              top: -9px;
              z-index: -1;
            }
            .title {
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              background: #fafcfc;
              padding: 14px 20px 11px;
              line-height: 1;
              color: #6b707f;
              position: relative;
              box-shadow: 0 1px 0 #e6edf0;
            }
            .cate-search-form {
              padding: 0 20px;
              margin-top: 10px;
              input {
                width: 100%;
                background: #fafcfc;
                border: 1px solid #dfe3e6;
                box-sizing: border-box;
                border-radius: 2px;
                padding: 6px 20px;
                font-size: 12px;
                height: 36px;
                color: #6b707f;
                @include placeholder {
                  font-size: 12px;
                  color: #6b707f;
                  font-weight: 400;
                }
              }
            }
            .all_users {
              font-size: 14px;
              padding: 10px 0 0 0;
              font-weight: 500;
              color: #6b707f;
              max-height: 200px;
              overflow-x: hidden;
              .dropdown-item {
                font-size: 12px;
                padding: 7px 40px;
                border: none;
                font-weight: 400;
                color: #54595d;
                box-shadow: 0 1px 0 #e6edf0;
                .color {
                  width: 14px;
                  height: 14px;
                  display: inline-block;
                  background: #ee0701;
                  border-radius: 50%;
                  margin-right: 10px;
                  position: relative;
                  top: 2px;
                  &.blue {
                    background: #5319e7;
                  }
                  &.dark_blue {
                    background: #051375;
                  }
                  &.green {
                    background: #128a0c;
                  }
                  &.pink {
                    background: #cc317c;
                  }
                }
                ion-icon {
                  color: green;
                  position: relative;
                  top: 4px;
                  font-size: 16px;
                  margin-right: 5px;
                  opacity: 0;
                  @include transition;
                }
                img {
                  padding-right: 8px;
                  border-radius: 50%;
                }
                &:hover,
                &.active-short {
                  color: $h_title;
                  background: #f2f9fc;
                  box-shadow: 0 1px 0 #f2f9fc;
                  ion-icon {
                    opacity: 1;
                  }
                }
              }
              .mCSB_scrollTools {
                width: 5px;
                background: #fff;
                border-radius: 2.5px;
              }
              .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                background-color: #cacbcc !important;
                width: 5px;
              }
              .mCSB_scrollTools .mCSB_draggerRail {
                width: 5px;
                background: transparent;
              }
            }
          }
          &.list_sirt {
            .dropdown-menu {
              border: 1px solid #e6eeef;
              background: #f7f8f9;
              box-shadow: 0 30px 40px 0 rgba(4, 73, 89, 0.1);
              width: 260px;
              top: 46px !important;
              animation: slideDown 0.12s cubic-bezier(0, 0.1, 0.1, 1) backwards;
              transform: translate3d(0, 23px, 0) !important;
              padding: 14px 0;
              z-index: 2;
              &:before {
                content: "";
                height: 16px;
                width: 16px;
                background: #f7f8f9;
                position: absolute;
                left: 30px;
                transform: rotate(45deg);
                top: -8px;
                z-index: -1;
              }
              a {
                width: 100%;
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: #6b707f;
                padding: 5px 20px;
                &.active-short,
                &:hover {
                  background: #fff;
                }
              }
            }
          }
          &.right_dir {
            .dropdown-menu {
              right: 0;
              left: auto !important;
              &:before {
                right: 30px;
                left: auto;
              }
              &:after {
                left: auto;
                right: 30px;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          .dropdown {
            .dropdown-menu {
              right: auto;
              left: 0;
              &:before {
                right: auto;
                left: 30px;
              }
            }
          }
        }
      }
    }
    .page {
      line-height: 58px;
      display: inline-block;
      font-size: 14px;
      color: $p_color;
    }
  }
  .forum_body {
    .topic_list {
      li {
        border-bottom: 1px solid #eaeeef;
        padding: 18px 20px;
        &:last-child {
          border-bottom: 0;
        }
        .media {
          align-items: center;
          .d-flex {
            padding-right: 18px;
          }
          .media-body {
            h4 {
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 4px;
              display: inline-block;
              @include transition;
              &:hover {
                color: $baseColor2;
              }
            }
            .t_title {
              .docbuzz {
                line-height: 22px;
                display: inline-block;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                padding: 0 6px;
                background: #8152e0;
                border-radius: 3px;
                margin-left: 7px;
                &.yellow {
                  background: #fad05a;
                  color: #1d2746;
                }
                &.gray {
                  color: $h_title;
                  background: #eeeeee;
                }
                &.green {
                  background: #22936d;
                }
                &.orange {
                  background: #f88546;
                }
                &.pest {
                  background: #27b2da;
                }
                &.pink {
                  background: #f053b3;
                }
                &.greenl {
                  background: #1eba17;
                }
              }
            }
            h6 {
              display: inline-block;
              margin-bottom: 0;
              font-size: 14px;
              color: #838793;
              font-weight: 400;
              font-family: $rob;
              margin-right: 16px;
              @include transition;
              &:hover {
                color: $baseColor2;
              }
              & + h6 {
                margin-right: 0;
              }
              i {
                padding-right: 5px;
                top: 0;
                position: relative;
              }
              img {
                padding-right: 5px;
                position: relative;
                top: -1px;
              }
            }
          }
          .media-right {
            vertical-align: middle;
            padding-right: 12px;
            .nav + .count ion-icon {
              margin-left: 0;
            }
            .count {
              display: inline-block;
              margin-left: 40px;
              font-size: 16px;
              color: #868b99;
              font-weight: 400;
              @include transition;
              &:hover {
                color: $baseColor2;
              }
              i, ion-icon {
                margin-right: 2px;
                @include transition;
                font-size: 18px;
                margin-bottom: -2px;
              }
              &.rate {
                &:hover {
                  color: #868b99;
                  i {
                    color: #f9ae44;
                  }
                }
              }
            }
          }
        }
        &.check {
          position: relative;
          &:before {
            content: "";
            background: url(../img/icon/green-check.png) no-repeat;
            height: 32px;
            width: 32px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}

.list_pagination {
  padding-top: 20px;
  .left {
    line-height: 36px;
    p {
      font-size: 14px;
      color: #6b6b80;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  .right {
    nav {
      ul {
        border: none;
        margin-bottom: 0;
        li {
          margin-right: 6px;
          a {
            border: none;
            background: #f8f8f8;
            padding: 0;
            font-size: 14px;
            color: #6b6b80;
            height: 36px;
            width: 36px;
            text-align: center;
            line-height: 36px;
            border-radius: 10px !important;
            outline: none;
            box-shadow: none;
          }
          &:hover,
          &.active {
            a {
              background: $baseColor2;
              color: #fff;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.profile_info {
  .p_info_item_inner {
    .col-sm-4 {
      &:last-child {
        .p_info_item {
          img {
            margin-top: -13px;
          }
        }
      }
    }
    .p_info_item {
      border: 1px solid $black_50;
      text-align: center;
      padding-bottom: 40px;
      padding-top: 40px;
      border-radius: 6px;
      img {
        margin-bottom: 20px;
      }
      h4 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .info_btn {
        color: $baseColor2;
        background: #f1f1fe;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        padding: 0 12px;
        display: inline-block;
        border-radius: 13px;
      }
      .info_number {
        background: #fff4ee;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        padding: 0 18px;
        display: inline-block;
        border-radius: 13px;
        color: #ff762b;
      }
    }
  }
}
.info_list {
  padding-top: 45px;
  padding-bottom: 60px;
  li {
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 500;
    color: $p_color;
    span {
      min-width: 250px;
      color: $h_title;
      font-size: 14px;
      font-weight: 700;
      float: left;
      overflow: hidden;
    }
    a {
      color: $p_color;
      display: block;
      overflow: hidden;
      font-weight: 400;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.p_social {
  border-top: 1px solid $black_50;
  margin-left: -40px;
  margin-right: -40px;
  padding-top: 22px;
  li {
    &:first-child {
      padding-left: 40px;
    }
    &:last-child {
      padding-right: 40px;
      margin-right: 0;
    }
    margin-right: 10px;
    a {
      height: 45px;
      width: 45px;
      background: $black_50;
      color: $black_500;
      font-size: 14px;
      text-align: center;
      line-height: 45px;
      display: inline-block;
      border-radius: 10px;
      @include transition;
      &:hover {
        color: #fff;
        background: $baseColor2;
      }
    }
  }
}
.topic_comment_list {
  li {
    .media {
      padding-left: 18px;
      padding-right: 25px;
      padding-top: 28px;
      .d-flex {
        padding-right: 18px;
      }
      .media-body {
        border-bottom: 1px solid #e8ecee;
        padding-bottom: 28px;
        .t_title {
          h4 {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 4px;
          }
        }
        .dropdown {
          margin-top: -30px;
          float: right;
          margin-right: 14px;
          button {
            &:after {
              display: none;
            }
            font-size: 34px;
            color: #838793;
            padding: 0;
            line-height: 26px;
            border: none;
            outline: none;
            box-shadow: none;
          }
          .dropdown-menu {
            left: auto !important;
            right: -14px;
            top: 20px !important;
            padding: 14px 0;
            border: 1px solid #e8eff0;
            background: #fff;
            min-width: 218px;
            box-shadow: 0 30px 40px 0 rgba(4, 4, 89, 0.1);
            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent #fff transparent;
              position: absolute;
              right: 16px;
              top: -10px;
              z-index: 1;
            }
            &:after {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent #e8eff0 transparent;
              position: absolute;
              right: 16px;
              top: -11px;
            }
            a {
              padding: 0 24px;
              line-height: 34px;
              font-size: 16px;
              color: $p_color;
            }
          }
        }
        h6 {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: $p_color;
          margin-right: 18px;
          margin-bottom: 13px;
          i {
            padding-right: 7px;
            font-size: 14px;
          }
        }
        p {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 20px;
        }
        .help_start {
          font-size: 14px;
          color: #838793;
          float: right;
          margin-top: -22px;
          i {
            color: #f9ae44;
            font-size: 14px;
            padding-right: 5px;
          }
        }
      }
      .help_btn {
        margin-left: 7px;
      }
    }
    &:last-child {
      .media {
        .media-body {
          border-bottom: 0;
        }
      }
    }
  }
}
/* End Forum Area css
============================================================================================ */

/* Forum Sidebar Area css
============================================================================================ */
.topics-filter {
  margin-bottom: 30px;
  .nav-item {
    .nav-link {
      position: relative;
      color: $black_400;
      ion-icon {
        margin-right: 2px;
        margin-top: -2px;
      }
      &.active, &:hover {
        color: $h_title;
        &::after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          content: ' ';
          width: 100%;
          height: 2px;
          border-radius: 25%;
          background: var(--brand_color);
        }
      }
    }
  }
}
.forum_sidebar_area {
  padding-top: 100px;
  padding-bottom: 100px;
  .custom_container {
    position: relative;
  }
  .right_side_forum {
    margin-left: 50px;
  }
}
.left_side_forum {
  max-width: 265px;
}
.wd_title {
  margin-bottom: 25px;
  font-size: 18px;
}
.l_widget {
  margin-bottom: 45px;
}
.forum_list {
  .navbar-nav {
    li {
      margin-bottom: 5px;
      a {
        font-size: 14px;
        font-weight: 400;
        color: $p-color;
        display: block;
        line-height: 38px;
        border-radius: 2px;
        padding: 0 15px;
        @include transition;
        i {
          font-size: 14px;
          font-weight: 400;
          color: #54595d;
          padding-right: 8px;
          @include transition;
        }
        &:hover {
          background: brand_color_rgba(.15);
          color: var(--brand_color);
          i {
            color: var(--brand_color);
          }
        }
      }
      &.active {
        a {
          background: var(--brand_color);
          color: #fff;
          font-weight: 500;
          i {
            color: #fff;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.l_tags_wd {
  .nav {
    margin-bottom: -10px;
    li {
      margin-right: 10px;
      margin-bottom: 10px;
      a {
        font-weight: 400;
        font-size: 14px;
        color: #54595d;
        background: #f2f5f7;
        border-radius: 3px;
        padding: 0 14.5px;
        display: inline-block;
        line-height: 24px;
        @include transition;
        &:hover {
          background: #dcedf5;
          color: var(--brand_color);
        }
      }
    }
  }
}
.comment_list {
  .navbar-nav {
    li {
      border-bottom: 1px solid #e6edf0;
      padding: 10px 0;
      &:first-child {
        padding-top: 0;
      }
      .media {
        .d-flex {
          padding-right: 10px;
          i {
            margin-top: 3px;
            color: $p_color;
          }
        }
        .media-body {
          h4 {
            font-size: 14px;
            font-weight: 400;
            display: inline-block;
            margin-bottom: 0;
            @include transition;
          }
          h5 {
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            margin-left: 9px;
            margin-bottom: 0;
          }
          p {
            margin-bottom: 0;
            font-size: 12px;
          }
        }
      }
      &:hover {
        .media {
          .media-body {
            h4 {
              color: var(--brand_color);
            }
          }
        }
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}

.r_widget {
  margin-bottom: 50px;
}
.qustion_wd {
  button {
    background: var(--brand_color);
    border: none;
    border-radius: 40px !important;
    height: 80px;
    line-height: 80px;
    outline: none !important;
    box-shadow: none !important;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    padding: 0;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    @include transition;
    img {
      margin-right: 20px;
      position: relative;
      top: -1px;
    }
    i {
      float: right;
      line-height: 80px;
    }
    &:hover {
      color: #fff;
      background: var(--brand_color);
    }
  }
}
.r_heading {
  background: brand_color_rgba(.05);
  border-bottom: 1px solid brand_color_rgba(.05);
  padding-left: 20px;
  padding-right: 20px;
  h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 50px;
  }
  h5 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    color: $p_color;
    line-height: 50px;
  }
}
.user_list_wd {
  border: 1px solid #eaeeef;
  border-radius: 6px;
  .navbar-nav {
    padding: 0 20px;
    li {
      padding: 11px 0 8px;
      border-bottom: 1px solid $black_50;
      &:last-child {
        border-bottom: 0;
      }
      a {
        .media {
          .d-flex {
            padding-right: 9px;
          }
          .media-body {
            vertical-align: middle;
            h4 {
              font-size: 14px;
              font-weight: 400;
              color: #54595d;
              margin-bottom: 0;
              margin-top: 2px;
            }
          }
          .media-right {
            .count {
              display: inline-block;
              font-size: 12px;
              color: $p_color;
              position: relative;
              top: -2px;
              margin-right: 4px;
            }
            i {
              color: $p_color;
            }
          }
        }
      }
      &:hover {
        a {
          .media {
            .media-body {
              h4 {
                color: var(--brand_color);
              }
            }
          }
        }
      }
    }
  }
}
.question_list_wd {
  border: 1px solid #e6edf0;
  border-radius: 6px;
  .nav-tabs {
    border: none;
    margin-bottom: 0;
    li {
      a {
        border: none;
        background: transparent !important;
        border-radius: 0;
        line-height: 50px;
        padding: 0 8px;
        color: $p_color;
        font-weight: 400;
        font-size: 12px;
        border-bottom: 3px solid transparent !important;
        position: relative;
        &:before {
          content: "";
          height: 14px;
          width: 1px;
          background: brand_color_rgba(.2);
          position: absolute;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
        }
        &.active {
          border-color: var(--brand_color) !important;
        }
      }
      &:last-child {
        a {
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .navbar-nav {
    padding: 7px 20px 18px;
    li {
      padding: 15px 0 15px;
      a {
        font-size: 14px;
        font-weight: 400;
        color: #54595d;
        position: relative;
        padding-left: 45px;
        display: inline-block;
        line-height: 1.5;
        span {
          height: 30px;
          width: 30px;
          border: 1px solid #e6edf0;
          text-align: center;
          line-height: 28px;
          color: $p_color;
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
          border-radius: 50%;
          margin-right: 9px;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:hover {
          color: var(--brand_color);
          span {
            color: var(--brand_color);
          }
        }
      }
    }
  }
}
.forum_topic_list_inner {
  .forum_l_inner {
    .forum_head {
      padding-left: 30px;
      padding-right: 30px;
      .nav.left {
        li {
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          &:nth-child(3) {
            a {
              i {
                color: #00aa6a;
              }
            }
          }
        }
      }
      .nav.right {
        li {
          margin-right: 50px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.forum_body {
  .navbar-nav {
    li {
      padding: 25.5px 30px;
      @include transition;
      .media {
        .media-body {
          .t_title {
            h4 {
              margin-bottom: 8px;
              ion-icon {
                color: #22863a;
                position: relative;
                top: 3px;
                font-size: 20px;
                margin-left: 5px;
              }
            }
          }
          h6 {
            svg {
              position: relative;
              top: -1px;
              margin-right: 5px;
            }
            &:hover {
              color: var(--brand_color);
              svg {
                path {
                  fill: var(--brand_color);
                }
              }
            }
          }
        }
        .media-right {
          .nav {
            display: inline-block;
            li {
              display: inline-block;
              padding: 0;
              margin-left: -12px;
              border: none;
              @include transition;
              a {
                margin-left: 0;
                &:after {
                  display: none;
                }
              }
              .dropdown-menu {
                min-width: 400px;
                background: $bs_white;
                left: auto !important;
                right: -18px !important;
                padding: 30px 30px 30px 30px;
                margin-top: 15px;
                border: 1px solid $black_50;
                box-shadow: 0 30px 40px rgba(10, 71, 102, 0.1);
                border-radius: 4px;
                display: block;
                visibility: hidden;
                position: absolute;
                opacity: 0;
                @include transition;
                &:before {
                  content: "";
                  position: absolute;
                  right: 18px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 8px 8px 8px;
                  border-color: transparent transparent $bs_white transparent;
                  top: -8px;
                  z-index: 1;
                }
                &:after {
                  content: "";
                  height: 16px;
                  width: 16px;
                  border-style: solid;
                  border-width: 0 8px 8px 8px;
                  border-color: transparent transparent #e6edf0 transparent;
                  position: absolute;
                  right: 18px;
                  top: -17px;
                }
                .media {
                  margin-bottom: 50px;
                  .d-flex {
                    padding-right: 15px;
                  }
                  .media-body {
                    h4 {
                      display: block;
                      width: 100%;
                      font-size: 18px;
                      font-weight: 400;
                      margin-bottom: 18px;
                    }
                    .follow_btn {
                      width: 90px;
                      height: 30px;
                      border: 1px solid #6b707f;
                      border-radius: 4px;
                      display: inline-block;
                      text-align: center;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 28px;
                      @include transition;
                      &:hover {
                        background: var(--brand_color);
                        color: #fff;
                        border-color: var(--brand_color);
                      }
                    }
                  }
                }
                .answere_items {
                  text-align: center;
                  h4 {
                    font-size: 14px;
                    font-weight: 400;
                  }
                  h6 {
                    font-size: 18px;
                    font-weight: 400;
                    margin-bottom: 0;
                  }
                }
              }
              &:hover {
                .dropdown-menu {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
            &:hover {
              li {
                margin-left: 4px;
              }
            }
          }
          a {
            margin-left: 45px;
            color: $p_color;
            i {
              margin-right: 10px;
              color: #818799;
            }
            &:hover {
              color: var(--brand_color);
              i {
                color: var(--brand_color);
              }
            }
          }
        }
      }
      &:hover {
        .media {
          .media-body {
            .t_title {
              h4 {
                color: var(--brand_color);
              }
            }
          }
        }
      }
    }
  }
}

.pagination_inner {
  margin-top: 40px;
  h6 {
    font-size: 16px;
    color: $p_color;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 32px;
    span {
      color: $h_title;
    }
  }
  .pagination {
    border: none;
    margin-bottom: 0;
    li {
      &:first-child,
      &:last-child {
        margin-right: 15px;
        a {
          border: none;
          height: auto;
          width: auto;
          &:hover {
            color: var(--brand_color);
          }
        }
      }
      &:last-child {
        margin-right: 0;
        margin-left: 15px;
      }
      margin-right: 5px;
      a {
        padding: 0;
        height: 32px;
        width: 32px;
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        color: $p-color;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        outline: none;
        box-shadow: none;
        background: $bs_white;
        i {
          font-size: 18px;
          top: 2px;
          position: relative;
        }
      }
      &.active {
        &:first-child,
        &:last-child {
          a {
            background: transparent;
            color: #54595d;
          }
        }
        a {
          background: var(--brand_color);
          color: #fff;
          &:hover {
            background: var(--brand_color);
            color: #fff;
            border-color: var(--brand_color);
          }
        }
      }
      &:hover {
        a {
          border-color: $black_50;
          background: transparent;
        }
      }
    }
  }
  .go_btn {
    float: right;
    width: 80px;
    input {
      border: 1px solid $black_50;
      box-sizing: border-box;
      border-radius: 4px;
      width: 40px;
      color: #54595d;
      font-size: 16px;
      font-weight: 400;
      background: transparent;
      height: 32px;
      overflow: hidden;
      line-height: 30px;
      padding: 0;
      max-width: 40px;
      outline: none;
      box-shadow: none;
      text-align: center;
      @include placeholder {
        color: #54595d;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .input-group-append {
      button {
        height: 32px;
        width: 40px;
        border-radius: 3px;
        border: 1px solid #e6edf0;
        box-sizing: border-box;
        padding: 0;
        text-align: center;
        color: var(--brand_color);
        font-size: 16px;
        font-weight: 400;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        outline: none !important;
        box-shadow: none !important;
        background: transparent;
        &:hover {
          background: brand_color_rgba(.2);
          border-color: brand_color_rgba(.2);
        }
        &:focus {
          color: var(--brand_color);
          border-color: #e6edf0;
        }
      }
    }
  }
}
.fade {
  transition: opacity 0.5s ease-in-out;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
/* End Forum Sidebar Area css
============================================================================================ */
