.ycp {
  .belah .luhur span.about {
    display: none;
  }
  .belah .luhur {
    height: 74px;
  }
  .belah .luhur span.tombol {
    font-size: 14px;
  }
  .belah .luhur span.tombol.vid-next {
    float: right;
    margin-right: 0;
  }
}

/* Video Area css
============================================================================================ */
.video_list_area {
  background: rgba(76, 76, 241, 0.07);
  &.bg_gradient {
    background: linear-gradient(180deg, #ffffff 0%, #f0f3f7 100%);
    .accordion {
      .card {
        box-shadow: 0 0.3px 0.9px rgba(29, 56, 70, 0.1),
          0 1.6px 3.6px rgba(29, 56, 70, 0.13);
      }
    }
  }
  .art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-highlight span {
    background: transparent !important;
  }
  .art-video-player .art-bottom {
    overflow: visible;
  }
  .art-state {
    width: 80px;
    height: 80px;
    ion-icon {
      font-size: 28px;
    }
  }
  .art-mask-show {
    .art-danmuku {
      &:before {
        content: "";
        background: rgba(54, 56, 149, 0.5);
        border-radius: 5px 5px 0 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 11;
      }
    }
  }
  .add_icon {
    .art-danmuku {
      &:before {
        content: "";
        background: rgba(54, 56, 149, 0.5);
        border-radius: 5px 5px 0 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 11;
      }
    }
  }
  .colg-lg-7,
  .col-lg-5 {
  }
  &.theatermode {
    overflow: hidden;
    .col-lg-7 {
      flex: 0 0 100%;
      max-width: 100%;
      @include transition;
    }
    .col-lg-5 {
      flex: 0 0 100%;
      max-width: 100%;

      .video_list {
        width: 100%;
        margin-top: 100px;
        .video_list_inner {
          .card {
            .card-body {
              .nav {
                li {
                  width: 100%;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
.art-auto-size {
  height: 530px !important;
  align-items: flex-start !important;
}
.art-video-player.art-subtitle-show.art-layer-show.art-control-show.art-mask-show.art-fullscreen:hover
  .art-bottom {
  bottom: -0 !important;
}
.art-video-player.art-fullscreen-web {
  height: 70% !important;
  position: relative !important;
}
.art-fullscreen-web {
  > & + .col-lg-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.art-video-player {
  width: 100% !important;
}
.video_list {
  h3 {
    font-size: 24px;
    color: $h_title;
    margin-bottom: 30px;
  }
  .mCustomScrollBox {
    padding-right: 20px;
  }
  .mCSB_scrollTools {
    width: 8px;
    background: #fff;
    border-radius: 4px;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 8px;
    background: #b8c3cc;
  }
  .mCSB_scrollTools .mCSB_draggerRail {
    width: 8px;
    background: #fff;
  }
  .video_list_inner {
    max-height: 472px;
    padding-right: 0;
    .card {
      background: var(--bs-white);
      border: none;
      border-radius: 5px !important;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .card-header {
        border: none;
        background: transparent;
        padding: 0;
        button {
          padding: 14px 35px;
          font-size: 18px;
          color: $h_title;
          font-weight: 500;
          text-decoration: none;
          margin-bottom: 0;
          border: none;
          outline: none;
          text-transform: inherit;
          span {
            color: #7f8493;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            top: 0;
            margin-left: 22px;
          }
          i {
            float: right;
            line-height: 26px;
            display: none;
            color: $p_color;
            & + i {
              display: inline-block;
            }
          }
          &.collapsed {
            i {
              display: inline-block;
              & + i {
                display: none;
              }
            }
          }
        }
      }
      .card-body {
        padding: 8px 35px 25px 35px;
        .nav {
          border: none;
          li {
            margin-bottom: 20px;
            a {
              padding: 0;
              border: none;
              background: transparent;
              .media {
                .d-flex {
                  padding-right: 20px;
                  .video_tab_img {
                    border-radius: 5px;
                    position: relative;
                    overflow: hidden;
                    &:before {
                      content: "";
                      background: rgba(29, 39, 70, 0.55);
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                      @include transition;
                    }
                    &:after {
                      content: "\f215";
                      font: normal normal normal 20px/1 ionicons;
                      left: 0;
                      top: 50%;
                      transform: translateY(-50%);
                      width: 100%;
                      text-align: center;
                      position: absolute;
                      color: #fff;
                      opacity: 0;
                      @include transition;
                    }
                  }
                }
                .media-body {
                  h4 {
                    color: $h_title;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 3px;
                    @include transition;
                  }
                  .list {
                    div {
                      display: inline-block;
                      color: #7f8493;
                      font-weight: 400;
                      font-size: 12px;
                      margin-right: 20px;
                      ion-icon {
                        padding-right: 6px;
                      }
                      &:last-child {
                        margin-right: 0;
                      }
                    }
                  }
                }
              }
              &:hover,
              &.active {
                .media {
                  .d-flex {
                    .video_tab_img {
                      &:before {
                        opacity: 1;
                      }
                      &:after {
                        opacity: 1;
                      }
                    }
                  }
                  .media-body {
                    h4 {
                      color: var(--brand_color);
                    }
                  }
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.art-video-player.art-layer-show .art-layers {
  display: none !important;
}
.art-control.art-control-control13.art-control-onlyText {
  display: none;
}
/* End Video Area css
============================================================================================ */

/* End Video Area css
============================================================================================ */
.video_slider_area {
  background: #f9fbfc;
  padding-top: 110px;
  padding-bottom: 120px;
  .container {
    position: relative;
  }
  &.theatermode {
    .col-lg-7 {
      @include transition;
    }
    .gallery_main_area {
      @include transition;
      position: absolute;
      right: 0;
      bottom: 177px;
      width: 60%;
      .gallery-thumbs {
        margin-top: 0;
        padding-left: 20px;
        padding-right: 20px;

        .item {
          padding-left: 10px;
          padding-right: 10px;
          img {
            max-width: 100%;
          }
          .caption_text {
            padding-left: 15px;
            padding-right: 15px;
            svg {
              width: 23px;
              height: 23px;
            }
            h4 {
              font-size: 12px;
              margin-top: 8px;
            }
          }
        }
      }
      .next svg,
      .prev svg {
        width: 10px;
      }
      .prev {
        left: 8px;
      }
      .next {
        right: 8px;
      }
    }
  }
  .gallery_inner_thumb {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    &:before {
      content: "";
      height: 145px;
      width: 100%;
      background: linear-gradient(180deg, rgba(44, 44, 81, 0) 0%, rgba(44, 44, 81, 0.9) 73.67%);
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 5px;
      top: 7px;
    }
  }
  .gallery-top {
    .col-lg-7 {
    }
    .art-video-player .art-bottom {
      .art-controls {
        .art-controls-right {
          .art-control.art-control-subtitle[aria-label="Hide subtitle"] {
            svg {
              fill: rgba(235, 245, 250, 0.5);
            }
          }
          .art-control.art-control-subtitle[aria-label="Show subtitle"] {
            svg {
              fill: #fff;
            }
          }
          .art-control.art-control-setting[aria-label="Hide subtitle"] {
            svg {
              fill: #fff;
            }
          }
          .art-control.art-control-setting[aria-label="Show subtitle"] {
            svg {
              fill: rgba(235, 245, 250, 0.5);
            }
          }
          .art-control.art-control-control13.art-control-onlyText {
            display: none;
          }
        }
      }
    }
    .art-auto-size {
      &:hover {
        .art-video-player .art-bottom {
          transform: translateY(0);
        }
      }
    }
    .art-auto-size {
      height: 410px !important;
    }
    .art-video-player .art-bottom {
      bottom: 0;
      background: rgba(235, 245, 250, 0.1);
      border-radius: 0 0 5px 5px;
      transform: translateY(48px);
      overflow: visible !important;
    }
    .art-video-player
      .art-bottom
      .art-controls
      .art-control-volume
      .art-volume-panel
      .art-volume-slider-handle {
      background: #fff;
    }
    .art-video-player
      .art-bottom
      .art-controls
      .art-control-volume
      .art-volume-panel
      .art-volume-slider-handle:after,
    .art-video-player
      .art-bottom
      .art-controls
      .art-control-volume
      .art-volume-panel
      .art-volume-slider-handle:before {
      background: #fff;
    }
    .art-video-player
      .art-bottom
      .art-controls
      .art-control-volume
      .art-volume-panel
      .art-volume-slider-handle:after {
      background: rgba(235, 245, 250, 0.5);
    }
    .art-video-player {
      border-radius: 10px;
      overflow: hidden;
    }
    .art-video-player .art-bottom .art-progress {
      background: rgba(235, 245, 250, 0.1);
    }
    .art-video-player .art-bottom .art-progress .art-control-progress {
      height: 2px;
      @include transition;
      &:hover {
        height: 4px;
        margin-top: -2px;
      }
    }
    .art-video-player .art-bottom .art-controls .art-control {
      color: #fff;
    }
    .art-video-player .art-bottom .art-controls .art-control .art-icon svg {
      fill: rgba(235, 245, 250, 0.5);
    }
    .art-video-player
      .art-bottom
      .art-controls
      .art-control
      .art-icon
      svg:hover {
      fill: #fff !important;
    }
    .art-video-player {
      height: 410px !important;
    }
  }
  &.theatermode {
    .gallery-top {
      .col-lg-7 {
        flex: 0 0 100%;
        max-width: 100%;
        .artplayer-app {
          height: 717px !important;
        }
        .art-video-player {
          height: 717px !important;
        }
      }
      .col-lg-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .slide_text {
        margin-top: 30px;
        padding-left: 0;
        padding-bottom: 4px;
        p {
          max-width: 100%;
        }
      }
    }
  }
}
.slide_text {
  padding-left: 8px;
  @include transition;
  h4 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  p {
    color: $black_600;
    max-width: 420px;
    margin-bottom: 12px;
    @include transition;
  }
  .video_user {
    a {
      color: $p_color;
      font-size: 14px;
      margin-right: 15px;
      display: inline-block;
    }
    img {
      width: auto;
      max-width: 100%;
      display: inline-block;
      position: relative;
      top: -1px;
    }
    ion-icon {
      position: relative;
      top: 2px;
    }
  }
}
.gallery_main_area {
  position: relative;
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      svg {
        path {
          fill: var(--brand_color);
        }
      }
    }
  }
  .gallery-thumbs .slick-track {
    margin: 0 -15px;
  }
  .prev {
    left: -20px;
  }
  .prev, .next {
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 8px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 1px solid #dfe1e5;
    z-index: 0;
    color: $black_400;
    background-color: #FFF;
    display: flex !important;
    justify-content: center;
    align-items: center;
    &.slick-disabled {
      display: none !important;
    }
    &:hover {
      color: $black_700;
    }
  }
  .next {
    right: -20px;
  }
}

.gallery-thumbs {
  margin-top: 45px;
  .item {
    padding: 0 15px;
    position: relative;
    cursor: pointer;
    > img {
      width: 100%;
    }
    .caption_text {
      .play-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-align: center;
        left: 50%;
        display: none;
        ion-icon {
          font-size: 18px;
        }
      }
      img {
        margin: auto !important;
      }
      h4 {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        text-align: center;
        position: absolute;
        bottom: 15px;
        transform: translateX(-50%);
        left: 50%;
      }
      svg {
        opacity: 0;
      }
    }
    &:hover {
      .caption_text {
        svg {
          opacity: 1;
        }
      }
    }
    &.slick-current {
      .caption_text {
        svg {
          opacity: 1;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 5;
  }
  .slick-prev {
    left: 0;
    &:before {
      content: "";
      font-size: 60px;
      color: $p_color;
      opacity: 1;
      background-image: url(../img/svg/arrow_left.svg);
    }
  }
  .slick-next {
    right: 0;
  }
}
.gallery-thumbs .item.slick-current .caption_text .play-icon {
  display: flex;
}

.social_option {
  margin-top: 32px;
  .count_div {
    &.active {
      i {
        color: var(--brand_color);
      }
    }
  }
  .count_div {
    display: inline-block;
    line-height: 40px;
    font-size: 14px;
    margin-right: 15px;
    color: $p_color;
    a, ion-icon {
      color: $p_color;
      margin-right: 2px;
    }
  }
  .share_gropu {
    position: relative;
    display: inline-block;
    width: 160px;
    @include transition;
    .nav {
      position: absolute;
      left: 0;
      top: 0;
      @include transition;
      transform: perspective(1px) translateZ(0);
      transform-origin: 0 50%;
      transition-property: transform;
      transform: scaleX(0);
    }
    &.active {
      width: 150px;
      .nav {
        transform: scaleX(1);
      }
    }
  }
  .nav {
    background: brand_color_rgba(.2);
    border-radius: 20px;
    box-shadow: 0 0.3px 0.9px rgba(29, 56, 70, 0.1),
      0 1.6px 3.6px rgba(29, 56, 70, 0.13);
    padding-right: 12px;
    li {
      display: inline-block;
      margin-right: 10px;
      ion-icon {
        color: $black_700;
      }
      &:first-child {
        a {
          background: #fafcfc;
          box-shadow: 0 0 3.6px rgba(29, 56, 70, 0.1);
          border-radius: 20px;
          height: 42px;
          width: 42px;
          text-align: center;
        }
      }
      a {
        color: #373b46;
        display: inline-block;
        line-height: 42px;
        ion-icon {
          position: relative;
          top: 2px;
        }
      }
      &:hover {
        a {
          color: var(--brand_color);
        }
      }
    }
  }
}
.status_area {
  margin-top: 125px;
  .status_inner {
    background: var(--bs-white);
    box-shadow: 0 1px 3px rgba(12, 0, 46, 0.1);
    border-radius: 5px;
    padding: 25px 50px;
    .media {
      align-self: center;
      align-items: center;
      .d-flex {
        margin-right: 86px;
      }
      .media-body {
        align-self: center;
        h4 {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 0;
        }
      }
      .media-right {
        .main_btn {
          background: var(--brand_color);
          color: #fff;
          line-height: 60px;
          padding: 0 37px;
          font-size: 20px;
          font-weight: 700;
          &:hover {
            color: #fff;
            background: var(--brand_color);
          }
        }
      }
    }
  }
}

.art-state, .play-icon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-icon {
    text-shadow: 0 6px 12px brand_color_rgba(0.5);
    color: var(--brand_color);
  }
}

.video_popup_slider {
  padding: 120px 0;
  background: #ffffff;
  .container {
    position: relative;
  }
  .prev1,
  .next1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50px;
    width: 50px;
    background: #54595d;
    opacity: 0.5;
    border-radius: 50%;
    @include transition;
    cursor: pointer;
    text-align: center;
    ion-icon {
      color: #fff;
      font-size: 30px;
      position: relative;
      top: 10px;
    }
    &:hover {
      opacity: 1;
    }
    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
  .prev1 {
    left: 25px;
  }
  .next1 {
    right: 25px;
  }
  .popup_slick {
    margin-left: -15px;
    margin-right: -15px;
    .item {
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      overflow: hidden;
      text-align: center;
      cursor: pointer;
      img {
        max-width: 100%;
      }
      &:hover {
        .item_modal_box {
          .text {
            svg {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
    .item_modal_box {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      height: 320px;
      video::-webkit-media-controls {
        display: none !important;
      }
      video::-moz-range-track {
        display: none !important;
      }
      video::-moz-range-thumb {
        display: none !important;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 145px;
        background: linear-gradient(
          180deg,
          rgba(29, 56, 70, 0) 0%,
          rgba(29, 56, 70, 0.8) 100%
        );
      }
      video {
        height: 320px;
        width: 100%;
        z-index: 3;
      }
      .text {
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 2;
        padding: 0 15px;

        svg,
        img {
          position: absolute;
          left: 50%;
          top: 39%;
          margin-left: -20px;
          margin-top: -20px;
          opacity: 0;
          transform: scale(0.8);
          @include transition;
        }
        h4 {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 17px;
          position: absolute;
          width: 100%;
          bottom: 0;
          text-align: center;
          padding: 0 15px;
          left: 0;
        }
      }
      &:hover {
        .text {
          img {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

.modal_slider_css {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      background: #4b5155;
      min-height: 100vh;
      .modal-header {
        border: none;
        padding: 0;
        padding-top: 25px;
        width: 100%;
        height: 76px;
        position: relative;
        .button_social {
          position: absolute;
          right: 0;
        }
        .close {
          height: 50px;
          width: 50px;
          background: #41484d;
          text-align: center;
          border-radius: 50%;
          font-size: 20px;
          color: #fff;
          opacity: 1;
          margin: 0;
          margin-right: 25px;
          float: none;
          display: inline-block;
          @include transition;
          ion-icon {
            position: relative;
            left: -1px;
            top: -1px;
          }
          &:hover {
            background: var(--brand_color);
          }
        }
        .share_icon {
          height: 50px;
          width: 50px;
          background: #41484d;
          text-align: center;
          border-radius: 50%;
          font-size: 20px;
          display: inline-block;
          color: #fff;
          opacity: 1;
          margin: 0;
          margin-right: 27px;
          padding: 1rem 1rem;
          cursor: pointer;
          @include transition;
          ion-icon {
            position: relative;
            left: -1px;
            top: -1px;
          }
          &:hover {
            background: var(--brand_color);
          }
        }
        .modal_social {
          position: absolute;
          right: 106px;
          top: 0;
          background: #393d40;
          width: 171px;
          border-radius: 25px;
          justify-content: flex-end;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
          transform-origin: 100% 50%;
          transition-property: transform;
          transform: scaleX(0);
          @include transition;
          &.active {
            transform: scaleX(1);
          }
          li {
            a {
              display: inline-block;
              margin-right: 15px;
              color: rgba(255, 255, 255, 0.5);
              @include transition;
              line-height: 50px;
              font-size: 18px;
              i {
                position: relative;
                top: 2px;
              }
              &:hover {
                color: #fff;
              }
            }
            &:last-child {
              a {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                text-align: center;
                background: #41484d;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04),
                  0 4px 8px rgba(0, 0, 0, 0.2);
                font-size: 22px;
                line-height: 56px;
                margin-right: 0;
              }
            }
          }
        }
      }
      .modal-body {
        padding-left: 0;
        padding-right: 0;
        #modal_slider {
          padding-left: 0;
        }
        .posting {
          position: relative;
        }
        .quesiton_slider {
          position: relative;
        }
        .prev_modal,
        .next_modal {
          position: absolute;
          top: 50%;
          height: 50px;
          width: 50px;
          background: rgba(166, 174, 179, 0.5);
          border-radius: 50%;
          @include transition;
          color: #c0c3cc;
          text-align: center;
          line-height: 60px;
          cursor: pointer;
          transform: translateY(-50%);
          z-index: 5;
          &:hover {
            background: var(--brand_color);
          }
          ion-icon {
            font-size: 20px;
          }
          &.slick-disabled {
            opacity: 0;
            visibility: hidden;
          }
        }
        .prev_modal {
          left: 60px;
        }
        .next_modal {
          right: 60px;
        }
        .posting {
          max-width: 1350px;
          margin: auto;
          .video_item {
            transform: scale(0.8);
            margin: 0 20px;
            @include transition;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            h4 {
              position: absolute;
              left: 0;
              bottom: 10px;
              width: 100%;
              text-align: center;
              color: #fff;
              font-size: 20px;
              font-weight: 400;
              opacity: 0;
              visibility: hidden;
              z-index: 3;
            }
            .video-js {
              max-width: 100%;
              height: 669px;
              border-radius: 5px;
              overflow: hidden;
              .vjs-poster {
                background-size: cover;
              }
              .vjs-progress-control.vjs-control {
                margin: 0;
                margin-right: -40px;
              }
              .vjs-time-tooltip {
                display: none;
              }
              .vjs-progress-control .vjs-play-progress:before {
                display: none;
              }
              .vjs-progress-control .vjs-load-progress {
                background-color: #9da2a6;
              }
              .vjs-progress-control .vjs-play-progress {
                background-color: #fff;
              }
              .vjs-progress-control .vjs-load-progress,
              .vjs-progress-control .vjs-play-progress {
                height: 5px;
              }
              .vjs-progress-control .vjs-slider:before {
                height: 5px;
                background: #9da2a6;
              }
              .vjs-big-play-button {
                background: url(../img/play-button.png) no-repeat;
                border: none;
                display: none;
              }
              .vjs-control-bar {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: transparent;
                height: 5px;
                .vjs-button {
                  display: none;
                }
                .vjs-progress-control .vjs-slider:before {
                }
                .vjs-current-time {
                  display: none;
                }
                .vjs-duration {
                  display: none;
                }
              }
            }
            video {
              border-radius: 5px;
              overflow: hidden;
            }
            img {
              max-width: 100%;
            }
          }
          li {
            .video_item {
              position: relative;
              &:before {
                content: "";
                background: rgba(107, 112, 127, 0.5);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
              }
            }
            .video_item {
              position: relative;
              filter: blur(5px);
              @include transition;
              &:before {
                content: "";
                background: rgba(107, 112, 127, 0.5);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                opacity: 0;
                @include transition;
                z-index: 5;
              }
              &:hover {
                filter: blur(0);
                &:before {
                  opacity: 1;
                }
              }
            }
            &.slick-current {
              .video_item {
                transform: scale(1);
                filter: blur(0);

                border-radius: 0 0 5px 5px;
                &:before {
                  display: none;
                }
                &:after {
                  content: "";
                  background: linear-gradient(
                    180deg,
                    rgba(29, 56, 70, 0) 0%,
                    rgba(29, 56, 70, 0.8) 100%
                  );
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: 285px;
                  width: 100%;
                }
                h4 {
                  visibility: visible;
                  opacity: 1;
                }
                .vjs-big-play-button {
                  display: block;
                }
                .vjs-playing {
                  .vjs-big-play-button {
                    display: none;
                  }
                }
                .vjs-has-started {
                  .vjs-big-play-button {
                    display: none;
                  }
                }
                .vjs-ended {
                  .vjs-big-play-button {
                    display: block;
                  }
                }
                .vjs-paused {
                  .vjs-big-play-button {
                    display: block;
                  }
                }
              }
              & + .slick-active {
                .video_item {
                  filter: blur(5px);
                  @include transition;
                  &:hover {
                    filter: blur(0);
                    &:before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
        h3 {
          color: #fff;
          font-size: 20px;
          font-weight: 400;
          margin-left: 50px;
          margin-bottom: 18px;
          margin-top: 50px;
        }
        .main_div_carousel {
          position: relative;
          .prev_car,
          .next_car {
            position: absolute;
            top: 50%;
            height: 50px;
            width: 50px;
            background: rgba(34, 37, 38, 0.9);
            border-radius: 50%;
            @include transition;
            color: #c0c3cc;
            text-align: center;
            line-height: 60px;
            cursor: pointer;
            transform: translateY(-50%);
            opacity: 0;
            ion-icon {
              font-size: 20px;
            }
            &:hover {
              background: #000;
            }
          }
          .prev_car {
            left: 60px;
            &.slick-disabled {
              opacity: 0;
              visibility: hidden;
            }
          }
          .next_car {
            right: 60px;
            &.slick-disabled {
              opacity: 0;
              visibility: hidden;
            }
          }
          &:hover {
            .prev_car,
            .next_car {
              opacity: 1;
            }
          }
          .slick-current {
            .text_item {
              background: #393d40;
              h4 {
                color: #fff;
              }
            }
          }
        }
        #modal_carousel {
          margin-bottom: 0;
          li {
            padding-left: 10px;
            padding-right: 10px;
          }
          .slick-list {
            padding: 0 27% 0 0 !important;
          }
        }
        .text_item {
          background: #393d40;
          padding: 13px 24px;
          border-radius: 5px;
          h4 {
            color: #8a8e99;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.video_slider_area {
  .video-js {
    min-height: 410px;
    border-radius: 5px;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(44, 44, 81, 0.6);
      border-radius: 5px;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &.vjs-has-started {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
    .vjs-big-play-button {
      border: none;
    }
    .vjs-control-bar {
      transform: translateY(45px);
    }
    .vjs-control-bar .vjs-button {
      color: rgba(235, 245, 250, 0.5);
      @include transition;
      &:hover {
        color: #fff;
      }
    }
    .vjs-control.vjs-button.vjs-control-button.vjs-mode-control {
      span {
        border-color: rgba(235, 245, 250, 0.5);
        @include transition;
      }
      &:hover {
        span {
          border-color: #fff;
        }
      }
    }
    &:hover {
      .vjs-control-bar {
        transform: translateY(0);
      }
    }
  }
}

.art-video-player .art-bottom .art-controls{
  background: var(--bs-white);
}

/* End Video Area css
============================================================================================ */
