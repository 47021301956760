.body_dark{
    /** === Focus, active color === **/
    &.onepage-doc .nav-sidebar .nav-item .docs-progress-bar, .fontsize-controllers .btn-group .btn:focus,
    .doc_rightsidebar .bootstrap-select > .dropdown-menu .inner .dropdown-menu li a.active, .doc_rightsidebar .bootstrap-select > .dropdown-menu .inner .dropdown-menu li a:hover{
        background: #444857;
    }
    /** !-- End Focus, active color **/

    &.onepage-doc .nav-sidebar .nav-item, .doc_rightsidebar .bootstrap-select > .dropdown-menu, .doc_rightsidebar .dropdown-toggle, .fontsize-controllers .btn-group,
    &.onepage-doc .nav-sidebar .nav-item .dropdown_nav, .doc_rightsidebar .doc_switch input[type=checkbox],
    .doc_rightsidebar .pageSideSection::before, .doc_rightsidebar .bootstrap-select > .dropdown-menu .inner .dropdown-menu {
        background: #2c303a;
    }
    .side_menu, .doc_left_sidebarlist:before{
        background: #1e1f26;
    }

    /** === Border color === **/
    .doc_rightsidebar .doc_switch input[type=checkbox], .topic_item_tabs .topic_list_item,
    .comment_box .post_comment .comment_author .media-body,
    .doc_rightsidebar .dropdown-toggle, .fontsize-controllers .btn-group .btn.rvfs-reset, .fontsize-controllers .btn-group,
    .doc_left_sidebarlist:before, .doc_left_sidebarlist,.nav-sidebar + .nav-sidebar,.doc_rightsidebar,.v_menu,.toggle_body,
    .basic_table_info,.basic_table_info tbody tr th, .basic_table_info tbody tr td,.typography_content .code-preview,.doc_left_sidebarlist:before{
        border-color: #2c303a;
    }
    .blog_comment_box.topic_comment .get_quote_form .form-group input:focus ~ .floating-label, .blog_comment_box.topic_comment .get_quote_form .form-group textarea:focus ~ .floating-label,
    .blog_comment_box.topic_comment .get_quote_form .form-group input:not(:focus):valid ~ .floating-label,
    .blog_comment_box.topic_comment .get_quote_form .form-group .form-control {
        background: #252830;
        border-color: #252830;
    }
    &.onepage-doc .nav-sidebar .nav-item .dropdown_nav:before{
        background: #272830;
    }
    /** !-- End Border color **/

    .nav-sidebar .nav-item {
        .nav-link {
            color: #c7c9d3;
            &.active, &:hover {
                color: var(--brand_color);
            }
        }
        .dropdown_nav li a {
            color: #bbb;
        }
    }
    .doc_menu .nav-link,
    .doc_rightsidebar .doc_right_link li a {
        color: #bbb;
    }
    .doc_menu .nav-link.active, .doc_menu .nav-link:hover, .side_menu .mobile_menu_header .close_nav {
        color: #fff;
    }
    .documentation_item .icon{
        background: rgba(255, 255, 255, 0.2);
    }
    .fontsize-controllers .btn-group .btn,
    .doc_rightsidebar .bootstrap-select .dropdown-toggle .filter-option-inner-inner{
        color: #c7c9d3;
    }
    .border_bottom{
        background: #384c59;
    }
    .link,.version,.question_box .signup_form .input-group,.tab_shortcode .tab-content{
        border: 1px solid #384c59;
        background: #1f3341;
    }
    .tab_shortcode .nav-tabs .nav-item .nav-link{
        background: #1f3341;
        border-color: #384c59;
    }
    .question_box .signup_form .input-group{
        .form-control{
            color: #6b707f;
        }
        button{
            &:before{
                background: #384c59
            }
        }
    }
    .search-banner-light .header_search_keyword .header-search-form__keywords-label,
    .search-banner-light .header_search_keyword ul li a,
    .get_started p{
        color: #d3d4d4;
    }
    .f_bg_color,.page_breadcrumb,.toggle_btn,.doc_accordion .card-header button{
        background: #1f3341;
    }

    .shortcode_text ul li a, .dark_menu .menu > .nav-item > .nav-link,
    .tab_shortcode .nav-tabs .nav-item .nav-link.active,.page_breadcrumb .breadcrumb .breadcrumb-item.active,.breadcrumb-item+.breadcrumb-item::before,.toggle_btn.collapsed:before,.toggle_btn.collapsed,.doc_accordion .card-header button,.table{
        color: #fff;
    }
    .toggle_body,.doc_accordion,.message_alert{
        background: #061f2f;
    }
    .toggle_btn{
        color: var(--brand_color);
    }
    .menu > .nav-item.submenu .dropdown-menu .nav-item .nav-link h5{
        color: #1d2746;
    }
    .card_tagged li a {
        color: #e3e4e8;
        background: #444857;
        &:hover {
            opacity: 0.9;
        }
    }
    .data_table_area .table.dataTable thead tr {
        background: #2f3337;
    }
}
