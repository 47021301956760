.doc_documentation_area {
  padding-bottom: 110px;
  z-index: 0;
  @media (min-width: 1400px) {
    min-height: 1000px;
  }
  .overlay_bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #1d2746;
    opacity: 0;
    z-index: 1;
    visibility: hidden;
    transition: all 0.4s linear;
  }
  &.body_fixed {
    .doc_left_sidebarlist {
      position: fixed;
      top: 0;
      width: 270px;
      @media (min-width: 1500px) {
        width: 307px;
      }
      @media (max-height: 630px) {
        padding-top: 70px;
      }
    }
    .doc_rightsidebar {
      position: fixed;
      top: 0;
      @media (max-height: 630px) {
        padding-top: 70px;
      }
    }
  }
  &.overlay {
    .overlay_bg {
      opacity: 0.5;
      visibility: visible;
    }
  }
}
.full-width-doc {
  .body_fixed {
    .doc_rightsidebar,
    .doc_left_sidebarlist {
      padding-top: 30px;
    }
  }
}

/*============ doc_left_sidebarlist css =========*/
.doc_left_sidebarlist {
  padding-top: 70px;
  padding-bottom: 30px;
  z-index: 1;
  margin-right: 30px;
  position: relative;
  height: 100%;
  &:before {
    content: "";
    width: 200%;
    right: 0;
    height: 100%;
    background: #fafcfc;
    position: absolute;
    border-right: 1px solid #E6EDF0;
    top: 0;
    z-index: -1;
  }
  .scroll {
    max-height: 760px;
    .mCSB_inside > .mCSB_container {
      margin-right: 0 !important;
    }
    @media (max-height: 630px) {
      padding-bottom: 0;
      max-height: 450px;
    }
  }
  h2 {
    margin-bottom: 25px;
  }
}
.nav-sidebar {
  margin-bottom: 0;
  .nav-item {
    margin-bottom: 20px;
    padding-right: 35px;
    position: relative;
    cursor: pointer;
    .nav-link {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3c4257;
      position: relative;
      img{
        padding-right: 15px;
        margin-top: -2px;
      }
      svg {
        margin-right: 15px;
        position: relative;
        top: -1px;
        path {
          @include transition;
        }
      }
      & + .icon {
        font-size: 20px;
        color: $p_color;
        float: right;
        position: absolute;
        right: 30px;
        top: -2px;
        transition: all 0.3s linear;
      }
    }
    &:hover,
    &.active {
      .nav-link {
        color: var(--brand_color);
      }
      .icon {
        color: var(--brand_color);
      }
      svg {
        path {
          fill: #5cbbe5;
        }
      }
    }
    &.active {
      .icon {
        transform: rotate(-180deg);
      }
    }
    .dropdown_nav {
      padding-left: 32px !important;
      margin-bottom: 0;
      padding-top: 12px;
      width: 100% !important;
      display: none;
      li {
        a {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          color: #697386;
          padding: 0;
          &:hover,
          &.active {
            color: #3c4257;
            i {
              color: var(--brand_color);
            }
          }
          &.active {
            font-weight: 500;
          }
        }
      }
    }
  }
  & + .nav-sidebar {
    border-top: 1px solid #e5e9eb;
    padding-top: 20px;
  }
}
.coding_nav {
  padding-top: 15px;
  padding-bottom: 20px;
}
.bottom_nav {
  .nav-item .nav-link {
    i {
      position: relative;
      color: $h_title;
      padding-left: 4px;
      transition: all 0.2s linear;
    }
    &:hover {
      i {
        transform: translateX(5px);
      }
    }
  }
}
/*============ doc_left_sidebarlist css =========*/

.documentation_info {
  .c_head {
    font-weight: 500;
    margin-bottom: 12px;
    .anchorjs-link {
      font-size: 22px !important;
    }
  }
  .slideshow {
    width: auto;
    height: 600px;
    .slide {
      width: inherit;
      height: inherit;
      .item--horse {
        top: 124px;
        left: 78px;
      }
    }
    .nav {
      display: none;
    }
    .title {
      color: var(--brand_color);
    }
  }
}

.sticky-nav-doc #documentation {
  padding-top: 174px;
}

.documentation_body {
  padding-top: 60px;
  footer {
    .border_bottom {
      margin-top: 100px;
    }
  }
}
.shortcode_title {
  .btn {
    display: inline-block;
    padding: 0 9.5px;
    background: brand_color_rgba(0.5);
    color: #fff;
    text-transform: uppercase;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    border-radius: 3px;
    outline: none;
    box-shadow: none;
    letter-spacing: .5px;
    padding-top: 1px;
  }
}
.table-of-content {
  padding-top: 15px;
}
.doc_documentation_area .shortcode_title {
  margin-bottom: 40px;
  h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  h4 {
    margin-bottom: 15px;
    a {
      color: $p_color;
      margin-left: 4px;
    }
    &:hover {
      a {
        color: var(--brand_color);
      }
    }
  }
  p {
    span {
      font-weight: 500;
    }
  }
  h4 {
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 500;
  }
  & + ul {
    margin-top: -0.8rem;
  }
}

.get_started {
  padding-top: 45px;
  padding-bottom: 50px;
  .c_head {
    i {
      color: var(--brand_color);
      font-size: 16px;
      margin-left: 8px;
    }
  }
  p {
    color: $p_color;
    a {
      color: var(--brand_color);
    }
  }
}
.link {
  background: #f1fdf3;
  padding: 14px 10px 14px 30px;
  border-radius: 4px;
  p {
    position: relative;
    margin-bottom: 0;
    padding-left: 40px;
    &:before {
      content: "\52";
      position: absolute;
      font-family: eleganticons;
      left: 0;
      top: 4px;
      font-size: 24px;
      color: #10d631;
    }
  }
}

.test_version {
  padding-top: 45px;
  p {
    font-size: 16px;
    span {
      color: $h_title;
    }
  }
}
.v_menu {
  border-bottom: 1px solid #e8ecee;
  padding-bottom: 20px;
  padding-top: 5px;
  margin-bottom: 20px;
  .nav-item {
    display: inline-block;
    .nav-link {
      font-size: 16px;
      color: $p_color;
      transition: all 0.2s linear;
      padding: 0;
      border: 0;
      border-radius: 0;
      span {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $bs_white;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        margin-right: 10px;
        transition: all 0.2s linear;
        position: relative;
        top: -2px;
      }
      &.active,
      &:hover {
        color: var(--brand_color);
        border: 0;
        background: transparent;
        span {
          background: var(--brand_color);
          color: #fff;
        }
      }
    }
    & + li {
      margin-left: 55px;
    }
  }
}
.process_tab_shortcode {
  .version {
    background: #f8fafb;
    padding: 30px;
    font-size: 15px;
    margin-bottom: 28px;
    p {
      margin-bottom: 20px;
      font-size: 15px;
      color: #6b707f;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .v_head {
      padding-bottom: 24px;
    }
    .v_middle {
      p {
        margin-bottom: 0;
        font-size: 15px;

        .red {
          color: #f12249;
        }

        .green {
          color: #11c52f;
        }
      }
    }
    .v_footer {
      padding-top: 25px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
.developer {
  padding: 34px 0 20px;
  p {
    a {
      color: var(--brand_color);
    }
  }
  .tag_list li {
    margin-top: 10px;
    i {
      padding-right: 10px;
    }
  }
}
.help_text {
  padding: 0 0 0;
  .help_info {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    margin-left: -30px;
    margin-right: -30px;
    .help_item {
      width: 33.33%;
      margin-bottom: 15px;
      padding: 0 30px;
      h4 {
        font-size: 16px;
        margin-bottom: 15px;
        transition: color 0.2s linear;
        i {
          font-size: 18px;
          top: 2px;
          position: relative;
        }
        &:hover {
          color: var(--brand_color);
        }
      }
    }
  }
}

.code_structure {
  padding-top: 50px;
  .c_head {
    margin-bottom: 8px;
  }
}

.process_tab_shortcode {
  position: relative;
  overflow: hidden;
  .previous,
  .next {
    position: absolute;
    top: 56%;
    transform: translateY(-50%);
    padding: 0;
    border-radius: 0;
    background: #e1ebf0;
    border: 0;
    color: var(--brand_color);
    outline: none;
    box-shadow: none;
    transition: all 0.4s linear;
    height: 32px;
    width: 19px;
    text-align: center;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      outline: none;
      box-shadow: none;
      background: var(--brand_color);
      color: #fff;
    }
  }
  .next {
    right: -30px;
    border-radius: 5px 0 0 5px;
  }
  .previous {
    left: -30px;
    border-radius: 0 5px 5px 0;
  }
  &:hover {
    .next {
      right: 0;
    }
    .previous {
      left: 0;
    }
  }
}

.question_box {
  padding-top: 35px;
  padding-bottom: 70px;
  .question_text {
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 18px;
    }
    p {
      a {
        color: var(--brand_color);
      }
    }
  }
  .question_text_two {
    padding-left: 50px;
  }
  .signup_form {
    padding-top: 10px;
    padding-bottom: 20px;
    .input-group {
      border-radius: 4px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 2px 6px 0 rgba(2, 48, 58, 0.14);
      .form-control {
        background: transparent;
        border: 0;
        font-size: 16px;
        color: $h_title;
        box-shadow: none;
        padding-left: 20px;
        height: 50px;
        @include placeholder {
          color: #9c9fa9;
        }
      }
      button {
        padding: 0;
        background: transparent;
        border: 0;
        font-size: 16px;
        font-weight: 500;
        color: var(--brand_color);
        padding: 0 20px;
        position: relative;
        &:before {
          content: "";
          width: 1px;
          background: #e8ecee;
          top: 8px;
          bottom: 8px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}
.feedback_link {
  align-items: center;
  padding-top: 20px;
  h6 {
    font-weight: 500;
    font-size: 14px !important;
    color: $p_color;
    margin-bottom: 0;
    margin-top: -10px;
    a {
      color: var(--brand_color);
    }
    i {
      padding-right: 8px;
    }
  }
  p {
    margin-bottom: 0;
    text-align: right;
  }
  .h_btn {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
    padding: 1px 14px 0;
    background-color: #27ae60;
    transition: all 0.2s linear;
    margin-left: 18px;
    border-radius: 2px;
    &.red {
      background: #e74c3c;
    }
    span {
      height: 15px;
      width: 15px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      font-size: 10px;
      background: #fff;
      font-weight: 400;
      color: $h_title;
      margin-left: 6px;
      position: relative;
      top: -1px;
    }
    &:hover {
      color: #fff;
    }
  }
}

.help_form {
  max-width: 730px;
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
  .modal-content {
    border: 0;
    .shortcode_title {
      margin-bottom: 30px;
    }
  }
}
.contact_form {
  .form-group {
    margin-bottom: 30px;
    .form-control,
    textarea {
      border: 1px solid rgb(244, 248, 248);
      height: 58px;
      border-radius: 4px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 4px 10px 0 rgba(4, 73, 89, 0.08);
      color: #878b99;
      font-weight: 400;
      padding-left: 30px;
      @include placeholder {
        color: #878b99;
      }
      &:focus {
        box-shadow: 0 20px 30px 0 rgba(4, 73, 89, 0.1);
      }
    }
    textarea {
      width: 100%;
      height: 160px;
      padding-top: 20px;
      display: block;
    }
    .action_btn {
      border: 0;
      padding: 15px 40px;
      box-shadow: none;
      font-weight: 500;
      &:hover {
        box-shadow: 0 20px 30px 0 brand_color_rgba(0.24);
      }
    }
  }
}

/*========== doc_rightsidebar css ============*/
.mCSB_scrollTools {
  width: 0;
}
.open_icon {
  width: 40px;
  height: 40px;
  background: #e8eeff;
  line-height: 40px;
  font-size: 32px;
  text-align: center;
  position: absolute;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  left: -40px;
  color: var(--brand_color);
  display: none;
  i {
    display: inline-block;
    & + i {
      display: none;
    }
  }
  &.overlay {
    i {
      display: none;
      & + i {
        display: inline-block;
      }
    }
  }
}
.full-width-doc {
  .doc_rightsidebar {
    padding-right: 15px;
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      width: 225px;
    }
  }
}
.doc_rightsidebar {
  margin-left: -20px;
  padding-top: 70px;
  height: 100%;
  position: relative;
  .pageSideSection {
    margin-left: 25px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background: #e6edf0;
      left: 0;
    }
  }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 204px;
  }
  @media (max-height: 530px) {
    padding-bottom: 0;
    max-height: 300px;
  }
  &.scroll {
    .mCSB_inside > .mCSB_container {
      margin-right: 0;
    }
  }
  h6 {
    font-size: 12px;
    font-weight: 600;
    color: #9DAAB6;
    i {
      margin-right: 6px;
      font-size: 16px;
      position: relative;
      top: 3px;
    }
  }
  .doc_right_link {
    li {
      margin-bottom: 10px;
      padding-left: 25px;
      position: relative;
      a {
        font-size: 14px;
        font-weight: 500;
        color: $p_color;
        i {
          padding-right: 12px;
          position: absolute;
          left: 0;
          font-size: 12px;
          top: 5px;
        }
      }
    }
  }
}
.doc_menu {
  .nav-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 0;
    color: #677681;
    position: relative;
    margin-bottom: 6px;
    &.active {
      color: var(--brand_color);
      font-weight: 500;
      + .nav .active::before {
        display: none;
      }
      &::before {
        left: -25px;
        height: 100%;
        content: " ";
        position: absolute;
        border-left: 2px solid;
        color: var(--brand_color);

      }
    }
  }
  .nav {
    padding-left: 20px;
  }
}

.mobile_menu {
  width: 300px;
  position: fixed;
  height: 100vh;
  max-height: 100vh !important;
  top: 0;
  background: #fff;
  right: -300px;
  z-index: 1050;
  transition: all 0.4s linear;
  .doc_left_sidebarlist {
    padding-left: 20px;
    &:before {
      display: none;
    }
  }
  &.open {
    right: 0;
  }
  .close_nav {
    padding-top: 20px;
    padding-left: 30px;
    font-size: 28px;
  }
}

/*=============== doc_documentation_full_area css =========*/
.full-width-doc {
  .doc_left_sidebarlist {
    margin-right: 0;
    padding-left: 0;
  }
  &.body_fixed {
    .doc_left_sidebarlist {
      width: 287px;
    }
  }
  .doc_rightsidebar {
    margin-left: 0;
  }
}

body.doc-no-banner {
  .doc_left_sidebarlist {
    padding-top: 0;
    margin-top: 70px;
    &::before {
      background: transparent;
    }
  }
}

body:not(.onepage-doc) .sticky_menu {
  .doc_documentation_area {
    &.body_fixed {
      .doc_left_sidebarlist,
      .doc_rightsidebar {
        top: 78px;
      }
    }
  }
}

.resource {
  .c_head {
    margin-bottom: 5px;
  }
  .tag_list {
    padding-top: 0;
    li {
      margin-top: 4px;
      a {
        display: inline-block;
        font-weight: 500;
      }
    }
  }
}

/*========== typography_content css ========*/
.doc {
  .code-preview {
    border: 1px solid #e5ebef;
    padding: 20px;
    box-shadow: 0 3px 6px 0 rgba(4, 73, 89, 0.02);
    position: relative;
    #header-name {
      margin-bottom: 0;
    }
    img {
      max-width: 100%;
    }
    &.video_img {
      display: inline-block;
    }
  }
  .code-toolbar {
    .snippets {
      margin-bottom: 0;
      code {
        margin-bottom: 20px;
      }
    }
  }
  .code_item {
    padding-top: 15px;
    p {
      a {
        color: var(--brand_color);
      }
    }
  }
}
.vjs-iframe-blocker {
  display: none;
}
.video-js {
  margin: 0 auto;
  width: 100%;
  max-width: 640px;
  height: 360px;
  .mfp-close {
    right: -55px;
    top: -10px;
  }
}

/*============ Sticky Nav doc css ================*/
.sticky-nav-doc {
  &:not(.onepage-doc) .body_fixed .doc_rightsidebar,
  &:not(.onepage-doc) .body_fixed .doc_left_sidebarlist {
    padding-top: 100px;
  }
  .body_fixed.body_navbar_fixed .doc_left_sidebarlist,
  .body_fixed.body_navbar_fixed .doc_rightsidebar {
    padding-top: 30px;
  }
}

/*============ onepage doc css ================*/
.onepage-doc {
  .shortcode_info {
    padding-left: 0;
    padding-right: 0;
  }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 230px;
  }
  &.body_fixed {
    .doc_left_sidebarlist {
      padding-top: 30px;
    }
  }
  .doc_left_sidebarlist {
    width: 270px;
    margin-right: 0;
    border: 0;
    padding-top: 0;
    &:before {
      display: none;
    }
  }
  .nav-sidebar {
    .nav-item {
      padding-right: 0;
      background: #f8fafb;
      margin-bottom: 0;
      margin-top: 3px;
      position: relative;
      .docs-progress-bar {
        position: absolute;
        background: #ebf0f1;
        bottom: 0;
        left: 0;
        top: 0;
        max-height: 48px;
      }
      .nav-link {
        padding: 13px 20px;
      }
      .icon {
        top: 0;
        right: 20px;
        line-height: 48px;
        font-size: 18px;
        position: absolute;
        color: var(--brand_color);
        transform: rotate(0deg);
        i {
          display: inline-block;
          & + i {
            display: none;
          }
        }
      }
      &.active {
        .icon {
          i {
            display: none;
            & + i {
              display: inline-block;
            }
          }
        }
        .dropdown_nav {
          display: block;
        }
      }
      .dropdown_nav {
        padding-top: 15px;
        padding-bottom: 15px;
        background: #fff;
        position: relative;
        &:before {
          content: "";
          width: 2px;
          bottom: 15px;
          background: #edf1f3;
          position: absolute;
          top: 15px;
          left: 20px;
        }
        .nav-item {
          margin-top: 0;
          background: transparent;
          position: relative;
          padding-left: 22px;
          &:before {
            content: "\35";
            position: absolute;
            left: 0;
            top: 0;
            font-family: "ElegantIcons";
            color: var(--brand_color);
          }
          .docs-progress-bar {
            display: none;
          }
          .nav-link {
            padding: 0;
            color: $p-color;
          }
          &.active {
            .nav-link {
              color: var(--brand_color);
            }
          }
        }
      }
    }
  }
  .doc_rightsidebar {
    margin-left: 0;
    padding-right: 10px;
    padding-top: 0;
  }
  .doc-container {
    padding-top: 100px;
  }
}
.doc_rightsidebar {
  .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-size: 16px;
    font-weight: 400;
  }
  .dropdown-toggle {
    background: #fafcfd;
    border: 1px solid #e1e4e5;
    border-radius: 5px;
    font-size: 16px;
    color: #6b707f;
    padding: 9px 20px 0;
    box-shadow: none;
    &:after {
      content: "\33";
      border: 0;
      font-family: "ElegantIcons";
      top: -2px;
      position: relative;
      color: #6b707f;
    }
    i {
      padding-right: 8px;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .bootstrap-select {
    margin-bottom: 30px;
    > .dropdown-menu {
      box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
      background: #fff;
      border: 0;
      .inner {
        .dropdown-menu {
          border: 0;
          li {
            a {
              color: $p-color;
              position: relative;
              font-size: 14px;
              &:before {
                content: "";
                width: 2px;
                height: 0;
                position: absolute;
                left: 0;
                top: 0;
                background: var(--brand_color);
                transition: all 0.2s linear;
              }
              i {
                padding-right: 8px;
              }
              &.active,
              &:hover {
                background: #f6f6f6;
                color: var(--brand_color);
                &:before {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  #font-switcher {
    margin-bottom: 30px;
  }
}
.remove {
  .doc_left_sidebarlist {
    @include transition;
  }
  .doc_rightsidebar {
    @include transition;
  }
}
.test.remove {
  .doc_left_sidebarlist {
    padding-top: 140px;
    @include transition;
  }
  .doc_rightsidebar {
    padding-top: 140px;
    @include transition;
  }
}
.test {
  .doc_left_sidebarlist {
    padding-top: 70px;
    @include transition;
  }
  .doc_rightsidebar {
    padding-top: 70px;
    @include transition;
  }
}

.fontsize-controllers{
  .btn-group{
    border: 1px solid #dfe2e4;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 0 0 rgba(3, 13, 37, 0.2),inset 0 -8px 14px 0 rgba(3, 13, 37, 0.1);
    border-radius: 4px;
    .btn{
      font-size: 16px;
      font-weight: 400;
      color: $h_title;
      line-height: 1.2;
      border: 0;
      &:focus{
        outline: none;
        box-shadow: none;
        background: #eff0f1;
      }
      &.rvfs-reset{
        border-left: 1px solid #dfe2e4;
        border-right: 1px solid #dfe2e4;
      }
    }
  }
}

.print {
  color: $p-color;
  font-size: 18px;
}
.nav_title {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  background: #1d2746;
  border-radius: 4px;
  line-height: 22px;
  padding: 13px 30px;
  margin-bottom: 0;
}

body:not(.onepage-doc) .body_fixed #font-switcher {
  width: 204px;
}

/* Collaps Tabs Area css
============================================================================================ */
.collaps_tabs {
  margin-bottom: 70px;
  .collaps_tab_inner {
    .shortcode_title {
      margin-bottom: 25px;
      h4 {
        a {
          color: $h_title;
        }
      }
    }
  }
}

.doc-btm {
  margin-top: 70px;
  .card_tagged {
    li {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      margin-right: 9.32px;

      a {
        background: #edf0f5;
        color: #616366;
        line-height: 25px;
        padding: 0 14px;
        display: inline-block;
        border-radius: 12.5px;
        font-size: 14px;
        font-weight: 400;

        &:hover {
          color: var(--brand_color);
          background: brand_color_rgba(0.15);
        }
      }
    }
  }
}

.topic_item_tabs {
  margin-top: 50px;
  margin-bottom: -30px;
  overflow: hidden;
  .topic_list_item {
    background: transparent;
    border: 1px solid #e6edf0;
    border-radius: 5px;
    padding: 37px 35px 35px;
    box-shadow: none !important;
    h4 {
      font-weight: 500;
      font-size: 16px;
    }
    .navbar-nav {
      li {
        a {
          color: var(--p_color);
          i {
            color: var(--brand_color);
          }
        }
        &:hover {
          a {
            color: var(--brand_color);
            i {
              color: var(--brand_color);
            }
          }
        }
      }
    }
  }
}
.blog_comment_box.topic_comment {
  .c_head {
    display: inline-block;
    margin-bottom: 15px;
    a {
      color: $h_title;
      text-decoration: underline;
      font-weight: 500;
    }
  }
  .cancle {
    display: inline-block;
    padding: 0;
    border: none;
    color: #54595d;
    background: transparent;
    font-weight: 400;
    font-size: 16px;
  }
}

.get_quote_form {
  .form-group {
    position: relative;
    .form-control {
      border-color: #e1e8ea;
      font-size: 16px;
      color: $p_color;
      font-weight: 400;
      box-shadow: none !important;
      border-radius: 4px;
      @include placeholder {
        font-size: 16px;
        font-weight: 400;
        color: $p_color;
      }
      &:focus {
        border-color: var(--brand_color);
      }
    }
    label {
      margin-bottom: 0;
    }
    input:focus ~ .floating-label,
    textarea:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label {
      top: -10px;
      left: 26px;
      font-size: 12px;
      color: var(--brand_color);
      background-color: #fff;
      padding: 0 5px 0 5px;
    }
    textarea:focus ~ .floating-label,
    textarea:not(:focus):valid ~ .floating-label {
      top: -11px;
      left: 26px;
      font-size: 12px;
      color: var(--brand_color);
      background-color: #fff;
      padding: 0 5px 0 5px;
    }
    .floating-label {
      position: absolute;
      pointer-events: none;
      left: 40px;
      top: 16px;
      transition: 0.2s;
      margin-bottom: 0;
      color: $p_color;
    }
    .action_btn {
      background: var(--brand_color);
      border-radius: 4px;
      &hover {
        box-shadow: 0 15px 35px -3px brand_color_rgba(0.3);
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.check_input input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check_input label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: $p_color;
}

.check_input label:before {
  content: "";
  background-color: transparent;
  border: 1px solid $p_color;
  padding: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 9px;
  top: -1px;
  position: relative;
}

.check_input input:checked + label:after {
  content: "\4e";
  font-family: "ElegantIcons";
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 12px;
}

/* Forum Collaps Tabs Area css
============================================================================================ */
.small-dialog {
  padding: 50px 40px;
  .count {
    background: var(--brand_color);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    left: -20px;
    top: -20px;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    color: $h_title;
    margin-bottom: 13px;
  }
  p {
    margin-bottom: 0;
  }
}
.gallery_content {
  .mfp-container {
    max-width: 510px;
    margin: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 42%;
    height: auto;
    padding: 0;
    .mfp-arrow {
      top: auto;
      margin-top: 0;
      padding: 0;
      bottom: 0;
      opacity: 1;
      width: 50%;
      top: auto;
      bottom: -50px;
      background: #fff;
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin: 0;
      &:hover {
        background: var(--brand_color);
        &:after {
          color: #fff;
        }
        &:before {
          color: #fff;
        }
      }
    }
    .mfp-arrow-right {
      &:after {
        content: "Next";
        border: none;
        margin: 0;
        top: 0;
        left: 40%;
        transform: translateX(-50%);
        color: $p_color;
        font-size: 16px;
        font-weight: 500;
        opacity: 1;
      }
      &:before {
        content: "\35";
        font-family: "ElegantIcons";
        background: transparent;
        border: none;
        margin: 0;
        left: 54%;
        transform: translateX(-50%);
        color: $p_color;
        font-size: 18px;
        top: -1px;
        opacity: 1;
      }
    }
    .mfp-arrow-left {
      &:after {
        content: "Previous";
        border: none;
        margin: 0;
        top: 0;
        left: 38%;
        transform: translateX(-50%);
        color: $p_color;
        font-size: 16px;
        font-weight: 500;
      }
      &:before {
        content: "\34";
        font-family: "ElegantIcons";
        background: transparent;
        border: none;
        margin: 0;
        left: 29%;
        transform: translateX(-50%);
        color: $p_color;
        font-size: 18px;
        top: -1px;
      }
    }
  }
}
.mfp-bg.gallery_content {
  width: 100%;
  max-width: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

/* Styles for dialog window */
.small-dialog {
  background: white;
  padding: 50px 40px;
  text-align: left;
  max-width: 510px;
  margin: 0 auto;
  position: relative;
}

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
 * Fade-move animation for second dialog
 */

/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;

  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;

  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Data Table Area css
============================================================================================ */
.data_table_area {
  .dataTables_length {
    margin-top: 15px;
    margin-bottom: 15px;
    label {
      color: $p_color;
      font-size: 16px;
      font-weight: 400;
      margin-right: 10px;
      margin-bottom: 0;
      position: relative;
      top: 2px;
    }
    .mdb-select {
      height: 30px;
      width: 80px;
      border: 1px solid $black_50;
      color: $p_color;
    }
  }
  .dataTables_filter {
    margin-top: 0;
    margin-bottom: 15px;
    input[type=search] {
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $black_50;
      border-radius: 0;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      &:focus {
        border-bottom: 1px solid var(--brand_color);
        -webkit-box-shadow: 0 1px 0 0 var(--brand_color);
        box-shadow: 0 1px 0 0 var(--brand_color);
      }
    }
  }
  .table.dataTable {
    border: 1px solid $black_50;
    thead {
      tr {
        background: #f7f8f9;
        border-bottom: none;
        th {
          border: none;
          font-weight: 500;
          font-size: 16px;
          position: relative;
          cursor: pointer;
          padding-right: 30px;
        }
        th[aria-sort="ascending"] {
          &:after {
            color: $h_title;
          }
        }
        th[aria-sort="descending"] {
          &:before {
            color: $h_title;
          }
          &:after {
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-top: 1px solid $black_50;
          font-weight: 400;
          font-size: 14px;
          color: $p_color;
          white-space: nowrap;
        }
      }
    }
  }
  .dataTables_info {
    font-weight: 400;
    color: $p_color;
    margin-top: 4px;
  }
  .dataTables_paginate {
      justify-content: flex-end;
      margin-bottom: 0;
      margin-top: 10px;
      .paginate_button {
        margin-right: 5px;
        padding: 0;
        height: 32px;
        width: 32px;
        border: 1px solid transparent !important;
        box-sizing: border-box;
        color: $p_color !important;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 31px;
        outline: none;
        box-shadow: none;
        background: transparent !important;
        border-radius: 3px !important;
        &.previous {
          margin-right: 15px;
          width: auto;
          border: none !important;
        }
        &.next {
          margin-right: 0;
          margin-left: 10px;
          width: auto;
          border: none !important;
        }
        &:hover {
          border-color: $black_50 !important;
          background: transparent;
          color: #54595d !important;
        }
        &.current {
          box-shadow: none !important;
          background: var(--brand_color) !important;
          color: #fff !important;
          border-color: var(--brand_color) !important;
        }
      }

  }
}

table.dataTable {
	tbody {
		tr {
			background: var(--bs-white);
		}
	}
}


/* 3d video Slider Area css
============================================================================================ */
.flipvideo_area {
  .slider-wrapper.no-padding {
    .js-slider--main {
      padding-top: 7px;
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        background-image: unset;
      }
      .swiper-slide.swiper-slide-prev,
      .swiper-slide.swiper-slide-next {
        position: relative;
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.6);
          position: absolute;
          left: 0;
          top: 3px;
          width: 100%;
          height: 98%;
        }
      }
    }
    .swiper-container.js-slider--nav {
      padding-bottom: 0;
      .swiper-button-prev {
        left: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      .swiper-button-next {
        right: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0;
        @include transition;
        background: var(--brand_color);

        &:after {
          font-size: 16px;
        }
      }
      &:hover {
        .swiper-button-prev,
        .swiper-button-next {
          opacity: 1;
        }
      }
    }
  }
}
.swiper-pagination-bullet {
  border: 1px solid #1da1f2;
}
.swiper-pagination-bullet-active {
  background: #1da1f2;
}
// img:not([draggable]),
// embed,
// object,
// video {
//   max-width: 100%;
//   height: 100%;
// }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper-container.one {
  padding-top: 40px;
  text-align: center;
}

.swiper-container.one .swiper-slide {
  padding: 0 43px;
}

.swiper-container {
  width: 100%;
  padding-bottom: 60px;
}

.swiper-slide img {
  display: block;
  margin: auto;
  width: 100%;
}

.js-slider--main .swiper-slide {
  width: 390px;
  height: auto;
  padding: 0 0;
}
.swiper-slide .slider-image .preview-icon {
  z-index: -1;
  width: calc(100% - 30px);
}

.swiper-slide.swiper-slide-active .slider-image:hover .preview-icon {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-pagination-bullet {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ee0f6f;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 26px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.swiper-pagination-bullet-active {
  background: rgba(0, 0, 0, 0) -webkit-linear-gradient(
      left,
      #ee0f6f 0%,
      #f89482 100%
    ) repeat scroll 0 0;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to right, #ee0f6f 0%, #f89482 100%) repeat scroll 0 0;
  border: medium none;
  height: 12px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  width: 12px;
}

/* End 3d video Slider Area css
============================================================================================ */
